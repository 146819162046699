import * as Yup from 'yup'
import {TFunction} from 'i18next'

export const getValidationSchema = (t: TFunction) => {
  return Yup.object().shape({
    title: Yup.string()
      .required(t`settings.schemas.helpers.validation.title.required`)
      .min(3, t`settings.schemas.helpers.validation.min`)
      .max(50, t`settings.schemas.helpers.validation.max`),
  })
}

export const getTextValues = (t: TFunction) => ({
  create: {
    header: t`settings.schemas.create.header`,
    successMsg: t`settings.schemas.create.successMsg`,
    loadingText: t`settings.schemas.create.loadingText`,
    actionText: t`settings.schemas.create.actionText`,
  },
  update: {
    header: t`settings.schemas.update.header`,
    successMsg: t`settings.schemas.update.successMsg`,
    loadingText: t`settings.schemas.update.loadingText`,
    actionText: t`settings.schemas.update.actionText`,
  },
})
