const exportDefaults = `print`

export const ooh = {
  billboard: {
    sizes: {
      large2x1: {
        label: `Billboard — output at 20×10m`,
        width: 1000,
        height: 500,
        exportDefaults,
      },
    },
  },
  verticalSignage: {
    sizes: {
      rollUp: {
        label: `Roll Up — output at 850×2000m`,
        width: 425,
        height: 1000,
        exportDefaults,
      },
    },
  },
} as const
