import {HTMLAttributes} from 'react'

export type AsteriskIntent = keyof typeof intentClasses

interface Props extends HTMLAttributes<HTMLElement> {
  intent?: AsteriskIntent
}

const successClassName = `
  text-green-400
  dark:text-green-500
`

const errorClassName = `
  text-red-400
  dark:text-red-400
`

const warningClassName = `
  text-yellow-400
  dark:text-yellow-500
`

const defaultClassName = `
  text-inherit
`

export const intentClasses = {
  success: successClassName,
  error: errorClassName,
  warning: warningClassName,
  default: defaultClassName,
} as const

export const Asterisk = ({intent = `default`}: Props) => {
  return (
    <span
      className={`
        ${intentClasses[intent]}
        mr-1
        inline-block
      `}
    >
      *
    </span>
  )
}

export default Asterisk
