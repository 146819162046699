import {gql} from '@apollo/client'

export const DISCONNECT_SOCIAL = gql`
  mutation DisconnectSocial($input: DisconnectSocialInput!)
  @api(name: "socialIntegrations") {
    disconnectSocial(input: $input) {
      success
      message
      deletedAt
    }
  }
`

export const EXCHANGE_TOKEN = gql`
  mutation ExchangeToken($input: ExchangeTokenInput!)
  @api(name: "socialIntegrations") {
    exchangeToken(input: $input) {
      success
      message
      expiresAt
    }
  }
`

export const EXPORT_SOCIAL = gql`
  mutation createImagePost($input: PostImageInput!)
  @api(name: "socialIntegrations") {
    createImagePost(input: $input) {
      success
      message
      createdAt
    }
  }
`
