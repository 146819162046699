import {ToggleInput} from '@myadbox/gatsby-theme-nebula'

import {UseAccountOutput} from '@myadbox/nebula-service-api'
import {FadeIn, LoaderIcon, Text} from '@myadbox/stellar-ui'
import {useTranslation} from 'gatsby-plugin-react-i18next'

type Props = {
  accountData: UseAccountOutput
}

const AccountWhiteLabel = ({accountData}: Props) => {
  const {t} = useTranslation(`settings`, {
    keyPrefix: `settings.account`,
  })
  const {account, toggleWhiteLabelResponse, toggleWhiteLabel} = accountData
  const isWhiteLabelled = account?.configuration?.isWhiteLabelled ?? false

  return (
    <span
      className={`
        flex
        items-baseline
        gap-2
      `}
    >
      <div className={`relative top-1`}>
        <ToggleInput
          id="isWhiteLabelled"
          name="isWhiteLabelled"
          on={isWhiteLabelled}
          disabled={toggleWhiteLabelResponse?.loading}
          onChange={e => {
            toggleWhiteLabel()
          }}
        />
      </div>
      {isWhiteLabelled && (
        <FadeIn>
          <Text color="textSecondary">{t`whiteLabel.enabled`}</Text>
        </FadeIn>
      )}
      {toggleWhiteLabelResponse?.loading && <LoaderIcon size={16} />}
    </span>
  )
}

export default AccountWhiteLabel
