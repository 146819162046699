import {
  AcademicCapIcon,
  BanknotesIcon,
  CalendarDaysIcon,
  CalendarIcon,
  ChartPieIcon,
  ClipboardDocumentCheckIcon,
  CurrencyDollarIcon,
  FolderIcon,
  HomeIcon,
  SwatchIcon,
  ViewColumnsIcon,
} from '@heroicons/react/24/outline'
import {
  AcademicCapIcon as AcademicCapSolidIcon,
  BanknotesIcon as BanknotesSolidIcon,
  CalendarDaysIcon as CalendarDaysSolidIcon,
  CalendarIcon as CalendarSolidIcon,
  ChartPieIcon as ChartPieSolidIcon,
  ClipboardDocumentCheckIcon as ClipboardDocumentCheckSolidIcon,
  CurrencyDollarIcon as CurrencyDollarSolidIcon,
  FolderIcon as FolderSolidIcon,
  HomeIcon as HomeSolidIcon,
  SwatchIcon as SwatchSolidIcon,
  ViewColumnsIcon as ViewColumnsSolidIcon,
} from '@heroicons/react/24/solid'
import type {ElementType, HTMLAttributes, ReactElement} from 'react'
import {memo} from 'react'
import type {RequiredMenuProps} from '../types'
import {ExternalLinkType, InternalLinkType} from '../types'
import MenuItem from './MenuItem'

type IconType = {
  [key: string]: {
    default: ElementType
    active: ElementType
  }
}

type CombinedLink =
  | (InternalLinkType & {type: `internal`})
  | (ExternalLinkType & {type: `external`})

const icons = {
  home: {
    default: HomeIcon,
    active: HomeSolidIcon,
  },
  assets: {
    default: FolderIcon,
    active: FolderSolidIcon,
  },
  brand: {
    default: SwatchIcon,
    active: SwatchSolidIcon,
  },
  reports: {
    default: ChartPieIcon,
    active: ChartPieSolidIcon,
  },
  funds: {
    default: BanknotesIcon,
    active: BanknotesSolidIcon,
  },
  planner: {
    default: CalendarDaysIcon,
    active: CalendarDaysSolidIcon,
  },
  awards: {
    default: AcademicCapIcon,
    active: AcademicCapSolidIcon,
  },
  workflows: {
    default: ViewColumnsIcon,
    active: ViewColumnsSolidIcon,
  },
  approvals: {
    default: ClipboardDocumentCheckIcon,
    active: ClipboardDocumentCheckSolidIcon,
  },
  plannerTitan: {
    default: CalendarIcon,
    active: CalendarSolidIcon,
  },
  finance: {
    default: CurrencyDollarIcon,
    active: CurrencyDollarSolidIcon,
  },
} as IconType

interface MenuProps extends HTMLAttributes<HTMLElement>, RequiredMenuProps {
  mobile?: boolean
}

const order = [
  `home`,
  `assets`,
  `brand`,
  `reports`,
  `funds`,
  `planner`,
  `awards`,
  `workflows`,
  `approvals`,
  `plannerTitan`,
  `finance`,
]

const Menu = ({
  className = ``,
  mobile,
  internalLinks,
  externalLinks,
}: MenuProps) => {
  // Merge internal and external links and keep a common structure
  const allLinks: CombinedLink[] = [
    ...internalLinks.map(link => ({...link, type: `internal` as const})),
    ...(externalLinks?.map(link => ({...link, type: `external` as const})) ||
      []),
  ]
  // Sort links based on the `order` array
  const sortedLinks = allLinks.sort((a, b) => {
    return order.indexOf(a.feature) - order.indexOf(b.feature)
  })

  return (
    <nav
      className={`
        ${className}
        flex-1
        gap-px
      `}
    >
      {sortedLinks.map(link => {
        if (link.type === `internal`) {
          return (
            <MenuItem
              key={link.to}
              mobile={mobile}
              to={link.to}
              defaultIcon={
                icons[link.feature].default as unknown as ReactElement
              }
              activeIcon={icons[link.feature].active as unknown as ReactElement}
              label={link.label}
              isActive={link.isActive}
              as={link.as}
            />
          )
        } else {
          return (
            <MenuItem.External
              key={link.href}
              mobile={mobile}
              href={link.href}
              defaultIcon={
                icons[link.feature].default as unknown as ReactElement
              }
              activeIcon={icons[link.feature].active as unknown as ReactElement}
              label={link.label}
              target={link.target}
              rel={link.rel}
            />
          )
        }
      })}
    </nav>
  )
}

export default Menu

export const MemoisedMenu = memo(Menu)
