import {CheckboxProps, checkboxClassName} from '@myadbox/stellar-ui'
import {Field} from 'formik'

/**
 *
 * TODO: Needs a refactor to something simpler like:
 * ```tsx
 *  <Field
 *   component={Checkbox}
 *   {...props}
 * />
 * ```
 */
const CheckboxFormik = ({id, name, className, ...props}: CheckboxProps) => {
  return (
    <Field
      name={name}
      type="checkbox"
      className={`${checkboxClassName} ${className}`}
      id={id}
      {...props}
    />
  )
}

export default CheckboxFormik
