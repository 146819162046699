import {HTMLAttributes} from 'react'

const Wrap = ({
  className = ``,
  children,
  ...props
}: HTMLAttributes<HTMLElement>) => {
  return (
    <div
      {...props}
      className={`
        ${className}
        flex
        min-h-full
        flex-col
      `}
    >
      {children}
    </div>
  )
}

export default Wrap
