import {useLazyQuery, useMutation} from '@apollo/client'
import {useCallback} from 'react'
import {ADD_EXTERNAL_SHARES, DELETE_EXTERNAL_SHARE} from '../../mutations'
import {GET_EXTERNAL_SHARES, VERIFY_EXTERNAL_SHARE_TOKEN} from '../../queries'
import type {ExternalShareInput, UseExternalShareOutput} from '../../types'
import {
  addExternalSharesUpdater,
  deleteExternalShareUpdater,
} from '../../updaters'

export const useExternalShares = (): UseExternalShareOutput => {
  const [getExternalShares, fetchExternalSharesResponse] =
    useLazyQuery(GET_EXTERNAL_SHARES)
  const fetchExternalShares = useCallback(
    (assetIds: string[]) => {
      getExternalShares({variables: {assetIds: [...assetIds].sort()}})
    },
    [getExternalShares]
  )

  const [doVerifyShareToken, verifyShareTokenResponse] = useLazyQuery(
    VERIFY_EXTERNAL_SHARE_TOKEN
  )
  const verifyShareToken = useCallback(
    (token: string) => {
      return doVerifyShareToken({
        variables: {token},
      })
    },
    [doVerifyShareToken]
  )

  const [doAddExternalShares, addExternalSharesResponse] =
    useMutation(ADD_EXTERNAL_SHARES)
  const addExternalShares = useCallback(
    (input: ExternalShareInput) => {
      const sortedInputAssetIds = [...input.assetIds].sort()

      return doAddExternalShares({
        variables: {input},
        update: addExternalSharesUpdater(sortedInputAssetIds),
      })
    },
    [doAddExternalShares]
  )

  const [doDeleteExternalShare, deleteExternalShareResponse] = useMutation(
    DELETE_EXTERNAL_SHARE
  )
  const deleteExternalShare = useCallback(
    (id: string, assetIds: string[]) => {
      const sortedAssetIds = [...assetIds].sort()

      return doDeleteExternalShare({
        variables: {id},
        update: deleteExternalShareUpdater(sortedAssetIds),
      })
    },
    [doDeleteExternalShare]
  )

  return {
    addExternalShares,
    addExternalSharesResponse,
    fetchExternalShares,
    fetchExternalSharesResponse,
    deleteExternalShare,
    deleteExternalShareResponse,
    verifyShareToken,
    verifyShareTokenResponse,
  }
}
