import type {JwtToken} from '@myadbox/nebula-service-api'
import {useContext, useDebugValue} from 'react'
import {UserSessionContext} from './UserSessionProvider'

export const useUserSession = () => {
  const userSession = useContext<JwtToken>(UserSessionContext)

  useDebugValue(`User session: ${userSession}`)
  return (
    userSession || {
      account: null,
      cognitoUser: null,
      isAccountAdmin: false,
      owner: false,
    }
  )
}

export default useUserSession
