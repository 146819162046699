import {User, useProfiles} from '@myadbox/nebula-service-api'
import {createContext, useEffect, useState} from 'react'
import {getDefaultTimezone} from '../../utils/timezone'

export const UserContext = createContext<User | undefined>(undefined)
UserContext.displayName = `UserContext`

const getTzProfile = (profile: User) => ({
  ...profile,
  timezone: profile?.timezone || getDefaultTimezone(),
})
interface Props {
  children: React.ReactNode | React.ReactNode[]
}

export const UserProvider = ({children}: Props) => {
  const {profile} = useProfiles()
  const [userProfile, setUserProfile] = useState<User>(getTzProfile(profile))

  useEffect(() => {
    profile?.userId && setUserProfile(getTzProfile(profile))
  }, [profile])

  return (
    <UserContext.Provider value={userProfile}>{children}</UserContext.Provider>
  )
}
