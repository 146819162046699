import {WalletIcon} from '@heroicons/react/24/outline'
import {
  Banner,
  Divider,
  TableEmptyState,
  Text,
} from '@myadbox/gatsby-theme-nebula'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {useEffect} from 'react'

import {
  AccountBilling,
  Invoice,
  isSesimiAdmin,
  useAccount,
} from '@myadbox/nebula-service-api'
import BillingHistoryTable from './BillingHistoryTable'

export type AccountBillingFormProps = {
  accountBilling: AccountBilling
  fetchInvoices: () => void
  createAndSendInvoiceData: string
  errorMsg: string
  invoices: Invoice[]
  invoicesLoading: boolean
}

export const AccountBillingForm = ({
  accountBilling,
  fetchInvoices,
  createAndSendInvoiceData,
  invoices,
  invoicesLoading,
  errorMsg,
}: AccountBillingFormProps) => {
  const {t} = useTranslation()
  const {account} = useAccount()

  useEffect(() => {
    if (accountBilling?.billing?.stripeCustomerId || createAndSendInvoiceData) {
      fetchInvoices()
    }
  }, [accountBilling?.billing, createAndSendInvoiceData, fetchInvoices])
  if (!account || !accountBilling) {
    return (
      <div className={`font-style:italic`}>
        <Text variant="subHeading">{t`settings.billing.accountBilling.doesNotExist`}</Text>
      </div>
    )
  }

  return (
    <>
      {errorMsg && (
        <div className="col-span-3">
          <Banner intent="error">{errorMsg}</Banner>
        </div>
      )}
      <div className="py-2 align-middle">{t`settings.billing.accountBilling.plan`}</div>
      <div className="py-2 align-middle">
        <Text>
          {t(
            `settings.billing.accountBilling.${account.configuration.subscriptionType}`
          )}
        </Text>
      </div>

      {isSesimiAdmin() && (
        <div className={`flex justify-end gap-2 py-2 align-middle`}></div>
      )}
      <div className="col-start-1 py-2">{t`settings.billing.accountBilling.price`}</div>
      <div className="py-2">
        <Text>
          {accountBilling.billing &&
            t(`settings.billing.currency.${accountBilling.billing.currency}`, {
              amount: accountBilling.billing.price / 100,
            })}
        </Text>
      </div>
      <div className="col-start-1 py-2">{t`settings.billing.accountBilling.billingEmail`}</div>

      <div className="py-2">
        <Text>{accountBilling.billing?.billingEmail}</Text>
      </div>

      <div className="col-start-1">{t`settings.billing.accountBilling.paymentTerm`}</div>
      <div className="py-2">
        <Text>{accountBilling.billing?.paymentTerm}</Text>
      </div>
      <Divider spanGrid />

      <div className="col-span-3">
        <div>
          <Text variant="heading">{t`settings.billing.accountBilling.invoiceHistory`}</Text>
        </div>
        <div>
          {invoices?.length ? (
            <BillingHistoryTable
              loading={invoicesLoading}
              invoices={invoices}
              billing={accountBilling?.billing}
            />
          ) : (
            <TableEmptyState
              icon={<WalletIcon width={36} height={36} />}
              text={t`settings.billing.accountBilling.invoiceEmptyState`}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default AccountBillingForm
