import {Legend, Pie, Progress, Text} from '@myadbox/gatsby-theme-nebula'
import {Segment} from '@myadbox/gatsby-theme-nebula/types'
import {useAccount, useAssets} from '@myadbox/nebula-service-api'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import prettyBites from 'pretty-bytes'
import {useEffect} from 'react'

export const AccountStorage = () => {
  const {t} = useTranslation()
  const {
    fetchAssetStats,
    fetchAssetStatsResults: {data},
  } = useAssets()
  const {account} = useAccount()

  useEffect(() => {
    fetchAssetStats()
  }, [fetchAssetStats])
  const storageLimit = account?.billing?.limits?.storage
  const storageLimitBytes = (storageLimit || 0) * 1000000000
  const hasStorageLimit = Boolean(storageLimit && storageLimit > 0)
  const hasData = !!data?.stats
  let segmentsTotal = 0
  let dataCountTotal = 0

  const legendSegments = []
  const segments: Segment[] = hasData
    ? Object.keys(data.stats).map(key => {
        segmentsTotal += data.stats[key].bytes
        dataCountTotal += data.stats[key].count
        legendSegments.push({
          value: data.stats[key].count,
          label: key,
          value2: prettyBites(data.stats[key].bytes),
        })
        return {
          value: data.stats[key].bytes,
          label: key,
        }
      })
    : [{value: 0, label: ``}]

  const storagePercentage = Math.round(
    (segmentsTotal / storageLimitBytes) * 100
  )

  if (hasData && legendSegments.length === 0) {
    return (
      <div className={`italic`}>
        <Text variant="subHeading">{t`storage.noAssets`}</Text>
      </div>
    )
  }

  return (
    <div>
      {hasStorageLimit && (
        <div
          className={`
            mb-4
            w-full
            md:w-[50%]
          `}
        >
          <Progress
            label={t(`storage.progress`, {
              value: prettyBites(segmentsTotal),
              max: prettyBites(storageLimitBytes),
            })}
            labelPos="right"
            value={segmentsTotal}
            max={storageLimitBytes}
          />
        </div>
      )}
      <div className={`flex`}>
        <div
          className={`
            w-18
            mr-4
            xl:w-[10%]
          `}
        >
          {hasData ? (
            <Pie
              segments={segments}
              total={storageLimitBytes}
              autoComplete={hasStorageLimit}
            >
              {hasStorageLimit && (
                <div className={`text-center`}>
                  <div
                    className={`
                      text-ui-900
                      dark:text-ui-900
                      text-sm
                      font-medium
                    `}
                  >
                    {storagePercentage}%
                  </div>
                  <div className={`text-tiny`}>{t`storage.utilised`}</div>
                </div>
              )}
            </Pie>
          ) : (
            <Pie segments={segments} total={1} />
          )}
        </div>
        <div className={`grow`}>
          {hasData ? (
            <Legend
              header={{
                value: dataCountTotal,
                label: t`storage.assets`,
                value2: storageLimit
                  ? prettyBites(storageLimitBytes)
                  : undefined,
              }}
              segments={legendSegments}
            />
          ) : (
            <Text variant="subHeading">{t`storage.loading`}</Text>
          )}
        </div>
      </div>
    </div>
  )
}

export default AccountStorage
