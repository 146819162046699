import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import {transResources} from './'

const nebulaInstance = i18n.createInstance()
const languageDetector = new LanguageDetector(null, {
  lookupLocalStorage: `gatsby-i18next-language`,
})

nebulaInstance.use(languageDetector).init({
  resources: transResources,
  fallbackLng: `en-AU`,
  interpolation: {
    escapeValue: false,
  },
})

export const handleLanguageChange = async () => {
  await nebulaInstance.changeLanguage()
}

export default nebulaInstance
