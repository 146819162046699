import {sesimiBrandColors} from '../../../styles/config/colors'

export const nullColor = `var(--ui-border)`

export const onBrandColors = [
  sesimiBrandColors.blue[600],
  sesimiBrandColors.blue[400],
  sesimiBrandColors.red[500],
  sesimiBrandColors.lime[600],
  sesimiBrandColors.green[400],
  sesimiBrandColors.yellow[400],
]

export const getOnBrandColor = (title: string): string => {
  return title ? onBrandColors[title.length % onBrandColors.length] : nullColor
}

export const getInitials = (title: string | null): string => {
  if (!title) {
    return ``
  }

  const titles = title.split(` `)
  if (titles.length > 1) {
    return titles[0].charAt(0) + titles[1].charAt(0)
  }
  return title.charAt(0)
}
