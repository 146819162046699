import Pusher, {Options, Channel} from 'pusher-js'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import {useRef, MutableRefObject, useEffect} from 'react'
import {getPusher} from './helpers'

interface DecodedUser {
  account: {id: string}
  cognitoUser: {username: string}
}

interface UsePusherInput {
  channelName: string
  eventType: string
  callback: (data?: unknown) => unknown
  init?: boolean
  debug?: boolean
}

interface UsePusherOutput {
  pusherRef: MutableRefObject<Pusher>
  channelRef: MutableRefObject<Channel>
}

export const pusherOptions: Options = {
  cluster: `eu`,
}

const usePusher = ({
  channelName,
  eventType,
  callback,
  init = true,
  debug = false,
}: UsePusherInput): UsePusherOutput => {
  const pusherRef = useRef<Pusher>(null)
  const channelRef = useRef<Channel>(null)
  const fullChannelNameRef = useRef<string>(null)

  useEffect(() => {
    return () => {
      channelRef.current?.unbind(eventType)
      pusherRef.current?.unsubscribe(channelName)
    }
  }, [channelName, eventType])

  useEffect(() => {
    Pusher.logToConsole = debug

    const token = Cookies.get(`nf_jwt`)
    if (!token || !init) return

    const {
      account: {id},
      cognitoUser: {username},
    } = jwtDecode<DecodedUser>(token)

    const fullName = `${id}-${username}-${channelName}`
    if (!pusherRef.current && fullName !== fullChannelNameRef.current) {
      pusherRef.current = getPusher(pusherOptions)
      fullChannelNameRef.current = fullName
      channelRef.current = pusherRef.current.subscribe(
        fullChannelNameRef.current
      )
      channelRef.current.bind(eventType, data => {
        callback(data)
      })
    }
  }, [init, channelName, eventType, callback, debug])

  return {
    channelRef,
    pusherRef,
  }
}

export default usePusher
