import {ChevronDownIcon} from '@heroicons/react/20/solid'
import {LoaderIcon} from '@myadbox/stellar-ui'
import {
  ButtonHTMLAttributes,
  ReactElement,
  createContext,
  useContext,
} from 'react'
import {noop} from '../../utils/noop'
import LocalisedLink from '../LocalisedLink'

import {
  Menu as ReachMenu,
  MenuButton as ReachMenuButton,
  MenuItem as ReachMenuItem,
  MenuLink as ReachMenuLink,
  MenuList as ReachMenuList,
} from '@reach/menu-button'
import {
  buttonBase,
  buttonDisabled,
  buttonLink,
  buttonVariantPrimary,
  buttonVariantSecondary,
  iconWrap,
  menuItem,
  menuItemDisabled,
  menuList,
} from './classes'

// no support for classNames for certain ReachMenu selectors
import useIsMounted from '../../hooks/useIsMounted'
import './dropdown-overrides.css'
import {SizeClassNames} from './helpers'

const SizeContext = createContext<SizeClassNames>(``)
const useSizeContext = (): SizeClassNames => useContext(SizeContext)

interface DropdownButtonProps extends ButtonHTMLAttributes<HTMLElement> {
  variant?: `primary` | `secondary`
  ariaLabel?: string
  unstyled?: boolean
  loading?: boolean
  loadingText?: string
  onButtonClick?: (args: unknown) => void
  hideIcon?: boolean
  href?: string
  id: string
}

const DropdownButton = ({
  className = ``,
  children,
  variant = `primary`,
  ariaLabel,
  disabled,
  unstyled,
  onButtonClick,
  href,
  hideIcon,
  loading,
  loadingText = `Loading...`,
  ...props
}: DropdownButtonProps) => {
  const styles = {
    base: buttonBase,
    variant: {
      primary: buttonVariantPrimary,
      secondary: buttonVariantSecondary,
    },
    disabled: buttonDisabled,
  }

  const sizeClassNames = useSizeContext()

  const classNames = `
    ${styles.base}
    ${styles.variant[variant]}

    ${disabled || loading ? styles.disabled : ``}
  `

  const icon = loading ? (
    <LoaderIcon size={16} />
  ) : (
    <ChevronDownIcon width={18} height={18} />
  )

  const content = loading ? loadingText : children
  return onButtonClick || href ? (
    <div className={unstyled ? className : classNames}>
      {href ? (
        <ReachMenuLink
          disabled={disabled}
          href={href}
          rel="noreferrer"
          target="_blank"
          className={buttonLink}
          onClick={onButtonClick}
        >
          {children}
        </ReachMenuLink>
      ) : (
        content && (
          <button
            type="button"
            className={`px-3 py-2 leading-5`}
            disabled={disabled || loading}
            onClick={onButtonClick}
            aria-label={ariaLabel}
          >
            {content}
          </button>
        )
      )}
      {unstyled || hideIcon ? null : (
        <ReachMenuButton
          type="button"
          disabled={disabled || loading}
          className={`${iconWrap(variant)} py-2`}
          {...props}
        >
          {icon}
        </ReachMenuButton>
      )}
    </div>
  ) : (
    <ReachMenuButton
      type="button"
      disabled={disabled}
      className={`${sizeClassNames} ${
        unstyled ? className : `${classNames} pl-4`
      } py-2`}
      {...props}
    >
      <span className={unstyled ? `` : `mr-4`}>{content}</span>
      {unstyled ? null : <span className={iconWrap(variant)}>{icon}</span>}
    </ReachMenuButton>
  )
}

type DropdownItemProps = {
  onSelect?: (args?: unknown) => void
  disabled?: boolean
  to?: string
  as?: typeof LocalisedLink
  children?: React.ReactNode
  href?: string
}

const DropdownItem = ({
  children,
  onSelect = noop,
  to,
  as,
  disabled,
  href,
}: DropdownItemProps) => {
  if (href) {
    return (
      <ReachMenuLink
        disabled={disabled}
        href={href}
        rel="noreferrer"
        target="_blank"
        className={`no-underline ${disabled ? menuItemDisabled : menuItem}`}
      >
        {children}
      </ReachMenuLink>
    )
  }
  return to && as ? (
    <ReachMenuLink
      disabled={disabled}
      as={as}
      to={to}
      className={disabled ? menuItemDisabled : menuItem}
    >
      {children}
    </ReachMenuLink>
  ) : (
    <ReachMenuItem
      disabled={disabled}
      onSelect={onSelect}
      className={disabled ? menuItemDisabled : menuItem}
    >
      {children}
    </ReachMenuItem>
  )
}

interface DropdownProps {
  trigger: ReactElement
  children: React.ReactNode | React.ReactNode[]
  /**
   * `triggerAppearance` is an optional element rendered **behind** the actual trigger button
   * without taking up any space in the layout (ie. it is position:absolute).
   * This (in combination with `triggerSizeClassNames`) allows you to combat layout shifting
   * caused by ReachMenu needing to await an `isMounted()` check before rendering.
   */
  triggerAppearance?: React.ReactNode
  /**
   * This optional prop allows you to pass SizeClassNames
   * which will reserve the space needed to render the trigger,
   * even when it hasn't been rendered yet. This is useful for
   * preventing layout jank.
   *
   * Note, triggerSizeClassNames is used by child components via context.
   *
   * @example
   * ```tsx
   * const sizeClassNames: SizeClassNames = `w-[2rem] h-[2rem]`
   * ...
   * <Dropdown triggerSizeClassNames={sizeClassNames}>
   *   { ... }
   * </Dropdown>
   * ```
   */
  triggerSizeClassNames?: SizeClassNames | ``
}

const Dropdown = ({
  children,
  trigger,
  triggerAppearance,
  triggerSizeClassNames = ``,
}: DropdownProps) => {
  const isMounted = useIsMounted()
  return (
    <SizeContext.Provider value={triggerSizeClassNames}>
      <div className={`${triggerSizeClassNames} group relative`}>
        {isMounted() && (
          <ReachMenu>
            {({isExpanded}) => (
              <>
                {trigger}
                {isExpanded ? (
                  <ReachMenuList className={menuList}>{children}</ReachMenuList>
                ) : null}
              </>
            )}
          </ReachMenu>
        )}

        {triggerAppearance}
      </div>
    </SizeContext.Provider>
  )
}

const DropdownTriggerPlaceholder = ({
  children,
  className = ``,
}: {
  children: React.ReactNode
  className?: string
}) => {
  const sizeClassNames = useSizeContext()
  return (
    <span
      className={`pointer-events-none absolute inset-0 ${sizeClassNames} ${className}`}
    >
      {children}
    </span>
  )
}

Dropdown.Button = DropdownButton
Dropdown.Item = DropdownItem
Dropdown.TriggerPlaceholder = DropdownTriggerPlaceholder

export default Dropdown
