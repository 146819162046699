import {FC, HTMLAttributes} from 'react'

const TheWrap: FC<HTMLAttributes<HTMLElement>> = ({
  className = ``,
  children,
  ...props
}) => {
  return (
    <div
      {...props}
      className={`
        ${className}
        flex
        min-h-full
        flex-col
      `}
    >
      {children}
    </div>
  )
}

export default TheWrap
