import {gql} from '@apollo/client'

export const APPROVER = gql`
  fragment ApproverInfo on Approver {
    approverId
    approverType
    approvalStatus
    approvedByUserId
    updatedAt
  }
`

export const REQUESTER = gql`
  fragment RequesterInfo on Approval {
    requesterId
    requesterType
  }
`
