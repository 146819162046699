import {
  ChevronLeftIcon as IconLeft,
  ChevronRightIcon as IconRight,
} from '@heroicons/react/24/outline'
import FocusTrap from 'focus-trap-react'
import noScroll from 'no-scroll'
import {FC, HTMLAttributes, ReactNode, useEffect, useRef, useState} from 'react'
import ReactDOM from 'react-dom'
import {Transition} from 'react-transition-group'
import Footer from './Footer'
import Header from './Header'

type GalleryItem = {
  title: string
  preview: ReactNode
}

interface GalleryProps {
  trigger: (props: {open(): void}) => ReactNode
  accessibleTitle?: string
  galleryItems: GalleryItem[]
}

const transitions = {
  entering: `opacity-0`,
  entered: `opacity-100`,
  exiting: `opacity-0`,
}

const duration = 200

const Gallery: FC<GalleryProps> = ({galleryItems, trigger}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeItem, setActiveItem] = useState(0)
  const [isVisible, setIsVisible] = useState(false)
  const timer = useRef(null)

  // prevent netlify SSR errors on build
  if (typeof window === `undefined`) return null

  const open = (): void => {
    setIsOpen(true)
    setIsVisible(true)
    noScroll.on()
  }

  const close = (): void => {
    setIsVisible(false)
    window?.clearTimeout(timer.current)
    timer.current = window?.setTimeout(() => {
      setIsOpen(false)
    }, duration)
    noScroll.off()
  }

  const goPrev = (currentActive: number): void => {
    setActiveItem(
      currentActive == 0 ? galleryItems.length - 1 : currentActive - 1
    )
  }

  const goNext = (currentActive: number): void => {
    setActiveItem(
      currentActive == galleryItems.length - 1 ? 0 : currentActive + 1
    )
  }

  return (
    <>
      {trigger({open})}
      <GalleryPortal>
        <Transition timeout={duration} in={isVisible}>
          {(transitionState: string): ReactNode =>
            isOpen && (
              <FocusTrap active={isOpen}>
                <div>
                  <Overlay close={close} transitionState={transitionState} />
                  <div
                    className={`
                      text-ui-300
                      dark-mode
                      pointer-events-none
                      fixed
                      left-0
                      top-0
                      z-50
                      grid
                      h-full
                      w-full
                      grid-cols-1
                      grid-rows-[72px,1fr,72px]
                      justify-between
                      ${transitions[transitionState] || `opacity-0`}
                    `}
                  >
                    <Header
                      title={galleryItems[activeItem]?.title}
                      close={close}
                    />
                    <Preview
                      goPrev={goPrev}
                      goNext={goNext}
                      activeItem={activeItem}
                      galleryItems={galleryItems}
                    />
                    <Footer
                      pageNum={`${activeItem + 1}/${galleryItems.length}`}
                    />
                  </div>
                </div>
              </FocusTrap>
            )
          }
        </Transition>
      </GalleryPortal>
    </>
  )
}

type GalleryPortalProps = HTMLAttributes<HTMLElement>

export const GalleryPortal: FC<GalleryPortalProps> = ({children}) => {
  const appendedDivRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (typeof document === `undefined`) return
    const root = document.getElementById(`___gatsby`)
    const div = (appendedDivRef.current = document.createElement(`div`))

    root.appendChild(div)
    return (): void => {
      root.removeChild(div)
    }
  }, [])

  return appendedDivRef.current
    ? ReactDOM.createPortal(children, appendedDivRef.current)
    : null
}

interface OverlayProps extends HTMLAttributes<HTMLElement> {
  transitionState: string
  close: () => void
}

const Overlay: FC<OverlayProps> = ({transitionState, close}) => (
  <div
    data-testid="overlay"
    className={`
      backdrop-blur-5
      duration-400
      bg-ui-950/95
      fixed
      inset-0
      z-50
      flex
      h-screen
      w-screen
      cursor-pointer
      items-center
      justify-center
      overflow-y-auto
      overflow-x-hidden
      ${transitions[transitionState] || `opacity-0`}
    `}
    onClick={(): void => close()}
  ></div>
)
interface PreviewProps extends HTMLAttributes<HTMLElement> {
  goPrev: (currentActive: number) => void
  goNext: (currentActive: number) => void
  activeItem: number
  galleryItems: GalleryItem[]
}

const Preview: FC<PreviewProps> = ({
  goPrev,
  goNext,
  activeItem,
  galleryItems,
}) => (
  <div
    className={`
      grid
      w-full
      grid-cols-[2rem_1fr_2rem]
      grid-rows-1
      items-center
      justify-between
    `}
  >
    <button
      aria-label="Previous"
      onClick={(): void => goPrev(activeItem)}
      className={`
        text-ui-300
        dark:text-ui-400
        hocus:text-ui-100
        dark:hocus:text-ui-400
        pointer-events-auto
        rounded-full
        p-0.5
      `}
    >
      <IconLeft width={32} height={32} />
    </button>
    <div
      className={`
        pointer-events-auto
        relative
        h-full
        max-h-full
        min-h-0
        min-w-0
        max-w-full
        items-stretch
        justify-center
        self-center
        justify-self-stretch
      `}
    >
      <div className={`absolute inset-0 flex justify-center`}>
        {galleryItems[activeItem].preview}
      </div>
    </div>
    <button
      aria-label="Next"
      onClick={(): void => goNext(activeItem)}
      className={`
        text-ui-300
        dark:text-ui-400
        hocus:text-ui-100
        dark:hocus:text-ui-500
        pointer-events-auto
        rounded-full
        p-0.5
      `}
    >
      <IconRight width={32} height={32} />
    </button>
  </div>
)

export default Gallery
