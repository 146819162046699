import type {FunctionComponent, HTMLAttributes, ReactElement} from 'react'
import {cloneElement, createElement, isValidElement} from 'react'

export interface HasConditionalIcon {
  defaultIcon: ReactElement
  activeIcon: ReactElement | FunctionComponent<unknown>
}

interface Props extends HTMLAttributes<SVGElement>, HasConditionalIcon {
  current?: boolean
}

export const ConditionalIcon = ({
  current = false,
  defaultIcon,
  activeIcon,
  ...props
}: Props) => {
  const icon = current ? activeIcon : defaultIcon

  let TheIcon: ReactElement | null = null
  // @ts-ignore-next-line
  if (typeof icon?.[`render`] === `function`) {
    TheIcon = createElement(icon as FunctionComponent<unknown>)
  } else if (isValidElement(icon)) {
    TheIcon = icon
  }

  return TheIcon
    ? cloneElement(TheIcon, {
        role: `presentation`,
        ...props,
      })
    : null
}

export default ConditionalIcon
