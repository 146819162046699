import classNames from 'classnames'
import {saveAs} from 'file-saver'
import {ReactElement, cloneElement} from 'react'
import {getToggledClassName} from '../components/Toggler/helpers'
import {CsvDataError} from './types'

// Using string literals to cast to string before calling string methods
export const toTitleCase = (str: string): string =>
  `${str}`
    .split(` `)
    .map(
      (w: string) => `${w[0]}`.toUpperCase() + `${w.substr(1)}`.toLowerCase()
    )
    .join(` `)

export const stripTrailingSlash = (path: string): string =>
  path.replace(/\/$/, ``)

export const slugifyText = (str: string): string =>
  `${str}`
    .toLowerCase()
    .trim()
    .replace(/ /g, `-`)
    .replace(/[^\w-]+/g, ``)

export {getToggledClassName}

// Returns function to be used as Children.map callback
export const createLastChildClassName =
  (lastClassName: string, children) => (child: ReactElement, i: number) => {
    const {
      props: {className: passedClassName = ``},
    } = child

    const className = classNames(passedClassName, {
      [lastClassName]: i < children.length - 1,
    })

    return cloneElement(child, {
      ...child.props,
      className,
    })
  }

export const isEmptyObject = (obj: unknown): boolean =>
  obj && Object.keys(obj).length === 0 && obj.constructor === Object

export const stripTrailingFileName = (fullPath: string): string => {
  const arr = fullPath.split(`/`)
  arr.pop()
  return arr.join(`/`)
}

export const isBrowser = () => {
  return typeof window !== `undefined`
}

export const downloadErrorTextFile = (
  errors: CsvDataError,
  name: string
): void => {
  const data = new Blob([JSON.stringify(errors)], {
    type: `text/csv;charset=utf-8`,
  })
  const filename = `${name}.txt`
  saveAs(data, filename)
}

export const startsWithSpecialChar = (str: string): boolean => {
  const pattern = /^[=\-@+]/

  return pattern.test(str)
}
