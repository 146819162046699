import {useCallback} from 'react'
import {useLazyQuery, useMutation} from '@apollo/client'
import {CREATE_AND_SEND_INVOICE} from '../../mutations'
import {FETCH_INVOICES} from '../../queries'
import {SendInvoiceMutator, UseBillingOutput} from '../../types'

export const useBilling = (): UseBillingOutput => {
  const [lazyFetchInvoices, fetchInvoicesResults] = useLazyQuery(FETCH_INVOICES)
  const fetchInvoices = useCallback(() => {
    lazyFetchInvoices()
  }, [lazyFetchInvoices])

  const [doCreateAndSendInvoice, createAndSendInvoiceResponse] = useMutation(
    CREATE_AND_SEND_INVOICE
  )

  const createAndSendInvoice: SendInvoiceMutator = useCallback(() => {
    return doCreateAndSendInvoice()
  }, [doCreateAndSendInvoice])

  return {
    createAndSendInvoice,
    createAndSendInvoiceResponse,
    fetchInvoices,
    fetchInvoicesResults,
  }
}
