const badgeBase = `
  text-ui-700
  leading-4
  relative
  inline-flex
  rounded-xl
  px-5
  py-1
  text-xs
  font-light
  w-fit
`

const badgeIntentInfo = `
  bg-ui-100
  text-ui-600
  dark:bg-ui-50
`

const badgeIntentSuccess = `
  bg-green-50
  text-green-600
  dark:text-green-400
  dark:bg-ui-50
`

const badgeIntentWarning = `
  bg-yellow-50
  text-yellow-600
  dark:text-yellow-500
  dark:bg-ui-50
`

const badgeIntentError = `
  bg-red-50
  text-red-600
  dark:text-red-400
  dark:bg-ui-50
`

const badgeIntentInfoContrast = `
  bg-ui-300
  text-ui-600
  dark:bg-ui-0
`

const badgeHasIcon = `
  gap-1
  flex
  items-center
`

export const badgeIntents = {
  info: badgeIntentInfo,
  success: badgeIntentSuccess,
  warning: badgeIntentWarning,
  error: badgeIntentError,
  infoContrast: badgeIntentInfoContrast,
}

export const getBadgeStyles = () => {
  return {
    base: badgeBase,
    intent: badgeIntents,
    hasIcon: badgeHasIcon,
  }
}
