import {FC, HTMLAttributes} from 'react'
import {getProgressColor} from './Progress.helper'

interface Props extends HTMLAttributes<HTMLProgressElement> {
  label: string
  labelPos?: `left` | `right`
  value: number
  max: number
  displayValue?: boolean
}

const Progress: FC<Props> = ({
  id,
  label,
  labelPos = `left`,
  value = 0,
  max = 1,
  displayValue = false,
}) => {
  const percent = Math.round((value / max) * 100)
  const progressColor = getProgressColor(percent)

  return (
    <div
      className={`
        flex
        items-center
        ${labelPos === `left` ? `flex-row` : `flex-row-reverse`}
        justify-between
      `}
    >
      <label
        htmlFor={id}
        className={`
          ${labelPos === `left` ? `mr-2` : `ml-2`}
        `}
      >
        {label}
      </label>
      <div
        className={`
          bg-ui-100
          relative
          inline-block
          h-4
          flex-1
          overflow-hidden
          rounded-full
        `}
      >
        <div
          className={`
            absolute
            left-0
            top-0
            flex
            h-full
            w-[--width-var-1]
            items-center
            justify-end
            text-right
            ${progressColor}
          `}
          style={{
            '--width-var-1': `${percent}%`,
          }}
        >
          {displayValue && percent > 25 && (
            <span
              className={`
                text-tiny
                px-1
              `}
            >
              {percent}%
            </span>
          )}
        </div>
        <progress id={id} value={value} max={max} className={`opacity-0`}>
          {percent}%
        </progress>
      </div>
    </div>
  )
}

export default Progress
