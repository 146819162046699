import {FC, ReactNode} from 'react'
import {Text, TextVariant} from '../Text/Text'
import {LegendSegment} from './Legend'

interface LegendLabelProps {
  item: LegendSegment
  spacer?: string
  labelVariant?: TextVariant
  children?: ReactNode
}

export const LegendLabel: FC<LegendLabelProps> = ({
  item: {label, value2, value},
  labelVariant = `bodySmall`,
  spacer = `|`,
  children,
}) => {
  return (
    <>
      {label && (
        <Text
          variant={labelVariant}
          tag="span"
          className={`
            min-w-0
            truncate
          `}
        >
          {children}
          {label}
        </Text>
      )}
      {value && (
        <Text
          variant="bodySmall"
          display="inline-block"
          tag="span"
          className={`
            ml-auto
            pl-4
            tabular-nums
          `}
        >
          {value}
        </Text>
      )}
      {value && value2 && (
        <span className={`mx-0.5`}>
          <Text variant="bodySmall" display="inline-block" tag="span">
            {spacer}
          </Text>
        </span>
      )}

      {value2 && (
        <Text
          variant="subHeading"
          display="inline-block"
          tag="span"
          className={`
            tabular-nums
          `}
        >
          {value2}
        </Text>
      )}
    </>
  )
}

export default LegendLabel
