import {enAU, enUS, frCA, id, ko, ptBR} from 'date-fns/locale'

const SUPPORTED_LOCALES = [
  `en-AU`,
  `en-US`,
  `fr-CA`,
  `id-ID`,
  `ko-KR`,
  `pt-BR`,
] as const
export type SupportedLocales = (typeof SUPPORTED_LOCALES)[number]

/**
 * This function is used to format string dates into Date objects.
 * Currently, the use case is mostly for the date picker
 * for a template field.
 * The format needs to follow the style of the output from
 * new Intl.DateTimeFormat('ko-KR', \{ dateStyle: 'short', timeStyle: 'short' \}).format(date))
 */
export const shortLocaleFormats: {
  dateAndTime: {[key in SupportedLocales]: string}
  date: Record<SupportedLocales, string>
} = {
  dateAndTime: {
    'en-AU': `dd/MM/yy, h:mm a`,
    'en-US': `MM/dd/yy, h:mm a`,
    'fr-CA': `yyyy-MM-dd, HH:mm`,
    'id-ID': `dd/MM/yy, HH:mm`,
    'ko-KR': `yy. MM. dd. a h:mm`,
    'pt-BR': `dd/MM/yy, HH:mm`,
  },
  date: {
    'en-AU': `dd/MM/yy`,
    'en-US': `MM/dd/yy`,
    'fr-CA': `yyyy-MM-dd`,
    'id-ID': `dd/MM/yyyy`,
    'ko-KR': `yy. MM. dd.`,
    'pt-BR': `dd/MM/yyyy`,
  },
}

export const supportedLocaleFormats: {
  dateAndTime: {[key in SupportedLocales]: string}
} = {
  // can't auto-revert human-readable formats: https://github.com/date-fns/date-fns/issues/1800
  dateAndTime: {
    'en-AU': `dd/MM/yyyy, h:mm a`,
    'en-US': `MM/dd/yyyy, h:mm a`,
    'fr-CA': `yyyy-MM-dd, HH:mm`,
    'id-ID': `dd/MM/yyyy, HH:mm`,
    'ko-KR': `yy. MM. dd. a h:mm`,
    'pt-BR': `dd/MM/yyyy, HH:mm`,
  },
}

export const localeMap: {
  [key in SupportedLocales]: Locale
} = {
  'en-AU': enAU,
  'en-US': enUS,
  'fr-CA': frCA,
  'id-ID': id,
  'ko-KR': ko,
  'pt-BR': ptBR,
}
