import type {JwtToken, SessionUserData} from '@myadbox/nebula-service-api'
import {getJwt, parseJwt} from '@myadbox/nebula-service-api'
import {useEffect, useState} from 'react'
import {useLocalStorage} from '../../../src/hooks/useLocalStorage/useLocalStorage'

export const clearCognito = (): void => {
  const cognitoKeys = Object.keys(localStorage).filter(key =>
    key.toLowerCase().startsWith(`cognito-`)
  )
  return cognitoKeys.forEach(key => localStorage.removeItem(key))
}

export const useUserData = () => {
  const [userData] = useLocalStorage<SessionUserData>(`userData`, null)
  return userData
}

export const getUserData = () => {
  if (typeof window !== `undefined` && window) {
    const userData = localStorage.getItem(`userData`)
    if (!userData) return

    try {
      return JSON.parse(userData) as SessionUserData
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`Failed to parse user data: ${err.message}`)
    }
  }
}

export const useToken = () => {
  const [jwt, setJwt] = useState<JwtToken>(null)
  const [userData] = useLocalStorage<SessionUserData>(`userData`, null)

  useEffect(() => {
    const jwt: JwtToken = parseJwt(getJwt())
    if (!jwt) return
    setJwt(jwt)
  }, [])

  return {
    jwt,
    userData,
  }
}

export const getToken = () => {
  const jwt: JwtToken = parseJwt(getJwt())
  const userData = getUserData()

  return {
    jwt,
    userData,
  }
}

export const getUserSession = (jwt: JwtToken, userData: SessionUserData) => {
  if (!jwt || !userData) return

  return {
    ...jwt,
    cognitoUser: {
      ...jwt.cognitoUser,
      attributes: {
        ...jwt.cognitoUser.attributes,
        ...userData,
      },
    },
  }
}
