import {useCallback} from 'react'
import {useLazyQuery, useMutation} from '@apollo/client'
import {
  APPROVE_APPROVAL_REQUEST,
  DECLINE_APPROVAL_REQUEST,
  CREATE_APPROVAL_REQUEST,
  REVOKE_APPROVAL_REQUEST,
} from '../../mutations'
import {FETCH_APPROVALS} from '../../queries'
import {
  UseApprovalsOutput,
  CreateRequestApprovalMutator,
  ApprovalRequestMutator,
} from '../../types'
import {createApprovalUpdater} from '../../updaters'

export const useApprovals = (): UseApprovalsOutput => {
  const [lazyFetchApprovals, fetchApprovalsResults] =
    useLazyQuery(FETCH_APPROVALS)
  const fetchApprovals = useCallback(
    (assetIds: string[]) => {
      return lazyFetchApprovals({variables: {assetIds}})
    },
    [lazyFetchApprovals]
  )

  const [doApproveApproval, approveApprovalResponse] = useMutation(
    APPROVE_APPROVAL_REQUEST
  )

  const approveApproval: ApprovalRequestMutator = useCallback(
    values => {
      return doApproveApproval({
        variables: {input: values},
      })
    },
    [doApproveApproval]
  )

  const [doDeclineApproval, declineApprovalResponse] = useMutation(
    DECLINE_APPROVAL_REQUEST
  )
  const declineApproval: ApprovalRequestMutator = useCallback(
    values => {
      return doDeclineApproval({
        variables: {input: values},
      })
    },
    [doDeclineApproval]
  )

  const [doCreateApprovalRequest, createApprovalRequestResponse] = useMutation(
    CREATE_APPROVAL_REQUEST
  )
  const createApprovalRequest: CreateRequestApprovalMutator = useCallback(
    values => {
      return doCreateApprovalRequest({
        variables: {input: values},
        update: createApprovalUpdater([values.assetId]),
      })
    },
    [doCreateApprovalRequest]
  )

  const [doRevokeApproval, revokeApprovalResponse] = useMutation(
    REVOKE_APPROVAL_REQUEST
  )
  const revokeApproval: ApprovalRequestMutator = useCallback(
    values => {
      return doRevokeApproval({
        variables: {input: values},
      })
    },
    [doRevokeApproval]
  )

  return {
    approveApproval,
    approveApprovalResponse,
    declineApproval,
    declineApprovalResponse,
    fetchApprovals,
    fetchApprovalsResults,
    createApprovalRequest,
    createApprovalRequestResponse,
    revokeApproval,
    revokeApprovalResponse,
  }
}
