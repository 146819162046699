import {Button, Modal} from '@myadbox/gatsby-theme-nebula'
import {Category} from '@myadbox/nebula-service-api'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {ReactElement} from 'react'
import DeleteMsg from './DeleteMsg'

interface Props {
  category: Category
  categories: Category[]
}

const DeleteCategoryModal = ({category, categories = []}: Props) => {
  const {t} = useTranslation()
  if (!category) return null

  return (
    <Modal
      accessibleTitle={t`settings.categories.removeTitle`}
      trigger={({open}): ReactElement => (
        <Button variant="link" slim onClick={open}>
          {t`settings.remove`}
        </Button>
      )}
    >
      {({close}): ReactElement => (
        <DeleteMsg close={close} category={category} categories={categories} />
      )}
    </Modal>
  )
}

export default DeleteCategoryModal
