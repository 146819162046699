export const convertCsvToJson = async ({
  csv,
  colParser,
  headerConverter,
}: {
  csv: string
  colParser: Record<string, string>
  headerConverter: (header: string) => string
}) => {
  const result = {
    data: [],
    error: null,
  }

  try {
    const {default: converter} = await import(`csvtojson`)

    const data = await converter({
      checkColumn: true,
      trim: true,
      noheader: false,
      colParser,
    })
      .fromString(csv)
      .preFileLine((fileLine, lineNumber) => {
        return lineNumber === 0 ? headerConverter(fileLine) : fileLine
      })

    if (data.length) {
      result.data = data
    } else {
      // TODO: Figure out translation for this
      result.error = `No data found in the .csv file`
    }
  } catch (e) {
    // TODO: Figure out translation for this
    result.error = `Invalid CSV`
  }

  return result
}
