import {gql} from '@apollo/client'

export const NESTEDSCHEMA_INFO = gql`
  fragment NestedSchemaInfo on NestedSchema {
    title
    name
    description
  }
`
export const NESTEDSCHEMA_DESCENDANTS = gql`
  fragment NestedSchemaDescendants on NestedSchema {
    descendants {
      id
      title
      name
      type
      options
    }
  }
`
