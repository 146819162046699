import {useField} from 'formik'
import {ErrorMessageFormikStyled, FieldError} from '../../../../'
import FormItem from '../../FormItem'
import {TextAreaProps, getTextAreaClassName} from '../TextArea'
import {useEffect, useRef} from 'react'

const TextAreaFormik = ({
  label,
  name,
  id,
  rows,
  labelHidden,
  details,
  readOnly,
  noResize = true,
  variant = `md`,
  scaleErrorMessage,
  className,
  autoHeight = false,
  ...props
}: TextAreaProps & {scaleErrorMessage?: boolean}) => {
  const [field] = useField(name)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (textAreaRef.current && autoHeight) {
      textAreaRef.current.style.height = `auto`
      textAreaRef.current.style.height = `calc(${textAreaRef.current.scrollHeight}px + 0.2rem)`
    }
  }, [autoHeight, field.value])

  if (!(name && label && id)) return null

  return (
    <FormItem
      label={
        labelHidden ? null : (
          <FormItem.Label htmlFor={id} dim={readOnly}>
            {label}
          </FormItem.Label>
        )
      }
      details={details}
    >
      <textarea
        ref={textAreaRef}
        aria-label={label}
        className={getTextAreaClassName({
          ...props,
          readOnly,
          noResize,
          variant,
          className,
        })}
        id={id}
        rows={rows || 3}
        {...field}
        {...props}
      ></textarea>

      <ErrorMessageFormikStyled
        scaleErrorMessage={scaleErrorMessage}
        name={name}
        component={FieldError}
        data-testid={`error-${name}`}
        className={`pt-1`}
      />
    </FormItem>
  )
}

export default TextAreaFormik
