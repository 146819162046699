import {bannerAd} from './bannerAd'
import {newspaper} from './newspaper'
import {paper} from './paper'
import {social} from './social'
import {website} from './website'
import {ooh} from './ooh'
import {email} from './email'
import {video} from './video'

/**
 * All presets as a structured object. Please see the [Output presets catalogue](https://sesimi-templates.netlify.app/en/api/presets/) page of the templating docs
 * @internal - looks terrible in the docs. We are formatting this catalogue in a more readable way elsewhere.
 */
export const allSizes = {
  bannerAd,
  newspaper,
  ooh,
  email,
  video,
  website,
  social,
  paper,
}
