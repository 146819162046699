// translations purely for cloudinary widget
import enAuTrans from './en-AU/cloudinary.json'
import enUsTrans from './en-US/cloudinary.json'
import frCaTrans from './fr-CA/cloudinary.json'

export const cloudinaryText = {
  'en-AU': enAuTrans,
  'en-US': enUsTrans,
  'fr-CA': frCaTrans,
}
