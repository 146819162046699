import {Segment, SegmentWithCommands} from './Pie'

export const getSegmentsWithCommandsAndOffsets = (
  segments: Segment[],
  radius: number,
  viewBox: number,
  totalValue: number,
  innerRadius: number
): SegmentWithCommands[] => {
  let accumulatedPercentage = 0
  return segments.map(segment => {
    const percentage = (segment.value / totalValue) * 100
    const segmentWithCommands = {
      ...segment,
      commands: getSegmentCommands(radius, viewBox, percentage, innerRadius),
      offset: accumulatedPercentage * 3.6 * -1,
    }
    accumulatedPercentage += percentage
    return segmentWithCommands
  })
}

export const getSegmentCommands = (
  radius: number,
  viewbox: number,
  percentage: number,
  innerRadius: number
): string => {
  const degrees = percentToDegrees(percentage)
  const longPathFlag = degrees > 180 ? 1 : 0

  const commands: string[] = []
  commands.push(`M ${viewbox / 2 + radius} ${viewbox / 2}`)
  commands.push(
    `A ${radius} ${radius} 0 ${longPathFlag} 0 ${getCoordFromDegrees(
      degrees,
      radius,
      viewbox
    )}`
  )
  commands.push(`L ${getCoordFromDegrees(degrees, innerRadius, viewbox)}`)
  commands.push(
    `A ${innerRadius} ${innerRadius} 0 ${longPathFlag} 1 ${
      viewbox / 2 + innerRadius
    } ${viewbox / 2}`
  )
  return commands.join(` `)
}

export const percentToDegrees = (percent: number): number => {
  // 359.99 Degress as the arc won't draw if the start/end points are the same
  return percent * 3.6 === 360 ? 359.99 : percent * 3.6
}

export const getCoordFromDegrees = (angle, radius, viewbox) => {
  const x = Math.cos((angle * Math.PI) / 180)
  const y = Math.sin((angle * Math.PI) / 180)
  const coordX = x * radius + viewbox / 2
  const coordY = y * -radius + viewbox / 2
  return [coordX, coordY]
}
