// This is a utility component that requires no storybook documentation and should be excluded from UI library.
import {FC} from 'react'
import {
  ErrorBoundary as ReactErrorBoundary,
  FallbackProps,
  ErrorBoundaryPropsWithRender,
} from 'react-error-boundary'
import useTries from '../../hooks/useTries/useTries'
import ErrorFallback from '../../components/ErrorFallback'
import Text from '../../components/Text'

type Resetter = FallbackProps[`resetErrorBoundary`]
type FallbackRender = ErrorBoundaryPropsWithRender[`fallbackRender`]

// eslint-disable-next-line quotes
interface Props extends Omit<ErrorBoundaryPropsWithRender, 'fallbackRender'> {
  fallbackRender?: FallbackRender
  FinalMessage?: JSX.Element
  children: React.ReactNode | React.ReactNode[]
}

const ErrorBoundary: FC<Props> = ({children, FinalMessage, ...props}) => {
  const {tried, remaining} = useTries()

  const recordAttempt = (resetErrorBoundary: Resetter) => () => {
    tried()
    resetErrorBoundary()
  }

  return (
    <ReactErrorBoundary
      fallbackRender={({error, resetErrorBoundary}) => (
        <ErrorFallback
          error={error}
          resetErrorBoundary={recordAttempt(resetErrorBoundary)}
          remainingMessage={
            <Text variant="bodySmall" color="textSecondary" tag="span">
              {remaining} tries left
            </Text>
          }
          expired={!remaining}
          FinalMessage={FinalMessage}
        />
      )}
      {...props}
    >
      {children}
    </ReactErrorBoundary>
  )
}

export default ErrorBoundary
