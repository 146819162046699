import {GET_EXTERNAL_SHARES} from './queries'

export const addExternalSharesUpdater = (assetIds: string[]) => {
  return (cache, {data}) => {
    const cachedData = cache.readQuery({
      query: GET_EXTERNAL_SHARES,
      variables: {assetIds},
    })

    if (!cachedData || !data) return

    cache.writeQuery({
      query: GET_EXTERNAL_SHARES,
      variables: {assetIds},
      data: {
        getExternalShares: [
          ...cachedData.getExternalShares,
          ...data.addExternalShares,
        ],
      },
    })
  }
}

export const deleteExternalShareUpdater = (assetIds: string[]) => {
  return (cache, {data}) => {
    const cachedData = cache.readQuery({
      query: GET_EXTERNAL_SHARES,
      variables: {assetIds},
    })

    if (!cachedData || !data) return

    const updatedData = cachedData.getExternalShares.filter(
      share => share.id !== data.deleteExternalShare.id
    )

    cache.writeQuery({
      query: GET_EXTERNAL_SHARES,
      variables: {assetIds},
      data: {getExternalShares: updatedData},
    })
  }
}
