import {gql} from '@apollo/client'
import {TEAM_BASIC_INFO} from './fragments'

export const CREATE_TEAM = gql`
  ${TEAM_BASIC_INFO}
  mutation CreateTeam($input: TeamParams!) @api(name: "profiles") {
    createTeam(input: $input) {
      id
      userIds
      ...TeamBasicInfo
    }
  }
`

export const UPDATE_TEAM = gql`
  ${TEAM_BASIC_INFO}
  mutation UpdateTeam($id: ID!, $input: TeamParams!) @api(name: "profiles") {
    updateTeam(id: $id, input: $input) {
      id
      userIds
      ...TeamBasicInfo
    }
  }
`

export const DELETE_TEAM = gql`
  mutation DeleteTeam($id: ID!) @api(name: "profiles") {
    deleteTeam(id: $id) {
      id
      name
    }
  }
`
