import {ReactNode, createElement} from 'react'
import LoaderIcon from '../Icons/LoaderIcon'
import {
  ConditionalButtonProps,
  CustomLinkLikeProps,
  isAnchor,
  isButton,
  isCustomLink,
} from './helpers'
import {getStyles} from './styles'

const Button = (props: ConditionalButtonProps) => {
  const {
    size = `md`,
    variant = `secondary`,
    children,
    className = ``,
    disabled = false,
    slim = false,
    state = {},
    loading,
    loadingText = `Loading`,
    iconPrefix,
    iconSuffix,
    ...restProps
  } = props

  const styles = getStyles(slim)

  const classNames = `
    ${className}
    ${styles.base}
    ${styles.size[size]}
    ${styles.variant[variant]}
    ${disabled ? styles.disabled : ``}
    ${loading ? styles.disabled : ``}
  `

  const contentWithLoader =
    loading && loadingText ? (
      <ButtonLoading loadingText={loadingText} />
    ) : (
      <ButtonContent iconPrefix={iconPrefix} iconSuffix={iconSuffix}>
        {children}
      </ButtonContent>
    )

  if (isAnchor(restProps)) {
    return (
      <a className={classNames} {...restProps}>
        {contentWithLoader}
      </a>
    )
  } else if (isButton(restProps)) {
    return (
      <button
        className={classNames}
        disabled={disabled || loading}
        aria-busy={loading}
        type={restProps.type || `submit`}
        {...restProps}
      >
        {contentWithLoader}
      </button>
    )
  } else if (isCustomLink(restProps)) {
    const {as, ...props} = restProps
    return createElement<CustomLinkLikeProps>(
      as,
      {
        state,
        className: classNames,
        ...props,
      },
      contentWithLoader
    )
  }
}

export default Button

const ButtonLoading = ({loadingText = `Loading`}) => (
  <>
    <span className={`flex`}>
      <LoaderIcon size={16} />
    </span>
    <span aria-label={loadingText} className="ml-1">
      {loadingText}
    </span>
  </>
)

const ButtonContent = ({
  children,
  iconPrefix,
  iconSuffix,
}: {
  children: ReactNode
  iconPrefix: ReactNode
  iconSuffix: ReactNode
}) => (
  <>
    {iconPrefix && <span className={`-my-1 mr-1 flex`}>{iconPrefix}</span>}
    {children}
    {iconSuffix && <span className={`-my-1 ml-1 flex`}>{iconSuffix}</span>}
  </>
)
