import {gql} from '@apollo/client'

export const QUERY_OVERVIEW = gql`
  query OverviewData(
    $startDateTime: DateTime
    $endDateTime: DateTime
    $userIds: [String!]
  ) @api(name: "report") {
    overview(
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      userIds: $userIds
    ) {
      data {
        id
        name
        derivedFrom {
          id
          name
          type
        }
        derivedAs {
          preset
        }
        type
        downloads
        views
        bytes
        added
        width
        height
      }
      dataUrl
    }
  }
`

export const QUERY_RAW_DATA = gql`
  query RawData(
    $startDateTime: DateTime
    $endDateTime: DateTime
    $userIds: [String!]
  ) @api(name: "report") {
    rawData(
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      userIds: $userIds
    ) {
      url
      warning
    }
  }
`
