import {ArrowLeftIcon} from '@heroicons/react/24/outline'
import {FormEvent, ReactNode} from 'react'

interface Props {
  path: string
  backHandler: (path: string) => void
  children?: ReactNode
}

const BackButton = ({path, backHandler, children = `Go back`}: Props) => {
  const createHandler = (to: string) => (e: FormEvent<Element>) => {
    e.preventDefault()
    backHandler(to)
  }

  return (
    <a
      href={path}
      onClick={createHandler(path)}
      aria-labelledby="headerBack"
      className={`
        md:w-18
        text-ui-500
        hocus:bg-ui-100
        hocus:text-ui-1000
        flex
        items-center
        self-stretch
        border-r
        px-4
      `}
    >
      <ArrowLeftIcon className={`mx-auto inline-block h-6 w-6`} />
      <span id="headerBack" className={`sr-only`}>
        {children}
      </span>
    </a>
  )
}

export default BackButton
