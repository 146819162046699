import {useLazyQuery} from '@apollo/client'
import {useCallback} from 'react'
import {WITH_INTEGRATIONS} from '../queries'
import {UseConnectedIntegrationsOutput} from '../types'

export const useConnectedIntegrations = (): UseConnectedIntegrationsOutput => {
  const [lazyFetchConnectedIntegrations, fetchConnectedIntegrationsResults] =
    useLazyQuery(WITH_INTEGRATIONS)

  const fetchConnectedIntegrations = useCallback(() => {
    lazyFetchConnectedIntegrations()
  }, [lazyFetchConnectedIntegrations])

  return {
    fetchConnectedIntegrations,
    fetchConnectedIntegrationsResults,
  }
}
