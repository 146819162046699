import {Button, Image, Modal} from '@myadbox/gatsby-theme-nebula'
import {useAccount} from '@myadbox/nebula-service-api'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {ReactElement} from 'react'
import {ResourceType, UploadButton, UploadResponse} from '../helpers'

const AccountImageUploadAndPreview = () => {
  const {t} = useTranslation()
  const {account, updateAccountImageConfig, updateAccountImageConfigResponse} =
    useAccount()

  const successHandler = (
    data: UploadResponse,
    accImageType: `background` | `logo`
  ) => {
    const configKey =
      accImageType === `background` ? `loginBackgroundUrl` : `logoUrl`
    updateAccountImageConfig({[configKey]: data.info.secure_url})
  }

  return (
    <div className={`flex gap-2`}>
      <UploadButton
        cropping={true}
        multiple={false}
        resourceType={ResourceType.image}
        clientAllowedFormats={[`png`, `jpg`, `jpeg`, `webp`]}
        minImageHeight={1200}
        minImageWidth={1600}
        tags={[`login-background`]}
        sources={[`local`, `url`]}
        trigger={({onClickHandler}): ReactElement => (
          <Button
            aria-label={t`settings.account.configurations.button.uploadBgAriaLabel`}
            size="sm"
            variant="secondary"
            onClick={(...args) => onClickHandler(args)}
            type="button"
            id="uploadImage"
          >
            {t`settings.upload`}
          </Button>
        )}
        successHandler={data => successHandler(data, `background`)}
      />

      <Modal
        accessibleTitle={t`settings.account.configurations.previewBgImgModal`}
        maxWidth="lg"
        trigger={({open}) => (
          <Button
            aria-label={t`settings.account.configurations.button.previewAriaLabel`}
            size="sm"
            variant="secondary"
            onClick={open}
            type="button"
            loading={updateAccountImageConfigResponse?.loading}
            disabled={!account?.configuration?.loginBackgroundUrl}
          >
            {t`settings.account.configurations.button.preview`}
          </Button>
        )}
      >
        <Modal.Body>
          {account?.configuration?.loginBackgroundUrl && (
            <Image
              src={account?.configuration.loginBackgroundUrl}
              alt={t`settings.account.configurations.previewModalImgAlt`}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AccountImageUploadAndPreview
