import type {NestedSchema, User} from '@myadbox/nebula-service-api'

export const filterSchemas = (schemas: NestedSchema[], filter: string) => {
  if (!schemas) return []
  if (!filter) return schemas

  return schemas.filter(schema => {
    return schema.title.toLowerCase().includes(filter.toLowerCase())
  })
}

export const getProfileMap = (profiles: User[]) => {
  const profileMap = new Map<string, User>()

  const profileList = profiles || []
  profileList.forEach(profile => {
    profileMap.set(profile.userId, profile)
  })
  return profileMap
}

export const getUsernameById = (profileMap: Map<string, User>) => {
  return (id: string): string => {
    const profile = profileMap.get(id)
    return profile ? profile.fullName : id
  }
}
