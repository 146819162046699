import {OperationVariables, QueryResult} from '@apollo/client'
import {
  ConnectedIntegration,
  ExchangeTokenInput,
  Integration,
} from '@myadbox/nebula-service-api'
import {
  ActionGroup,
  Button,
  FacebookIcon,
  InstagramIcon,
  Text,
} from '@myadbox/stellar-ui'
import {useExchangeToken} from 'packages/data/nebula-service-api'
import {Dispatch} from 'react'
import {useTranslation} from 'react-i18next'
import {useDefaultIntegrations, useSocialsExport} from '../../hooks'
import {ActiveIntegrationType} from '../../hooks/types'
import {useClientMetaAuth} from '../../hooks/useClientMetaAuth'
import {paramsToObject, redirectSource} from '../../utils/helpers'
import {IntegrationModalAction} from '../DefaultIntegrationsModal/types'

const appId = process.env.GATSBY_META_CLIENT_ID || ``
const apiBaseUrl = process.env.GATSBY_SERVICES_API_GATEWAY || ``

export const IntegrationIcon = ({type}: {type: ActiveIntegrationType}) => {
  if (type === `Facebook`) return <FacebookIcon size={34} />
  if (type === `Instagram`) return <InstagramIcon size={34} />
  return <div></div>
}

export const getSelectedExportIntegrationId = (
  defaultIntegrations: Integration[],
  activeIntegrationType: ActiveIntegrationType
) =>
  defaultIntegrations.find(
    integration => integration.name === activeIntegrationType
  )?.id

export const handleReturnMessageBuilder = ({
  selectedExportIntegrationId,
  activeIntegrationType,
  exchangeToken,
  fetchConnectedIntegrationsResults,
  dispatch,
}: {
  selectedExportIntegrationId: string
  activeIntegrationType: ActiveIntegrationType
  exchangeToken: (input: ExchangeTokenInput) => void
  fetchConnectedIntegrationsResults: QueryResult<
    {
      withIntegrations: ConnectedIntegration[]
    },
    OperationVariables
  >
  dispatch: Dispatch<IntegrationModalAction>
}) => {
  const {data, refetch} = fetchConnectedIntegrationsResults

  return async (event: {
    origin: string
    data: {payload: string; source: string}
  }) => {
    if (
      event.origin !== window.location.origin &&
      event.data &&
      event.data.source &&
      event.data.source !== redirectSource
    ) {
      return
    }
    const params = paramsToObject(event.data.payload)
    if (
      params &&
      selectedExportIntegrationId &&
      activeIntegrationType &&
      Object.prototype.hasOwnProperty.call(params, `access_token`)
    ) {
      await exchangeToken({
        integrationId: selectedExportIntegrationId,
        type: String(activeIntegrationType).toLocaleUpperCase(),
        temporaryToken: params.access_token,
      })
      await refetch()
      if (data?.withIntegrations.length === 0) {
        dispatch({type: `SET_CONTEXT`, payload: `ERROR`})
      } else {
        dispatch({type: `SET_CONTEXT`, payload: `SELECT`})
      }
    }
  }
}

export const DefaultIntegrationsConnect = ({
  handleCancel,
  dispatch,
  integration,
}: {
  handleCancel: () => void
  dispatch: Dispatch<IntegrationModalAction>
  integration: ConnectedIntegration | null
}) => {
  const {t} = useTranslation(`defaultIntegrations`)
  const {activeIntegrationType, defaultIntegrations} = useDefaultIntegrations()
  const {exchangeToken, exchangeTokenResponse} = useExchangeToken()
  const {fetchConnectedIntegrationsResults} = useSocialsExport()

  const selectedExportIntegrationId =
    getSelectedExportIntegrationId(
      defaultIntegrations,
      activeIntegrationType
    ) || ``

  const handleReturnMessage = handleReturnMessageBuilder({
    exchangeToken,
    selectedExportIntegrationId,
    activeIntegrationType,
    fetchConnectedIntegrationsResults,
    dispatch,
  })

  const {handleMetaLogin} = useClientMetaAuth(handleReturnMessage, appId)

  const handleConnect = () => {
    // istanbul ignore next
    handleMetaLogin(activeIntegrationType, apiBaseUrl)
  }

  const expiryDate = integration
    ? new Date(integration.user.token.expiresAt).getTime()
    : Date.now() - 1000
  const hasExpired = expiryDate <= Date.now() ? `getToken` : `resetToken`

  return (
    <div className="flex flex-col gap-4">
      <IntegrationIcon type={activeIntegrationType} />
      <Text>
        {t(`connect.${hasExpired}.body`, {
          integration: activeIntegrationType,
        })}
      </Text>
      <ActionGroup>
        <Button variant="secondary" onClick={handleCancel}>
          {t(`connect.${hasExpired}.cancel`)}
        </Button>
        <Button
          variant="primary"
          onClick={handleConnect}
          disabled={exchangeTokenResponse.loading}
        >
          {t(`connect.${hasExpired}.connect`, {
            integration: activeIntegrationType,
          })}
        </Button>
      </ActionGroup>
    </div>
  )
}
