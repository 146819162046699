import {FC} from 'react'
import './style.scss'

const NoAsset: FC = () => (
  <svg
    width="100%"
    height="100%"
    data-testid="noAsset"
    viewBox="0 0 330 292"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      marginRight: `10%`,
    }}
    className="no-asset"
  >
    <path
      d="M225.591 160.949C199.683 116.311 186.688 65.4789 186.706 13.9355C186.91 11.53 184.455 10.5427 182.506 11.1978C143.964 24.8453 105.421 38.4937 66.8784 52.1422C64.764 52.9108 62.7974 55.2884 63.2132 57.665C71.1482 108.667 93.0163 156.358 125.447 196.411C126.786 198.119 129.452 199.154 131.554 198.474C162.458 187.53 193.361 176.588 224.265 165.645C226.131 164.899 226.701 162.607 225.592 160.949H225.591Z"
      className="shadow"
    />
    <path
      d="M187.339 289.97C252.705 289.97 305.694 286.072 305.694 281.263C305.694 276.454 252.705 272.556 187.339 272.556C121.973 272.556 68.9834 276.454 68.9834 281.263C68.9834 286.072 121.973 289.97 187.339 289.97Z"
      className="shadow"
    />
    <path
      d="M259.253 224.641C259.253 224.641 259.251 224.641 259.25 224.643C258.605 225.453 257.718 226.079 256.666 226.392C256.525 226.434 256.381 226.471 256.234 226.502L123.429 254.178C122.179 254.439 120.94 254.229 119.898 253.668C118.587 252.964 117.585 251.707 117.256 250.132L99.0385 162.657C98.712 161.083 99.1287 159.53 100.049 158.359L153.688 80.3687C154.902 78.6025 157.292 78.1046 159.11 79.239L239.395 129.303C240.718 130.003 241.731 131.267 242.06 132.85L260.279 220.326C260.609 221.906 260.184 223.469 259.253 224.641V224.641Z"
      className="envelopeBg"
    />
    <path
      d="M229.766 179.723C198.042 187.99 166.318 196.257 134.594 204.524C132.442 205.023 129.873 203.764 128.686 201.948C99.7876 159.275 82.0638 109.892 78.5047 58.4002C78.2926 55.9965 80.4544 53.7958 82.6275 53.2095C122.193 42.899 161.758 32.5875 201.324 22.277C203.323 21.7903 205.684 22.9833 205.276 25.3627C200.865 76.7162 209.48 128.473 231.488 175.158C232.452 176.904 231.688 179.139 229.766 179.723V179.723Z"
      className="sheet"
    />
    <path
      d="M198.378 118.532C169.16 126.145 139.941 133.76 110.724 141.374C109.229 141.722 107.711 140.873 107.152 139.458C106.639 138.071 107.373 136.563 108.815 136.178C138.257 128.505 167.699 120.833 197.14 113.16C198.599 112.798 200.013 113.723 200.35 115.171C200.662 116.632 199.834 118.123 198.378 118.532Z"
      className="patternLight"
    />
    <path
      d="M204.68 140.736C176.401 148.105 148.122 155.475 119.844 162.844C118.338 163.178 116.813 162.335 116.144 160.975C115.498 159.813 116.165 158.068 117.511 157.784C146.013 150.357 174.516 142.929 203.018 135.501C204.48 135.156 205.902 136.066 206.358 137.466C206.784 138.836 206.105 140.342 204.681 140.736H204.68Z"
      className="patternLight"
    />
    <path
      d="M212.749 162.295C185.409 169.419 158.07 176.544 130.73 183.669C129.217 183.99 127.678 183.159 126.905 181.861C126.14 180.511 126.627 179.126 127.979 178.773C155.542 171.59 183.105 164.407 210.668 157.224C212.13 156.895 213.565 157.784 214.133 159.13C214.702 160.531 214.089 161.946 212.749 162.296V162.295Z"
      className="patternLight"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M122.953 66.1629C119.95 67.273 116.242 70.4564 116.884 74.053C118.741 83.8936 121.659 93.4839 125.755 102.628C127.368 106.144 131.658 107.117 135.086 105.974C141.433 103.873 147.758 102.097 154.307 100.763C159.949 99.6135 165.731 98.2744 171.463 97.7449C174.868 97.5812 177.128 94.6136 176.403 91.299C174.221 80.6981 173.507 69.9791 173.072 59.1865C172.922 55.2549 169.199 53.03 165.547 53.8247C151.075 56.9597 136.859 61.0765 122.953 66.1629V66.1629ZM170.068 91.3185C164.306 92.0974 158.523 93.5882 152.838 94.8295C146.031 96.3165 139.391 98.2 132.767 100.363C134.921 91.1445 138.021 82.1824 140.268 72.9876C145.249 77.8227 149.971 82.8924 154.439 88.204C155.605 83.6796 156.948 79.2074 158.172 74.6988C162.333 80.0988 166.175 85.7231 170.068 91.3176V91.3185Z"
      className="imgIcon"
    />
    <path
      d="M261.72 238.571C235.812 193.933 222.817 143.101 222.835 91.5576C223.039 89.1521 220.584 88.1648 218.635 88.8199C180.093 102.467 141.55 116.116 103.007 129.764C100.893 130.533 98.9263 132.91 99.3422 135.287C107.277 186.289 129.145 233.98 161.576 274.033C162.915 275.741 165.581 276.776 167.683 276.096C198.587 265.152 229.49 254.21 260.394 243.267C262.26 242.521 262.83 240.229 261.721 238.571H261.72Z"
      className="shadow"
    />
    <path
      d="M258.105 253.489C226.381 261.756 194.658 270.023 162.934 278.29C160.782 278.789 158.213 277.53 157.025 275.714C128.127 233.041 110.404 183.658 106.845 132.166C106.632 129.762 108.794 127.562 110.967 126.975C150.533 116.665 190.098 106.353 229.664 96.0429C231.662 95.5562 234.024 96.7491 233.616 99.1286C229.205 150.482 237.82 202.239 259.828 248.924C260.792 250.67 260.028 252.904 258.105 253.489V253.489Z"
      className="sheet"
    />
    <path
      d="M195.774 129.943L196.522 129.748V129.663C198.117 128.911 199.276 127.313 199.322 125.466L199.322 125.454L199.322 125.442C199.323 123.123 197.141 121.878 195.178 122.428L170.143 128.952L145.098 135.479L145.089 135.481L145.079 135.484C143.153 136.027 141.6 137.818 141.698 139.983L141.699 140.002L141.701 140.022C141.895 142.309 144.111 143.425 146.036 142.904C154.325 140.744 162.614 138.584 170.903 136.424L170.92 136.42C179.205 134.261 187.489 132.102 195.774 129.943Z"
      className="highlight"
    />
    <path
      d="M219.531 146.403C188.434 154.507 157.337 162.61 126.241 170.714C124.776 171.089 123.257 170.255 122.939 168.743C122.673 167.185 123.71 165.736 125.204 165.324C156.524 157.162 187.845 149 219.166 140.837C220.611 140.441 222.023 141.378 222.106 142.9C222.158 144.505 221.068 145.961 219.531 146.403V146.403Z"
      className="patternLight"
    />
    <path
      d="M222.211 169.56C192.054 177.42 161.896 185.278 131.739 193.138C130.257 193.5 128.743 192.654 128.301 191.187C127.904 189.709 128.797 188.229 130.263 187.831C160.644 179.914 191.025 171.997 221.407 164.079C222.861 163.701 224.27 164.635 224.484 166.123C224.67 167.662 223.706 169.136 222.212 169.561L222.211 169.56Z"
      className="patternLight"
    />
    <path
      d="M226.717 192.297C197.499 199.911 168.28 207.526 139.062 215.14C137.568 215.488 136.05 214.639 135.491 213.224C134.978 211.837 135.712 210.329 137.154 209.944C166.596 202.271 196.038 194.599 225.479 186.926C226.938 186.564 228.352 187.489 228.689 188.937C229.001 190.398 228.173 191.889 226.717 192.297Z"
      className="patternLight"
    />
    <path
      d="M233.019 214.5C204.74 221.87 176.461 229.24 148.182 236.609C146.677 236.943 145.152 236.1 144.483 234.74C143.837 233.578 144.504 231.833 145.849 231.549C174.352 224.121 202.855 216.694 231.357 209.266C232.819 208.921 234.241 209.831 234.697 211.23C235.123 212.601 234.444 214.107 233.02 214.5H233.019Z"
      className="patternLight"
    />
    <path
      d="M241.089 236.061C213.749 243.185 186.41 250.31 159.07 257.434C157.557 257.755 156.018 256.924 155.245 255.627C154.48 254.277 154.967 252.891 156.319 252.539C183.882 245.356 211.445 238.173 239.008 230.99C240.47 230.661 241.904 231.55 242.473 232.896C243.042 234.297 242.429 235.712 241.089 236.061V236.061Z"
      className="patternLight"
    />
    <path
      d="M259.253 224.641C259.253 224.641 259.251 224.641 259.251 224.643C258.606 225.453 257.718 226.078 256.666 226.392L182.404 200.199C179.708 199.253 178.904 195.83 180.896 193.781L235.373 132.288C236.715 132.956 237.758 134.194 238.128 135.765L258.57 222.75C258.713 223.358 259.003 224.007 259.253 224.642V224.641Z"
      className="shadow"
    />
    <svg>
      <path
        d="M242.061 132.85L260.278 220.327C260.608 221.908 260.184 223.471 259.251 224.643L180.135 185.195C177.463 184.181 176.746 180.737 178.79 178.741L239.395 129.303C240.719 130.004 241.732 131.268 242.062 132.849L242.061 132.85Z"
        className="envelope"
      />
    </svg>
    <path
      d="M186.961 219.337L119.898 253.668C118.586 252.963 117.583 251.706 117.256 250.133L99.0379 162.657C98.7104 161.084 99.1281 159.531 100.048 158.36L185.642 213.008C188.234 214.025 188.931 217.369 186.961 219.337V219.337Z"
      className="shadow"
    />
    <path
      d="M187.638 202.629L119.898 253.668C118.586 252.963 117.583 251.706 117.256 250.133L99.0379 162.657C98.7104 161.084 99.1281 159.531 100.048 158.36L186.319 196.301C188.91 197.318 189.607 200.661 187.637 202.629H187.638Z"
      className="envelope"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.6809 224.742C51.6006 233.831 51.6006 248.567 60.6809 257.655C69.7612 266.744 84.4832 266.744 93.5635 257.655C102.644 248.567 102.644 233.831 93.5635 224.742C84.4832 215.654 69.7612 215.654 60.6809 224.742ZM58.5606 259.778C48.3092 249.517 48.3092 232.881 58.5606 222.62C68.8119 212.359 85.4325 212.359 95.6839 222.62C105.579 232.525 105.923 248.37 96.7143 258.687L111.435 273.421C112.021 274.007 112.021 274.957 111.435 275.543C110.85 276.129 109.9 276.129 109.315 275.543L94.5939 260.809C84.2864 270.026 68.4561 269.682 58.5606 259.778Z"
      className="patternLight"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.1948 251.25C62.4775 251.664 61.5605 251.419 61.1466 250.701C58.8384 246.699 58.1633 242.198 58.9154 237.979C59.0608 237.163 59.8396 236.62 60.6549 236.765C61.4701 236.91 62.0132 237.689 61.8677 238.505C61.2352 242.054 61.8021 245.833 63.744 249.2C64.158 249.918 63.9121 250.835 63.1948 251.25ZM61.6818 233.935C60.9635 233.523 60.7153 232.606 61.1275 231.887C61.9425 230.465 62.9489 229.14 64.1353 227.954C64.7213 227.368 65.6707 227.368 66.2559 227.954C66.8411 228.54 66.8404 229.49 66.2545 230.076C65.2587 231.072 64.4136 232.185 63.7286 233.379C63.3165 234.098 62.4001 234.347 61.6818 233.935Z"
      className="patternLight"
    />
    <path
      d="M266.283 27.0326C269.311 19.6524 273.94 9.92314 266.395 3.31521C263.451 0.736723 259.358 -0.208683 255.595 0.616816C251.384 1.5412 248.479 4.73484 246.257 8.14279C240.627 16.7825 237.25 26.8582 233.33 36.3372C232.323 38.7717 231.317 41.2052 230.31 43.6397C229.759 44.9706 231.86 45.9546 232.414 44.6164C235.868 36.2646 239.167 27.8287 242.836 19.5691C244.656 15.4721 246.636 11.2914 249.372 7.7067C251.704 4.65059 254.868 2.32093 258.996 2.5811C262.932 2.82984 266.427 5.46146 267.742 9.12484C269.11 12.9324 267.69 16.8911 266.373 20.4412C263.21 28.9715 259.351 37.2244 255.195 45.311C251.053 53.3715 246.614 61.277 242.211 69.1985C240.208 72.8023 238.462 76.8186 235.974 80.1206C235.066 81.3272 233.902 82.5243 232.29 82.714C230.198 82.9612 227.509 81.5763 226.075 80.1188C223.497 77.4964 224.167 73.4053 225.044 70.3008C226.214 66.1603 227.944 62.1484 229.696 58.2297C233.355 50.0476 237.397 42.0275 241.286 33.9535C243.117 30.1525 244.93 24.9927 249.132 23.1301C250.798 22.3915 252.952 22.2248 254.664 23.0353C256.808 24.0506 257.318 26.2867 257.144 28.4066C256.748 33.2462 254.218 37.7879 251.908 41.9369C249.442 46.364 246.942 50.7728 244.459 55.19C243.75 56.4499 245.857 57.4209 246.562 56.1667C249.095 51.6622 251.651 47.1708 254.157 42.6523C256.456 38.5081 258.767 34.0744 259.371 29.2974C259.824 25.7104 258.642 21.9776 254.817 20.6944C251.259 19.5014 247.543 20.8086 245.017 23.268C241.728 26.4715 240.047 31.1837 238.099 35.2269C235.84 39.9159 233.579 44.604 231.322 49.2941C229.055 54.006 226.81 58.7349 224.925 63.615C223.476 67.3645 221.859 71.385 221.98 75.4992C222.097 79.4637 224.417 82.4528 228.11 84.0613C231.951 85.7346 235.335 84.8226 237.751 81.6033C240.539 77.8878 242.511 73.4191 244.761 69.371C247.316 64.7717 249.881 60.1766 252.391 55.5524C257.436 46.2553 262.264 36.8224 266.281 27.0308L266.283 27.0326Z"
      className="patternLight"
    />
    <path
      d="M24.2495 104.678C25.9064 104.678 27.2495 103.335 27.2495 101.678C27.2495 100.021 25.9064 98.6779 24.2495 98.6779C22.5927 98.6779 21.2495 100.021 21.2495 101.678C21.2495 103.335 22.5927 104.678 24.2495 104.678Z"
      className="patternLight"
    />
    <path
      d="M55.7446 193.173C57.4015 193.173 58.7446 191.83 58.7446 190.173C58.7446 188.516 57.4015 187.173 55.7446 187.173C54.0878 187.173 52.7446 188.516 52.7446 190.173C52.7446 191.83 54.0878 193.173 55.7446 193.173Z"
      className="patternDark"
    />
    <path
      d="M297.838 93.2837C299.495 93.2837 300.838 91.9405 300.838 90.2837C300.838 88.6268 299.495 87.2837 297.838 87.2837C296.181 87.2837 294.838 88.6268 294.838 90.2837C294.838 91.9405 296.181 93.2837 297.838 93.2837Z"
      className="patternLight"
    />
    <path
      d="M46.41 117.456C46.8118 116.754 47.8672 116.739 48.2865 117.431C49.1421 118.843 50.447 120.832 51.6892 122.079C52.9621 123.356 55.0051 124.699 56.433 125.568C57.1174 125.984 57.1088 127.024 56.418 127.43C54.9845 128.272 52.9417 129.572 51.6892 130.814C50.4272 132.066 49.151 134.052 48.3075 135.488C47.888 136.202 46.7929 136.186 46.3913 135.462C45.594 134.024 44.3837 132.048 43.1514 130.814C41.8973 129.559 39.8314 128.283 38.3532 127.45C37.6361 127.046 37.6275 125.961 38.3385 125.546C39.8115 124.686 41.8781 123.369 43.1514 122.079C44.3634 120.851 45.6006 118.871 46.41 117.456Z"
      className="highlight"
    />
    <path
      d="M286.427 164.868C286.841 164.173 287.9 164.158 288.33 164.843C288.934 165.804 289.738 166.974 290.509 167.747C291.303 168.544 292.512 169.376 293.491 169.993C294.169 170.419 294.161 171.464 293.477 171.88C292.495 172.477 291.29 173.283 290.509 174.057C289.732 174.828 288.947 175.984 288.355 176.951C287.923 177.657 286.82 177.642 286.404 176.926C285.844 175.961 285.098 174.815 284.341 174.057C283.565 173.28 282.357 172.492 281.355 171.903C280.646 171.487 280.637 170.396 281.34 169.968C282.341 169.361 283.552 168.547 284.341 167.747C285.092 166.987 285.856 165.828 286.427 164.868Z"
      className="highlight"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M298.332 52.4155C298.918 53.0013 298.918 53.9515 298.333 54.5378L282.791 70.1085C282.206 70.6949 281.257 70.6953 280.671 70.1095C280.085 69.5237 280.085 68.5735 280.67 67.9872L296.212 52.4165C296.797 51.8302 297.746 51.8297 298.332 52.4155Z"
      className="patternDark"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M310.539 236.923C310.125 237.641 309.208 237.886 308.491 237.471L289.447 226.456C288.73 226.041 288.484 225.123 288.898 224.406C289.313 223.688 290.23 223.443 290.947 223.858L309.991 234.873C310.708 235.288 310.953 236.206 310.539 236.923Z"
      className="patternDark"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M329.391 133.165C329.606 133.965 329.131 134.788 328.331 135.003L307.083 140.705C306.282 140.92 305.46 140.445 305.246 139.645C305.031 138.845 305.506 138.022 306.306 137.807L327.554 132.105C328.354 131.89 329.177 132.365 329.391 133.165Z"
      className="patternLight"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.0244 157.014C27.0244 157.843 26.3528 158.514 25.5244 158.514L3.52441 158.514C2.69599 158.514 2.02441 157.843 2.02441 157.014C2.02441 156.186 2.69599 155.514 3.52441 155.514L25.5244 155.514C26.3528 155.514 27.0244 156.186 27.0244 157.014Z"
      className="patternLight"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.5459 229.259C24.1317 228.542 24.3772 227.624 25.0943 227.209L44.1382 216.194C44.8553 215.779 45.7724 216.024 46.1866 216.742C46.6008 217.459 46.3553 218.377 45.6382 218.792L26.5943 229.807C25.8772 230.222 24.9601 229.976 24.5459 229.259Z"
      className="patternDark"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.48 61.3205C11.8942 60.603 12.8113 60.3577 13.5284 60.7725L32.5722 71.7877C33.2893 72.2025 33.5348 73.1203 33.1206 73.8378C32.7064 74.5552 31.7893 74.8006 31.0722 74.3858L12.0284 63.3705C11.3113 62.9558 11.0658 62.0379 11.48 61.3205Z"
      className="patternDark"
    />
    <path
      d="M72.7839 17.4462C73.2053 16.7556 74.2671 16.7405 74.7041 17.4214C75.2029 18.1986 75.8298 19.0858 76.4329 19.6911C77.0566 20.3171 77.9782 20.9684 78.7749 21.4804C79.4488 21.9134 79.4402 22.9603 78.7599 23.3834C77.9629 23.8792 77.0456 24.5094 76.4329 25.1171C75.8281 25.7169 75.218 26.5882 74.7302 27.3647C74.2896 28.0661 73.1848 28.0503 72.7598 27.3394C72.2979 26.5668 71.7178 25.7058 71.1297 25.1171C70.523 24.5098 69.6103 23.8947 68.8025 23.4079C68.0975 22.9831 68.0888 21.8894 68.7876 21.4545C69.5955 20.9517 70.5126 20.3161 71.1297 19.6911C71.7159 19.0973 72.3116 18.2202 72.7839 17.4462Z"
      className="highlight"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      className="border"
      d="M67.7324 19.7552C68.5129 19.2695 69.2636 18.7349 69.7081 18.2847C70.1247 17.8626 70.6226 17.1498 71.0782 16.4032C72.2634 14.4608 75.1466 14.408 76.3864 16.3399C76.8693 17.0925 77.4026 17.8304 77.8487 18.278C78.3126 18.7436 79.0819 19.2996 79.8552 19.7965C81.7682 21.0257 81.7405 23.8855 79.8155 25.0829C79.0411 25.5645 78.2849 26.0976 77.8402 26.5385C77.4032 26.9719 76.8912 27.6844 76.4229 28.4298C75.182 30.405 72.2298 30.3491 71.0447 28.3668C70.6032 27.6283 70.1249 26.9407 69.7165 26.532C69.2904 26.1055 68.5529 25.5929 67.7718 25.1221C65.7895 23.9276 65.7623 20.9813 67.7324 19.7552ZM74.7044 17.4213C74.2675 16.7405 73.2056 16.7555 72.7842 17.4461C72.3119 18.2201 71.7162 19.0973 71.13 19.691C70.513 20.3161 69.5959 20.9517 68.788 21.4545C68.0891 21.8894 68.0978 22.9831 68.8029 23.4079C69.6107 23.8947 70.5233 24.5098 71.13 25.1171C71.7182 25.7058 72.2983 26.5668 72.7601 27.3394C73.1851 28.0503 74.29 28.066 74.7306 27.3647C75.2184 26.5882 75.8284 25.7168 76.4332 25.1171C77.046 24.5094 77.9633 23.8791 78.7603 23.3834C79.4405 22.9603 79.4492 21.9134 78.7753 21.4803C77.9785 20.9684 77.057 20.317 76.4332 19.691C75.8301 19.0858 75.2032 18.1986 74.7044 17.4213Z"
    />
  </svg>
)

export default NoAsset
