import {Select, matchOptionValue} from '@myadbox/gatsby-theme-nebula'
import {DatasetFieldOptions} from '@myadbox/nebula-service-api'
import {Options, SelectFieldOptions} from '@myadbox/nebula-template-utils'
import {Input} from '@myadbox/stellar-ui'
import {ControllerRenderProps} from 'react-hook-form'
import {Inputs} from './IntegrationForm'
import {default as SchemaPicker} from './SchemaPicker'
import {isModifierField} from './typeguards'

export const FormSelect = ({
  options,
  ...field
}: ControllerRenderProps<Inputs, string> & {options: SelectFieldOptions}) => {
  const selectOptions = options.choices.map(({value, label}) => ({
    label,
    value: value.toString(),
  }))
  return (
    <Select
      {...field}
      id={field.name}
      label={options.label}
      onChange={({value}) => field.onChange(value.toString())}
      options={selectOptions}
      value={matchOptionValue(selectOptions, field.value)}
    />
  )
}

export const FormSchemaPicker = ({
  options,
  ...field
}: ControllerRenderProps<Inputs, string> & {options: DatasetFieldOptions}) => (
  <SchemaPicker
    {...field}
    criteria={options.selectionCriteria}
    label={options.label}
  />
)

export const FormInput = ({
  options,
  ...field
}: ControllerRenderProps<Inputs, string> & {options: Options}) => (
  <Input
    {...field}
    id={field.name}
    label={options.label}
    suffixIcon={
      isModifierField(options) && options.modifier === `percentage` ? (
        <div className="pointer-events-none">%</div>
      ) : null
    }
  />
)
