import {ClipboardDocumentIcon} from '@heroicons/react/24/outline'
import {
  Banner,
  RadioButton,
  Table,
  TableEmptyState,
  TableLoadingState,
} from '@myadbox/gatsby-theme-nebula'
import {OptionValue} from '@myadbox/gatsby-theme-nebula/types'
import {DatasetData, useDatasets} from '@myadbox/nebula-service-api'
import {HTMLAttributes, useEffect} from 'react'
import {getSortedFields} from '../../utils/datasetHelpers'

interface Props extends HTMLAttributes<HTMLElement> {
  schemaId: string
  noDatasetsMsg: string
  invalidSchemaMsg: string
  fieldName: string
  setSelected(id: OptionValue): void
}

const DatasetsInSchemaTable = ({
  schemaId,
  noDatasetsMsg,
  invalidSchemaMsg,
  fieldName,
  setSelected,
}: Props) => {
  const {
    fetchSchemaWithDatasets,
    fetchSchemaWithDatasetsResult: {loading, error, data},
  } = useDatasets()

  const fields = getSortedFields(data?.nestedSchema?.descendants)
  const hasDatasets = data?.nestedSchema?.datasets?.length > 0
  const invalidSchema = !schemaId || (!loading && !data)

  useEffect(() => {
    fetchSchemaWithDatasets(schemaId)
  }, [schemaId, fetchSchemaWithDatasets])

  if (!schemaId) return null

  const getCellContent = (data: DatasetData, name: string) => {
    if (data[name] === null) {
      return ``
    }
    if (typeof data[name] === `string`) {
      return `${data[name]}`
    }
    return JSON.stringify(data[name])
  }

  return (
    <>
      {data?.nestedSchema && (
        <Table contrast>
          <Table.Head
            sticky
            className={`
              bg-ui-100
              z-10
            `}
          >
            <Table.Row>
              <Table.Cell>{` `}</Table.Cell>
              {fields.map(({id, title}) => (
                <Table.HeadCell key={id}>{title}</Table.HeadCell>
              ))}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {hasDatasets &&
              data.nestedSchema.datasets.map(({data, id}, index) => (
                <Table.Row
                  key={id}
                  className={`
                    hover:bg-ui-50
                    relative
                    ${
                      /* a hack to make older supported browsers (as used in CI) respect relative on the table row… */ ``
                    }
                    scale-100
                  `}
                >
                  <Table.Cell
                    className={`
                      px-4
                      py-2
                    `}
                  >
                    <label
                      className={`
                        absolute
                        inset-0
                        p-2
                        pl-0.5
                      `}
                      htmlFor={`row-${id}`}
                      aria-label={`row ${index + 1}`}
                    >
                      <RadioButton
                        name={fieldName}
                        id={`row-${id}`}
                        value={id}
                        onChange={() => setSelected(id)}
                      />
                    </label>
                  </Table.Cell>
                  {fields.map(({name}) => (
                    <Table.Cell key={name}>
                      {getCellContent(data, name)}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      )}

      {data?.nestedSchema && !hasDatasets && (
        <TableEmptyState
          icon={<ClipboardDocumentIcon width={36} height={36} />}
          text={noDatasetsMsg}
        />
      )}

      {loading && <TableLoadingState />}

      {error && <Banner intent="error">{error.message}</Banner>}

      {invalidSchema && <Banner intent="error">{invalidSchemaMsg}</Banner>}
    </>
  )
}

export default DatasetsInSchemaTable
