import {gql} from '@apollo/client'

export const AUTHENTICATE_USER = gql`
  query Authenticate($accessToken: String!, $owner: Boolean!)
  @api(name: "users") {
    authenticate(accessToken: $accessToken, owner: $owner) {
      token
      userData {
        searchKey
        teamIds
        accesses {
          id
          shortId
          accessLevel
        }
      }
    }
  }
`
