import {
  ActionGroup,
  Banner,
  Button,
  Modal,
  Select,
  Text,
} from '@myadbox/gatsby-theme-nebula'
import {BannerIntent} from '@myadbox/gatsby-theme-nebula/types'
import {Dataset, useProfiles} from '@myadbox/nebula-service-api'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {Dispatch, useEffect, useState} from 'react'
import {EditUserContext, EditUserModalState} from '../EditUserModal'
import UserSchemaSelector from '../UserSchemaSelector'

interface Props {
  amzCognitoPoolId: string
  close: Dispatch<unknown>
  datasetIds: string[]
  isAdmin: boolean
  profileId: string
  setContext: Dispatch<EditUserModalState>
  datasets?: Dataset[]
  sso: boolean
}

const Role = {
  Admin: `admin`,
  Member: `member`,
} as const

type Role = (typeof Role)[keyof typeof Role]

const EditUserActions = ({
  amzCognitoPoolId,
  close,
  datasetIds,
  isAdmin,
  profileId,
  setContext,
  datasets = [],
  sso,
}: Props) => {
  const {t} = useTranslation()
  const {
    promoteToAdmin,
    promoteToAdminResponse,
    demoteToUser,
    demoteToUserResponse,
  } = useProfiles()
  const [banner, setBanner] = useState<{
    intent: BannerIntent
    message: string
  }>(null)

  const handleRoleChange = (role: Role): void => {
    if (role === Role.Admin) promoteToAdmin(profileId)
    if (role === Role.Member) demoteToUser(profileId)
    return null
  }

  useEffect(() => {
    if (promoteToAdminResponse?.data) {
      setBanner({
        intent: `success`,
        message: t`settings.users.edit.roleSuccess.admin`,
      })
    }
    if (demoteToUserResponse?.data) {
      setBanner({
        intent: `success`,
        message: t`settings.users.edit.roleSuccess.user`,
      })
    }
  }, [promoteToAdminResponse, demoteToUserResponse, t])

  const memberOption = {
    value: Role.Member,
    label: t`settings.users.edit.member`,
  }
  const adminOption = {
    value: Role.Admin,
    label: t`settings.users.edit.admin`,
  }
  const options = [memberOption, adminOption]

  return (
    <>
      <Modal.Header>{t`settings.users.edit.header`}</Modal.Header>
      <Modal.Body>
        <div
          className={`
            min-h-[--min-height-var-1]
          `}
          style={{
            '--min-height-var-1': `8rem`,
          }}
        >
          <div
            className={`
              grid
              grid-cols-[--grid-template-columns-var-1]
              items-center
              gap-1
            `}
            style={{
              '--grid-template-columns-var-1': `1fr 2fr`,
            }}
          >
            <Text>{t`settings.users.edit.password`}:</Text>
            <div>
              <Button
                type="button"
                variant="secondary"
                slim
                onClick={() =>
                  setContext({context: EditUserContext.RESET_PASSWORD})
                }
                disabled={sso}
              >
                {sso
                  ? t`settings.users.edit.ssoPasswordResetBtn`
                  : t`settings.users.edit.passwordBtn`}
              </Button>
            </div>
            <label htmlFor="role">
              <Text>{t`settings.users.edit.role`}:</Text>
            </label>
            <Select
              labelHidden
              id="role"
              name="role"
              value={isAdmin ? options[1] : options[0]}
              onChange={({value}) => handleRoleChange(value)}
              options={options}
            />
            <label
              className={`
              mt-3
              self-start
            `}
              htmlFor="datasets"
            >
              <Text>{t`settings.users.edit.datasets`}:</Text>
            </label>
            <UserSchemaSelector
              amzCognitoPoolId={amzCognitoPoolId}
              datasetIds={datasetIds}
              datasets={datasets}
              onChange={(value: string) =>
                setContext({context: EditUserContext.ADD_DATASET, value})
              }
              profileId={profileId}
            />
          </div>
          {banner && (
            <div className={`mt-4`}>
              <Banner intent={banner.intent}>{banner.message}</Banner>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ActionGroup>
          <Button type="button" variant="secondary" onClick={close}>
            {t`settings.users.edit.cancel`}
          </Button>
        </ActionGroup>
      </Modal.Footer>
    </>
  )
}

export default EditUserActions
