import {
  ActionGroup,
  Avatar,
  Banner,
  Button,
  Modal,
  Select,
  Text,
} from '@myadbox/gatsby-theme-nebula'
import {SelectOption} from '@myadbox/gatsby-theme-nebula/types'
import {useProfiles, useTeams} from '@myadbox/nebula-service-api'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {useEffect, useRef, useState} from 'react'
import {UserOption} from '../../../../utils/types'
import {getUserOptions} from '../../../../utils/userOptions'
import {DeleteUserModalProps} from './DeleteUserModal'

interface DeleteUserModalContentProps extends DeleteUserModalProps {
  close(): void
}

export const DeleteUserModalContent = ({
  avatar,
  close,
  fullName,
  keywords,
  userId,
  userPoolId,
}: DeleteUserModalContentProps) => {
  const {t} = useTranslation()
  const [selectedUserId, setSelectedUserId] = useState<string>(``)
  const originalFullName = useRef(fullName)
  const originalAvatar = useRef(avatar)
  const {
    fetchShareOptions,
    shareOptionsResult: {data: userListData, loading: userListLoading},
  } = useTeams()

  const {
    disableUserProfile,
    disableUserProfileResult: {
      data: disabledUserData,
      error: disabledUserError,
      loading: disableUserLoading,
    },
  } = useProfiles()

  useEffect(() => {
    if (!userListData && !userListLoading) {
      fetchShareOptions()
    }
  }, [userListData, fetchShareOptions, userListLoading])

  const handleConfirm = () => {
    if (disabledUserData) {
      close()
    } else {
      disableUserProfile({
        userId,
        amzCognitoPoolId: userPoolId,
        transferredTo: selectedUserId,
        keywords,
      })
    }
  }

  // Could expand to include data.getTeams
  const options: UserOption[] =
    userListData?.allUsersByAccountId
      ?.filter(user => user.userId !== userId)
      ?.map(user => ({
        value: user.userId,
        label: user.fullName,
        email: user.email,
        img: user.avatar,
        id: user.userId,
      })) || []

  return (
    <>
      <Modal.Header>{t`settings.users.delete.header`}</Modal.Header>
      <Modal.Body>
        {disabledUserError && (
          <Banner intent="error">{disabledUserError.message}</Banner>
        )}
        {disabledUserData && !disabledUserError && (
          <div className={`mb-4`}>
            <Banner intent="success">{t`settings.users.delete.complete`}</Banner>
          </div>
        )}
        <div className={`mb-4`}>
          <Text>{t`settings.users.delete.intro`}</Text>
        </div>
        <div className={`mb-2`}>
          <Text
            tag="h3"
            variant="heading"
          >{t`settings.users.delete.subHeader`}</Text>
        </div>
        <div className={`mb-4`}>
          <Text>{t`settings.users.delete.description`}</Text>
        </div>
        <div className={`pb-2`}>
          <Text
            color="textSecondary"
            variant="bodySmall"
          >{t`settings.users.delete.fromUser`}</Text>
        </div>
        <div
          className={`
            relative
            mb-4
            pl-10
          `}
        >
          <div
            className={`
              absolute
              left-0
              top-[50%]
              -translate-y-[50%]
            `}
          >
            <Avatar
              src={originalAvatar.current}
              title={originalFullName.current}
            />
          </div>
          {originalFullName.current}
        </div>

        <Select
          isSearchable
          label={t`settings.users.delete.toUser`}
          name="searchUsers"
          id="searchUsers"
          openDirection="up"
          onChange={(option: SelectOption): void =>
            setSelectedUserId(option.value.toString())
          }
          options={getUserOptions(options, ``)}
        />
      </Modal.Body>
      <Modal.Footer>
        {!disabledUserData && (
          <ActionGroup>
            <Button type="button" variant="text" onClick={close}>
              {t`settings.users.delete.cancel`}
            </Button>
            <Button
              disabled={!selectedUserId || disableUserLoading}
              loading={disableUserLoading}
              onClick={handleConfirm}
              type="button"
              variant={`danger`}
            >
              {t`settings.users.delete.removeAndTransfer`}
            </Button>
          </ActionGroup>
        )}
      </Modal.Footer>
    </>
  )
}

export default DeleteUserModalContent
