import {create} from 'zustand'

type ContentState = `connect` | `connected`

interface GoogleModalStore {
  isModalOpen: boolean
  contentState: ContentState
  setContentState: (content: ContentState) => void
  setModalOpen: () => void
}

export const useGoogleModalStore = create<GoogleModalStore>()(set => ({
  isModalOpen: false,
  contentState: `connect`,
  setContentState: (content: ContentState) => set({contentState: content}),
  setModalOpen: () => set(state => ({isModalOpen: !state.isModalOpen})),
}))
