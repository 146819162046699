type Props = {
  size: number | string
}

const LoaderIcon = ({size = 24}: Props) => {
  return (
    <svg
      className={`
        inline-block
        animate-[spin_1.5s_linear_infinite]
      `}
      width={size}
      height={size}
      viewBox={`0 0 22 22`}
      fill="currentColor"
      role="presentation"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.71395 3.01023C8.93808 3.51499 8.71058 4.10587 8.20582 4.33C5.73513 5.42707 4 7.93828 4 10.8698C4 14.823 7.14917 18 11 18C14.8508 18 18 14.823 18 10.8698C18 7.93828 16.2649 5.42707 13.7942 4.33C13.2894 4.10587 13.0619 3.51499 13.286 3.01023C13.5102 2.50547 14.1011 2.27797 14.6058 2.5021C17.786 3.91421 20 7.13344 20 10.8698C20 15.897 15.9857 20 11 20C6.01428 20 2 15.897 2 10.8698C2 7.13344 4.21397 3.91421 7.39418 2.5021C7.89894 2.27797 8.48982 2.50547 8.71395 3.01023Z"
      />
    </svg>
  )
}

export default LoaderIcon
