import {FETCH_TEAMS_BY_ACCOUNT} from './queries'

export const createTeamUpdater = () => {
  return (cache, {data}) => {
    const cachedData = cache.readQuery({query: FETCH_TEAMS_BY_ACCOUNT})

    if (!cachedData || !data) return

    cache.writeQuery({
      query: FETCH_TEAMS_BY_ACCOUNT,
      data: {
        getTeams: [...cachedData.getTeams, data.createTeam],
      },
    })
  }
}
