import {useMutation} from '@apollo/client'
import {useCallback} from 'react'
import {APPLY_VERSION} from '../../mutations'
import {ApplyVersionMutator, UseAssetVersionsOutput} from '../../types'

export const useAssetVersions = (): UseAssetVersionsOutput => {
  const [doApplyVersion, applyVersionResponse] = useMutation(APPLY_VERSION)
  const applyVersion: ApplyVersionMutator = useCallback(
    (assetId, versionIndex) => {
      return doApplyVersion({
        variables: {assetId, versionIndex},
      })
    },
    [doApplyVersion]
  )

  return {
    applyVersion,
    applyVersionResponse,
  }
}
