const exportDefaults = `jpgMax`

export const website = {
  banner: {
    main: {
      sizes: {
        large: {
          label: `Website Banner - large (1820×375)`,
          width: 1820,
          height: 375,
          exportDefaults,
        },
        extraLarge: {
          label: `Website Banner - x-large (2020×575)`,
          width: 2020,
          height: 575,
          exportDefaults,
        },
      },
    },
  },

  masthead: {
    main: {
      sizes: {
        small: {
          label: `Website Masthead - small (830×500)`,
          width: 830,
          height: 500,
          exportDefaults,
        },
        medium: {
          label: `Website Masthead - medium (1030×700)`,
          width: 1030,
          height: 700,
          exportDefaults,
        },
        large: {
          label: `Website Masthead - large (1820×550)`,
          width: 1820,
          height: 550,
          exportDefaults,
        },
        extraLarge: {
          label: `Website Masthead - x-large (2020×750)`,
          width: 2020,
          height: 750,
          exportDefaults,
        },
      },
    },
  },
} as const
