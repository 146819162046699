import type {ReactNode} from 'react'
import LayoutHeader from '../../LayoutHeader/LayoutHeader'
import MenuOpenButton from './MenuOpenButton/MenuOpenButton'

type HeaderProps = {
  children?: ReactNode
  endContent?: ReactNode
  headerMenu?: ReactNode
}

const Header = ({
  children,
  endContent,
  headerMenu = <div></div>,
  ...props
}: HeaderProps) => {
  return (
    <LayoutHeader
      startContent={<MenuOpenButton />}
      endContent={
        <>
          {endContent && (
            <div
              className={`
                flex
                items-center
                border-r
                pr-3
                md:hidden
              `}
            >
              {endContent}
            </div>
          )}
          {headerMenu}
        </>
      }
      {...props}
    >
      {children}
    </LayoutHeader>
  )
}

export default Header
