import {Select} from '@myadbox/gatsby-theme-nebula'
import {useDatasets} from '@myadbox/nebula-service-api'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {useEffect} from 'react'

export interface SchemaPickerProps {
  criteria: string[]
  value: string
  name: string
  label: string
  onChange: (value: string) => void
}

export const SchemaPicker = ({
  criteria,
  onChange,
  name,
  label,
  value,
}: SchemaPickerProps) => {
  const {t} = useTranslation()
  const {
    fetchSchemasWithDatasetsByFields,
    fetchSchemasWithDatasetsByFieldsResult: {data, loading, called},
  } = useDatasets()

  useEffect(() => {
    fetchSchemasWithDatasetsByFields(criteria)
  }, [fetchSchemasWithDatasetsByFields, criteria])

  if (loading || !called) return null

  const options =
    data.parentSchemasByFields?.map(({title, id}) => ({
      label: title,
      value: id,
    })) || []

  return (
    <Select
      noOptionsLabel={t(`settings.integrations.noDatablocksFound`, {
        fields: criteria,
      })}
      isLoading={loading}
      id={name}
      label={label}
      name={name}
      onChange={({value}) => onChange(value.toString())}
      options={options}
      value={options.find(o => o.value === value)}
    />
  )
}

export default SchemaPicker
