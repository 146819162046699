import {useCallback} from 'react'
import {useLazyQuery, useMutation} from '@apollo/client'
import {UPSERT_SHARES, UPDATE_SHARES} from '../../mutations'
import {FETCH_ACCESS} from '../../queries'
import {ASSETS_BY_IDS} from '../../../assets/queries'
import {
  UseAccessOutput,
  UpdateSharesMutator,
  UpsertSharesMutator,
} from '../../types'

export const useAccess = (): UseAccessOutput => {
  const [lazyFetchAccess, fetchAccessResults] = useLazyQuery(FETCH_ACCESS)
  const fetchAccess = useCallback(
    (ids: string[]) => lazyFetchAccess({variables: {ids}}),
    [lazyFetchAccess]
  )

  const [doUpsertShares, upsertSharesResponse] = useMutation(UPSERT_SHARES)
  const upsertShares: UpsertSharesMutator = useCallback(
    (shares, context) => {
      return doUpsertShares({
        variables: {inputs: shares, context},
        refetchQueries: [
          {
            query: ASSETS_BY_IDS,
            variables: {assetIds: context.assetIds},
          },
        ],
      })
    },
    [doUpsertShares]
  )

  const [doUpdateShares, updateSharesResponse] = useMutation(UPDATE_SHARES)
  const updateShares: UpdateSharesMutator = useCallback(
    (shares, context) => {
      return doUpdateShares({
        variables: {inputs: shares, context},
        refetchQueries: [
          {
            query: ASSETS_BY_IDS,
            variables: {assetIds: context.assetIds},
          },
        ],
      })
    },
    [doUpdateShares]
  )

  return {
    fetchAccess,
    fetchAccessResults,
    upsertShares,
    upsertSharesResponse,
    updateShares,
    updateSharesResponse,
  }
}
