import {
  ActionGroup,
  Banner,
  Button,
  Modal,
  Text,
} from '@myadbox/gatsby-theme-nebula'
import {useProfile} from '@myadbox/gatsby-theme-nebula/hooks'
import {useAssets} from '@myadbox/nebula-service-api'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {useRef} from 'react'

const ExportAssetsModal = () => {
  const {t} = useTranslation()
  const profile = useProfile()
  const {
    bulkAssetsExport,
    bulkAssetsExportResponse: {data, error, loading},
  } = useAssets()
  const prevData = useRef(null)

  return (
    <Modal
      maxWidth="sm"
      trigger={({open}) => (
        <Button
          name="exportAssetData"
          onClick={open}
          type="button"
          role="button"
          size="sm"
        >{t`settings.account.assetData.csvExporter.exportBtn`}</Button>
      )}
    >
      {({close}) => {
        if (!prevData.current && data) {
          prevData.current = data
        }
        return (
          <>
            <Modal.Header>{t`settings.account.assetData.csvExporter.header`}</Modal.Header>
            <Modal.Body>
              {error && <Banner intent="error">{error.message}</Banner>}
              {data && !error ? (
                <div className={`mb-4`}>
                  <Banner intent="info">{t`settings.account.assetData.csvExporter.requested`}</Banner>
                </div>
              ) : (
                <>
                  <Text variant="body">{t`settings.account.assetData.csvExporter.detail`}</Text>
                  <Text className={`my-2`}>{profile.email}</Text>
                  <Text variant="body">{t`settings.account.assetData.csvExporter.confirmation`}</Text>
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <ActionGroup>
                <Button
                  onClick={close}
                  disabled={!!data}
                >{t`settings.account.assetData.csvExporter.cancel`}</Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    if (data) {
                      close()
                    }
                    bulkAssetsExport()
                  }}
                  loading={loading}
                  loadingText={t`settings.account.assetData.csvExporter.preparing`}
                >
                  {data
                    ? t`settings.account.assetData.csvExporter.ok`
                    : t`settings.account.assetData.csvExporter.confirm`}
                </Button>
              </ActionGroup>
            </Modal.Footer>
          </>
        )
      }}
    </Modal>
  )
}

export default ExportAssetsModal
