import {HTMLAttributes} from 'react'

interface Props extends HTMLAttributes<HTMLElement> {
  bottom?: boolean
  /**
   * Use Tailwind CSS color classes to define the gradient `from` colors.
   * @example `from-ui-300 dark:from-ui-0`
   */
  fromColors?: String
  opacity?: number
}

export const className = {
  physical: `
    xl:h-18
    md:h-6
    until-md:h-4
    shrink-0
    pointer-events-none
    w-full
  `,
  bottom: `
    bottom-0
    translate-y-px
    bg-gradient-to-t
    z-10
  `,
  top: `
    top-0
    -translate-y-px
    bg-gradient-to-b
    z-0
  `,
}

export const ScrollGradient = ({
  bottom = false,
  fromColors = `from-ui-300 dark:from-ui-0`,
  opacity = 1,
  ...props
}: Props) => {
  const direction = bottom ? `top` : `bottom`

  return (
    <div
      data-testid="scroll-gradient"
      {...props}
      className={`
        ${className.physical}
        ${className[direction]}
        ${fromColors}
        absolute
        from-10%
      `}
      style={{opacity}}
    />
  )
}

export default ScrollGradient
