import {gql} from '@apollo/client'
import {CATEGORY_BASIC, CATEGORY_DETAIL} from './fragments'

export const ADD_CATEGORY = gql`
  ${CATEGORY_BASIC}
  ${CATEGORY_DETAIL}
  mutation CreateCategory($input: CategoryInput!) @api(name: "accounts") {
    createCategory(input: $input) {
      id
      ...CategoryBasic
      ...CategoryDetail
    }
  }
`

export const UPDATE_CATEGORY = gql`
  ${CATEGORY_BASIC}
  ${CATEGORY_DETAIL}
  mutation UpdateCategory($id: ID!, $input: CategoryInput!)
  @api(name: "accounts") {
    updateCategory(id: $id, input: $input) {
      id
      ...CategoryBasic
      ...CategoryDetail
    }
  }
`

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($id: ID!) @api(name: "accounts") {
    deleteCategory(id: $id) {
      id
    }
  }
`
