import {gql} from '@apollo/client'
import {CATEGORY_BASIC} from './fragments'

export const FETCH_CATEGORIES = gql`
  query Categories($isActive: Boolean) @api(name: "accounts") {
    categories(isActive: $isActive) {
      id
      active
      title
      createdAt
      createdById
      parent
    }
  }
`

export const FETCH_CATEGORIES_BY_IDS = gql`
  ${CATEGORY_BASIC}
  query CategoriesByIds($ids: [ID!]) @api(name: "accounts") {
    categoriesByIds(ids: $ids) {
      id
      ...CategoryBasic
      ancestors {
        id
        title
        parent
      }
    }
  }
`
