import {useState, useCallback} from 'react'

export interface UseTriesOutput {
  current: number
  remaining: number
  tried(): void
}

export const defaultMaxTries = 3

export const useTries = (
  maxTries: number = defaultMaxTries
): UseTriesOutput => {
  const [tries, setTries] = useState(maxTries)
  const tried = useCallback(() => setTries(tries => tries - 1), [])

  return {
    current: maxTries - tries,
    remaining: tries > 0 ? tries : 0,
    tried,
  }
}

export default useTries
