import {useState, useEffect} from 'react'

export interface CognitoAuthToken {
  accessToken?: {
    jwtToken: string
    payload: {
      exp: string
    }
  }
  refreshToken?: {
    token: string
  }
}

const useCognitoAuthToken = () => {
  const [state, setState] = useState<CognitoAuthToken>({})

  useEffect(() => {
    const cognitoAuthItem = localStorage.getItem(`cognito-session`)
    if (!cognitoAuthItem) return

    try {
      setState(JSON.parse(cognitoAuthItem))
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Failed to parse token`, e)
    }
  }, [])

  return state
}

export default useCognitoAuthToken
