import {isPageKey} from './guards'

export const calculatePageKey = (page: string | number) => {
  const pageKey = page.toString().padStart(3, `0`)
  return isPageKey(pageKey) ? pageKey : raise(`Invalid pageKey: ${pageKey}`)
}

const raise = (message: string): never => {
  throw new Error(message)
}
