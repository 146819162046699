type Props = React.SVGAttributes<SVGElement> & {
  className?: string
  size?: number
}

const ItalicTextIcon = ({size, ...rest}: Props) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...rest}>
    <path
      d="M13.5256 5.90909L11.3523 19H9.76704L11.9403 5.90909H13.5256Z"
      fill="currentColor"
    />
  </svg>
)

export default ItalicTextIcon
