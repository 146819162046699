import {exportDefaults as exportDefaultsObj} from './exportDefaults'

const exportDefaults = `videoMax`

// Ref: https://sproutsocial.com/insights/social-media-video-specs-guide/
export const video = {
  social: {
    facebook: {
      post: {
        sizes: {
          landscape: {
            label: `Facebook Video Post`,
            width: 1280,
            height: 720,
            exportDefaults,
          },
          portrait: {
            label: `Facebook Video Post (Portrait)`,
            width: 720,
            height: 1280,
            exportDefaults,
          },
        },
      },
    },
    instagram: {
      feed: {
        sizes: {
          square: {
            label: `Instagram In-Feed Video (Square)`,
            width: 1080,
            height: 1080,
            exportDefaults,
          },
          landscape: {
            label: `Instagram In-Feed Video (Landscape)`,
            width: 1920,
            height: 1080,
            exportDefaults,
          },
          portrait: {
            label: `Instagram In-Feed Video (Portrait)`,
            width: 1080,
            height: 1920,
            exportDefaults,
          },
        },
      },
    },
  },
  screen: {
    sizes: {
      sd: {
        label: `SD Video (720p)`,
        width: 1280,
        height: 720,
        exportDefaults,
      },
      hd: {
        label: `HD Video (1080p)`,
        width: 1920,
        height: 1080,
        exportDefaults,
      },
      qhd: {
        label: `QHD Video (2K)`,
        width: 2560,
        height: 1440,
        exportDefaults,
      },
      uhd: {
        label: `UHD Video (4K)`,
        width: 3840,
        height: 2160,
        exportDefaults,
      },
      prores422PCM: {
        label: `ProRes 422 (PCM Audio)`,
        width: 1920,
        height: 1080,
        ...exportDefaultsObj[`videoProRes`],
      },
    },
  },
} as const
