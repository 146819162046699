import {ZodTypeAny, z} from 'zod'

const alphaNumericRegex = /^[a-z0-9]+$/

export const assetIdSchema = z
  .string()
  .length(24, `Asset ID must be exactly 24 alphanumeric characters`)
  .refine(val => alphaNumericRegex.test(val), {
    message: `Asset ID may only contain alphanumeric characters`,
  })

const blankStringSchema = z
  .string()
  .length(0, `Field must be blank to disable brand guidelines`)

export const brandAssetIdChoiceSchema = z.object({
  brandAssetId: z.union([assetIdSchema, blankStringSchema]).optional(),
})

export type AssetIdSchema = z.infer<typeof assetIdSchema>
export type BrandAssetIdChoiceSchema = z.infer<typeof brandAssetIdChoiceSchema>

/**
 * Types that get passed to templates via postMessage
 */

const defaultDatasetsSchema = z.record(z.unknown())

/**
 * Creates a schema for an asset's JSON as sent to templates via postMessage.
 * @param datasetsSchema - The asset's attached datasets schema to use (optional).
 * @returns The asset JSON schema.
 *
 * @example
 *
 * ```ts
 * const imageWithDealershipSchema = createAssetMessageSchema(z.object({
 *   dealership: z.object({
 *     name: z.string(),
 *     address: z.string(),
 *   }),
 * }))
 *
 * // optionally derive a static type from the schema
 * type ImageWithDealership = z.infer<typeof imageWithDealershipSchema>
 *
 * // example parsed data
 * {
 *   url: 'https://example.com/image.jpg',
 *   name: 'Image',
 *   description: 'An image',
 *   id: '123456'
 *   datasets: {
 *     dealership: {
 *       name: 'My Dealership',
 *       address: '123 Main St',
 *     },
 *   },
 * }
 * ```
 *
 *
 */
export const createAssetMessageSchema = <
  TData extends ZodTypeAny = typeof defaultDatasetsSchema
>(
  datasetsSchema: TData = defaultDatasetsSchema as unknown as TData
) =>
  z.object({
    url: z.string().url(),
    name: z.string(),
    description: z.string(),
    id: assetIdSchema,
    datasets: datasetsSchema,
  })

const assetMessageSchema = createAssetMessageSchema()
export type AssetValue = z.infer<typeof assetMessageSchema>
