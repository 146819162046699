import {gql} from '@apollo/client'

export const COMMENT_INFO = gql`
  fragment CommentInfo on Comment {
    body
    userId
    resolved
    createdAt
  }
`

export const COMMENT_REPLY = gql`
  fragment CommentReply on Reply {
    body
    userId
    createdAt
  }
`
export const COMMENT_ANNOTATION = gql`
  fragment CommentAnnotation on AnnotationOutput {
    coordinates {
      x
      y
    }
    version
    page
  }
`
