import {ReactElement, useEffect} from 'react'

export const GoogleSheetRedirectListener = ({
  children,
}: {
  children: ReactElement
}) => {
  useEffect(() => {
    const url = window.location.href
    const handleTokenExtraction = () => {
      const urlObj = new URL(url)
      const regex = /(code).*(scope)/
      const isUrlValid = regex.test(url)
      if (urlObj && isUrlValid) {
        const source = `google-auth`
        const params = new URLSearchParams(urlObj.search)
        const code = params.get(`code`)
        const scope = params.get(`scope`)
        window.opener.postMessage(
          {payload: {code, scope}, source},
          window.location.origin
        )
        window.close()
      } else if (window.opener && url && !isUrlValid) {
        window.close()
      }
    }
    if (url.includes(`/integrations`)) {
      handleTokenExtraction()
    }
  }, [])
  return children
}
