import {
  ActionGroup,
  Banner,
  Button,
  Modal,
  Text,
} from '@myadbox/gatsby-theme-nebula'
import {NestedSchema, useDatasets} from '@myadbox/nebula-service-api'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next'
import {useEffect} from 'react'
import {getUsage} from './helpers'

interface Props {
  schema: NestedSchema
  close: () => void
}

const SchemaDelete = ({schema, close}: Props) => {
  const {t} = useTranslation()
  const {id, title} = schema

  const {
    fetchSchemaUsage,
    fetchSchemaUsageResult: {
      data: usageData,
      error: usageError,
      loading: usageLoading,
    },
    deleteSchema,
    deleteSchemaResult: {loading: deleteLoading, error: deleteError},
  } = useDatasets()

  useEffect(() => {
    fetchSchemaUsage(id)
  }, [id, fetchSchemaUsage])

  const usage = getUsage(usageData?.nestedSchemaUsage, t)

  return (
    <>
      <Modal.Header>{t`settings.schemas.removeTitle`}</Modal.Header>
      <Modal.Body>
        <div
          className={`
            grid
            gap-2
          `}
        >
          <Text>
            <Trans
              i18nKey={`settings.schemas.removeText`}
              values={{schemaTitle: title}}
              components={[<strong key="1" />]}
              tOptions={{
                interpolation: {escapeValue: false},
              }}
            />
          </Text>
          {usageLoading && (
            <div
              className={`
                bg-ui-50
                p-2
                text-xs
              `}
            >
              {t`settings.schemas.usage.loading`}
            </div>
          )}
          {usageError && (
            <Banner hasIcon={false} intent="error" size="sm">
              {t`settings.schemas.usage.error`}
            </Banner>
          )}
          {usage && (
            <Banner hasIcon={false} intent="warning" size="sm">
              <div>{t(`settings.schemas.usage.warning`, {usage})}</div>
            </Banner>
          )}
          {!usageLoading && !usageError && !usage && (
            <Banner hasIcon={false} size="sm">
              <div>{t(`settings.schemas.usage.proceed`, {usage})}</div>
            </Banner>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ActionGroup>
          <Button type="button" variant="secondary" onClick={close}>
            {t`settings.cancel`}
          </Button>
          <Button
            type="button"
            variant="danger"
            loading={deleteLoading}
            loadingText={t`settings.deleting`}
            disabled={usageLoading || deleteLoading}
            onClick={() => {
              deleteSchema(id)
            }}
          >
            {t`settings.removeConfirm`}
          </Button>
        </ActionGroup>
        {deleteError && (
          <div className="col-span-full mt-4">
            <Banner intent="error" fadeIn>
              <div>
                <span>{deleteError.message}</span>
              </div>
            </Banner>
          </div>
        )}
      </Modal.Footer>
    </>
  )
}

export default SchemaDelete
