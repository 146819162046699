const hasIntercom = (
  win: NonNullable<{}>
): win is {Intercom: (...args: unknown[]) => void} => {
  return (
    typeof win === `object` &&
    `Intercom` in win &&
    typeof win.Intercom === `function`
  )
}

const showNewMessage = () => {
  hasIntercom(window) && window.Intercom(`showNewMessage`)
}

const startTour = (tourId: number) => {
  hasIntercom(window) && window.Intercom(`startTour`, tourId)
}

const trackEvent = (eventId: string) => {
  hasIntercom(window) && window.Intercom(`trackEvent`, eventId)
}

export const Intercom = {
  hasIntercom,
  showNewMessage,
  startTour,
  trackEvent,
}

export default Intercom
