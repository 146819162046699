import {useCallback} from 'react'
import {useMutation, useLazyQuery} from '@apollo/client'
import {UPDATE_TEAM, CREATE_TEAM, DELETE_TEAM} from '../../mutations'
import {
  FETCH_TEAMS_BY_ACCOUNT,
  FETCH_TEAMS_BY_IDS,
  FETCH_TEAMS_BY_USER_ID,
  FETCH_SHARE_OPTIONS_DATA,
} from '../../queries'
import {createTeamUpdater} from '../../updaters'
import {
  UseTeamsOutput,
  CreateTeamMutator,
  UpdateTeamMutator,
  DeleteTeamMutator,
} from '../../types'

export const useTeams = (): UseTeamsOutput => {
  const [fetchAllTeams, allTeamsResult] = useLazyQuery(FETCH_TEAMS_BY_ACCOUNT)
  const [fetchShareOptions, shareOptionsResult] = useLazyQuery(
    FETCH_SHARE_OPTIONS_DATA
  )

  const [lazyFetchTeamsByIds, teamsByIdsResult] =
    useLazyQuery(FETCH_TEAMS_BY_IDS)
  const fetchTeamsByIds = useCallback(
    teamIds => lazyFetchTeamsByIds({variables: {teamIds}}),
    [lazyFetchTeamsByIds]
  )

  const [lazyFetchTeamsByUserId, teamsByUserIdResult] = useLazyQuery(
    FETCH_TEAMS_BY_USER_ID
  )
  const fetchTeamsByUserId = useCallback(
    (accountId, userId) =>
      lazyFetchTeamsByUserId({variables: {accountId, userId}}),
    [lazyFetchTeamsByUserId]
  )

  const [doCreateTeam, createTeamResponse] = useMutation(CREATE_TEAM)
  const createTeam: CreateTeamMutator = useCallback(
    input => {
      return doCreateTeam({
        variables: {input},
        update: createTeamUpdater(),
      })
    },
    [doCreateTeam]
  )

  const [doUpdateTeam, updateTeamResponse] = useMutation(UPDATE_TEAM)
  const updateTeam: UpdateTeamMutator = useCallback(
    (id, input) => {
      return doUpdateTeam({
        variables: {id, input},
      })
    },
    [doUpdateTeam]
  )

  const [doDeleteTeam, deleteMutationResult] = useMutation(DELETE_TEAM)
  const deleteTeam: DeleteTeamMutator = useCallback(
    id => {
      return doDeleteTeam({
        variables: {id},
        refetchQueries: [{query: FETCH_TEAMS_BY_ACCOUNT}],
      })
    },
    [doDeleteTeam]
  )

  return {
    fetchAllTeams,
    allTeamsResult,
    fetchTeamsByIds,
    teamsByIdsResult,
    fetchTeamsByUserId,
    teamsByUserIdResult,
    createTeam,
    createTeamResponse,
    updateTeam,
    updateTeamResponse,
    deleteTeam,
    deleteMutationResult,
    fetchShareOptions,
    shareOptionsResult,
  }
}
