import {FC, HTMLAttributes} from 'react'
import Text from '../../Text'

interface FooterProps extends HTMLAttributes<HTMLElement> {
  pageNum?: string
}

const Footer: FC<FooterProps> = ({pageNum}) => (
  <div
    className={`
      pointer-events-auto
      flex
      items-center
      justify-between
      p-4
    `}
  >
    <Text variant="heading" color="inherit">
      {pageNum}
    </Text>
  </div>
)

export default Footer
