type Props = React.SVGAttributes<SVGElement> & {
  className?: string
  size?: number
}

const BoldTextIcon = ({size, ...rest}: Props) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...rest}>
    <path
      d="M7.13779 19V5.90909H12.3793C13.3423 5.90909 14.1456 6.05184 14.7891 6.33736C15.4325 6.62287 15.9162 7.01917 16.2401 7.52628C16.5639 8.02912 16.7259 8.60866 16.7259 9.26491C16.7259 9.77628 16.6236 10.2258 16.419 10.6136C16.2145 10.9972 15.9332 11.3125 15.5753 11.5597C15.2216 11.8026 14.8168 11.9751 14.3608 12.0774V12.2053C14.8594 12.2266 15.326 12.3672 15.7607 12.6271C16.1996 12.8871 16.5554 13.2514 16.8281 13.7202C17.1009 14.1847 17.2372 14.7386 17.2372 15.3821C17.2372 16.0767 17.0646 16.6967 16.7195 17.2422C16.3786 17.7834 15.8736 18.2116 15.2045 18.527C14.5355 18.8423 13.7109 19 12.7308 19H7.13779ZM9.90554 16.7372H12.1619C12.9332 16.7372 13.4957 16.5902 13.8494 16.2962C14.2031 15.9979 14.38 15.6016 14.38 15.1072C14.38 14.745 14.2926 14.4254 14.1179 14.1484C13.9432 13.8714 13.6939 13.6541 13.37 13.4964C13.0504 13.3388 12.669 13.2599 12.2259 13.2599H9.90554V16.7372ZM9.90554 11.3871H11.9574C12.3367 11.3871 12.6733 11.321 12.9673 11.1889C13.2656 11.0526 13.5 10.8608 13.6705 10.6136C13.8452 10.3665 13.9325 10.0703 13.9325 9.72514C13.9325 9.25213 13.7642 8.87074 13.4276 8.58096C13.0952 8.29119 12.6222 8.1463 12.0085 8.1463H9.90554V11.3871Z"
      fill="currentColor"
    />
  </svg>
)

export default BoldTextIcon
