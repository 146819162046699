import {cloneElement, ReactChild, ReactNode} from 'react'

const addStripe =
  className =>
  (child, index): ReactNode => {
    const childClassName =
      index % 2
        ? child.className
        : `${className} ${child.props.className || ``}`

    return cloneElement(child, {
      className: childClassName,
      key: index,
    })
  }

export const useStripedChildren = (
  children = [],
  className: string
): ReactChild[] => {
  const childCollection = Array.isArray(children) ? children : [children]

  const result = className
    ? childCollection.map(addStripe(className))
    : childCollection
  return result as ReactChild[]
}
export default useStripedChildren
