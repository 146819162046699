import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'

// Import locales
import 'dayjs/locale/en-au'
import 'dayjs/locale/en'
import 'dayjs/locale/fr-ca'
import 'dayjs/locale/id'
import 'dayjs/locale/ko'
import 'dayjs/locale/pt-br'

dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)

const SUPPORTED_LOCALES = [
  `en-AU`,
  `en-US`,
  `fr-CA`,
  `id-ID`,
  `ko-KR`,
  `pt-BR`,
] as const
export type SupportedLocales = (typeof SUPPORTED_LOCALES)[number]

export const shortLocaleFormats: {
  dateAndTime: {[key in SupportedLocales]: string}
  date: Record<SupportedLocales, string>
} = {
  dateAndTime: {
    'en-AU': `DD/MM/YY, h:mm A`,
    'en-US': `MM/DD/YY, h:mm A`,
    'fr-CA': `YYYY-MM-DD, HH:mm`,
    'id-ID': `DD/MM/YY, HH:mm`,
    'ko-KR': `YY. MM. DD. A h:mm`,
    'pt-BR': `DD/MM/YY, HH:mm`,
  },
  date: {
    'en-AU': `DD/MM/YY`,
    'en-US': `MM/DD/YY`,
    'fr-CA': `YYYY-MM-DD`,
    'id-ID': `DD/MM/YYYY`,
    'ko-KR': `YY. MM. DD.`,
    'pt-BR': `DD/MM/YYYY`,
  },
}

export const supportedLocaleFormats: {
  dateAndTime: {[key in SupportedLocales]: string}
} = {
  dateAndTime: {
    'en-AU': `DD/MM/YYYY, h:mm A`,
    'en-US': `MM/DD/YYYY, h:mm A`,
    'fr-CA': `YYYY-MM-DD, HH:mm`,
    'id-ID': `DD/MM/YYYY, HH:mm`,
    'ko-KR': `YY. MM. DD. A h:mm`,
    'pt-BR': `DD/MM/YYYY, HH:mm`,
  },
}

export const localeMap: {
  [key in SupportedLocales]: string
} = {
  'en-AU': `en-au`,
  'en-US': `en`,
  'fr-CA': `fr-ca`,
  'id-ID': `id`,
  'ko-KR': `ko`,
  'pt-BR': `pt-br`,
}
