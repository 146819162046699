import {useLazyQuery, useMutation} from '@apollo/client'
import {useCallback} from 'react'
import {CREATE_SHARE_LINK} from '../../mutations'
import {SHARE_LINK} from '../../queries'
import {ShareLinkResult} from '../../types'

export const useShareLink = () => {
  const [doFetchShareLink, fetchShareLinkResponse] = useLazyQuery(SHARE_LINK)
  const fetchShareLink = useCallback(
    id => {
      doFetchShareLink({variables: {id}})
    },
    [doFetchShareLink]
  )

  const [doCreateShareLink, createShareLinkResponse] = useMutation<{
    createShareLink: ShareLinkResult
  }>(CREATE_SHARE_LINK)
  const createShareLink = useCallback(
    assetIds => {
      return doCreateShareLink({variables: {assetIds}})
    },
    [doCreateShareLink]
  )

  return {
    fetchShareLink,
    fetchShareLinkResponse,
    createShareLink,
    createShareLinkResponse,
  }
}
