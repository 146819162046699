import {useLazyQuery, useMutation} from '@apollo/client'
import {ColorMode} from '@myadbox/nebula-template-utils'
import {useCallback} from 'react'
import {
  ADD_ASSETS,
  BULK_UPDATE_ASSETS,
  DELETE_ASSETS,
  DERIVE_ASSET,
  IMPORT_BULK_ASSETS,
  INTERACT_WITH_ASSET,
  REMOVE_THUMBNAIL,
  UPDATE_ASSET,
  UPDATE_ASSET_SOURCE,
  UPDATE_COMMON_ATTRIBUTES,
  UPDATE_CURRENT_VERSION,
  UPDATE_THUMBNAIL,
} from '../../mutations'
import {
  ASSETS_BY_IDS,
  ASSET_STATS_OBJ,
  ASSET_URL_WITH_COLOR_PROFILE,
  BULK_ASSETS_EXPORT,
} from '../../queries'
import {
  AddAssetsMutator,
  BulkUpdateAssetsMutator,
  DeleteAssetsMutator,
  DeriveAssetMutator,
  ImportAssetsWithCSVMutator,
  InteractWithAssetMutator,
  RemoveThumbnailMutator,
  UpdateAssetMutator,
  UpdateAssetSourceMutator,
  UpdateCommonAttributesMutator,
  UpdateCurrentVersionMutator,
  UpdateThumbnailMutator,
  UseAssetsOutput,
} from '../../types'
import {useShareLink} from './useShareLink'

export const useAssets = (): UseAssetsOutput => {
  const [assetsByIds, fetchAssetsResults] = useLazyQuery(ASSETS_BY_IDS)
  const fetchAssets = useCallback(
    (assetIds: string[]) => {
      assetsByIds({variables: {assetIds}})
    },
    [assetsByIds]
  )

  const [doAddAssets, addAssetsResponse] = useMutation(ADD_ASSETS)
  const addAssets: AddAssetsMutator = useCallback(
    input => {
      return doAddAssets({
        variables: {input},
      })
    },
    [doAddAssets]
  )

  const [doDeriveAsset, deriveAssetResponse] = useMutation(DERIVE_ASSET)
  const deriveAsset: DeriveAssetMutator = useCallback(
    input => {
      return doDeriveAsset({
        variables: {input},
      })
    },
    [doDeriveAsset]
  )

  const [assetUrlWithColorProfile, fetchAssetUrlWithColorProfileResponse] =
    useLazyQuery(ASSET_URL_WITH_COLOR_PROFILE)
  const fetchAssetUrlWithColorProfile = useCallback(
    (assetId: string, colorProfile: ColorMode) => {
      return assetUrlWithColorProfile({
        variables: {assetId, colorProfile},
      })
    },
    [assetUrlWithColorProfile]
  )

  const [doUpdateAsset, updateAssetResponse] = useMutation(UPDATE_ASSET)
  const updateAsset: UpdateAssetMutator = useCallback(
    (assetId, input) => {
      return doUpdateAsset({
        variables: {assetId, input},
      })
    },
    [doUpdateAsset]
  )

  const [doBulkUpdateAssets, bulkUpdateAssetsResponse] =
    useMutation(BULK_UPDATE_ASSETS)
  const bulkUpdateAssets: BulkUpdateAssetsMutator = useCallback(
    params => {
      return doBulkUpdateAssets({
        variables: {params},
      })
    },
    [doBulkUpdateAssets]
  )

  const [doUpdateAssetSource, updateAssetSourceResponse] =
    useMutation(UPDATE_ASSET_SOURCE)
  const updateAssetSource: UpdateAssetSourceMutator = useCallback(
    (assetId, input) => {
      return doUpdateAssetSource({
        variables: {assetId, input},
      })
    },
    [doUpdateAssetSource]
  )

  const [doImportAssetsWithCSV, importAssetsWithCSVResponse] =
    useMutation(IMPORT_BULK_ASSETS)
  const importAssetsWithCSV: ImportAssetsWithCSVMutator = cloudinaryId => {
    return doImportAssetsWithCSV({
      variables: {cloudinaryId},
    })
  }

  const [doInteractWithAsset, interactWithAssetResponse] =
    useMutation(INTERACT_WITH_ASSET)
  const interactWithAsset: InteractWithAssetMutator = useCallback(
    (assetIds, eventType) => {
      return doInteractWithAsset({
        variables: {assetIds, eventType},
      })
    },
    [doInteractWithAsset]
  )

  const [doUpdateCommonAttributes, updateCommonAttributesResponse] =
    useMutation(UPDATE_COMMON_ATTRIBUTES)
  const updateCommonAttributes: UpdateCommonAttributesMutator = useCallback(
    input => {
      return doUpdateCommonAttributes({
        variables: {input},
        refetchQueries: [
          {
            query: ASSETS_BY_IDS,
            variables: {assetIds: input.assetIds},
          },
        ],
      })
    },
    [doUpdateCommonAttributes]
  )

  const [doDeleteAssets, deleteAssetsResponse] = useMutation(DELETE_ASSETS)
  const deleteAssets: DeleteAssetsMutator = useCallback(
    assetIds => {
      return doDeleteAssets({
        variables: {assetIds},
      })
    },
    [doDeleteAssets]
  )

  const [doBulkAssetsExport, bulkAssetsExportResponse] =
    useLazyQuery(BULK_ASSETS_EXPORT)
  const bulkAssetsExport = useCallback(() => {
    return doBulkAssetsExport()
  }, [doBulkAssetsExport])

  const [doFetchAssetStats, fetchAssetStatsResults] =
    useLazyQuery(ASSET_STATS_OBJ)
  const fetchAssetStats = useCallback(() => {
    return doFetchAssetStats()
  }, [doFetchAssetStats])

  const [doUpdateCurrentVersion, updateCurrentVersionResponse] = useMutation(
    UPDATE_CURRENT_VERSION
  )
  const updateCurrentVersion: UpdateCurrentVersionMutator = useCallback(
    (assetId, input) => {
      return doUpdateCurrentVersion({
        variables: {assetId, input},
      })
    },
    [doUpdateCurrentVersion]
  )

  const [doUpdateThumbnail, updateThumbnailResponse] =
    useMutation(UPDATE_THUMBNAIL)
  const updateThumbnail: UpdateThumbnailMutator = useCallback(
    (assetId, thumbnailId) => {
      return doUpdateThumbnail({
        variables: {assetId, thumbnailId},
        refetchQueries: [
          {
            query: ASSETS_BY_IDS,
            variables: {assetIds: [assetId]},
          },
        ],
      })
    },
    [doUpdateThumbnail]
  )

  const [doRemoveThumbnail, removeThumbnailResponse] =
    useMutation(REMOVE_THUMBNAIL)
  const removeThumbnail: RemoveThumbnailMutator = useCallback(
    assetId => {
      return doRemoveThumbnail({
        variables: {assetId},
        refetchQueries: [
          {
            query: ASSETS_BY_IDS,
            variables: {assetIds: [assetId]},
          },
        ],
      })
    },
    [doRemoveThumbnail]
  )

  const {
    fetchShareLink,
    fetchShareLinkResponse,
    createShareLink,
    createShareLinkResponse,
  } = useShareLink()

  return {
    fetchAssets,
    fetchAssetsResults,
    fetchAssetUrlWithColorProfile,
    fetchAssetUrlWithColorProfileResponse,
    addAssets,
    addAssetsResponse,
    deriveAsset,
    deriveAssetResponse,
    deleteAssets,
    deleteAssetsResponse,
    importAssetsWithCSV,
    importAssetsWithCSVResponse,
    updateAsset,
    updateAssetResponse,
    updateAssetSource,
    updateAssetSourceResponse,
    interactWithAsset,
    interactWithAssetResponse,
    updateCommonAttributes,
    updateCommonAttributesResponse,
    bulkAssetsExport,
    bulkAssetsExportResponse,
    bulkUpdateAssets,
    bulkUpdateAssetsResponse,
    fetchAssetStats,
    fetchAssetStatsResults,
    updateCurrentVersion,
    updateCurrentVersionResponse,
    updateThumbnail,
    updateThumbnailResponse,
    removeThumbnail,
    removeThumbnailResponse,
    fetchShareLink,
    fetchShareLinkResponse,
    createShareLink,
    createShareLinkResponse,
  }
}
