import {HTMLInputTypeAttribute} from 'react'
import {cn} from '../../util'
import {InputProps, InputSizeVariant} from './types'

export const inputClasses = `
  focus:shadow-outline
  focus:outline-none
  rounded-400
  border
  block
  form-input
  duration-150
  ease-in-out
  transition-default
  w-full
`

export const enabledClassName = `
  focus:bg-white
  hover:bg-white
  hocus:text-ui-1000
  bg-ui-50
  text-ui-800
  dark:hocus:bg-ui-200
  dark:bg-ui-200
  dark:border-ui-200
`

export const disabledClassName = `
  bg-ui-200
  border-ui-200
  text-ui-500
  dark:text-ui-300
  dark:bg-ui-50
  dark:border-ui-50
  cursor-not-allowed
`

export const textAlignClassName = {
  left: `text-left`,
  right: `text-right`,
  center: `text-center`,
}

export const sizesClassName = {
  md: `
    sm:text-sm
    sm:leading-5
    px-3
    py-2
  `,
  sm: `
    sm:text-xs
    sm:leading-5
    px-2
    py-1
  `,
} as const

export const typeClassName: Partial<{[key in HTMLInputTypeAttribute]: string}> =
  {
    color: `
      p-0
      [&::-webkit-color-swatch]:border-0
      [&::-webkit-color-swatch-wrapper]:p-0
    `,
  }

export const getInputClassName = (props: Partial<InputProps>) => {
  const variant: InputSizeVariant = props.variant || `md`
  const classNameProps = props.className || ``
  const isStyledDisabled = props.disabled || props.readOnly
  return cn(
    isStyledDisabled ? disabledClassName : enabledClassName,
    inputClasses,
    sizesClassName[variant],
    props.type && typeClassName[props.type],
    classNameProps
  )
}
