import {gql} from '@apollo/client'
import {ACCESS_DETAIL, EXTERNAL_SHARE_DETAIL} from './fragments'

export const FETCH_ACCESS = gql`
  ${ACCESS_DETAIL}
  query getAccess($ids: [String!]!) @api(name: "access") {
    access(ids: $ids) {
      id
      name
      ...AccessDetail
    }
  }
`

/**
 * External Shares
 */

export const GET_EXTERNAL_SHARES = gql`
  ${EXTERNAL_SHARE_DETAIL}
  query GetExternalShares($assetIds: [String!]!) @api(name: "access/external") {
    getExternalShares(assetIds: $assetIds) {
      id
      ...ExternalShareDetail
    }
  }
`

export const VERIFY_EXTERNAL_SHARE_TOKEN = gql`
  query VerifyShareToken($token: String!) @api(name: "access/external") {
    verifyShareToken(token: $token) {
      share {
        id
        email
        expiry
        accessLevel
      }
      assets {
        id
        name
        description
        type
        versions {
          cloudinaryId
          previewId
          thumbnailId
          originalFilename
          originalFormat
          bytes
          resourceType
        }
        terms {
          details
          expiry
        }
      }
    }
  }
`
