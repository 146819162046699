import ReactSelect, {GroupBase, Props} from 'react-select'
import FormItem from '../FormItem'
import {NativeSelect, NativeSelectOption} from './NativeSelect/NativeSelect'

import {OurVirtualMenuList} from './OurMenuList/OurMenuList'
import SelectFormik from './SelectFormik'
import {ourSelectComponents, usePortalTarget} from './helpers'
import {BaseSelectProps, OptionData} from './types'

export const styles = {
  menuPortal: (base = {}) => ({...base, zIndex: 9999}),
}

function Select<
  Option = OptionData,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  noOptionsLabel = `No options`,
  disabled,
  inlineActions,
  readOnly,
  label = `Select an option`,
  details,
  labelHidden,
  findCurrentByProperty,
  inModal,
  openDirection = `down`,
  virtualList,
  isSearchable = false,
  isClearable = false,
  id: inputId,
  placeholder = `Select...`,
  options = [],
  value,
  isMulti,
  components = {},
  variants = [],
  ...rest
}: Props<Option, IsMulti, Group> & BaseSelectProps) {
  const passProps = {
    placeholder: placeholder.toString(),
    isSearchable,
    isClearable,
    inputId,
    styles,
    options,
    label,
    openDirection,
    virtualList,
    readOnly,
    variants,
    ...rest,
  }

  const {target} = usePortalTarget()

  const mergedComponents = {
    ...ourSelectComponents,
    MenuList: virtualList ? OurVirtualMenuList : ourSelectComponents.MenuList,
    // override any of the above components if the user has passed in their own…
    ...components,
  }

  return (
    <FormItem
      label={
        labelHidden ? null : (
          <FormItem.Label htmlFor={inputId} dim={readOnly}>
            {label}
          </FormItem.Label>
        )
      }
      details={details}
    >
      <ReactSelect
        {...passProps}
        unstyled
        id={`${inputId}-select`}
        isDisabled={disabled}
        isMulti={isMulti}
        value={value}
        aria-label={label}
        isOptionDisabled={(option: OptionData): boolean => option.disabled}
        components={mergedComponents}
        noOptionsMessage={() => noOptionsLabel}
        menuPortalTarget={inModal ? target : undefined}
        menuPosition={inModal ? `fixed` : `absolute`}
      />
      {inlineActions}
    </FormItem>
  )
}

Select.Native = NativeSelect
Select.NativeOption = NativeSelectOption
Select.Formik = SelectFormik

export default Select
