import {TFunction} from 'i18next'
import * as Yup from 'yup'

// See here for name validation regex tests https://regexr.com/71h1q
export const NAME_REGEX = /^([a-zA-Z]+[-'’\p{L} ]+(?: [a-zA-Z'-.]+)?)/gu

export const getValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    fullName: Yup.string()
      .matches(
        NAME_REGEX,
        t`settings.users.form.validation.name.invalid` as string
      )
      .min(2, t`settings.users.form.validation.name.min` as string)
      .max(50, t`settings.users.form.validation.name.max` as string)
      .required(t`settings.users.form.validation.name.required` as string),
    email: Yup.string()
      .email(t`settings.users.form.validation.email.invalid` as string)
      .required(t`settings.users.form.validation.email.required` as string),
  })

export const nameValidationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(NAME_REGEX, `Please enter a valid name`)
    .min(2, `Name must be at least 2 characters long`)
    .max(50, `Name cannot be over 50 characters long`)
    .required(`Name field cannot be empty`),
})

export const emailValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(`Please enter a valid email`)
    .required(`Email field cannot be empty`),
})
