import {gql} from '@apollo/client'

export const BASIC_ACCOUNT = gql`
  fragment BasicAccount on Account {
    name
    accountName
  }
`

export const PRODUCTS = gql`
  fragment Products on Account {
    products {
      awards
      funds
      planner
      workflows
      brand
      approvals
      brandTitan
    }
  }
`

export const BILLING = gql`
  fragment Billing on Account {
    billing {
      limits {
        storage
        users
        variables
      }
    }
  }
`
