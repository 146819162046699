import {useContext, useDebugValue} from 'react'
import {blankUser} from '@myadbox/nebula-service-api'
import {UserContext} from './UserProvider'
import {getDefaultTimezone} from '../../utils/timezone'

const defaultProfile = {...blankUser, timezone: getDefaultTimezone()}

export const useProfile = () => {
  const profile = useContext(UserContext)

  useDebugValue(`profile: ${profile}`)
  return profile ?? defaultProfile
}

export default useProfile
