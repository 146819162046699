import {FC, InputHTMLAttributes, ReactNode} from 'react'
import FormItem from '../FormItem/FormItem'
import RangeInputFormik from './RangeInputFormik'
import './range-overrides.css'

export interface RangeInputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string
  label: string | ReactNode
  details?: ReactNode
  name: string
  labelHidden?: boolean
  readOnly?: boolean
  message?: string | ReactNode
  scaleErrorMessage?: boolean
  className?: string
}

type RangeInputGroup = {
  RangeInput?: typeof RangeInput
  Formik?: typeof RangeInput
}

export const inputClasses = `
  w-full
  cursor-pointer
  bg-ui-50
  appearance-none
  rounded-3xl
  overflow-hidden
`

export const inputStyles = {
  accentColor: `#1C64F2`,
}

export const enabledClassName = ``

export const disabledClassName = `
  opacity-50
  cursor-not-allowed
`

export const getInputClassName = (props: Partial<RangeInputProps>) => {
  return `
    ${inputClasses}
    ${props.className || ``}
    ${props.disabled || props.readOnly ? disabledClassName : enabledClassName}
  `
}

const RangeInput: FC<RangeInputProps> & RangeInputGroup = ({
  label,
  details,
  name,
  id,
  labelHidden,
  readOnly,
  className,
  ...props
}) => {
  if (!(name && label && id)) return null

  const classNames = `${getInputClassName({...props, className, readOnly})}`

  return (
    <FormItem
      label={
        labelHidden ? null : (
          <FormItem.Label htmlFor={id} dim={readOnly}>
            {label}
          </FormItem.Label>
        )
      }
      details={details}
    >
      <input
        className={classNames}
        id={id}
        name={name}
        readOnly={readOnly}
        aria-label={labelHidden ? `${label}` : null}
        type="range"
        style={inputStyles}
        {...props}
      />
    </FormItem>
  )
}

RangeInput.Formik = RangeInputFormik
export default RangeInput
