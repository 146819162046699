import {SVGAttributes} from 'react'
import TheLogoText from './TheLogoText'

const TheLogo = ({className = ``, ...props}: SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 100 100"
      height="80"
      width="80"
      className={`${className}`}
      role="presentation"
      {...props}
    >
      <rect fill="#fff" width="19.44" height="100" />
      <rect fill="#fff" x="80.56" width="19.44" height="100" />
      <polygon fill="#fff" points="61.11 0 80.56 0 69.44 100 50 100 61.11 0" />
      <polygon
        fill="#fff"
        points="41.67 0 61.11 0 38.89 100 19.44 100 41.67 0"
      />
    </svg>
  )
}

TheLogo.Text = TheLogoText
export default TheLogo
