import {gql} from '@apollo/client'
import {BRAND_SETTINGS} from './fragments'

export const UPDATE_BRAND = gql`
  ${BRAND_SETTINGS}
  mutation UpdateBrand($input: BrandInput!) @api(name: "brands") {
    updateBrand(input: $input) {
      id
      ...BrandSettings
    }
  }
`
