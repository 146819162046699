import {Format} from '../types'

export const allFormats = {
  audio: [Format.MP3, Format.WAV],

  email: [Format.HTML],

  print: [Format.PDF, Format.JPG, Format.PNG],

  video: [Format.MP4, Format.MOV],

  web: {
    static: [Format.HTML, Format.JPG, Format.PNG],
    animated: [Format.HTML, Format.GIF, Format.JPG, Format.PNG],
  },
}
