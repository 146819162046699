const markdownStyles = {
  ltr: `text-left`,
  rtl: `text-right`,
  placeholder: `text-gray-400 overflow-hidden absolute overflow-ellipsis top-3 left-2 text-base select-none inline-block pointer-events-none`,
  list: {
    nested: {
      listitem: `list-none`,
    },
    ol: `list-decimal p-0 m-0 ml-2`,
    ul: `list-disc p-0 m-0 ml-2`,
    listitem: `my-0.5 mx-2`,
  },
  image: `editor-image`,
  text: {
    bold: `font-bold`,
    italic: `italic`,
    overflowed: `editor-text-overflowed`,
    strikethrough: `line-through`,
  },
}

export default markdownStyles
