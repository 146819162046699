import {Modal} from '@myadbox/gatsby-theme-nebula'
import {useGoogleModalStore} from '../hooks'
import {useTranslation} from 'react-i18next'
import withTranslationProvider from '../../../locales/withTranslationProvider'
import {ActionGroup, Button, GoogleSheetsIcon, Text} from '@myadbox/stellar-ui'
import {useGoogleIntegrations} from 'packages/data/nebula-service-api'
import {useEffect} from 'react'

const GoogleIntegrationsSynced = ({id}: {id: string}) => {
  const setGoogleModalOpen = useGoogleModalStore(state => state.setModalOpen)
  const {
    fetchGoogleSheetBySchemaId,
    fetchBySchemaIdResult: {data},
  } = useGoogleIntegrations()
  const {fetchGoogleSheetBySchemaId: integration} = data ?? {}

  const {t} = useTranslation(`googleIntegrations`)

  useEffect(() => {
    fetchGoogleSheetBySchemaId(id)
  }, [fetchGoogleSheetBySchemaId, id])

  return (
    <>
      <Modal.Body>
        <div className="flex flex-col items-start justify-center gap-3">
          <GoogleSheetsIcon size={30} />
          <Text>{t(`connected.body`)}</Text>
          <div className="max-w-full text-wrap">
            <Text>
              {t(`connected.urlkey`)}
              <a
                href={integration?.url ?? `#`}
                target="_blank"
                rel="noreferrer"
                className="hocus:underline text-wrap break-words text-blue-500 visited:text-blue-800 active:text-blue-800"
              >
                {t(`connected.urlvalue`, {url: integration?.url})}
              </a>
            </Text>
          </div>
          <Text>
            {t(`connected.uniquecolumn`, {
              uniqueColumn: integration?.uniqueColumn,
            })}
          </Text>
          <Text>
            {t(`connected.sheetname`, {
              sheetName: integration?.sheetName,
            })}
          </Text>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ActionGroup>
          <Button
            variant="secondary"
            type="button"
            onClick={setGoogleModalOpen}
          >
            {t(`connect.cancel`)}
          </Button>
        </ActionGroup>
      </Modal.Footer>
    </>
  )
}

export default withTranslationProvider(GoogleIntegrationsSynced)
