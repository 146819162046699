import {MutationResult} from '@apollo/client'
import {
  ActionGroup,
  Banner,
  Button,
  Modal,
  Text,
  Toast,
  UploadField,
} from '@myadbox/gatsby-theme-nebula'
import {downloadErrorTextFile} from '@myadbox/gatsby-theme-nebula/utils'
import {BulkUpdateProfilesMutator, User} from '@myadbox/nebula-service-api'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {ReactNode, useEffect, useState} from 'react'
import {convertUserData, transformHeaders, validateData} from './helpers'

type BulkUploadModalProps = {
  isOpen: boolean
  onClose: () => void
  setOpenCsvUploadModal: (flag: boolean) => void
  bulkUpdateProfiles: BulkUpdateProfilesMutator
  bulkUpdateProfilesResponse: MutationResult<{bulkUpdateProfiles: User[]}>
}

export const BulkUploadModal = ({
  isOpen,
  onClose,
  setOpenCsvUploadModal,
  bulkUpdateProfiles,
  bulkUpdateProfilesResponse: {data, error, loading},
}: BulkUploadModalProps) => {
  const {t} = useTranslation()
  const [userData, setUserData] = useState(null)
  const [csvError, setCsvError] = useState({})
  const [bulkUpdateResponse, setBulkUpdateResponse] = useState(null)

  useEffect(() => {
    !isOpen && setCsvError({})
  }, [isOpen])

  useEffect(() => {
    if (userData) setCsvError({})
  }, [setCsvError, userData])

  useEffect(() => {
    if (data && !error) {
      setBulkUpdateResponse(data)
    }
  }, [data, error])

  useEffect(() => {
    if (bulkUpdateResponse && !error) {
      Toast.show(
        <Toast intent="success">
          {t(`settings.users.bulkActions.csvUploader.successMsg`)}
        </Toast>
      )
      setOpenCsvUploadModal(false)
      setBulkUpdateResponse(null)
    }
  }, [setOpenCsvUploadModal, bulkUpdateResponse, error, t])

  const handleSubmit = () => {
    if (!userData) return
    const errors = validateData(userData, t)
    if (Object.keys(errors).length === 0) {
      const convertedData = convertUserData(userData)
      bulkUpdateProfiles(convertedData)
    } else {
      setCsvError(errors)
    }
  }

  return (
    <Modal
      accessibleTitle={t`settings.users.bulkActions.csvUploader.title`}
      controls={{isOpen, onClose}}
      maxWidth="md"
    >
      {({close}): ReactNode => (
        <>
          <Modal.Header>{t`settings.users.bulkActions.csvUploader.header`}</Modal.Header>
          <form
            name="csv-upload-form"
            onSubmit={e => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            <Modal.Body>
              <div className="grid gap-4">
                <Text tag="p">{t`settings.users.bulkActions.csvUploader.uploadInfo`}</Text>
                <UploadField
                  id="usersCsvData"
                  name="csvData"
                  label={t`settings.users.bulkActions.csvUploader.csvFieldLable`}
                  onUpload={setUserData}
                  headerTransformer={transformHeaders}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              {(!!error || Object.keys(csvError).length !== 0) && (
                <div className="col-span-full mt-4">
                  <Banner intent="error" fadeIn>
                    <div>
                      <span>
                        {error?.message ||
                          t`settings.users.bulkActions.csvUploader.dataErrorMsg`}
                      </span>
                    </div>
                  </Banner>
                </div>
              )}
              {Object.keys(csvError).length !== 0 && (
                <Button
                  slim
                  size="sm"
                  variant="link"
                  className={`ml-2`}
                  onClick={() =>
                    downloadErrorTextFile(csvError, `users-csv-upload-errors`)
                  }
                >
                  {t`settings.users.bulkActions.csvUploader.csvErrorDownloadBtn`}
                </Button>
              )}
              <ActionGroup>
                <Button type="button" variant="secondary" onClick={close}>
                  {t`settings.users.bulkActions.csvUploader.cancelBtn`}
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={!userData || loading}
                  loading={loading}
                  loadingText={t`settings.users.bulkActions.loading`}
                >
                  {t`settings.users.bulkActions.csvUploader.uploadBtn`}
                </Button>
              </ActionGroup>
            </Modal.Footer>
          </form>
        </>
      )}
    </Modal>
  )
}

export default BulkUploadModal
