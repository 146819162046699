// This is part of a utility component that requires no storybook documentation and should be excluded from UI library.
import {FC} from 'react'
import Text from '../Text'
import Button from '../Button'
import Banner from '../Banner'

interface ErrorFallbackGroup {
  DefaultFinalMessage?: FC
}

interface Props {
  error: Error
  expired?: boolean
  FinalMessage?: JSX.Element
  remainingMessage?: JSX.Element
  resetErrorBoundary(): void
}

const ErrorFallback: FC<Props> & ErrorFallbackGroup = ({
  error,
  expired,
  FinalMessage = <ErrorFallback.DefaultFinalMessage />,
  remainingMessage = <></>,
  resetErrorBoundary,
}) => {
  return (
    <Banner intent="error" fadeIn>
      <div
        className={`
          grid
          justify-items-start
          gap-3
        `}
      >
        {expired ? (
          FinalMessage
        ) : (
          <>
            <Text color="inherit" tag="h2">
              Sorry, something went wrong:
            </Text>
            <code
              className={`
                text-ui-600
                dark:text-ui-400
                text-xs
              `}
            >
              “{error?.message}”
            </code>
            <p
              className={`
                flex
                items-center
              `}
            >
              <Button onClick={resetErrorBoundary} variant="primary" size="sm">
                Try to recover?
              </Button>

              {remainingMessage && (
                <>
                  &#160;&#160;
                  {remainingMessage}
                </>
              )}
            </p>
          </>
        )}
      </div>
    </Banner>
  )
}

export const DefaultFinalMessage = ({
  children = (
    <>
      Thank you for trying, but we were unable to recover from the&nbsp;error.
    </>
  ),
}) => (
  <Text color="inherit" tag="h2">
    {children}
  </Text>
)

ErrorFallback.DefaultFinalMessage = DefaultFinalMessage
export default ErrorFallback
