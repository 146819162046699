import {XMarkIcon} from '@heroicons/react/20/solid'
import {ClearIndicatorProps} from 'react-select'
import {cn} from '../../../util/tailwind'

const OurClearIndicator = ({
  className = ``,
  innerProps,
}: ClearIndicatorProps) => {
  return (
    <span
      {...innerProps}
      className={cn(
        `
        border-r-ui-200
        text-ui-500
        cursor-pointer
        border-r
        pr-1.5
      `,
        className
      )}
    >
      <XMarkIcon width={18} height={18} />
    </span>
  )
}

export default OurClearIndicator
