import {ReactNode, TextareaHTMLAttributes} from 'react'
import FormItem from '../FormItem'
import TextAreaFormik from './TextAreaFormik'

export interface TextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  id: string
  label: string
  name: string
  className?: string
  labelHidden?: boolean
  readOnly?: boolean
  details?: ReactNode
  variant?: `sm` | `md`
  noResize?: boolean
  autoHeight?: boolean
}

export const textAreaClassName = `
  focus:shadow-outline
  focus:outline-none
  rounded-400
  border
  block
  form-textarea
  duration-150
  ease-in-out
  transition-default
  w-full
`

export const enabledClassName = `
  focus:bg-white
  hover:bg-white
  hocus:text-ui-1000
  bg-ui-50
  text-ui-800
  dark:hocus:bg-ui-200
  dark:bg-ui-200
  dark:border-ui-200
`

export const disabledClassName = `
  bg-ui-200
  border-ui-200
  text-ui-500
  dark:text-ui-300
  dark:bg-ui-50
  dark:border-ui-50
  cursor-not-allowed
`

export const sizesClassName = {
  md: `
    sm:text-sm
    sm:leading-5
    px-3
    py-2
  `,
  sm: `
    sm:text-xs
    sm:leading-5
    px-2
    py-1
  `,
}

export const getTextAreaClassName = (props: Partial<TextAreaProps>) => {
  return `
    ${textAreaClassName}
    ${sizesClassName[props.variant]}
    ${props.className || ``}
    ${props.readOnly ? disabledClassName : enabledClassName}
    ${props.noResize ? `resize-none` : `resize-y`}
  `
}

const TextArea = ({
  label,
  name,
  id,
  rows,
  labelHidden,
  details,
  readOnly,
  noResize = true,
  variant = `md`,
  className,
  ...props
}: TextAreaProps) => {
  if (!(name && label && id)) return null

  return (
    <FormItem
      label={
        labelHidden ? null : (
          <FormItem.Label htmlFor={id} dim={readOnly}>
            {label}
          </FormItem.Label>
        )
      }
      details={details}
    >
      <textarea
        aria-label={labelHidden ? label : null}
        className={getTextAreaClassName({
          ...props,
          readOnly,
          noResize,
          variant,
          className,
        })}
        id={id}
        rows={rows || 3}
        {...props}
      ></textarea>
    </FormItem>
  )
}

TextArea.Formik = TextAreaFormik
export default TextArea
