import {HTMLAttributes, ReactNode} from 'react'

interface Props extends HTMLAttributes<HTMLElement> {
  secondarySlot?: ReactNode
}

export const ActionGroup = ({children, secondarySlot = null}: Props) => {
  return (
    <div
      className={`
        flex
        w-full
        justify-end
        gap-2
      `}
    >
      <div
        className={`
          flex
          grow
        `}
      >
        {secondarySlot}
      </div>
      <div
        className={`
          flex
          items-center
          justify-end
          gap-2
        `}
      >
        {children}
      </div>
    </div>
  )
}

export default ActionGroup
