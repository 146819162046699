import {CodeBracketIcon} from '@heroicons/react/20/solid'
import {Dropdown} from '@myadbox/gatsby-theme-nebula'
import {useFeatureFlags} from '@myadbox/gatsby-theme-nebula/hooks'
import {FacebookIcon, InstagramIcon} from '@myadbox/stellar-ui'
import {useTranslation} from 'react-i18next'
import {ActiveIntegrationType, useDefaultIntegrations} from '../../../index'
import withTranslationProvider from '../../locales/withTranslationProvider'

const IntegrationIcon = ({type}: {type: ActiveIntegrationType}) => {
  if (type === `Facebook`) return <FacebookIcon size={20} />
  if (type === `Instagram`) return <InstagramIcon size={20} />
  if (type === `Embed`)
    return (
      <div className="size-5">
        <CodeBracketIcon />
      </div>
    )
}

export const DefaultIntegrationsList = () => {
  const {t} = useTranslation(`defaultIntegrations`)
  const {defaultIntegrations, setDefaultActiveIntegration} =
    useDefaultIntegrations()
  const {
    featureFlags: {showSocialAppsIntegration, showEmbedCopy},
  } = useFeatureFlags()

  const defaultIntegrationOptions: ActiveIntegrationType[] = [
    `Facebook`,
    `Instagram`,
    `Embed`,
  ]
  if (!defaultIntegrations || !showSocialAppsIntegration) return null

  const openModal = (option: ActiveIntegrationType) => {
    setDefaultActiveIntegration(option)
  }

  return (
    <>
      {defaultIntegrationOptions
        .filter(option => {
          if (option === `Embed`) return showEmbedCopy
          return true
        })
        .map(option => {
          return (
            <Dropdown.Item key={option} onSelect={() => openModal(option)}>
              <div className="flex gap-2">
                <IntegrationIcon type={option} />
                {option !== `Embed`
                  ? t(`list.exportTo`, {name: option})
                  : t(`list.copyEmbed`)}
              </div>
            </Dropdown.Item>
          )
        })}
    </>
  )
}

export default withTranslationProvider(DefaultIntegrationsList)
