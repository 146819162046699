import {ActionGroup} from '@myadbox/stellar-ui'
import {CSSProperties, ReactNode} from 'react'
import {TRAY_HEIGHT, actionGroupWrapClassName} from './helpers'

type Props = {
  children?: ReactNode
  reveal?: boolean
  height?: string
  endContent?: ReactNode
}

export const LayoutFloatingActionGroup = ({
  children,
  reveal = true,
  height = TRAY_HEIGHT,
  endContent,
}: Props) => {
  const style = {
    '--pos-var-1': `4.5rem`,
    '--height-var-1': height,
  } as CSSProperties

  return (
    <div className={actionGroupWrapClassName} style={style}>
      {reveal && (
        <div
          className={`
            fadeIn
            relative
            whitespace-nowrap
          `}
        >
          <ActionGroup>{children}</ActionGroup>
        </div>
      )}
      {endContent}
    </div>
  )
}

export default LayoutFloatingActionGroup
