import {components, InputProps} from 'react-select'
import {cn} from '../../../util/tailwind'

const Input = ({className = ``, ...props}: InputProps) => {
  return (
    <components.Input {...props} className={cn(`text-ui-800`, className)} />
  )
}

export default Input
