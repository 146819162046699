import {FC, HTMLAttributes} from 'react'

const ScreenOverlay: FC<HTMLAttributes<HTMLElement>> = ({
  className = ``,
  ...props
}) => {
  return (
    <div
      className={`
        ${className}
        animate-fade-in
        fixed
        inset-0
      `}
      {...props}
    >
      <div
        className={`
          absolute
          inset-0
          bg-[--cloudinary-overlay]
        `}
      ></div>
    </div>
  )
}

export default ScreenOverlay
