export const F_AUTO = `f_auto`
export const E_BACKGROUND_REMOVAL = `e_background_removal`

export const ROOT_CHAIN_TRANSFORMATIONS = [F_AUTO, E_BACKGROUND_REMOVAL]

const processTransformations = (transforms: string, src: string) => {
  // We use Set to filter duplicates
  const rootTransformsSet: Set<string> = new Set([
    ...(src.includes(F_AUTO) ? [] : [F_AUTO]),
  ]) // Always include F_AUTO in rootTransforms if src does not contain it
  const otherTransformsSet: Set<string> = new Set()

  transforms.split(`/`).forEach(chain => {
    const groups = chain.split(`,`)
    const filteredGroups = groups.filter(group => {
      // Check if the group starts with any root transformation
      const isRootTransform = ROOT_CHAIN_TRANSFORMATIONS.some(
        transform => group.startsWith(transform + `:`) || group === transform
      )
      if (isRootTransform) {
        rootTransformsSet.add(group)
        return false
      }
      return true
    })

    if (filteredGroups.length) otherTransformsSet.add(filteredGroups.join(`,`))
  })

  return [Array.from(rootTransformsSet), Array.from(otherTransformsSet)]
}

/**
 * A helper function that allows you to inject a string of Cloudinary transforms into an existing Cloudinary src URL.
 *
 * @param src - the Cloudinary URL to inject transforms into
 * @param transforms - the transforms to inject into the Cloudinary URL
 * @returns a valid Cloudinary URL with the transforms injected in the correct place
 *
 * @example
 * ### NOTE: the result is…
 * 1. a valid Cloudinary URL for use in ResponsiveImage’s src prop
 * 1. has `f_auto` added
 * 1. `e_sharpen `transform appears last in the URL
 * 1. if subsequent transforms are already present, new ones are added later in the chain
 *
 * ```
 * const cloudinarySrc = `https://res.cloudinary.com/mabx-eu-uat/image/upload/v1/demo/osrxy6zh0rrpaqx012c7`
 * const transforms = `e_sharpen:50/c_crop,h_0.40,w_0.65`
 * const result = injectTransforms(cloudinarySrc, transforms)
 *
 * // result = `https://res.cloudinary.com/mabx-eu-uat/image/upload/f_auto/c_crop,h_0.40,w_0.65/e_sharpen:50/v1/demo/osrxy6zh0rrpaqx012c7`
 * ```
 * @see {@link ResponsiveImage}
 */
export const injectTransforms = (src = ``, transforms = ``) => {
  if (!src || !/^https/.test(src)) return ``

  const srcUrl = new URL(src)
  const srcBits = srcUrl.pathname.split(`/`)
  const insertionBitIndex = srcBits.findIndex(bit =>
    insertionBitPattern.test(bit)
  )
  const [rootTransforms, transformBits] = processTransformations(
    transforms,
    src
  )

  const sharpBitIndex = transformBits.findIndex(bit =>
    bit.includes(`e_sharpen`)
  )
  const sharpBit =
    sharpBitIndex > -1 ? transformBits.splice(sharpBitIndex, 1) : ``

  const earlyTransforms = transformBits.join(`/`)
  const lateTransforms = sharpBit

  const uniqueBits = new Set([
    // everything before insertionBit
    ...srcBits.slice(0, insertionBitIndex),
    rootTransforms.join(`,`),
    // cropping, resize, and similar transforms should happen before others
    earlyTransforms,
    // sharpening transforms should happen after sizing and cropping, etc.
    lateTransforms,
    // the rest of the URL which identifies the source image, including insertionBit
    ...srcBits.slice(insertionBitIndex),
  ])

  // the order in which the transforms are listed is important…
  const finalBits = [...uniqueBits].filter(bit => Boolean(bit))
  srcUrl.pathname = finalBits.join(`/`).replace(`//`, `/`)
  return srcUrl.toString()
}

const insertionBitPattern = /^v\d+$/

/**
 * A factory function that allows you to inject a string of Cloudinary transforms into an existing Cloudinary src URL. This only exists for ergonomic reasons as it looks cleaner when repeating the various members of ResponsiveImage’s data-srcset collection.
 *
 * @param src - the Cloudinary URL to inject transforms into
 * @returns a function that accepts a string of transforms
 *
 * @internal
 */
export const getSrcTransformer = (src: string) => (transformation: string) =>
  injectTransforms(src, transformation)

/**
 * This function contains the logic for returning srcSet and sizes attributes for a responsive image.
 *
 * @param src - A valid Cloudinary source URL.
 * @returns an object containing the src and srcSet attributes for the image.
 */
export const createSrcSet = (src: string): {src: string; srcSet?: string} => {
  const transformSrc = getSrcTransformer(src)

  // TODO: useful when using <picture/> for art direction…
  // const transformPortraitSrc = getSrcTransformer(portraitSrc)
  // const transformLandscapeSrc = getSrcTransformer(landscapeSrc)

  const fallbackSrc = transformSrc(`q_60,w_300`)

  return {
    src: fallbackSrc,
    srcSet: fallbackSrc
      ? `
    ${fallbackSrc} 300w,
    ${transformSrc(`q_60,w_600`)} 600w,
    ${transformSrc(`q_60,w_900`)} 900w,
    ${transformSrc(`q_60,w_1200`)} 1200w,
    ${transformSrc(`q_60,w_1600`)} 1600w,
    `
      : undefined,
  }
}
