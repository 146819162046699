const exportDefaults = `jpgMax`

export const bannerAd = {
  main: {
    sizes: {
      halfPageAd: {
        label: `Half Page Ad (Double MREC)`,
        width: 300,
        height: 600,
        exportDefaults,
      },
      leaderboard: {
        label: `Leaderboard`,
        width: 728,
        height: 90,
        exportDefaults,
      },
      mediumRectangle: {
        label: `Medium Rectangle (MREC)`,
        width: 300,
        height: 250,
        exportDefaults,
      },
      mobileLeaderboard: {
        label: `Mobile Leaderboard`,
        width: 320,
        height: 50,
        exportDefaults,
      },
      wideSkyscraper: {
        label: `Wide Skyscraper`,
        width: 160,
        height: 600,
        exportDefaults,
      },
    },
  },

  extended: {
    sizes: {
      square: {
        label: `Square`,
        width: 250,
        height: 250,
        exportDefaults,
      },

      inlineRectangle: {
        label: `Inline Rectangle`,
        width: 450,
        height: 90,
        exportDefaults,
      },
      largeRectangle: {
        label: `Large Rectangle`,
        width: 336,
        height: 280,
        exportDefaults,
      },
      largeLeaderboard: {
        label: `Large Leaderboard`,
        width: 970,
        height: 90,
        exportDefaults,
      },
      mobileFullScreen: {
        label: `Mobile full screen (320×480)`,
        width: 320,
        height: 480,
        exportDefaults,
      },
      googleAd: {
        label: `Google Ad (450×405)`,
        width: 450,
        height: 405,
        exportDefaults,
      },
      // there are more…
    },
  },
} as const
