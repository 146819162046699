import {sanitizeCsvString} from '@myadbox/gatsby-theme-nebula/utils'
import {Dataset, Team, User, UserState} from '@myadbox/nebula-service-api'
import {IntentStatus} from '@myadbox/stellar-ui'
import {saveAs} from 'file-saver'
import {TFunction} from 'i18next'

export type Status = UserState

export const statusToIntent: Partial<Record<Status, IntentStatus>> = {
  ENABLED: `success`,
  PENDING: `default`,
  EXPIRED: `error`,
}

export const getStatusText = (t: TFunction, status: Status): string => {
  const statusToText = {
    ENABLED: t`settings.users.status.active`,
    PENDING: t`settings.users.status.pending`,
    EXPIRED: t`settings.users.status.expired`,
  }
  return statusToText[status]
}

export const teamMapByUserId = (teams: Team[]): Record<string, Team[]> => {
  return teams.reduce((agg, curr) => {
    for (const userId of curr.userIds) {
      agg[userId] = (agg[userId] || []).concat({
        id: curr.id,
        name: curr.name,
        userIds: curr.userIds,
        avatar: curr.avatar,
      })
    }
    return agg
  }, {})
}

interface DatasetsTableData {
  name: string
  title: string
  rows: DatasetsTableRowData[]
}

interface DatasetsTableRowData {
  label: string
  value: string
  type: string
}

export const getFormattedDatasetsFromUser = (
  datasets: Dataset[]
): DatasetsTableData[] => {
  return datasets.map(dataset => {
    const {
      nestedSchema: {name, title, descendants},
      data,
    } = dataset

    const desendantsMap = descendants.reduce((acc, cur) => {
      acc[cur.name] = cur
      return acc
    }, {})

    const rows = []
    for (const [key, value] of Object.entries(data)) {
      rows.push({
        label: desendantsMap[key]?.title || key,
        value,
        type: desendantsMap[key]?.type,
      })
    }

    return {
      name,
      title,
      rows,
    }
  })
}

export const usersCSVFields = [
  {
    label: `ID`,
    value: `id`,
  },
  {
    label: `First name`,
    value: `givenName`,
  },
  {
    label: `Last name`,
    value: `familyName`,
  },
  {
    label: `Email`,
    value: `email`,
  },
  {
    label: `Teams`,
    value: `teams`,
  },
  {
    label: `Data records ids`,
    value: `datasetIds`,
  },
  {
    label: `SSO`,
    value: `sso`,
  },
]

export const downloadUsersData = async (
  userData: User[],
  teamsMap: Record<string, Team[]>
): Promise<void> => {
  if (!userData.length) return

  const {Parser} = await import(`@json2csv/plainjs`)

  const userDataWithTeams = userData.map(user => {
    return {
      ...user,
      datasetIds: user.datasetIds?.toString(),
      teams: teamsMap[user.userId]?.map(team => team.name).toString(),
    }
  })

  const opts = {
    fields: usersCSVFields,
    formatters: {string: sanitizeCsvString()},
    withBom: true,
  }
  const parser = new Parser(opts)
  const csvData = parser.parse(userDataWithTeams)

  const csv = new Blob([csvData], {type: `text/csv;charset=utf-8`})
  saveAs(csv, `users-data.csv`)
}
