import {Team, User} from '@myadbox/nebula-service-api'
import * as Yup from 'yup'
import {UserOption} from '../../../utils/types'

export const applyDisabledBool = (
  availableOptions,
  disableOptions
): UserOption[] => {
  const optionsWithDisabled = availableOptions.map((option: UserOption) => ({
    ...option,
    disabled: !disableOptions
      ? false
      : Boolean(disableOptions.includes(option.id)),
  }))
  return optionsWithDisabled
}

export const removeDuplicates = (value: string[]): string[] => [
  ...new Set(value || []),
]

export const blankTeam: Team = {
  name: ``,
  userIds: [],
}

export const generateOptions = (users: User[]): UserOption[] =>
  users.map(user => ({
    label: user.fullName,
    email: user.email,
    value: user.email, //setting value to email since that's what select can search for
    id: user.userId,
  }))

export const getValidationSchema = t =>
  Yup.object().shape({
    name: Yup.string().required(t`settings.teams.validation.name.required`),
  })

export const generateUserMap = (users: User[]): Record<string, User> => {
  const userMap = {}

  users.forEach(user => {
    userMap[user.userId] = user
  })

  return userMap
}
