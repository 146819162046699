import {
  useConnectedIntegrations,
  useDisconnectSocial,
  useIntegrations,
} from '@myadbox/nebula-service-api'
import {useEffect, useReducer} from 'react'
import {
  SocialConnectionAction,
  SocialConnectionState,
  UseSocialConnectionsOutput,
} from './types'

const initialState: SocialConnectionState = {
  integratedPlatforms: [],
  isSubmitting: false,
}

const socialConnectionReducer = (
  state: SocialConnectionState,
  action: SocialConnectionAction
): SocialConnectionState => {
  switch (action.type) {
    case `SET_SUBMITTING`:
      return {...state, isSubmitting: action.payload.isSubmitting}
    case `DISCONNECT_PLATFORM`:
      return {
        ...state,
        integratedPlatforms: state.integratedPlatforms.filter(
          platform =>
            platform.integrationId !== action.payload.disconnectIntegrationId
        ),
        isSubmitting: false,
      }
    case `SET_CONNECTED_PLATFORMS`:
      return {
        ...state,
        integratedPlatforms: action.payload.integratedPlatforms,
      }
    default:
      return state
  }
}

const useSocialConnections = (): UseSocialConnectionsOutput => {
  const [state, dispatch] = useReducer(socialConnectionReducer, initialState)
  const {disconnectSocial} = useDisconnectSocial()
  const {fetchConnectedIntegrations, fetchConnectedIntegrationsResults} =
    useConnectedIntegrations()
  const {fetchIntegrations, fetchIntegrationsResults} = useIntegrations()

  useEffect(() => {
    fetchIntegrations()
    fetchConnectedIntegrations()
  }, [fetchIntegrations, fetchConnectedIntegrations])

  useEffect(() => {
    const {data: connectedData} = fetchConnectedIntegrationsResults
    const {data: integrationsData} = fetchIntegrationsResults

    if (connectedData && integrationsData) {
      const {integrations} = integrationsData
      const {withIntegrations: connectedIntegrations} = connectedData

      const isIntegrationConnected = (integrationId: string) =>
        connectedIntegrations.some(conn => conn.integrationId === integrationId)

      const connectedPlatforms = integrations
        .filter(integration => isIntegrationConnected(integration.id))
        .map(integration => ({
          name: integration.name,
          integrationId: integration.id,
        }))

      dispatch({
        type: `SET_CONNECTED_PLATFORMS`,
        payload: {integratedPlatforms: connectedPlatforms},
      })
    }
  }, [fetchConnectedIntegrationsResults, fetchIntegrationsResults])

  const setSubmitting = (isSubmitting: boolean) => {
    dispatch({type: `SET_SUBMITTING`, payload: {isSubmitting}})
  }

  const handleDisconnect = async (integrationId: string) => {
    if (state.isSubmitting) return

    setSubmitting(true)
    try {
      await disconnectSocial({integrationId})
      dispatch({
        type: `DISCONNECT_PLATFORM`,
        payload: {disconnectIntegrationId: integrationId},
      })
    } finally {
      setSubmitting(false)
    }
  }

  return {
    ...state,
    handleDisconnect,
    fetchConnectedIntegrationsResults,
    fetchIntegrationsResults,
  }
}

export default useSocialConnections
