import {ComponentProps, useEffect, useState} from 'react'
import {components} from 'react-select'
import Select from 'react-select/base'
import Input from './Input/Input'
import Menu from './Menu/Menu'
import ClearIndicator from './OurClearIndicator/OurClearIndicator'
import Control from './OurControl/OurControl'
import DropdownIndicator from './OurDropdownIndicator/OurDropdownIndicator'
import MenuList from './OurMenuList/OurMenuList'
import MultiValueContainer from './OurMultiValueContainer'
import MultiValueLabel from './OurMultiValueLabel'
import MultiValueRemove from './OurMultiValueRemove'
import NoOptionsMessage from './OurNoOptionsMessage/OurNoOptionsMessage'
import Option from './OurOption/OurOption'
import ValueContainer from './OurValueContainer/OurValueContainer'
import SingleValue from './SingleValue/SingleValue'
import {
  BaseSelectProps,
  OptionData,
  OptionValue,
  SelectVariant,
  ValidOption,
} from './types'

export const ourSelectComponents: ComponentProps<typeof Select>[`components`] =
  {
    ...components,
    ClearIndicator,
    Control,
    DropdownIndicator,
    Input,
    Menu,
    MenuList,
    MultiValueContainer,
    MultiValueLabel,
    MultiValueRemove,
    NoOptionsMessage,
    // @ts-ignore - TODO: Fix it! This is just a hack
    Option,
    SingleValue,
    ValueContainer,
  }

export const createCurrentFinder =
  (value: OptionValue<OptionData>, keyToMatch?: string) =>
  <T extends {value: OptionValue<OptionData>}>(item: T): boolean => {
    const shouldMatchNested = typeof item.value === `object`

    if (shouldMatchNested) {
      // checking if matching makes sense to avoid false matches when comparing undefined with undefined

      const requestedMatchersExist =
        // @ts-ignore - TODO: Fix it! This is just a hack
        item.value?.[keyToMatch || `id`] && value && value[keyToMatch]

      // provide support for older templates that haven't adopted adding a unique `id` property to object values, but likely do have `name`
      const fallbackMatcher = `name`
      // @ts-ignore - TODO: Fix it! This is just a hack
      const fallbackMatchersExist =
        // @ts-ignore - TODO: Fix it! This is just a hack
        item.value?.[fallbackMatcher] && value?.[fallbackMatcher]

      const matcher = requestedMatchersExist
        ? keyToMatch
        : fallbackMatchersExist
        ? fallbackMatcher
        : null

      // @ts-ignore - TODO: Fix it! This is just a hack
      return matcher ? item.value?.[matcher] === value[matcher] : false
    }

    return item.value === value
  }

export const getMultiValue = (
  value: OptionValue[] = [],
  options: OptionData[],
  keyToMatch?: string
) =>
  value.map(value =>
    options.find(
      // @ts-ignore - TODO: Fix it! This is just a hack
      createCurrentFinder(value, keyToMatch)
    )
  )

type CurrentValueProps = {
  options: OptionData[]
  value: OptionValue[]
  isMulti: boolean
  findCurrentByProperty?: string
}

export const getCurrentValue = ({
  options,
  value,
  isMulti,
  findCurrentByProperty,
}: CurrentValueProps) =>
  isMulti && typeof value === `object`
    ? getMultiValue(
        value,
        // @ts-ignore - TODO: Fix it! This is just a hack
        options,
        findCurrentByProperty
      )
    : options.find(
        // @ts-ignore - TODO: Fix it! This is just a hack
        createCurrentFinder(value as string, findCurrentByProperty)
      )

export const MODAL_ID = `react-aria-modal-dialog`

export const usePortalTarget = () => {
  const [target, setTarget] = useState<HTMLElement | null>(null)

  useEffect(() => {
    const target = document.getElementById(MODAL_ID)
    setTarget(target)
  }, [])

  return {target}
}

export const matchOptionValue = <T extends ValidOption>(
  options: T[],
  value: unknown
): T | null => {
  return options?.find(o => o.value === value) || null
}

export const getIsVariant = <T>(
  variant: SelectVariant,
  selectProps: T & {variants?: BaseSelectProps[`variants`]}
) => selectProps.variants?.includes(variant)
