import {gql} from '@apollo/client'
import {TEAM_BASIC_INFO} from './fragments'

export const FETCH_TEAMS_BY_ACCOUNT = gql`
  ${TEAM_BASIC_INFO}
  query AllTeamsByAccount @api(name: "profiles") {
    getTeams {
      id
      userIds
      ...TeamBasicInfo
    }
  }
`

export const FETCH_TEAMS_BY_IDS = gql`
  ${TEAM_BASIC_INFO}
  query TeamsByIds($teamIds: [String!]!) @api(name: "profiles") {
    teamsByIds(teamIds: $teamIds) {
      id
      default
      ...TeamBasicInfo
    }
  }
`

export const FETCH_TEAMS_BY_USER_ID = gql`
  ${TEAM_BASIC_INFO}
  query TeamsByUserId($accountId: String!, $userId: String!)
  @api(name: "profiles") {
    teamsByUserId(accountId: $accountId, userId: $userId) {
      id
      userIds
      ...TeamBasicInfo
    }
  }
`

export const FETCH_SHARE_OPTIONS_DATA = gql`
  ${TEAM_BASIC_INFO}
  query FetchShareOptionsData @api(name: "profiles") {
    getTeams {
      id
      userIds
      ...TeamBasicInfo
    }
    allUsersByAccountId {
      id
      userId
      fullName
      email
      avatar
      sso
    }
  }
`
