import {gql} from '@apollo/client'
import {
  ASSET_APPROVERS,
  ASSET_STATS,
  ASSET_VERSIONS,
  COMMON_ATTRIBUTES,
  EDITABLE_INFO,
} from './fragments'

export const DELETE_ASSETS = gql`
  mutation ($assetIds: [String!]!) @api(name: "assets") {
    deleteAssets(assetIds: $assetIds) {
      id
    }
  }
`

export const ADD_ASSETS = gql`
  mutation AddAssets($input: [AddAssetInput]!) @api(name: "assets") {
    addAssets(input: $input) {
      id
      type
    }
  }
`
export const DERIVE_ASSET = gql`
  mutation DeriveAsset($input: DeriveAssetInput!) @api(name: "assets") {
    deriveAsset(input: $input)
  }
`
export const UPDATE_ASSET = gql`
  ${EDITABLE_INFO}
  ${COMMON_ATTRIBUTES}
  ${ASSET_APPROVERS}
  mutation ($assetId: String!, $input: UpdateAssetInput!)
  @api(name: "gateway") {
    updateAsset(assetId: $assetId, input: $input) {
      id
      ...EditableInfo
      ...CommonAttributes
      ...AssetApprovers
    }
  }
`

export const APPLY_VERSION = gql`
  ${ASSET_VERSIONS}
  mutation ApplyVersion($assetId: String!, $versionIndex: Int!)
  @api(name: "assets") {
    applyVersion(assetId: $assetId, versionIndex: $versionIndex) {
      id
      type
      ...AssetVersions
    }
  }
`

export const BULK_UPDATE_ASSETS = gql`
  ${EDITABLE_INFO}
  mutation ($params: [UpdateMultipleAssetInput!]!) @api(name: "assets") {
    updateMultipleAssets(params: $params) {
      id
    }
  }
`

export const UPDATE_ASSET_SOURCE = gql`
  ${ASSET_VERSIONS}
  mutation UpdateAssetSource($assetId: String!, $input: UpdateAssetSourceInput!)
  @api(name: "gateway") {
    updateAssetSource(assetId: $assetId, input: $input) {
      id
      type
      ...AssetVersions
    }
  }
`

export const IMPORT_BULK_ASSETS = gql`
  mutation ImportBulkAssets($cloudinaryId: String!) @api(name: "assets") {
    validateBulkData(cloudinaryId: $cloudinaryId)
  }
`

export const INTERACT_WITH_ASSET = gql`
  ${ASSET_STATS}
  mutation ($assetIds: [String!]!, $eventType: String!) @api(name: "assets") {
    interactWithAsset(assetIds: $assetIds, eventType: $eventType) {
      id
      ...AssetStats
    }
  }
`
export const UPDATE_COMMON_ATTRIBUTES = gql`
  ${COMMON_ATTRIBUTES}
  ${ASSET_APPROVERS}
  mutation UpdateCommonAttributes($input: UpdateCommonAttributesInput!)
  @api(name: "gateway") {
    updateCommonAttributes(input: $input) {
      id
      ...CommonAttributes
      ...AssetApprovers
    }
  }
`

export const UPDATE_CURRENT_VERSION = gql`
  ${ASSET_VERSIONS}
  mutation UpdateCurrentVersion($assetId: String!, $input: VersionUpdateInput!)
  @api(name: "gateway") {
    updateCurrentVersion(assetId: $assetId, input: $input) {
      id
      ...AssetVersions
    }
  }
`

export const UPDATE_THUMBNAIL = gql`
  ${ASSET_VERSIONS}
  mutation UpdateThumbnail($assetId: String!, $thumbnailId: String!)
  @api(name: "gateway") {
    updateThumbnail(assetId: $assetId, thumbnailId: $thumbnailId) {
      id
      ...AssetVersions
    }
  }
`

export const REMOVE_THUMBNAIL = gql`
  mutation RemoveThumbnail($assetId: String!) @api(name: "assets") {
    removeThumbnail(assetId: $assetId) {
      id
    }
  }
`

export const CREATE_SHARE_LINK = gql`
  mutation CreateShareLink($assetIds: [String!]!) @api(name: "assets") {
    createShareLink(assetIds: $assetIds) {
      id
    }
  }
`
