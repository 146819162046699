import {ChevronDownIcon, ChevronRightIcon} from '@heroicons/react/20/solid'
import {HTMLAttributes, useEffect, useRef, useState} from 'react'
interface Props extends HTMLAttributes<HTMLElement> {
  label?: string
  position?: `left` | `right`
}

export const Details = ({
  className = ``,
  children,
  label = `toggle details`,
  position = `right`,
  ...props
}: Props) => {
  const detailsRef = useRef<HTMLDetailsElement>(null)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    // Used for hot-reloading
    if (detailsRef.current !== null && open !== detailsRef.current.open) {
      setOpen(detailsRef.current.open)
    }
  }, [detailsRef, open])

  const Icon = open ? ChevronDownIcon : ChevronRightIcon

  return (
    <details
      className={`
        w-full
        ${className}
      `}
      ref={detailsRef}
      {...props}
    >
      <summary
        role="button"
        onClick={() => {
          setOpen(!detailsRef.current?.open)
        }}
        className={`
          hocus:text-ui-1000
          absolute
          ${position === `left` ? `left-0` : `right-0`}
          top-0
          -my-1.5
          cursor-pointer
          select-none
          list-none
          py-1.5
          text-xs
          font-light
          leading-5
        `}
      >
        <div
          className={`
            flex
            items-center
          `}
        >
          <Icon height={12} width={12} />
          {label}
        </div>
      </summary>
      <div hidden={!open}>{children}</div>
    </details>
  )
}

export default Details
