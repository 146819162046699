type Props = React.SVGAttributes<SVGElement> & {
  className?: string
  size?: number
}

const GoogleSheets = ({size, ...rest}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 11 15"
      fill="none"
      {...rest}
    >
      <path
        d="M7.43183 0.5L10.9318 4L9.18183 4.31818L7.43183 4L7.11365 2.25L7.43183 0.5Z"
        fill="#FCFCFD"
      />
      <path
        d="M7.43182 4V0.5H1.70455C1.17716 0.5 0.75 0.927159 0.75 1.45455V13.5455C0.75 14.0728 1.17716 14.5 1.70455 14.5H9.97727C10.5047 14.5 10.9318 14.0728 10.9318 13.5455V4H7.43182Z"
        fill="#34A853"
      />
      <path
        d="M2.65906 5.90906V10.5227H9.02269V5.90906H2.65906ZM5.44315 9.72724H3.45451V8.6136H5.44315V9.72724ZM5.44315 7.81815H3.45451V6.70451H5.44315V7.81815ZM8.22724 9.72724H6.2386V8.6136H8.22724V9.72724ZM8.22724 7.81815H6.2386V6.70451H8.22724V7.81815Z"
        fill="white"
      />
    </svg>
  )
}

export default GoogleSheets
