import {gql} from '@apollo/client'

export const WITH_INTEGRATIONS = gql`
  query WithIntegrations @api(name: "socialIntegrations") {
    withIntegrations {
      integrationId
      apps {
        id
        category
        name
      }
      user {
        id
        name
        token {
          expiresAt
        }
      }
    }
  }
`
