export const TRAY_HEIGHT = `6.4rem`

export const actionGroupWrapClassName = `
  lg:px-6
  lg:absolute
  items-center
  backdrop-blur-3
  bg-ui-overlay
  border-t-ui-200
  border-t
  flex
  h-[--height-var-1]
  justify-end
  bottom-0
  left-[--pos-var-1]
  right-0
`
