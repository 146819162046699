import {XMarkIcon as IconClose} from '@heroicons/react/20/solid'
import {
  XCircleIcon as IconError,
  InformationCircleIcon as IconInfo,
  CheckCircleIcon as IconSuccess,
  ExclamationCircleIcon as IconWarning,
} from '@heroicons/react/24/outline'
import {HTMLAttributes} from 'react'
import {ToastContainer, ToastContent, toast} from 'react-toastify'
import {
  ToastIntent,
  bodyClassNames,
  intentClasses,
  toastClassNamer,
} from './helpers'
import './toast-overrides.css'

// icons
const icons: Record<ToastIntent, JSX.Element> = {
  success: <IconSuccess style={{width: 22}} />,
  error: <IconError style={{width: 22}} />,
  warning: <IconWarning style={{width: 22}} />,
  default: <IconInfo style={{width: 22}} />,
}

const CloseButton = () => (
  <button
    aria-label="close"
    className={`
      group-hocus:text-ui-600
      text-ui-500
      dark:text-ui-400
      rounded-full
      p-1
    `}
  >
    <IconClose width={20} height={20} />
  </button>
)

// types
type ToastShow = (content: ToastContent) => unknown

interface ToastProps extends HTMLAttributes<HTMLElement> {
  intent?: ToastIntent
  hasIcon?: boolean
}

const showToast: ToastShow = (content: ToastContent) => {
  toast(content, {
    progressClassName: `default-progress-bar`,
    draggable: false,
    autoClose: 5000,
  })
}

const Toast = ({children, intent = `default`, hasIcon = true}: ToastProps) => {
  return (
    <div
      className={`
        flex
        break-words
      `}
      style={{
        textWrap: `pretty`,
      }}
    >
      <span
        className={`
          ${intentClasses[intent]}
          mr-2
        `}
      >
        {hasIcon && icons[intent]}
      </span>
      <span
        className={`
          pt-0.5
          md:pt-0
        `}
      >
        {children}
      </span>
    </div>
  )
}

const Container = () => {
  return (
    <ToastContainer
      role="alert"
      closeButton={CloseButton}
      toastClassName={toastClassNamer}
      bodyClassName={(): string => bodyClassNames}
      position="top-center"
      autoClose={6000}
      hideProgressBar={true}
      newestOnTop={false}
    />
  )
}

Toast.Container = Container
Toast.show = showToast

export default Toast
