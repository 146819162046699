const exportDefaults = `email`

const defaultEmailSize = {
  label: `Email - desktop`,
  width: 650,
  height: 650,
  exportDefaults,
}

export const email = {
  main: {
    sizes: {
      /**
       * @deprecated Use `email.main.sizes.desktop` instead of `email.main.sizes.default`
       */
      default: defaultEmailSize,
      desktop: {
        label: `Email - desktop`,
        width: 650,
        height: 650,
        exportDefaults,
      },
      mobile: {
        label: `Email - mobile`,
        width: 375,
        height: 700,
        exportDefaults,
      },
    },
  },
} as const
