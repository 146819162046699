import {gql} from '@apollo/client'
import {NESTEDSCHEMA_INFO, NESTEDSCHEMA_DESCENDANTS} from './fragments'

export const ADD_SCHEMA = gql`
  ${NESTEDSCHEMA_INFO}
  ${NESTEDSCHEMA_DESCENDANTS}
  mutation AddSchema($input: SchemaInput!) @api(name: "datasets") {
    addSchema(input: $input) {
      id
      ...NestedSchemaInfo
      ...NestedSchemaDescendants
      datasetsCount
      createdById
      createdAt
    }
  }
`

export const UPDATE_SCHEMA = gql`
  ${NESTEDSCHEMA_INFO}
  mutation UpdateSchema($id: ID!, $input: UpdateSchemaInput!)
  @api(name: "datasets") {
    updateSchema(id: $id, input: $input) {
      id
      ...NestedSchemaInfo
    }
  }
`

export const ADD_SCHEMA_FIELD = gql`
  ${NESTEDSCHEMA_INFO}
  mutation AddSchemaField($schemaId: ID!, $input: SchemaFieldInput!)
  @api(name: "datasets") {
    addSchemaField(schemaId: $schemaId, input: $input) {
      id
      ...NestedSchemaInfo
      options
    }
  }
`

export const UPSERT_SCHEMA_FIELDS = gql`
  ${NESTEDSCHEMA_INFO}
  mutation UpsertSchemaFields($schemaId: ID!, $inputs: [SchemaFieldInput!]!)
  @api(name: "datasets") {
    upsertSchemaFields(schemaId: $schemaId, inputs: $inputs) {
      id
      ...NestedSchemaInfo
      options
    }
  }
`

export const DELETE_SCHEMA = gql`
  mutation DeleteSchema($id: ID!) @api(name: "datasets") {
    deleteNestedSchema(id: $id) {
      id
    }
  }
`

export const ADD_DATASET = gql`
  ${NESTEDSCHEMA_INFO}
  mutation AddDataSet($input: DatasetInput!) @api(name: "datasets") {
    addDataset(input: $input) {
      id
      nestedSchema {
        id
        ...NestedSchemaInfo
      }
      data
    }
  }
`

export const UPDATE_DATASET = gql`
  ${NESTEDSCHEMA_INFO}
  mutation UpdateDataSet($id: ID!, $data: JSON!) @api(name: "datasets") {
    updateDatasetData(id: $id, data: $data) {
      id
      nestedSchema {
        id
        ...NestedSchemaInfo
      }
      data
    }
  }
`

export const DELETE_DATASET = gql`
  mutation DeleteDataSet($id: ID!) @api(name: "datasets") {
    deleteDataset(id: $id) {
      id
    }
  }
`

export const UPSERT_DATASETS = gql`
  mutation UpsertDatasets($schemaId: ID!, $data: [JSON])
  @api(name: "datasets") {
    upsertDatasets(schemaId: $schemaId, data: $data) {
      success
      result
      error
    }
  }
`
