type Props = React.SVGAttributes<SVGElement> & {
  className?: string
  size?: number
}

const OrderedListIcon = ({size, ...rest}: Props) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...rest}>
    <path
      d="M8.25 6.75H20.25M8.25 12H20.25M8.25 17.25H20.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.87678 5.36363V9H3.10795V6.09339H3.08665L2.25391 6.61541V5.93359L3.15412 5.36363H3.87678Z"
      fill="currentColor"
    />
    <path
      d="M2.29474 14V13.446L3.58913 12.2475C3.69922 12.141 3.79155 12.0451 3.86612 11.9599C3.94188 11.8746 3.99929 11.7912 4.03835 11.7095C4.07741 11.6267 4.09694 11.5373 4.09694 11.4414C4.09694 11.3349 4.07268 11.2431 4.02415 11.1662C3.97561 11.0881 3.90933 11.0283 3.82528 10.9869C3.74124 10.9442 3.64595 10.9229 3.53942 10.9229C3.42815 10.9229 3.33108 10.9454 3.24822 10.9904C3.16536 11.0354 3.10144 11.0999 3.05646 11.1839C3.01148 11.268 2.98899 11.368 2.98899 11.484H2.25923C2.25923 11.2461 2.31309 11.0395 2.42081 10.8643C2.52853 10.6892 2.67945 10.5536 2.87358 10.4577C3.06771 10.3619 3.29143 10.3139 3.54474 10.3139C3.80516 10.3139 4.03184 10.3601 4.22479 10.4524C4.41891 10.5436 4.56984 10.6702 4.67756 10.8324C4.78527 10.9946 4.83913 11.1804 4.83913 11.3899C4.83913 11.5272 4.81191 11.6628 4.75746 11.7965C4.70419 11.9303 4.6089 12.0788 4.47159 12.2422C4.33428 12.4044 4.14074 12.5991 3.89098 12.8263L3.36008 13.3466V13.3714H4.88707V14H2.29474Z"
      fill="currentColor"
    />
    <path
      d="M3.63885 19.0497C3.3737 19.0497 3.13755 19.0041 2.9304 18.913C2.72443 18.8207 2.56167 18.694 2.44212 18.533C2.32374 18.3709 2.26278 18.1838 2.25923 17.9719H3.03338C3.03811 18.0607 3.06712 18.1388 3.12038 18.2063C3.17483 18.2726 3.24704 18.3241 3.337 18.3608C3.42696 18.3975 3.52817 18.4158 3.64062 18.4158C3.75781 18.4158 3.86139 18.3951 3.95135 18.3537C4.04131 18.3123 4.11174 18.2549 4.16264 18.1815C4.21354 18.1081 4.23899 18.0234 4.23899 17.9276C4.23899 17.8305 4.21176 17.7447 4.15731 17.6701C4.10405 17.5943 4.02711 17.5352 3.92649 17.4925C3.82706 17.4499 3.70869 17.4286 3.57138 17.4286H3.23224V16.864H3.57138C3.68738 16.864 3.78977 16.8439 3.87855 16.8036C3.96851 16.7634 4.03835 16.7077 4.08807 16.6367C4.13778 16.5645 4.16264 16.4805 4.16264 16.3846C4.16264 16.2934 4.14074 16.2135 4.09694 16.1449C4.05433 16.075 3.99396 16.0206 3.91584 15.9815C3.8389 15.9425 3.74893 15.9229 3.64595 15.9229C3.54178 15.9229 3.44649 15.9419 3.36008 15.9798C3.27367 16.0165 3.20443 16.0691 3.15234 16.1378C3.10026 16.2064 3.07244 16.2869 3.06889 16.3793H2.33203C2.33558 16.1697 2.39536 15.9851 2.51136 15.8253C2.62737 15.6655 2.78362 15.5406 2.98011 15.4506C3.17779 15.3595 3.40092 15.3139 3.6495 15.3139C3.90045 15.3139 4.12003 15.3595 4.30824 15.4506C4.49645 15.5418 4.64264 15.6649 4.7468 15.82C4.85215 15.9738 4.90424 16.1467 4.90305 16.3384C4.90424 16.542 4.84091 16.7119 4.71307 16.848C4.58641 16.9841 4.42128 17.0705 4.21768 17.1072V17.1357C4.4852 17.17 4.6888 17.2629 4.82848 17.4144C4.96934 17.5647 5.03918 17.753 5.038 17.979C5.03918 18.1862 4.9794 18.3703 4.85866 18.5312C4.73911 18.6922 4.57398 18.8189 4.36328 18.9112C4.15258 19.0035 3.9111 19.0497 3.63885 19.0497Z"
      fill="currentColor"
    />
  </svg>
)

export default OrderedListIcon
