import {
  ActionGroup,
  Banner,
  Button,
  Input,
  Modal,
  Select,
  ToggleInput,
} from '@myadbox/gatsby-theme-nebula'
import {Category, useCategories} from '@myadbox/nebula-service-api'
import {Form, Formik, FormikValues} from 'formik'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {ReactNode, useEffect} from 'react'
import {getParentOptions, getValidationSchema} from '../helpers'

interface Props {
  category: Category
  categories: Category[]
  close: () => void
  isNew: boolean
}

const CategoryForm = ({category, categories, close, isNew}: Props) => {
  const {t} = useTranslation()

  const {
    addCategory,
    addCategoryResponse: {data: addData, error: addError, loading: addLoading},
    updateCategory,
    updateCategoryResponse: {
      data: updateData,
      error: updateError,
      loading: updateLoading,
    },
  } = useCategories()

  useEffect(() => {
    if (close && (addData || updateData)) {
      close()
    }
  }, [addData, close, updateData])

  const handleSubmit = async (values: FormikValues): Promise<void> => {
    const {title, parent, active} = values
    const input = {title, active, parent: parent?.value || parent || null}
    if (isNew) {
      await addCategory(input)
    } else {
      await updateCategory(category.id, input)
    }
  }

  const error = addError || updateError
  const loading = addLoading || updateLoading

  const parentOptions = getParentOptions(categories, category)
  const validationSchema = getValidationSchema(t)

  return (
    <Formik
      enableReinitialize
      initialValues={category}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({isSubmitting, dirty}): ReactNode => (
        <>
          <Modal.Header>
            {isNew
              ? t`settings.categories.createCategory`
              : t`settings.categories.updateCategory`}
          </Modal.Header>
          <Form name="category-form">
            <Modal.Body>
              <div
                className={`
                  grid
                  gap-8
                `}
              >
                <Input.Formik
                  name="title"
                  label={t`settings.categories.form.title`}
                  id="categories-title"
                  placeholder={t`settings.categories.form.titlePlaceholder`}
                />
                <Select.Formik
                  name="parent"
                  id="categories-parent"
                  label={t`settings.categories.form.parent`}
                  options={parentOptions}
                  isClearable
                  isSearchable
                  placeholder="-"
                  inModal
                />
                <ToggleInput.Formik
                  name="active"
                  label={t`settings.categories.form.active`}
                  id="categories-active"
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <ActionGroup>
                <Button
                  type="button"
                  variant="secondary"
                  disabled={isSubmitting}
                  onClick={close}
                >
                  {t`settings.cancel`}
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={isSubmitting || loading || !dirty}
                  loading={isSubmitting || loading}
                  loadingText={
                    isNew ? t`settings.creating` : t`settings.updating`
                  }
                >
                  {isNew
                    ? t`settings.categories.create`
                    : t`settings.categories.update`}
                </Button>
              </ActionGroup>
              {error && (
                <div className="col-span-full mt-4">
                  <Banner intent="error" fadeIn>
                    {error.message === `duplicateCategory`
                      ? t`settings.categories.helpers.validation.title.duplicate`
                      : error.message}
                  </Banner>
                </div>
              )}
            </Modal.Footer>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default CategoryForm
