import {XMarkIcon} from '@heroicons/react/24/outline'
import {MultiValueRemoveProps, components} from 'react-select'
import {cn} from '../../../utils/tailwind'

type OurMultiValueRemoveProps = MultiValueRemoveProps & {className?: string}

const OurMultiValueRemove = ({
  className = ``,
  ...props
}: OurMultiValueRemoveProps) => {
  const newProps = {
    ...props,
    innerProps: {
      ...props.innerProps,
      className: cn(
        `
          p-px
          hover:bg-ui-200
          hover:text-ui-700
          dark:hover:text-ui-700
        `,
        className
      ),
    },
  }
  return (
    <components.MultiValueRemove {...newProps}>
      <XMarkIcon width={14} height={14} />
    </components.MultiValueRemove>
  )
}

export default OurMultiValueRemove
