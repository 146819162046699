import {ExportSettings, Format, Quality, Unit} from '../types'

export const exportDefaults: Record<string, ExportSettings> = {
  print: {
    format: Format.PDF,
    quality: Quality.Optimised,
    unit: Unit.MM,
  },
  html: {
    format: Format.HTML,
    quality: Quality.Optimised,
    unit: Unit.PX,
  },
  jpgMax: {
    format: Format.JPG,
    quality: Quality.Maximum,
    unit: Unit.PX,
  },
  email: {
    format: Format.EMAIL,
    quality: Quality.Maximum,
    unit: Unit.PX,
  },
  videoMax: {
    format: Format.MP4,
    quality: Quality.Maximum,
    unit: Unit.PX,
  },
  videoProRes: {
    format: Format.MOV,
    quality: Quality.Maximum,
    unit: Unit.PX,
    videoCodec: `prores`,
    audioCodec: `pcm-16`,
  },
}
