import {useStaticQuery, graphql} from 'gatsby'
import {useContext} from 'react'
import {StateContext} from './PluginOptionsProvider'

interface PluginOptions {
  activeEnv: string
  cloudName: string
  region: string
  adminPoolId: string
  adminPoolAppId: string
  primaryColor?: string
  loginBackgroundFileUrl?: string
  loginBackgroundPosition?: string
  loginBackgroundOverlay?: number
}

export const mergedPluginOptions = (nodes = []): PluginOptions =>
  nodes.reduce(
    (accumulated, node) => ({...accumulated, ...node.pluginOptions}),
    {}
  )

export const usePluginOptionsQuery = (): PluginOptions => {
  const data = useStaticQuery(graphql`
    {
      allSitePlugin(
        filter: {
          name: {
            regex: "/gatsby-theme-(nebula|chevron|mimosa|acrux|arkaroola|gacrux|imai)/i"
          }
        }
      ) {
        nodes {
          pluginOptions
        }
      }
    }
  `)

  // memoise the merged plugin options?
  return mergedPluginOptions(data?.allSitePlugin?.nodes)
}

export const usePluginOptions = () => {
  const state = useContext(StateContext)
  return state
}

export default usePluginOptions
