export const getProgressColor = (progress: number) => {
  if (progress > 75) {
    return `bg-blue-500`
  } else if (progress > 50) {
    return `bg-blue-400`
  } else if (progress > 25) {
    return `bg-blue-300`
  } else {
    return `bg-blue-50`
  }
}
