import {gql} from '@apollo/client'
import {APPROVER, REQUESTER} from './fragments'

export const APPROVE_APPROVAL_REQUEST = gql`
  ${APPROVER}
  mutation ApproveApproval($input: RespondApprovalInput)
  @api(name: "approvals") {
    approveApproval(input: $input) {
      id
      approved
      approvers {
        id
        ...ApproverInfo
      }
    }
  }
`

export const DECLINE_APPROVAL_REQUEST = gql`
  ${APPROVER}
  mutation DeclineApproval($input: RespondApprovalInput)
  @api(name: "approvals") {
    declineApproval(input: $input) {
      id
      approved
      approvers {
        id
        ...ApproverInfo
      }
    }
  }
`

export const CREATE_APPROVAL_REQUEST = gql`
  ${APPROVER}
  ${REQUESTER}
  mutation CreateApproval($input: CreateApprovalInput) @api(name: "approvals") {
    createApproval(input: $input) {
      id
      assetId
      approved
      approvers {
        id
        ...ApproverInfo
      }
      ...RequesterInfo
    }
  }
`

export const REVOKE_APPROVAL_REQUEST = gql`
  ${APPROVER}
  mutation RevokeApproval($input: RespondApprovalInput)
  @api(name: "approvals") {
    revokeApproval(input: $input) {
      id
      approved
      approvers {
        id
        ...ApproverInfo
      }
    }
  }
`
