import {HTMLAttributes, ReactNode} from 'react'
import {cn} from '../../util'
import Message from './Message'

interface FormItemProps extends HTMLAttributes<HTMLElement> {
  label: ReactNode
  details?: ReactNode
  className?: string
  labelClassName?: string
  align?: `baseline` | `start` | `end` | `center`
  message?: ReactNode | string
  children: ReactNode
}

export const inputWrapInnerWithShadow = `
  rounded-600
  shadow-sm
`

export const alignmentClassNames = {
  start: `
    content-start
    items-start
  `,
  end: `
    content-end
    items-end
  `,
  baseline: `
    content-baseline
    items-baseline
  `,
  center: `
    content-center
    items-center
  `,
}

const FormItem = ({
  label = ``,
  details,
  labelClassName = ``,
  className = ``,
  align = `baseline`,
  message,
  children,
  ...props
}: FormItemProps) => {
  return (
    <div
      className={`
        ${className}
        ${alignmentClassNames[align]}
        relative
        m-auto
        grid
        w-full
        gap-1
        lg:m-0
        lg:flex
        lg:flex-wrap
      `}
      {...props}
    >
      <div
        data-name="labelWrap"
        data-testid="labelWrap"
        className={`
          ${labelClassName}
          flex
          grow
          flex-wrap
          items-center
          lg:shrink-0
          lg:basis-[max(10em,_23%)]
        `}
        style={{
          // TODO: label is required, so this needs fixing.
          // Find the usecase, and do it differently.
          display: label ? undefined : `contents`,
        }}
      >
        {details ? <SpacerWrap>{label}</SpacerWrap> : label}
        {details}
      </div>

      <div
        className={`
          shrink-0
          grow
          basis-[75%]
        `}
      >
        <div
          className={`
            relative
            max-w-xl
          `}
        >
          {children}
        </div>
        {typeof message === `string` ? (
          <Message asterisk text={message} />
        ) : (
          message
        )}
      </div>
    </div>
  )
}

export const labelSpacerClassName = `mr-20 block`
export const SpacerWrap = ({children}: {children: ReactNode}) => (
  <span className={labelSpacerClassName}>{children}</span>
)

type BaseLabelProps = {
  children: ReactNode

  dim?: boolean
}

type LabelLabelProps = BaseLabelProps & {
  htmlFor?: string
  tag?: `label`
}

type FauxLabelProps = BaseLabelProps & {
  htmlFor?: never
  tag: `span`
}

export const dimClassName = `opacity-50`

const Label = ({
  children,
  htmlFor,
  tag = `label`,
  dim,
  ...props
}: LabelLabelProps | FauxLabelProps) => {
  const Tag = tag
  return (
    <Tag
      {...props}
      htmlFor={htmlFor}
      className={cn(
        `
          text-ui-600
          dark:text-ui-500
          block
          text-xs
          font-light
          leading-5
        `,
        dim && dimClassName
      )}
    >
      {children}
    </Tag>
  )
}

FormItem.Label = Label
FormItem.Message = Message
export default FormItem
