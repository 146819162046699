import {CheckCircleIcon, XCircleIcon} from '@heroicons/react/24/outline'
import {Table} from '@myadbox/gatsby-theme-nebula'
import {useProfile} from '@myadbox/gatsby-theme-nebula/hooks'
import {
  NumDateStyle,
  toLocaleTimezone,
} from '@myadbox/gatsby-theme-nebula/utils'
import {Category, User} from '@myadbox/nebula-service-api'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {HTMLAttributes} from 'react'
import CategoriesModal from '../CategoriesModal'
import DeleteCategoryModal from '../DeleteCategoryModal'
import {CategoryTree} from '../helpers'

interface Props extends HTMLAttributes<HTMLElement> {
  category: CategoryTree
  categories: Category[]
  profilesMap: Record<string, User>
}

const CategoryRow = ({
  category,
  categories,
  profilesMap = {},
  className = ``,
}: Props) => {
  const {language} = useI18next()
  const {timezone} = useProfile()
  const {id, parent, title, active, createdAt, createdById, children} = category

  const formattedDate = toLocaleTimezone(createdAt, {
    timeZone: timezone,
    locale: language,
    year: NumDateStyle.Numeric,
    month: NumDateStyle.Numeric,
    day: NumDateStyle.Numeric,
  })
  return (
    <>
      <Table.Row
        key={id}
        className={`${className} ${parent ? `` : `bg-ui-100`}`}
      >
        <Table.Cell className={parent ? `pl-6` : `pl-3`}>{title}</Table.Cell>
        <Table.Cell>{parent ? `` : children?.length}</Table.Cell>
        <Table.Cell verticalAlign="middle">
          {active ? (
            <CheckCircleIcon
              className={`text-green-600`}
              width={24}
              height={24}
            />
          ) : (
            <XCircleIcon className={`text-red-600`} width={24} height={24} />
          )}
        </Table.Cell>
        <Table.Cell>{formattedDate}</Table.Cell>
        <Table.Cell>
          {profilesMap[createdById]?.fullName || createdById}
        </Table.Cell>
        <Table.Cell>
          <div
            className={`
              text-ui-400
              dark:text-ui-300
              -ml-3
            `}
          >
            <CategoriesModal category={category} categories={categories} />
            {`|`}
            <DeleteCategoryModal category={category} categories={categories} />
          </div>
        </Table.Cell>
      </Table.Row>
      {children?.map(subCategory => (
        <CategoryRow
          key={subCategory.id}
          category={subCategory}
          categories={categories}
          profilesMap={profilesMap}
        />
      ))}
    </>
  )
}

export default CategoryRow
