import {HTMLAttributes} from 'react'

type Props = HTMLAttributes<HTMLElement>

const FadeIn = ({className = ``, children}: Props) => (
  <div
    className={`
      ${className}
      animate-fade-in
      contents
    `}
  >
    {children}
  </div>
)

export default FadeIn
