import {FC, SVGProps} from 'react'

interface Props extends SVGProps<SVGSVGElement> {
  height?: number
  name: string
  solid?: boolean
  width?: number
}

const StaticIcon: FC<Props> = ({
  height = 20,
  name = `chevron-right`,
  solid,
  width = 20,
  ...props
}) => {
  const appendToId = solid ? `-solid` : ``
  return (
    <svg width={width} height={height} {...props}>
      <use xlinkHref={`/sprites.svg#${name}${appendToId}`}></use>
    </svg>
  )
}

export default StaticIcon
