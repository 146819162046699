const exportDefaults = `print`

export const newspaper = {
  tallTabloid: {
    sizes: {
      fullPage: {
        label: `Tall tabloid full page`,
        width: 262,
        height: 360,
        exportDefaults,
      },
      halfPage: {
        label: `Tall tabloid half page`,
        width: 262,
        height: 174.6,
        exportDefaults,
      },
      strip: {
        label: `Tall tabloid strip`,
        width: 262,
        height: 113,
        exportDefaults,
      },
      quarterPageHorizontal: {
        label: `Tall tabloid 1/4 page horizontal`,
        width: 117.5,
        height: 88.9,
        exportDefaults,
      },
      sixthPageVertical: {
        label: `Tall tabloid 1/6 page vertical`,
        width: 85.7,
        height: 120.7,
        exportDefaults,
      },
      t24: {
        label: `ACM tabloid T84: T24 (260×92)`,
        width: 260,
        height: 92,
        exportDefaults,
      },
      t44: {
        label: `ACM tabloid T84: T44 (260×186)`,
        width: 260,
        height: 186,
        exportDefaults,
      },
    },
  },

  compactTabloid: {
    sizes: {
      fullPage: {
        label: `Compact tabloid full page`,
        width: 262,
        height: 266.7,
        exportDefaults,
      },
      halfPage: {
        label: `Compact tabloid half page`,
        width: 262,
        height: 130.7,
        exportDefaults,
      },
    },
  },
} as const
