import {gql} from '@apollo/client'
import {BASIC_ACCOUNT, BILLING, PRODUCTS} from './fragments'

export const FETCH_BASIC_ACCOUNT = gql`
  ${BASIC_ACCOUNT}
  ${PRODUCTS}
  ${BILLING}
  query Account($accountName: String!) @api(name: "accounts") {
    account(accountName: $accountName) {
      id
      ...BasicAccount
      configuration {
        dashboardUrl
        loginBackgroundUrl
        logoUrl
        coreUrl
        subscriptionType
        aiTagsEnabled
        isWhiteLabelled
        loginConfig {
          userPoolId
        }
      }
      ...Products
      ...Billing
    }
  }
`

export const FETCH_LOGIN_ACCOUNT = gql`
  ${BASIC_ACCOUNT}
  query Account($accountName: String!) @api(name: "accounts") {
    account(accountName: $accountName) {
      id
      ...BasicAccount
      configuration {
        loginBackgroundUrl
        logoUrl
        subscriptionType
        isWhiteLabelled
        brandAssetId

        loginConfig {
          userPoolAppId
          userPoolId
          ssoEnabled
          ssoConfig {
            idps {
              name
              identifier
            }
            domain
            scope
          }
        }
      }
      deactivated
    }
  }
`

export const FETCH_ACCOUNT = gql`
  ${BASIC_ACCOUNT}
  ${PRODUCTS}
  ${BILLING}
  query Account($accountName: String!) @api(name: "accounts") {
    account(accountName: $accountName) {
      id
      ...BasicAccount
      configuration {
        dashboardUrl
        loginBackgroundUrl
        logoUrl
        coreUrl
        subscriptionType
        aiTagsEnabled
        isWhiteLabelled
        brandAssetId
        brandName
        loginConfig {
          userPoolAppId
          userPoolId
          ssoEnabled
          ssoConfig {
            idps {
              name
              identifier
            }
            domain
            scope
            attributeMaps {
              attribute
              binding
              data {
                key
                value
              }
            }
          }
        }
      }
      ...Products
      ...Billing
      deactivated
    }
  }
`

export const FETCH_ACCOUNT_INTEGRATIONS = gql`
  query AccountIntegrations($accountName: String!) @api(name: "gateway") {
    accountIntegrations(accountName: $accountName) {
      id
      integrations {
        integrationId
        enabled
        values
        integration {
          name
          logo
          description
          intro
          config
        }
      }
    }
  }
`

export const FETCH_ACCOUNTS = gql`
  query Accounts @api(name: "accounts") {
    accounts {
      id
      name
      accountName
      configuration {
        subscriptionType
      }
    }
  }
`

export const FETCH_ACCOUNT_BILLING = gql`
  query AccountBilling @api(name: "accounts") {
    accountBilling {
      id
      billing {
        price
        billingEmail
        currency
        paymentTerm
        additionalInfo
        stripeCustomerId
        autoBilling
      }
    }
  }
`
