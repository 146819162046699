import {SingleValueProps, components} from 'react-select'
import {cn} from '../../../utils/tailwind'

const OurSingleValue = ({
  children,
  className = ``,
  ...props
}: SingleValueProps) => {
  return (
    <components.SingleValue
      {...props}
      className={cn(
        `
          text-ui-800
          col-span-full
          row-span-full
          mr-0.5
          truncate
        `,
        className
      )}
    >
      {children}
    </components.SingleValue>
  )
}

export default OurSingleValue
