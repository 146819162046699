export async function processUrl(href: string): Promise<boolean> {
  if (!href) return false

  try {
    const response = await fetch(href, {method: `HEAD`})
    if (!response.ok) {
      if (response.status === 423) {
        // If the resource is locked, retry after a delay
        await new Promise(resolve => setTimeout(resolve, 1000)) // Delay for 1 second
        return processUrl(href)
      }
      throw new Error(`Resource fetch failed`)
    }
    return true
  } catch (e) {
    return false
  }
}
