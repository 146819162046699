import AppLogo from './AppLogo'

export const logoColours = {
  frame: {
    full: `text-ui-900 dark:text-ui-800`,
    fade: `text-ui-300`,
  },
  brand: {
    full: `text-brand`,
    fade: ``,
  },
}

export const Logo = ({fullStrength = false}) => (
  <AppLogo
    className={`
      dark:text-ui-200
      group-hocus:text-ui-500
      transition-default
      m-4
      block
      h-10
      w-10
      duration-200
      ${fullStrength ? logoColours.frame.full : logoColours.frame.fade}
    `}
  />
)

export default Logo
