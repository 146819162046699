import {Button, GoogleSheetsIcon} from '@myadbox/stellar-ui'
import {useGoogleModalStore} from '../hooks'
import {TriggerType} from '../types'
import {Dropdown} from 'packages/plugin/gatsby-theme-nebula'
import withTranslationProvider from '../../../locales/withTranslationProvider'
import {useTranslation} from 'react-i18next'

const GoogleIntegrationsTrigger = ({
  text,
  type,
  key,
  disabled = false,
}: {
  text: string
  type: TriggerType
  key?: string
  disabled?: boolean
}) => {
  const setGoogleModalOpen = useGoogleModalStore(state => state.setModalOpen)
  const {t} = useTranslation(`googleIntegrations`)

  if (type === `LIST`)
    return (
      <Dropdown.Item
        key={key}
        onSelect={setGoogleModalOpen}
        disabled={disabled}
      >
        {t(text)}
      </Dropdown.Item>
    )
  else if (type === `BUTTON`)
    return (
      <Button
        onClick={setGoogleModalOpen}
        variant="secondary"
        type="button"
        disabled={disabled}
        className="inline-flex items-center gap-1"
      >
        <GoogleSheetsIcon size={20} />
        {t(text)}
      </Button>
    )
}

export default withTranslationProvider(GoogleIntegrationsTrigger)
