import {ReactNode, cloneElement, isValidElement} from 'react'
import {ModalProps} from './types'

const triggerJsxWithOpenElement = (
  trigger: JSX.Element,
  open: () => void,
  additionalProps: Record<string, unknown> = {}
): ReactNode =>
  cloneElement(trigger, {
    onClick: (): void => {
      open()
      trigger.props.onClick?.()
    },
    ...additionalProps,
  })

const isFunction = (func: unknown): func is Function =>
  typeof func === `function`

export const generateTrigger = (
  trigger: ModalProps[`trigger`],
  open: () => void,
  additionalProps: Record<string, unknown> = {}
) => {
  if (isFunction(trigger)) {
    return trigger({open, ...additionalProps})
  } else if (isValidElement(trigger)) {
    return triggerJsxWithOpenElement(trigger, open, additionalProps)
  }
  return null
}
