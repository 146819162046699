import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  type ComponentRef,
  type ForwardedRef,
  type ReactNode,
  type TextareaHTMLAttributes,
} from 'react'
import {FormItem} from '../FormItem'
import {getTextAreaClassName} from './helpers'

export interface TextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  id: string
  label: string
  name: string
  className?: string
  labelHidden?: boolean
  readOnly?: boolean
  details?: ReactNode
  variant?: `sm` | `md`
  noResize?: boolean
  autoHeight?: boolean
  endContent?: ReactNode
}

const TextArea = (
  {
    label,
    name,
    id,
    rows,
    labelHidden,
    details,
    readOnly,
    noResize = true,
    autoHeight = false,
    variant = `md`,
    className,
    endContent,
    ...props
  }: TextAreaProps,
  ref: ForwardedRef<ComponentRef<`textarea`>>
) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useImperativeHandle(ref, () => textareaRef.current as HTMLTextAreaElement, [])

  useEffect(() => {
    if (!autoHeight) return

    const textarea = textareaRef.current
    if (textarea) {
      textarea.style.height = `auto`
      textarea.style.height = `${textarea.scrollHeight}px`
    }
  }, [props.value, autoHeight])

  if (!(name && label && id)) return null

  return (
    <FormItem
      label={
        labelHidden ? null : (
          <FormItem.Label htmlFor={id} dim={readOnly}>
            {label}
          </FormItem.Label>
        )
      }
      details={details}
    >
      <textarea
        ref={textareaRef}
        aria-label={labelHidden ? label : undefined}
        className={getTextAreaClassName({
          ...props,
          readOnly,
          noResize,
          variant,
          className,
        })}
        id={id}
        rows={rows || 3}
        {...props}
      />
      {endContent}
    </FormItem>
  )
}

export default forwardRef(TextArea)
