import jwtDecode from 'jwt-decode'
import {JwtToken} from './types'
import {isExpired} from './jwt'

export const parseJwt = (jwt: string): JwtToken | null => {
  try {
    const decodedJwt = jwtDecode<JwtToken>(jwt)
    return isExpired(decodedJwt) ? null : decodedJwt
  } catch (e) {
    return null
  }
}
