import {FC, ReactNode} from 'react'
import Card from '../Card/Card'
import Text from '../Text/Text'

interface Props {
  text?: string
  icon?: ReactNode
  actionNode?: ReactNode
  paddingVertical?: string
  className?: string
}

const TableEmptyState: FC<Props> = ({
  text,
  icon,
  actionNode,
  paddingVertical = `8rem`,
  className,
}) => {
  return (
    <Card
      isSelectable={false}
      className={`
        mt-2
        py-[--py-var-1]
        ${className}
      `}
      style={{'--py-var-1': paddingVertical}}
    >
      <div
        className={`
          flex
          flex-col
          items-center
          gap-4
          text-center
        `}
      >
        {icon}
        <Text>{text}</Text>
        {actionNode}
      </div>
    </Card>
  )
}

export default TableEmptyState
