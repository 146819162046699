import {useLazyQuery} from '@apollo/client'
import {AUTHENTICATE_USER} from '../../queries'
import {UseUsersOutput} from '../../types'
import {useCallback} from 'react'

export const useUsers = (): UseUsersOutput => {
  const [lazyAuthenticateUser, authenticateUserResult] = useLazyQuery(
    AUTHENTICATE_USER,
    {fetchPolicy: `network-only`}
  )
  const authenticateUser = useCallback(
    (accessToken: string, owner: boolean) =>
      lazyAuthenticateUser({
        variables: {accessToken, owner},
      }),
    [lazyAuthenticateUser]
  )

  return {
    authenticateUser,
    authenticateUserResult,
  }
}
