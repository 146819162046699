import {Button} from '@myadbox/gatsby-theme-nebula'
import {useAccount} from '@myadbox/nebula-service-api'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {ReactElement} from 'react'
import {ResourceType, UploadButton, UploadResponse} from '../helpers'

const AccountLogoUpload = () => {
  const {t} = useTranslation()
  const {updateAccountImageConfig, updateAccountImageConfigResponse} =
    useAccount()

  const successHandler = (
    data: UploadResponse,
    accImageType: `background` | `logo`
  ) => {
    const configKey =
      accImageType === `background` ? `loginBackgroundUrl` : `logoUrl`
    updateAccountImageConfig({[configKey]: data.info.secure_url})
  }

  return (
    <UploadButton
      cropping={true}
      multiple={false}
      resourceType={ResourceType.image}
      clientAllowedFormats={[`png`, `jpg`, `jpeg`, `webp`, `svg`]}
      minImageHeight={50}
      minImageWidth={50}
      tags={[`login-logo`]}
      sources={[`local`, `url`]}
      trigger={({onClickHandler}): ReactElement => (
        <Button
          aria-label={t`settings.account.configurations.button.uploadLogoAriaLabel`}
          size="sm"
          variant="secondary"
          onClick={(...args) => onClickHandler(args)}
          type="button"
          loading={updateAccountImageConfigResponse?.loading}
          id="uploadLogo"
        >
          {t`settings.upload`}
        </Button>
      )}
      successHandler={data => successHandler(data, `logo`)}
    />
  )
}

export default AccountLogoUpload
