import {HTMLAttributes, ReactNode} from 'react'
import {getBadgeStyles} from './styles'

interface BadgeProps extends HTMLAttributes<HTMLElement> {
  children: ReactNode
  intent?: `info` | `success` | `warning` | `error` | `infoContrast`
  hasIcon?: boolean
}

const Badge = ({
  children,
  intent = `info`,
  hasIcon = false,
  ...props
}: BadgeProps) => {
  const styles = getBadgeStyles()

  const classNames = `
    ${styles.base}
    ${styles.intent[intent]}
    ${hasIcon ? styles.hasIcon : ``}
  `

  return (
    <span className={classNames} {...props}>
      {children}
    </span>
  )
}

export default Badge
