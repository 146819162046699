export const buttonBase = `
  items-center
  rounded
  border
  inline-flex
  font-medium
  text-left
  duration-150
  ease-in-out
  transition-default
  text-sm
  leading-5
`

export const buttonVariantPrimary = `
  bg-lime-500
  text-lime-950
  bg-gradient-to-b
  hover:bg-lime-400
  active:from-lime-600
  disabled:bg-lime-500
  disabled:from-lime-500
  dark:border-transparent
  dark:text-lime-950
  shadow-lime-inset
  font-medium-only
`

export const buttonVariantSecondary = `
  active:bg-ui-50
  active:text-ui-800
  focus:border-lime-300
  hover:border-ui-500
  hover:text-ui-700
  text-ui-600
  dark:hover:border-ui-300
`

export const buttonDisabled = `
  opacity-70
  relative
  cursor-not-allowed
`

export const focusedClassName = `
  bg-ui-100
  dark:bg-ui-100
`

export const menuItem = `
  dark:border-ui-200
  text-ui-800
  cursor-pointer
  block
  py-2
  px-3
  duration-150
  ease-in-out
  transition-default
  hover:bg-ui-100
  hover:dark:bg-ui-100
`

export const menuItemDisabled = `
  dark:border-ui-200
  dark:text-ui-400
  text-ui-600
  cursor-not-allowed
  block
  py-2
  px-3
`

export const menuList = `
  bg-ui-50
  rounded-400
  py-1
  shadow-md
  dark:shadow-lg-dense
  dark:bg-ui-200
  text-sm
`

export const iconWrap = (type: `primary` | `secondary`) => `
  px-2
  border-l
  ${type === `primary` ? `border-lime-600` : ``}
`

export const buttonLink = `
  py-2
  px-3
  leading-5
  no-underline
  transition-default
  hover:!bg-transparent
`
