import {ReactNode} from 'react'
import {cn} from '../../util'

interface Props {
  children: ReactNode
  className?: string
  variants?: (keyof typeof errorMessageVariants)[]
}

const errorMessageVariants = {
  absolute: `absolute top-[1.5rem]`,
  scaled: `scale-[0.8]`,
  background: `bg-ui-50 dark:bg-ui-200`,
} as const

export const ErrorMessageStyler = ({
  children,
  className = ``,
  variants = [],
}: Props) => {
  const variantStyles = variants.map(variant => errorMessageVariants[variant])
  return <div className={cn(variantStyles, className)}>{children}</div>
}

export default ErrorMessageStyler
