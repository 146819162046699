import {useIntegrations} from '@myadbox/nebula-service-api'
import {
  Dispatch,
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from 'react'
import {
  ActiveIntegrationType,
  DefaultIntegrationsActions,
  DefaultIntegrationsState,
  UseDefaultIntegrationsOutput,
} from './types'

export const StateContext = createContext({
  hasDefaultIntegrations: false,
  defaultIntegrations: [],
  activeIntegrationType: null,
} as DefaultIntegrationsState)
export const DispatchContext = createContext(
  {} as Dispatch<DefaultIntegrationsActions>
)

export const defaultIntegrationsReducer = (
  prevState: DefaultIntegrationsState,
  action: DefaultIntegrationsActions
): DefaultIntegrationsState => {
  switch (action.type) {
    case `INITIALISE`:
      return {
        ...prevState,
        defaultIntegrations: action.payload.defaultIntegrations,
        hasDefaultIntegrations: action.payload.hasDefaultIntegrations,
      }
    case `SET_ACTIVE_INTEGRATION`:
      return {
        ...prevState,
        activeIntegrationType: action.payload,
      }
    case `REMOVE_ACTIVE_INTEGRATION`:
      return {
        ...prevState,
        activeIntegrationType: null,
      }
    default:
      throw new Error(
        `Unknown dispatch action type supplied to integrations reducer`
      )
  }
}

export const DefaultIntegrationsProvider = ({
  children,
}: {
  children: ReactElement
}): ReactElement => {
  const {
    fetchIntegrations,
    fetchIntegrationsResults: {data},
  } = useIntegrations()

  const [state, dispatch] = useReducer(defaultIntegrationsReducer, {
    hasDefaultIntegrations: false,
    defaultIntegrations: [],
    activeIntegrationType: null,
  })

  useEffect(() => {
    fetchIntegrations()
  }, [fetchIntegrations])

  useEffect(() => {
    const {integrations} = data ?? {}

    if (!integrations) return

    const defaultIntegrations = integrations.filter(
      ({isActive, isEnabledByDefault}) => isEnabledByDefault && isActive
    )

    const hasDefaultIntegrations = defaultIntegrations.length > 0

    if (hasDefaultIntegrations) {
      dispatch({
        type: `INITIALISE`,
        payload: {
          defaultIntegrations,
          hasDefaultIntegrations,
        },
      })
    }
  }, [data])

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export const useDefaultIntegrations = (): UseDefaultIntegrationsOutput => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)

  const setDefaultActiveIntegration = (
    activeIntegrationType: ActiveIntegrationType
  ): void =>
    dispatch({
      type: `SET_ACTIVE_INTEGRATION`,
      payload: activeIntegrationType,
    })

  const removeDefaultActiveIntegration = (): void =>
    dispatch({type: `REMOVE_ACTIVE_INTEGRATION`})

  return {
    ...state,
    setDefaultActiveIntegration,
    removeDefaultActiveIntegration,
  }
}
