import {
  DatasetFieldOptions,
  IntegrationFieldOptions,
  ModifierFieldOptions,
} from '@myadbox/nebula-service-api'

export const isDatasetOptions = (
  options: IntegrationFieldOptions
): options is DatasetFieldOptions => `selectionCriteria` in options

export const isModifierField = (
  options: IntegrationFieldOptions
): options is ModifierFieldOptions => `modifier` in options
