import {ArrowLeftIcon, PencilIcon} from '@heroicons/react/24/outline'
import {Button, Modal, PageActionHeader} from '@myadbox/gatsby-theme-nebula'
import type {NestedSchema} from '@myadbox/nebula-service-api'
import {Link} from 'gatsby-link'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import SchemaForm from '../../SchemaForm'

interface Props {
  fetched: boolean
  schema?: NestedSchema
}

const SchemaHeader = ({fetched, schema}: Props) => {
  const {t} = useTranslation()

  return (
    <PageActionHeader
      prepend={
        <Button
          slim
          size="xs"
          variant="text"
          title={t`settings.schemas.backBtnTitle`}
          to="/settings/schemas/"
          as={Link}
        >
          <ArrowLeftIcon width={20} height={20} />
        </Button>
      }
      text={
        fetched && schema ? (
          schema.title
        ) : (
          <span
            className={`
              bg-ui-100
              text-xs
              text-transparent
            `}
          >
            {t`settings.loading`}
          </span>
        )
      }
      append={
        schema && (
          <div
            className={`
            -mt-0.5
            ml-1
            flex
          `}
          >
            <Modal
              maxWidth="md"
              trigger={({open}) => (
                <Button
                  size="xs"
                  slim
                  variant="text"
                  onClick={open}
                  type="button"
                  title={t(`settings.schemas.editSchema.triggerBtn`)}
                >
                  <PencilIcon width={16} />
                </Button>
              )}
            >
              {({close}) => <SchemaForm schema={schema} close={close} />}
            </Modal>
          </div>
        )
      }
    ></PageActionHeader>
  )
}

export default SchemaHeader
