import {cn} from '@myadbox/stellar-ui'
import {HTMLAttributes} from 'react'

interface Props extends HTMLAttributes<HTMLElement> {
  tag?: `div` | `section` | `article` | `li`
}

/**
 * Put immediate children in a grid with padding column on the sides and 1fr in the middle, unless they have the class `col-span-full`. This allows LayoutFocussedContent to be nested inside other LayoutFocussedContents.
 */
const placeChildren = `[&>*:not([class~="col-span-full"])]:col-start-2`

const LayoutFocussedContent = ({
  children,
  className = ``,
  tag: Tag = `div`,
  ...props
}: Props) => {
  const mergedClassName = getLayoutFocussedContentClassName(className)
  return (
    <Tag {...props} className={mergedClassName}>
      {children}
    </Tag>
  )
}

export default LayoutFocussedContent

export const getLayoutFocussedContentClassName = (className = ``) =>
  cn(
    `
    grid-cols-[1rem_1fr_1rem]
    lg:grid-cols-[2rem_1fr_2rem]
    col-span-full
    grid
    gap-y-8
    qap-x-0
  `,
    className,
    placeChildren
  )
