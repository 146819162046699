import {ErrorMessage, useField} from 'formik'
import {FieldError} from '../../../../'
import DatePicker, {type DatePickerProps} from '../DatePicker'

const DatePickerFormik = ({
  name,
  locale,
  onChange,
  ...props
}: DatePickerProps) => {
  const [, meta, helpers] = useField(name)
  const {value} = meta
  const {setValue, setTouched} = helpers

  const defaultOnChange = ({date}) => {
    setValue(date)
    setTouched(true)
  }

  return (
    <div>
      <DatePicker
        name={name}
        {...props}
        startDateTime={value}
        onChange={onChange || defaultOnChange}
        locale={locale}
      />
      <ErrorMessage
        name={name}
        component={FieldError}
        data-testid={`error-${name}`}
        className={`pt-1`}
      />
    </div>
  )
}

export default DatePickerFormik
