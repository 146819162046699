import {InMemoryCache} from '@apollo/client'

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        fetchMoreUsers: {
          keyArgs: false,
          merge(existing, incoming, {args: {offset = 0}}) {
            const merged = existing ? existing.slice(0) : []
            for (let i = 0; i < incoming.length; ++i) {
              merged[offset + i] = incoming[i]
            }
            return merged
          },
        },
      },
    },
    Comment: {
      fields: {
        replies: {
          merge(existing, incoming, {readField, mergeObjects}) {
            const merged = existing ? existing.slice(0) : []
            const replyIdToIndex: Record<string, number> = Object.create(null)
            if (existing) {
              existing.forEach((reply, index) => {
                replyIdToIndex[readField<string>(`id`, reply)] = index
              })
            }
            incoming.forEach(reply => {
              const id = readField<string>(`id`, reply)
              const index = replyIdToIndex[id]
              if (typeof index === `number`) {
                // Merge the new author data with the existing author data.
                merged[index] = mergeObjects(merged[index], reply)
              } else {
                // First time we've seen this author in this array.
                replyIdToIndex[id] = merged.length
                merged.push(reply)
              }
            })
            return merged
          },
        },
      },
    },
  },
})
