import {useCallback} from 'react'
import {useLazyQuery, useMutation} from '@apollo/client'
import {FETCH_CATEGORIES, FETCH_CATEGORIES_BY_IDS} from '../../queries'
import {ADD_CATEGORY, UPDATE_CATEGORY, DELETE_CATEGORY} from '../../mutations'
import {addCategoryUpdater, deleteCategoryUpdater} from '../../updaters'
import {
  UseCategoriesOutput,
  AddCategoryMutator,
  UpdateCategoryMutator,
  DeleteCategoryMutator,
} from '../../types'

export const useCategories = (): UseCategoriesOutput => {
  const [getCategories, fetchCategoriesResponse] =
    useLazyQuery(FETCH_CATEGORIES)
  const fetchCategories = useCallback(
    (isActive?: boolean) => {
      getCategories({
        variables: {isActive},
      })
    },
    [getCategories]
  )

  const [categoriesByIds, fetchCategoriesByIdsResponse] = useLazyQuery(
    FETCH_CATEGORIES_BY_IDS
  )
  const fetchCategoriesByIds = useCallback(
    (ids: string[]) => {
      categoriesByIds({
        variables: {ids},
      })
    },
    [categoriesByIds]
  )

  const [doAddCategory, addCategoryResponse] = useMutation(ADD_CATEGORY)
  const addCategory: AddCategoryMutator = useCallback(
    input => {
      return doAddCategory({
        variables: {input},
        update: addCategoryUpdater(),
      })
    },
    [doAddCategory]
  )

  const [doUpdateCategory, updateCategoryResponse] =
    useMutation(UPDATE_CATEGORY)
  const updateCategory: UpdateCategoryMutator = useCallback(
    (id, input) => {
      return doUpdateCategory({
        variables: {id, input},
      })
    },
    [doUpdateCategory]
  )

  const [doDeleteCategory, deleteCategoryResponse] =
    useMutation(DELETE_CATEGORY)
  const deleteCategory: DeleteCategoryMutator = useCallback(
    id => {
      return doDeleteCategory({
        variables: {id},
        update: deleteCategoryUpdater(),
      })
    },
    [doDeleteCategory]
  )

  return {
    fetchCategories,
    fetchCategoriesResponse,
    fetchCategoriesByIds,
    fetchCategoriesByIdsResponse,
    addCategory,
    addCategoryResponse,
    updateCategory,
    updateCategoryResponse,
    deleteCategory,
    deleteCategoryResponse,
  }
}
