import {useState, useEffect, useRef} from 'react'

const useIsMounted = () => {
  const ref = useRef(false)
  const [, setIsMounted] = useState(false)
  useEffect(() => {
    ref.current = true
    setIsMounted(true)
    return () => {
      ref.current = false
    }
  }, [])
  return () => ref.current
}

export default useIsMounted
