// TODO: fix enum style naming to be consistent with other enums

import {qualitiesMap} from '.'

/**
 * Stores valid "Quality" values.
 * NOTE: Prefer using the `qualitiesMap` and data derived from that, as we want to avoid using enums altogether.
 *
 * @privateRemarks
 * This enum is inconsistent with other enums in the package. Here is an example of the correct format style:
 * ```
 * // preferred enum style…
 * // UPPER_SNAKE_CASE = `Proper Queen's English value goes here`
 * // example:
 * enum SomeEnum {
 * SOME_KEY = `Some readable value`,
 * SOME_OTHER_KEY = `Some other value`
 * }
 *
 * // usage:
 * if (something === SomeEnum.SOME_KEY) ...
 * ```
 */
export enum Quality {
  /**
   * Optimised for the web. It is a good balance of quality and file-size. Often maps to Cloudinary's `eco` quality.
   */
  Optimised = `ECO`,
  Low = `LOW`,
  High = `HIGH`,
  Maximum = `MAX`,
}

export type QualityUnion = (typeof qualitiesMap)[keyof typeof qualitiesMap]
