const defaultOptions = {
  color: `var(--ui-50)`,
  filter: ``,
}

/**
 * A helper function to generate the styles for a silhouette when a given state is active.
 * @param condition - if true, returns the silhouette styles, otherwise returns an empty object
 * @param options - options for customising the color and extra filters for the silhouette styles
 * @returns a CSSProperties object with the silhouette styles
 *
 * @remarks This technique is inspired by the skeleton pattern, but avoids the busy-work of creating matching skeleton components.
 */
const silhouetteStylesWhen = (condition = false, options = defaultOptions) => {
  const {color, filter} = Object.assign({}, defaultOptions, options)

  const style = condition
    ? {
        transform: `translateX(-100vw)`,
        filter: `drop-shadow(100vw 0px 0px ${color}) ${filter}`,
      }
    : {}

  return {
    style,
  }
}

export default silhouetteStylesWhen
