type Props = {
  className?: string
  size?: number
}

export const BulletPoint = ({className = ``, size = 12}: Props) => (
  <svg
    className={`${className}`}
    viewBox={`0 0 ${size * 2} ${size * 2}`}
    width={size}
    height={size}
    role="presentation"
  >
    <circle fill="currentColor" r={size / 2} cx={size} cy={size} />
  </svg>
)

export default BulletPoint
