import {ToggleInput} from '@myadbox/gatsby-theme-nebula'
import {UseAccountOutput} from '@myadbox/nebula-service-api'

import {FadeIn, LoaderIcon, Text} from '@myadbox/stellar-ui'
import {useTranslation} from 'gatsby-plugin-react-i18next'

type Props = {
  accountData: UseAccountOutput
}

const AccountAiTags = ({accountData}: Props) => {
  const {t} = useTranslation(`settings`, {
    keyPrefix: `settings.account`,
  })

  const {account, updateAITagsConfig, updateAITagsConfigResponse} = accountData

  const aiTagsEnabled = account?.configuration?.aiTagsEnabled ?? false
  return (
    <span
      className={`
        flex
        items-baseline
        gap-2
      `}
    >
      <div className={`relative top-1`}>
        <ToggleInput
          id="aiTagsEnabled"
          name="aiTagsEnabled"
          on={aiTagsEnabled}
          disabled={updateAITagsConfigResponse?.loading}
          onChange={e => {
            updateAITagsConfig(e.target.checked)
          }}
        />
      </div>
      {aiTagsEnabled && (
        <FadeIn>
          <Text color="textSecondary">{t`aiTags.enabled`}</Text>
        </FadeIn>
      )}
      {updateAITagsConfigResponse?.loading && <LoaderIcon size={16} />}
    </span>
  )
}

export default AccountAiTags
