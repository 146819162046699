import {FC, HTMLAttributes} from 'react'

type Props = HTMLAttributes<HTMLElement>

const SidebarInternal: FC<Props> = ({className = ``, children, ...props}) => {
  return (
    <aside
      id="sidebar"
      className={`
        ${className}
        until-md:bg-ui-200
        until-md:gap-4
        dark:until-md:bg-ui-100
        relative
        min-w-0
        grow-0
        md:basis-[--flex-basis-var-1]
      `}
      style={{
        '--flex-basis-var-1': `var(--cell-min-width)`,
      }}
      {...props}
    >
      <div
        className={`
          lap:after:content
          lap:after:h-[--height-var-1]
          box-content
          grid
          content-start
          gap-8
          overflow-y-auto
          px-4
          py-10
          md:fixed
          md:max-h-[--max-height-var-1]
          md:w-[--width-var-1]
          md:pl-8
          md:pr-4
        `}
        style={{
          '--width-var-1': `calc(var(--cell-min-width))`,
          '--max-height-var-1': `calc(var(--viewport-height) - 9.6rem)`,
          '--height-var-1': `4.5rem`,
        }}
      >
        {children}
      </div>
    </aside>
  )
}

export default SidebarInternal
