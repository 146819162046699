import {Config, MultiPageConfig, PagesRecord, MultiPage} from './types'
import {Fields} from '../types'
import {
  isMultiPageFormat,
  convertToMultiPageFields,
  extractPagesFields,
} from '../fields'

/**
 * Returns unchanged MultiPage format or converts legacy to MultiPage while retaining type-safety.
 * @param values - the values to assign as 001
 * @returns the {@link MultiPage} formatted data
 */
export const toMultiPage = <T extends object>(
  values: T | MultiPage<T>
): MultiPage<T> => {
  if (isMultiPageFormat(values)) return values
  const defaultType = Array.isArray(values) ? [] : {}
  return {
    global: defaultType as T,
    '001': values,
  }
}

/**
 * Combines supplied data into MultiPage format while retaining type-safety. Used by Exoplanet build step.
 * @param globalValues - the values to assign as global
 * @param pagesValues - the {@link PagesRecord} of values to assign to page numbers
 * @returns the {@link MultiPage} formatted data
 */
export const combineToMultiPage = <Values>(
  globalValues: Values,
  pagesValues?: PagesRecord<Values>
): MultiPage<Values> => {
  return {
    global: globalValues,
    ...pagesValues,
  }
}

/**
 * This function provides type-checking while defining a template’s config.
 * @param config - a global {@link Config} or page {@link PageConfig} object defining a template’s config.
 * @returns a valid Config or PageConfig object
 *
 * @example
 * Note that `// @ts-check` should appear as the first line of the config.mjs file in order to get the type-checking aids working.
 * ```
 * //@ts-check
 * import {
 *   defineConfig,
 *   createField,
 *   embedSizes,
 *   allSizes,
 *   allFormats,
 * } from '@myadbox/nebula-template-utils'
 *
 * export default defineConfig({
 *   sizes: embedSizes({ ... omitted for brevity ...}),
 *   defaultSize: allSizes.bannerAd.main.sizes.mobileLandscape,
 *   features: [Feature.FIXED_SIZE],
 *   formats: allFormats.web.static,
 *   fields: [{
 *     type: `text`,
 *     name: 'title',
 *     options: {
 *       label: 'Title',
 *       details: `Further (optional) helper text for this field.`
 *     }
 *   }]
 * })
 * ```
 *
 * @see {@link Config}
 * @see {@link embedSizes}
 * @see {@link modifySizes}
 */
function defineConfig(config: PageConfig): PageConfig
function defineConfig(config: Config): Config
function defineConfig(config: Config | PageConfig) {
  return config
}
export {defineConfig}

export type PageConfig = {
  fields: Fields
}

/**
 * Normalise legacy Config to MultiPageConfig. Used by Exoplanet build step.
 * @param config - the supplied global config
 * @param pages - the pages config assigned to page numbers
 * @returns the MultiPageConfig
 */
export const processMultiPageConfig = (
  config: Config,
  pages = {} as PagesRecord<Config>
): MultiPageConfig => {
  // optional chaining used on config.fields to ensure this works
  // in a non-type-checked environment, ie. build tasks, etc.
  const fields = convertToMultiPageFields(config?.fields)
  const pagesFields = extractPagesFields(pages)

  return {
    ...config,
    fields: combineToMultiPage(fields.global, pagesFields),
  }
}
