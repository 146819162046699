import {FETCH_APPROVALS} from './queries'

export const createApprovalUpdater = (assetIds: string[]) => {
  return (cache, {data}) => {
    const cachedData = cache.readQuery({
      query: FETCH_APPROVALS,
      variables: {assetIds},
    })

    if (!cachedData || !data) return

    cache.writeQuery({
      query: FETCH_APPROVALS,
      variables: {assetIds},
      data: {
        approvals: [...cachedData.approvals, data.createApproval],
      },
    })
  }
}
