import {gql} from '@apollo/client'

export const RENDER = gql`
  mutation Render($input: RenderInput!) @api(name: "templates") {
    render(input: $input) {
      publicId
      secureUrl
    }
  }
`

export const PACKAGE = gql`
  mutation PackageTemplate($input: PackageTemplateInput!)
  @api(name: "templates") {
    packageTemplate(input: $input) {
      cloudinaryId
      originalFilename
      originalFormat
      name
      bytes
      resourceType
      secureUrl
      type
    }
  }
`

export const GET_AI_SUGGESTION = gql`
  mutation GetAiSuggestion($input: String!) @api(name: "templates") {
    getAiSuggestion(input: $input)
  }
`
