import {FETCH_COMMENTS, FETCH_COMMENT_COUNT} from './queries'

export const createUpdater = (entityId, entityType, entityIds) => {
  return (cache, {data}) => {
    const cachedData = cache.readQuery({
      query: FETCH_COMMENTS,
      variables: {entityId, entityType},
    })

    if (!cachedData || !data) return

    cache.writeQuery({
      query: FETCH_COMMENTS,
      variables: {entityId, entityType},
      data: {
        fetchComments: [...cachedData.fetchComments, data.createComment],
      },
    })
    commentCountCacheUpdater(cache, entityId, entityType, entityIds, 1)
  }
}

export const replyUpdater = (entityId, entityType, entityIds) => {
  return (cache, {data}) => {
    const cachedData = cache.readQuery({
      query: FETCH_COMMENTS,
      variables: {entityId, entityType},
    })

    if (!cachedData || !data) return

    cache.writeQuery({
      query: FETCH_COMMENTS,
      variables: {entityId, entityType},
      data: {
        fetchComments: [
          ...cachedData.fetchComments.filter(
            ({id}) => id !== data.replyToComment.id
          ),
          data.replyToComment,
        ],
      },
    })

    commentCountCacheUpdater(cache, entityId, entityType, entityIds, 1)
  }
}

export const countUpdater =
  (entityId, entityType, entityIds, count) => cache => {
    commentCountCacheUpdater(cache, entityId, entityType, entityIds, count)
  }

export const commentCountCacheUpdater = (
  cache,
  entityId,
  entityType,
  entityIds,
  countDifference
) => {
  const countData = cache.readQuery({
    query: FETCH_COMMENT_COUNT,
    variables: {entityIds, entityType},
  })

  const newCountData = countData
    ? countData.fetchCommentCount.map(commentCount =>
        commentCount.entityId === entityId
          ? {...commentCount, total: commentCount.total + countDifference}
          : commentCount
      )
    : [{__typename: `CommentCountOutput`, entityId, total: countDifference}]

  if (!newCountData.find(commentCount => commentCount.entityId === entityId)) {
    newCountData.push({
      __typename: `CommentCountOutput`,
      entityId,
      total: 1,
    })
  }

  cache.writeQuery({
    query: FETCH_COMMENT_COUNT,
    variables: {entityIds, entityType},
    data: {
      fetchCommentCount: newCountData,
    },
  })
}
