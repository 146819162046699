import {Asterisk} from '../../Asterisk'
import {MessageProps} from './types'

const Message = ({
  text,
  asterisk,
  className = ``,
  children,
  ...props
}: MessageProps) => {
  if (!text && !children) return null
  return (
    <div
      role="status"
      className={`
        ${className}
        text-ui-600
        animate-zoom-fade-in
        pt-1
        text-xs
        font-light
        italic
      `}
      {...props}
    >
      {asterisk && <Asterisk />}
      {children || text}
    </div>
  )
}

export default Message
