type Props = React.SVGAttributes<SVGElement> & {
  className?: string
  size?: number
}

const StrikethroughTextIcon = ({size, ...rest}: Props) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...rest}>
    <path
      d="M14.7443 9.18182C14.6676 8.5341 14.3565 8.03125 13.8111 7.6733C13.2656 7.31535 12.5966 7.13637 11.804 7.13637C11.2244 7.13637 10.7173 7.23012 10.2827 7.41762C9.85227 7.60512 9.51562 7.86293 9.27272 8.19106C9.03408 8.51918 8.91477 8.89205 8.91477 9.30966C8.91477 9.65909 8.99786 9.95952 9.16406 10.2109C9.33451 10.4581 9.55184 10.6648 9.81604 10.831C10.0802 10.9929 10.3572 11.1271 10.647 11.2337C10.9368 11.3359 11.2031 11.419 11.446 11.483L12.7756 11.8409C13.1165 11.9304 13.4957 12.054 13.9133 12.2117C14.3352 12.3693 14.7379 12.5845 15.1214 12.8572C15.5092 13.1257 15.8288 13.4709 16.0802 13.8928C16.3317 14.3146 16.4574 14.8324 16.4574 15.446C16.4574 16.1534 16.272 16.7926 15.9013 17.3636C15.5348 17.9347 14.9979 18.3885 14.2905 18.7251C13.5874 19.0618 12.7329 19.2301 11.7273 19.2301C10.7898 19.2301 9.97798 19.0788 9.2919 18.7763C8.61008 18.4737 8.07315 18.0519 7.6811 17.5107C7.29332 16.9695 7.07386 16.3409 7.02272 15.625H8.65908C8.7017 16.1193 8.86789 16.5284 9.15766 16.8523C9.4517 17.1719 9.82244 17.4105 10.2699 17.5682C10.7216 17.7216 11.2074 17.7983 11.7273 17.7983C12.3324 17.7983 12.8757 17.7003 13.3572 17.5043C13.8388 17.304 14.2202 17.027 14.5014 16.6733C14.7827 16.3153 14.9233 15.8977 14.9233 15.4205C14.9233 14.9858 14.8018 14.6321 14.5589 14.3594C14.316 14.0867 13.9964 13.8651 13.6001 13.6946C13.2038 13.5242 12.7756 13.375 12.3153 13.2472L10.7045 12.7869C9.68181 12.4929 8.87215 12.0732 8.27556 11.5277C7.67897 10.9822 7.38067 10.2685 7.38067 9.38637C7.38067 8.65341 7.57883 8.01421 7.97514 7.46875C8.3757 6.91904 8.91264 6.4929 9.58593 6.19035C10.2635 5.88353 11.0199 5.73012 11.8551 5.73012C12.6989 5.73012 13.4489 5.8814 14.1051 6.18395C14.7614 6.48225 15.2812 6.89134 15.6648 7.41123C16.0526 7.93111 16.2571 8.52131 16.2784 9.18182H14.7443Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 13.5L4 13.5L4 12.5L20 12.5L20 13.5Z"
      fill="currentColor"
    />
  </svg>
)

export default StrikethroughTextIcon
