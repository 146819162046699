import {gql} from '@apollo/client'
import {COMMENT_ANNOTATION, COMMENT_INFO, COMMENT_REPLY} from './fragments'

export const CREATE_COMMENT = gql`
  ${COMMENT_INFO}
  ${COMMENT_REPLY}
  ${COMMENT_ANNOTATION}
  mutation CreateComment(
    $annotation: Annotation
    $body: String!
    $entityId: String!
    $entityType: EntityType!
  ) @api(name: "comments") {
    createComment(
      annotation: $annotation
      body: $body
      entityId: $entityId
      entityType: $entityType
    ) {
      id
      ...CommentInfo
      annotation {
        ...CommentAnnotation
      }
      replies {
        id
        ...CommentReply
      }
    }
  }
`

export const DELETE_COMMENT = gql`
  mutation DeleteComment($id: ID!) @api(name: "comments") {
    deleteComment(id: $id) {
      id
    }
  }
`

export const REPLY_TO_COMMENT = gql`
  ${COMMENT_INFO}
  ${COMMENT_REPLY}
  ${COMMENT_ANNOTATION}
  mutation ReplyToComment($id: ID!, $body: String!) @api(name: "comments") {
    replyToComment(id: $id, body: $body) {
      id
      ...CommentInfo
      replies {
        id
        ...CommentReply
      }
    }
  }
`

export const DELETE_REPLY = gql`
  ${COMMENT_REPLY}
  mutation DeleteReply($id: ID!, $replyId: String!) @api(name: "comments") {
    deleteReply(id: $id, replyId: $replyId) {
      id
      replies {
        id
        ...CommentReply
      }
    }
  }
`
