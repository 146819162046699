import {ActionGroup, Button} from '@myadbox/stellar-ui'
import {CopyDetail, Text, TextArea} from '@myadbox/gatsby-theme-nebula'
import {useTranslation} from 'react-i18next'
import {Asset, useAccount, useProfiles} from '@myadbox/nebula-service-api'
import {useEffect, useState} from 'react'

export const DefaultIntegrationsEmbed = ({
  assets,
  handleCancel,
}: {
  assets: Asset[]
  handleCancel: () => void
}) => {
  const {t} = useTranslation(`defaultIntegrations`)
  const [embedCode, setEmbedCode] = useState(``)
  const {account} = useAccount()
  const {profile} = useProfiles()

  useEffect(() => {
    const {id, name, versions} = assets[0]
    const {userId} = profile

    const payload = {
      id: id,
      uid: userId,
      acc: account?.id,
    }

    const base64Payload = window.btoa(JSON.stringify(payload))

    const imgSrc = `${process.env.GATSBY_EMBED_SUBDOMAIN}/image/upload/${versions[0].cloudinaryId}?p=${base64Payload}`
    const embedTag = `<img alt="${name}" src="${imgSrc}" />`
    setEmbedCode(embedTag)
  }, [account, assets, profile])

  return (
    <div className="flex flex-col gap-4">
      <Text>{t(`embed.code`)}</Text>
      <TextArea
        id="embedCode"
        name="embedCode"
        label={t(`embed.code`)}
        labelHidden
        value={embedCode}
        rows={7}
        disabled
        noResize
      />
      <div
        className="
          transition-property-default
          active:bg-ui-50
          active:text-ui-800
          hover:border-ui-500
          hover:text-ui-700
          text-ui-600
          dark:hover:border-ui-300
          relative
          inline-flex
          items-center
          justify-center
            whitespace-nowrap
            rounded
            border
            px-4
            py-2
          text-left
          text-sm
          font-medium
          leading-5
          duration-75
          ease-in-out
          focus:border-lime-300
        "
      >
        <CopyDetail title={t(`embed.copy`)} copyText={embedCode}>
          {t(`embed.copy`)}
        </CopyDetail>
      </div>
      <ActionGroup>
        <Button variant="secondary" onClick={handleCancel}>
          {t(`embed.close`)}
        </Button>
      </ActionGroup>
    </div>
  )
}
