export enum Feature {
  HIDE_SIZES = `hide-sizes`,
  FIXED_SIZES = `fixed-sizes`,
  BLEED = `bleed`,
  SCROLL = `scroll`,
  KEYFRAMES = `keyframes`,
  INTERACTIVE = `interactive`,
}

// exporting Features (plural) for legacy reasons as it was
// used in template config for a while prior to making it
// consistent with the other (singular) types
export {Feature as Features}

export type FeatureUnion = `${Feature}`
