import {Logo} from '@myadbox/stellar-ui'
import FocusTrap from 'focus-trap-react'
import {ReactNode, useContext} from 'react'
import {Transition} from 'react-transition-group'
import {MobileMenuContext} from '../../contexts/MenuContext'
import ScreenOverlay from '../../ScreenOverlay'
import type {LogoType} from '../../types'
import MenuCloseButton from './MenuCloseButton/MenuCloseButton'

interface Props extends LogoType {
  expanded?: boolean
  Menu: ReactNode
}

const overlayWrapperTransitions: {[key: string]: string} = {
  entering: `opacity-0 pointer-events-none`,
  entered: `opacity-100 pointer-events-auto`,
  exiting: `opacity-0 pointer-events-none`,
  exited: `opacity-0 pointer-events-none`,
}

const menuTransitions: {[key: string]: string} = {
  entering: `pointer-events-none -translate-x-1`,
  entered: `pointer-events-auto translate-x-0`,
  exiting: `pointer-events-none -translate-x-1`,
  exited: `pointer-events-none -translate-x-1`,
}

const duration = 200

const SidebarOffscreen = ({expanded = false, Menu = <div />}: Props) => {
  const {closeMenu} = useContext(MobileMenuContext)
  return (
    <Transition timeout={duration} in={expanded}>
      {(transitionState: string): ReactNode => (
        <FocusTrap active={expanded}>
          <div
            data-testid="offscreen-menu-wrap"
            hidden={!expanded}
            aria-hidden={!expanded}
            role="menu"
            className={`
              fixed
              inset-0
              z-40
              flex
              duration-200
              md:hidden
              ${overlayWrapperTransitions[transitionState]}
            `}
          >
            <ScreenOverlay className={`z-index-[-1]`} onClick={closeMenu} />

            <div
              className={`
                bg-ui-0
                relative
                flex
                w-full
                max-w-xs
                flex-1
                flex-col
                pb-4
                transition-all
                duration-200
                ${menuTransitions[transitionState]}
              `}
            >
              <Logo />
              <div className="mt-5 h-0 flex-1 overflow-y-auto">{Menu}</div>
              <div className="absolute left-[100%] top-0">
                <MenuCloseButton expanded={expanded} />
              </div>
            </div>
            <div className="w-14 shrink-0">
              {/*<!-- Dummy element to force sidebar to shrink to fit close icon -->*/}
            </div>
          </div>
        </FocusTrap>
      )}
    </Transition>
  )
}

export default SidebarOffscreen
