import {
  ActionGroup,
  Button,
  Modal,
  Text,
  Toast,
} from '@myadbox/gatsby-theme-nebula'
import {Category, useCategories} from '@myadbox/nebula-service-api'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next'
import {useEffect} from 'react'
import {getAncestry} from '../helpers'

interface Props {
  category: Category
  categories: Category[]
  close: () => void
}

const DeleteMsg = ({category, categories, close}: Props) => {
  const {t} = useTranslation()
  const {
    deleteCategory,
    deleteCategoryResponse: {data, called, error, loading},
  } = useCategories()

  useEffect(() => {
    if (called && data) {
      close()
    }
  }, [data, called, close])

  if (error) {
    Toast.show(
      <Toast intent="error">
        <Trans
          i18nKey="settings.removeError"
          values={{errMessage: error.message}}
        />
      </Toast>
    )
  }

  const {id, parent} = category

  return (
    <>
      <Modal.Header>{t`settings.categories.removeTitle`}</Modal.Header>
      <Modal.Body>
        <Text>
          <Trans
            i18nKey={`settings.categories.${
              parent ? `removeText` : `removeNestedText`
            }`}
            values={{
              categoryTitle: getAncestry(category, categories),
            }}
            components={[<strong key="1" />]}
            tOptions={{
              interpolation: {escapeValue: false},
            }}
          />
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <ActionGroup>
          <Button
            type="button"
            variant="secondary"
            onClick={(): void => {
              close()
            }}
          >
            {t`settings.cancel`}
          </Button>
          <Button
            type="submit"
            variant="danger"
            loading={loading}
            loadingText={t`settings.deleting`}
            disabled={loading}
            onClick={() => {
              deleteCategory(id)
            }}
          >
            {t`settings.removeConfirm`}
          </Button>
        </ActionGroup>
      </Modal.Footer>
    </>
  )
}

export default DeleteMsg
