import {
  isArkaroolaPage,
  isExternalPage,
  returnToLogin,
  useUsers,
} from '@myadbox/nebula-service-api'
import {createContext, useEffect, useMemo} from 'react'
import useCognitoAuthToken from '../useCognitoAuthToken'
import {UserProvider} from '../useProfile/UserProvider'
import {clearCognito, getToken, getUserSession} from './helpers'

export const UserSessionContext = createContext(null)
UserSessionContext.displayName = `UserSessionContext`

export const UserSessionProvider = ({pathname, children}) => {
  const {authenticateUser, authenticateUserResult} = useUsers()
  const {jwt, userData} = getToken()
  const cognitoAuthToken = useCognitoAuthToken()
  const accessToken = cognitoAuthToken?.accessToken ?? null

  const hasIsAccountAdmin = useMemo(() => jwt && `isAccountAdmin` in jwt, [jwt])

  useEffect(() => {
    if (accessToken?.jwtToken && hasIsAccountAdmin) {
      authenticateUser(accessToken.jwtToken, jwt?.isAccountAdmin)
    }
  }, [
    authenticateUser,
    accessToken?.jwtToken,
    jwt?.isAccountAdmin,
    hasIsAccountAdmin,
  ])

  useEffect(() => {
    const {data, error, loading} = authenticateUserResult || {}

    if (loading) return

    /**
     * When a new network request is made while the previous one is still in transition
     * ApolloClient seems to throw this error
     */
    if (error) {
      if (error.message === `Failed to fetch` && !error.graphQLErrors?.length) {
        return
      } else {
        localStorage.removeItem(`userData`)
        return
      }
    }

    const res = data?.authenticate?.userData
    if (res) localStorage.setItem(`userData`, JSON.stringify(res))
  }, [authenticateUserResult])

  const userSession = getUserSession(jwt, userData)

  if (isArkaroolaPage(pathname) || isExternalPage(pathname)) {
    return children
  } else if (userSession) {
    return (
      <UserSessionContext.Provider value={userSession}>
        <UserProvider>{children}</UserProvider>
      </UserSessionContext.Provider>
    )
  } else {
    clearCognito()
    returnToLogin()
    return null
  }
}

export default UserSessionProvider
