import {gql} from '@apollo/client'

export const TEMPLATE = gql`
  query GetTemplate($templateUrl: String!) @api(name: "templates") {
    template(templateUrl: $templateUrl) {
      id
      type
      secureUrl
      publicId
      remotionSiteUrl
      thumbnailPublicId
      pageThumbnailPublicIds
      templateUrl
      configValues
      defaultValues
    }
  }
`
