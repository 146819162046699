import {ReactElement} from 'react'

export const InputIcon = ({
  position = `before`,
  icon,
}: {
  position?: `before` | `after`
  icon: ReactElement
}) => {
  return (
    <span
      className={`
        text-ui-500
        absolute
        z-10
        flex
        h-full
        items-center
        px-2
        ${position === `after` ? `bottom-0 right-0` : ``}
      `}
    >
      {icon}
    </span>
  )
}
