import {ToastClassName, TypeOptions} from 'react-toastify'

export const toastClassNamer: ToastClassName = (
  context = {type: `default`}
): string => {
  const contextClassesIndex: ToastIntent = isIntentSubset(context.type)
    ? context.type
    : `default`
  return `
  ${toastClasses}
  ${contextClasses[contextClassesIndex]}
`
}

export type ToastIntent = keyof typeof contextClasses

// classes
export const contextClasses = {
  success: `
    border-b
    border-green-400
  `,
  error: `
    border-b
    border-red-500
  `,
  warning: `
    border-b
    border-yellow-400
  `,
  default: `
    border-b
    border-ui-border
  `,
} as const satisfies Partial<Record<TypeOptions, string>>

export const intentClasses = {
  success: `
    text-green-400
  `,
  error: `
    text-red-500
  `,
  warning: `
    text-yellow-400
  `,
  default: `
    text-ui-400
  `,
} as const satisfies Partial<Record<TypeOptions, string>>

export const toastClasses = `
  flex
  items-start
  justify-between
  bg-ui-700
  border-b
  dark:bg-ui-200
  dark:text-ui-500
  shadow-lg
  rounded-md
  cursor-pointer
  relative
  group
  overflow-hidden
  md:mt-2
`

export const bodyClassNames = `
  block
  flex-1
  text-xs
  md:text-sm
  pr-2
  pl-3
  py-5
  md:pl-7
  md:py-7
`

const isIntentSubset = (type: TypeOptions | undefined): type is ToastIntent => {
  return typeof type === `string` && Object.keys(contextClasses).includes(type)
}
