import {QuestionMarkCircleIcon} from '@heroicons/react/24/outline'
import {ChatBubbleOvalLeftEllipsisIcon} from '@heroicons/react/24/solid'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import Dropdown from '../../../Dropdown'
import {SizeClassNames} from '../../../Dropdown/helpers'
import {Intercom} from './helper'
import {HelpMenuProps} from './types'

const sizeClassNames: SizeClassNames = `w-[2rem] h-[2rem]`

export const HelpMenu = ({
  tours = [],
  hideIntercomMessenger,
}: HelpMenuProps) => {
  const {t, i18n} = useTranslation(`layout`)

  const shortLanguageCode = i18n.language ? i18n.language.split(`-`)[0] : `en`

  useEffect(() => {
    if (Intercom.hasIntercom(window)) {
      window.Intercom(`update`, {
        hide_default_launcher: Boolean(hideIntercomMessenger),
      })
    }
  })
  const intercomTours = tours.filter(
    tour => tour.language === shortLanguageCode
  )

  return (
    <Dropdown
      /**
       * This is a workaround to ensure the icon is always visible,
       * rather than flickering in when Reach Dropdown renders the trigger button.
       */
      triggerAppearance={
        <Dropdown.TriggerPlaceholder
          className={`
            group-hover:text-ui-900
            text-ui-400
            p-1
          `}
        >
          <QuestionMarkCircleIcon width={24} height={24} />
        </Dropdown.TriggerPlaceholder>
      }
      // w- and h- reserves space to prevent layout jank
      triggerSizeClassNames={sizeClassNames}
      trigger={
        <Dropdown.Button
          unstyled
          aria-label={t(`help.toggleSupportMenu`)}
          title={t(`help.toggleSupportMenu`)}
          className={`
            hocus:bg-ui-100
            rounded-full
            !p-1
          `}
          id="help-menu-trigger"
        />
      }
    >
      {intercomTours.map(({tourId, name}) => (
        <Dropdown.Item
          key={tourId}
          aria-label={name}
          onSelect={() => Intercom.startTour(tourId)}
        >
          {name}
        </Dropdown.Item>
      ))}
      <Dropdown.Item
        href={`https://intercom.help/sesimi/${shortLanguageCode}/`}
      >
        {t(`help.centre`)}
      </Dropdown.Item>
      <Dropdown.Item onSelect={() => Intercom.showNewMessage()}>
        <div className={`flex`}>
          <ChatBubbleOvalLeftEllipsisIcon
            width={24}
            height={24}
            className={`
                mr-1
                inline
                text-green-400
              `}
          />
          {t(`help.live`)}
        </div>
      </Dropdown.Item>
    </Dropdown>
  )
}

export default HelpMenu
