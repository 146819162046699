import {FC} from 'react'
import NoAsset from './NoAsset'

const nameToSvg = {
  noAsset: <NoAsset />,
}

interface Props {
  name: keyof typeof nameToSvg
}

const Illustration: FC<Props> = ({name = `noAsset`}) => {
  return <div className={`flex justify-center`}>{nameToSvg[name]}</div>
}

export default Illustration
