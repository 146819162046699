import {FC, HTMLAttributes, MutableRefObject} from 'react'

interface Props extends HTMLAttributes<HTMLElement> {
  ref?: MutableRefObject<HTMLElement>
}

const CodeInline: FC<Props> = ({children, className = ``, ...props}) => (
  <code
    className={`
      ${className}
      hover:text-ui-800
      rounded-200
      group
      inline-flex
      min-w-0
      max-w-full
      cursor-grab
      items-baseline
      font-sans
      active:cursor-grabbing
    `}
    {...props}
  >
    {children}
  </code>
)

export default CodeInline
