/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import {ApolloProvider} from '@apollo/client'
import {client} from '@myadbox/nebula-service-api'
import {GatsbyBrowser} from 'gatsby'

import {
  FeatureFlagsProvider,
  PluginOptionsProvider,
  UserSessionProvider,
} from '@myadbox/gatsby-theme-nebula'

export const wrapPageElement: GatsbyBrowser[`wrapPageElement`] = ({
  element,
  props: {
    location: {pathname},
  },
}) => {
  return (
    <UserSessionProvider pathname={pathname}>
      <FeatureFlagsProvider>{element}</FeatureFlagsProvider>
    </UserSessionProvider>
  )
}

export const wrapRootElement: GatsbyBrowser[`wrapRootElement`] = ({
  element,
}) => {
  return (
    <PluginOptionsProvider>
      <ApolloProvider client={client}>{element}</ApolloProvider>
    </PluginOptionsProvider>
  )
}

// /**
//  * This function replaces the default `hydrate` function in Gatsby.
//  * It's very definitely a hack to hide hydration errors, but since
//  * Gatsby's DEV_SSR flag no longer works, it's prohibitively difficult
//  * to debug them.
//  */
// export const replaceHydrateFunction = () => {
//   return (element, container) => {
//     const root = ReactDOM.createRoot(container)
//     root.render(element)
//   }
// }
