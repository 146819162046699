import {Bars4Icon, XMarkIcon} from '@heroicons/react/24/outline'
import {Button, Checkbox, Input, Select} from '@myadbox/gatsby-theme-nebula'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import React from 'react'
import {getFieldTypeOptions} from './helpers'

import type {FieldArrayRenderProps} from 'formik'
import type {SchemaFieldFormInput} from '../../types'

type Props = {
  fields: SchemaFieldFormInput[]
  arrayHelpers: FieldArrayRenderProps
  columnsClassName: string
  columnsStyle: React.CSSProperties
}

const ColumnsList = ({
  fields,
  arrayHelpers,
  columnsClassName,
  columnsStyle,
}: Props) => {
  const {t} = useTranslation()
  const [dropzoneId, setDropzoneId] = React.useState<number>()

  const handleDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    id: number
  ): void => {
    event.dataTransfer.setData(`text`, id.toString())
  }

  const handleDragEnter = (
    event: React.DragEvent<HTMLDivElement>,
    id: number
  ) => {
    event.preventDefault()
    setDropzoneId(id)
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    swap: (from: number, to: number) => void
  ) => {
    event.preventDefault()
    const dragId = Number(event.dataTransfer.getData(`text`))
    swap(dragId, dropzoneId)
    setDropzoneId(undefined)
  }

  const fieldTypeOptions = getFieldTypeOptions(t)

  const {remove, swap} = arrayHelpers

  const dropzoneStyle = `
    border-2
    border-dashed
    rounded-200
    border-ui-400
  `

  return (
    <>
      {fields.map((_, index) => (
        <div
          key={index}
          className={`
            ${columnsClassName}
            ${index === dropzoneId ? dropzoneStyle : ``}
          `}
          style={columnsStyle}
          draggable
          onDragStart={e => handleDragStart(e, index)}
          onDragEnter={e => handleDragEnter(e, index)}
          onDragOver={handleDragOver}
          onDrop={e => handleDrop(e, swap)}
        >
          <div className={`cursor-grab`}>
            <Bars4Icon width={16} height={16} />
          </div>
          <Input.Formik
            name={`fields.${index}.title`}
            label={t(`settings.schemas.fieldAriaTitle`, {
              fieldIndex: index + 1,
            })}
            labelHidden
            id={`schema-field-title-${index}`}
            placeholder={t`settings.schemas.fieldTitlePlaceholder`}
          />
          <Select.Formik
            name={`fields.${index}.type`}
            label={t(`settings.schemas.fieldAriaType`, {
              fieldIndex: index + 1,
            })}
            inModal
            labelHidden
            id={`schema-field-type-${index}`}
            options={fieldTypeOptions}
          />
          <div className="text-center">
            <Checkbox.Formik
              aria-label={t(`settings.schemas.fieldAriaRequired`, {
                fieldIndex: index + 1,
              })}
              id={`schema-field-required-${index}`}
              name={`fields.${index}.required`}
            />
          </div>
          <div>
            <Button
              slim
              size="xs"
              variant="text"
              type="button"
              title={t`settings.schemas.removeField`}
              disabled={fields.length === 1}
              onClick={(): void => remove(index)}
              aria-label={t`settings.schemas.removeField`}
            >
              <XMarkIcon width={16} height={16} />
            </Button>
          </div>
        </div>
      ))}
    </>
  )
}

export default ColumnsList
