import {SVGAttributes} from 'react'

const TheLogoText = ({className = ``, ...props}: SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 372.29 100"
      className={`${className}`}
      role="presentation"
      {...props}
    >
      <path
        fill="#fff"
        d="M212.6,9a8.89,8.89,0,1,1,17.77,0A8.89,8.89,0,1,1,212.6,9Zm2.48,19.84h12.81V97.67H215.08Z"
      />
      <path
        fill="#fff"
        d="M342,52.76V97.67H329.2V55.1c0-9.64-4.13-16.25-12.4-16.25-10.47,0-17.91,10-17.91,25.35V97.67H286.08V55.1c0-9.78-4.13-16.39-12.53-16.39-10.47,0-17.91,10.06-17.91,25.62V97.67H242.83V28.79h12.81V40.91A23.64,23.64,0,0,1,277.54,27c10.19,0,17.36,5.79,20.11,14.88a24.61,24.61,0,0,1,23-14.88C334.71,27,342,37.06,342,52.76Z"
      />
      <path
        fill="#fff"
        d="M354.52,9a8.89,8.89,0,1,1,17.77,0,8.89,8.89,0,1,1-17.77,0ZM357,28.79h12.81V97.67H357Z"
      />
      <path
        fill="#fff"
        d="M132.14,76.82c-4,14.3-14.78,23.18-31.3,23.18-21.09,0-33.38-14.57-33.38-36.5S79.47,27,101,27c22.49,0,34.14,17,32.66,40.66H81.2c1.39,13,7.71,20.82,19.64,20.82,8.88,0,14.65-4.44,17.28-11.66ZM81.48,57.12h38.3c-1.46-11.9-8-18.88-18.8-18.88C89.46,38.24,83.28,45.18,81.48,57.12Z"
      />
      <path
        fill="#fff"
        d="M14.25,47.66c0-6.06,6.48-10,15-10s15.15,4.16,16,10.51H57.88c0-12.3-12-21.12-28.34-21.12C13.84,27,1,35.4,1,48.77c0,25.34,45.18,14.6,45.18,30.44,0,5.92-6.2,9.64-15.7,9.64-10.41,0-17-4.66-18.08-12H0C.92,91.26,13.08,99.46,30.37,99.46s28.93-8,28.93-21.35C59.3,52.07,14.25,63.09,14.25,47.66Z"
      />
      <path
        fill="#fff"
        d="M156.67,47.66c0-6.06,6.48-10,15-10,8.69,0,15.16,4.16,16,10.51H200.3c0-12.3-12-21.12-28.34-21.12-15.7,0-28.51,8.4-28.51,21.77,0,25.34,45.18,14.6,45.18,30.44,0,5.92-6.2,9.64-15.7,9.64-10.41,0-17-4.66-18.07-12H142.42c.93,14.44,13.08,22.64,30.37,22.64s28.93-8,28.93-21.35C201.72,52.07,156.67,63.09,156.67,47.66Z"
      />
    </svg>
  )
}

export default TheLogoText
