import {CheckIcon} from '@heroicons/react/24/outline'
import {
  ActionGroup,
  Banner,
  Button,
  Checkbox,
  Input,
  Modal,
  Select,
  Text,
} from '@myadbox/gatsby-theme-nebula'
import {useDatasets} from '@myadbox/nebula-service-api'
import {Form, Formik} from 'formik'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {useEffect} from 'react'
import type {SchemaFieldFormInput} from '../../types'
import {
  emptySchemaField,
  fieldValuesConverter,
  getFieldTypeOptions,
  getFieldValidationSchema,
} from '../EditColumnsForm/helpers'

interface Props {
  schemaId: string
  order: number
  close: () => void
}

const AddColumnForm = ({schemaId, order, close}: Props) => {
  const {t} = useTranslation()

  const {
    addSchemaField,
    addSchemaFieldResult: {data, error, loading},
  } = useDatasets()

  useEffect(() => {
    if (data && !error) {
      setTimeout(() => {
        close()
      }, 1000)
    }
  }, [data, close, error])

  const fieldTypeOptions = getFieldTypeOptions(t)

  const handleSubmit = (values: SchemaFieldFormInput) => {
    const field = fieldValuesConverter({
      ...values,
      type: values.type[`value`] || values.type,
      order,
    })
    addSchemaField(schemaId, field)
  }

  return (
    <Formik
      initialValues={emptySchemaField}
      onSubmit={handleSubmit}
      validationSchema={getFieldValidationSchema(t)}
      enableReinitialize
    >
      {({values, dirty}) => (
        <Form>
          <Modal.Header>{t(`settings.schemas.addColumn.header`)}</Modal.Header>
          <Modal.Body>
            <div className="flex flex-col gap-6">
              <Input.Formik
                label={t(`settings.schemas.addColumn.title`)}
                id="column-title"
                type="text"
                name="title"
                placeholder={t(`settings.schemas.addColumn.titlePlaceholder`)}
              />
              <Select.Formik
                label={t(`settings.schemas.addColumn.type`)}
                id="column-type"
                name="type"
                value={values.type}
                options={fieldTypeOptions}
                inModal
              />
              <div className="flex items-center gap-2">
                <Checkbox.Formik id="column-required" name="required" />
                <label htmlFor="column-required">
                  <Text variant="bodySmall">
                    {t(`settings.schemas.addColumn.required`)}
                  </Text>
                </label>
              </div>
              {values.required && (
                <Input.Formik
                  label={t(`settings.schemas.addColumn.defaultValue`)}
                  id="column-default-value"
                  type="text"
                  name="defaultValue"
                  placeholder={t(
                    `settings.schemas.addColumn.defaultValuePlaceholder`
                  )}
                />
              )}

              {error && (
                <div className="col-span-full mt-4">
                  <Banner intent="error" fadeIn>
                    {error.message === `duplicateSchema`
                      ? t(
                          `settings.schemas.helpers.validation.title.duplicate`,
                          {title: values.title}
                        )
                      : error.message}
                  </Banner>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ActionGroup>
              {data && !error ? (
                <div
                  className={`
                    flex
                    items-center
                    justify-center
                    gap-1
                    text-green-600
                  `}
                >
                  <CheckIcon width={24} height={24} />
                  <Text>{t(`settings.schemas.update.successMsg`)}</Text>
                </div>
              ) : (
                <>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={close}
                    disabled={loading}
                  >
                    {t(`settings.schemas.addColumn.cancelBtn`)}
                  </Button>
                  <Button
                    variant="primary"
                    disabled={loading || !dirty}
                    loading={loading}
                    loadingText={t`settings.adding`}
                  >
                    {t(`settings.schemas.addColumn.addBtn`)}
                  </Button>
                </>
              )}
            </ActionGroup>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  )
}

export default AddColumnForm
