import {gql} from '@apollo/client'
import {USER_INFO, USER_SETTINGS, USER_DATASETS} from './fragments'

export const FETCH_USER_PROFILES = gql`
  ${USER_INFO}
  query GetProfilesByUserIds($userIds: [String]) @api(name: "profiles") {
    profilesByUserIds(userIds: $userIds) {
      id
      ...UserInfo
    }
  }
`

export const FETCH_CURRENT_USER_PROFILE = gql`
  ${USER_INFO}
  ${USER_SETTINGS}
  query ProfileByCurrentUser @api(name: "profiles") {
    profileByCurrentUser {
      id
      ...UserInfo
      ...UserSettings
    }
  }
`

export const FETCH_USERS_BY_ACCOUNT = gql`
  ${USER_INFO}
  query AllUsersByAccountId @api(name: "profiles") {
    allUsersByAccountId {
      id
      datasetIds
      ...UserInfo
    }
  }
`

export const FETCH_MORE_USERS = gql`
  ${USER_INFO}
  ${USER_DATASETS}
  query FetchMoreUsers($limit: Int, $offset: Int) @api(name: "gateway") {
    fetchMoreUsers(limit: $limit, offset: $offset) {
      id
      ...UserInfo
      ...UserDatasets
    }
  }
`

export const SEARCH_USERS = gql`
  ${USER_INFO}
  ${USER_DATASETS}
  query SearchUsers($keywords: String!) @api(name: "gateway") {
    searchUsers(keywords: $keywords) {
      id
      ...UserInfo
      ...UserDatasets
    }
  }
`

export const PROFILE_STATS = gql`
  query Stats @api(name: "profiles") {
    userStats {
      admins
      members
    }
  }
`
