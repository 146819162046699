import {gql} from '@apollo/client'

export const FETCH_INTEGRATIONS = gql`
  query Integrations @api(name: "integrations") {
    integrations {
      id
      name
      description
      intro
      logo
      config
      category
      isEnabledByDefault
      isActive
    }
  }
`
export const FETCH_GOOGLE_SHEETS = gql`
  query FetchGoogleSheets @api(name: "integrations") {
    fetchGoogleSheets {
      id
      spreadsheetId
      nestedSchemaId
      accountId
      url
      sheetName
      sync
      uniqueColumn
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`

export const FETCH_GOOGLE_SHEET_BY_SCHEMA_ID = gql`
  query FetchGoogleSheetBySchemaId($schemaId: ID!) @api(name: "integrations") {
    fetchGoogleSheetBySchemaId(schemaId: $schemaId) {
      id
      spreadsheetId
      nestedSchemaId
      accountId
      url
      sheetName
      sync
      uniqueColumn
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`
