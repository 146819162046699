import {OptionProps} from 'react-select'
import {cn} from '../../../utils/tailwind'
import {getIsVariant} from '../helpers'
import {OptionData} from '../types'

const baseClassName = `
  dark:border-ui-200
  text-ui-800
  py-2
  cursor-pointer
`

export const inlineClassName = `text-xs px-2`
export const notInlineClassName = `text-sm px-3`
export const layoutClassName = `flex items-center`

export const focusedClassName = `
  bg-ui-100
  dark:bg-ui-100
`
export const selectedClassName = `
  bg-ui-100
  dark:bg-ui-100
  font-medium
  dark:font-semibold
`

export const disabledClassName = `opacity-60 cursor-pointer:none`

type OurOptionProps = OptionProps<OptionData>

const OurOption = ({children, className = ``, ...props}: OurOptionProps) => {
  const {
    isFocused,
    isDisabled,
    isSelected,
    innerRef,
    innerProps,
    data: {displayNode},
  } = props
  const isInline = getIsVariant(`inline`, props.selectProps)
  return (
    <div
      {...innerProps}
      ref={innerRef}
      className={cn(
        baseClassName,
        !displayNode && layoutClassName,
        isFocused && focusedClassName,
        isSelected && selectedClassName,
        isDisabled && disabledClassName,
        isInline ? inlineClassName : notInlineClassName,
        className
      )}
    >
      {displayNode || children}
    </div>
  )
}

export default OurOption
