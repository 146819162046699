import {
  Tab as ReachTab,
  TabList as ReachTabList,
  TabPanel as ReachTabPanel,
  TabPanels as ReachTabPanels,
  Tabs as ReachTabs,
  TabsKeyboardActivation,
} from '@reach/tabs'
import {HTMLAttributes, ReactElement, ReactNode} from 'react'

export const classNames = {
  disabled: `pointer-events-auto cursor-not-allowed opacity-60`,
  selected: `border-ui-600 font-medium text-ui-950`,
  default: `border-transparent font text-ui-600 dark:text-ui-500`,
}

export type TabContent = {
  label: string
  content: ReactElement
  disabled?: boolean
  to?: string
  as?: `button` | `a` | React.ComponentType
}

interface TabProps {
  isSelected?: boolean
  as?: `button` | `a` | React.ComponentType
  to?: string
  disabled?: boolean
  children?: ReactNode
}

interface Props extends HTMLAttributes<HTMLElement> {
  tabs: TabContent[]
  defaultIndex?: number
}

const Tab = (props: TabProps): ReactElement => {
  const {isSelected, children, disabled, to, as = `button`} = props
  return (
    <ReachTab
      className={`
        mr-4
        border-b-2
        px-0.5
        py-3
        text-sm
        ${isSelected ? classNames.selected : classNames.default}
        ${disabled ? classNames.disabled : ``}
      `}
      disabled={disabled}
      as={as}
      to={to}
      style={{marginBottom: -1}}
      {...props}
    >
      {children}
    </ReachTab>
  )
}

const Tabs = ({tabs, defaultIndex = 0}: Props) => {
  if (!tabs) return null
  return (
    <ReachTabs
      className={`
        py-3
      `}
      defaultIndex={defaultIndex}
      keyboardActivation={TabsKeyboardActivation.Manual}
    >
      <ReachTabList
        className={`
          flex
          border-b
        `}
      >
        {tabs.map(({label, disabled, to, as}) => (
          <Tab disabled={disabled} key={label} as={as} to={to}>
            {label}
          </Tab>
        ))}
      </ReachTabList>

      <ReachTabPanels>
        {tabs.map(({label, content}) => (
          <ReachTabPanel
            key={label}
            className={`
              py-4
              focus:outline-none
            `}
          >
            {content}
          </ReachTabPanel>
        ))}
      </ReachTabPanels>
    </ReachTabs>
  )
}

export default Tabs
