import {isEqual as _isEqual, omit as _omit} from 'lodash'
import {ComponentProps, useEffect, useState} from 'react'
import {components} from 'react-select'
import Select from 'react-select/base'
import Input from './Input/Input'
import Menu from './Menu/Menu'
import ClearIndicator from './OurClearIndicator/OurClearIndicator'
import Control from './OurControl/OurControl'
import DropdownIndicator from './OurDropdownIndicator/OurDropdownIndicator'
import MenuList from './OurMenuList/OurMenuList'
import MultiValueContainer from './OurMultiValueContainer'
import MultiValueLabel from './OurMultiValueLabel'
import MultiValueRemove from './OurMultiValueRemove'
import NoOptionsMessage from './OurNoOptionsMessage/OurNoOptionsMessage'
import Option from './OurOption/OurOption'
import ValueContainer from './OurValueContainer/OurValueContainer'
import SingleValue from './SingleValue/SingleValue'

import {
  BaseSelectProps,
  OptionData,
  OptionValue,
  SelectOption,
  SelectVariant,
  ValidOption,
} from './types'

export const ourSelectComponents: ComponentProps<typeof Select>[`components`] =
  {
    ...components,
    ClearIndicator,
    Control,
    DropdownIndicator,
    Input,
    Menu,
    MenuList,
    MultiValueContainer,
    MultiValueLabel,
    MultiValueRemove,
    NoOptionsMessage,
    Option,
    SingleValue,
    ValueContainer,
  }

export const createCurrentFinder =
  (value: OptionValue<OptionData>, keyToMatch?: string) =>
  <T extends {value: OptionValue<OptionData>}>(item: T): boolean => {
    const shouldMatchNested = typeof item.value === `object`

    if (shouldMatchNested) {
      // checking if matching makes sense to avoid false matches when comparing undefined with undefined
      const requestedMatchersExist =
        item.value?.[keyToMatch] && value && value[keyToMatch]
      // provide support for older templates that haven't adopted adding a unique `id` property to object values, but likely do have `name`
      const fallbackMatcher = `name`
      const fallbackMatchersExist =
        item.value?.[fallbackMatcher] && value?.[fallbackMatcher]

      const matcher = requestedMatchersExist
        ? keyToMatch
        : fallbackMatchersExist
        ? fallbackMatcher
        : null

      return matcher ? item.value?.[matcher] === value[matcher] : false
    }

    return item.value === value
  }

export const getMultiValue = (
  value: OptionValue[] = [],
  options: OptionData[],
  keyToMatch?: string
) =>
  value.map(value =>
    options.find(
      // @ts-ignore because it has beaten me for now
      createCurrentFinder(value, keyToMatch)
    )
  )

export const getCurrentValue = ({
  options,
  value,
  isMulti,
  findCurrentByProperty,
}) =>
  isMulti && typeof value === `object`
    ? getMultiValue(
        value as string[],
        // @ts-ignore because it works, but the typings aren't quite right
        options,
        findCurrentByProperty
      )
    : options.find(
        // @ts-ignore because it works, but the typings aren't quite right
        createCurrentFinder(value as string, findCurrentByProperty)
      )

export const MODAL_ID = `react-aria-modal-dialog`

export const usePortalTarget = () => {
  const [target, setTarget] = useState<HTMLElement | null>(null)

  useEffect(() => {
    const target = document.getElementById(MODAL_ID)
    setTarget(target)
  }, [])

  return {target}
}

export const matchOptionValue = <T extends ValidOption>(
  options: T[],
  value: unknown
): T | null => {
  return options?.find(o => o.value === value)
}

export const getIsVariant = <T>(
  variant: SelectVariant,
  selectProps: T & {variants?: BaseSelectProps[`variants`]}
) => selectProps.variants?.includes(variant)

export const isValueInOptions = (
  options: ComponentProps<typeof Select>[`options`],
  value: unknown,
  isMulti: boolean,
  childFieldKeys = []
): boolean => {
  const compareValues = (optionValue: unknown, value: unknown) => {
    return typeof optionValue === `object` && typeof value === `object`
      ? _isEqual(
          _omit(optionValue, childFieldKeys),
          _omit(value, childFieldKeys)
        )
      : optionValue === value
  }

  return isMulti
    ? Array.isArray(value) &&
        value.every(val =>
          options.some((option: SelectOption) =>
            compareValues(option.value, val)
          )
        )
    : options.some((option: SelectOption) => compareValues(option.value, value))
}
