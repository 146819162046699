import {Config, MultiPage, PagesRecord} from '../types'
import {Fields, SelectField} from './types'

/**
 * Checks that the given fields data is in legacy format (an array) and typeguards it so that TypeScript knows what to expect.
 * @param fields - the data to check
 * @returns true or false
 */
export const isLegacyFields = (
  fields: Fields | MultiPage<Fields>
): fields is Fields => Array.isArray(fields)

/**
 * Fields of both legacy or multipage types can be passed through this function and the output will be MultiPage<Fields> format.
 * @param fields - the fields object to convert
 * @returns the fields in MultiPage<Fields> format
 */
export const convertToMultiPageFields = (
  fields: Fields | MultiPage<Fields> = []
): MultiPage<Fields> => {
  if (!isLegacyFields(fields)) return fields
  return {global: [], [`001`]: fields}
}

/**
 * Checks that the given data is in MultiPage format and typeguards it so that TypeScript knows what to expect.
 * @param data - the data to check
 * @returns true or false
 */
export const isMultiPageFormat = <T extends object>(
  data: T | MultiPage<T>
): data is MultiPage<T> => {
  return data && `global` in data && `001` in data
}

/**
 * Extracts a {@link PagesFieldsRecord} from a {@link PagesConfigRecord}. Used by Exoplanet build step.
 * @param pages - pages data in the {@link PagesConfigRecord} format.
 * @returns the {@link PagesFieldsRecord} object
 */
export const extractPagesFields = (
  pages: PagesRecord<Config>
): PagesRecord<Fields> => {
  return (
    pages &&
    Object.entries(pages).reduce((acc, [key, config]) => {
      // @ts-ignore - don't have time to figure out without risking breaking Exoplanet build step
      acc[key] = config.fields
      return acc
    }, {})
  )
}

// type guards
export const isSelectField = (props: unknown): props is SelectField => {
  return Boolean(
    props &&
      typeof props === `object` &&
      `options` in props &&
      isSelectOptions(props.options)
  )
}

export const isSelectOptions = (
  options: unknown
): options is SelectField[`options`] => {
  return Boolean(
    options &&
      typeof options === `object` &&
      `choices` in options &&
      Array.isArray(options.choices)
  )
}
