const height = `2.6875rem` // 43px === actual row height

const TableLoadingState = (props: React.ComponentProps<`div`>) => (
  <div {...props}>
    {Array(4)
      .fill(null)
      .map((_, index) => (
        <div
          key={index}
          style={{
            height,
            animationDelay: `${index * 0.5}s`,
            animationDirection: `alternate`,
            animationIterationCount: `infinite`,
            animationDuration: `1.5s`,
            animationFillMode: `backwards`,
          }}
          className={`
            animate-fade-in
            border-b-ui-300
            dark:border-b-ui-200
            bg-ui-50
            border-b
            text-xs
            text-transparent
          `}
        >
          Content is loading
        </div>
      ))}
  </div>
)

export default TableLoadingState
