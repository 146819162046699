import {supportedLanguages} from '@myadbox/nebula-languages'
import {getJwt} from './getJwt'
import {isBrowser} from './isBrowser'
import {parseJwt} from './parseJwt'
import {JwtToken} from './types'

const NONAUTH_PAGES = [`forgot`, `reset`, `logout`, `admin`]

export const isExpired = (jwt: JwtToken): boolean => jwt.exp < Date.now() / 1000 // Convert milliseconds to seconds

export const isArkaroolaPage = (pathname: string): boolean => {
  const page = pathname.replace(/\/$/, ``).split(`/`).pop()
  const oneOfThePages = NONAUTH_PAGES.includes(page)
  const isBaseUrl =
    pathname === `/` || Object.keys(supportedLanguages).includes(page)
  return oneOfThePages || isBaseUrl
}

export const isSesimiAdmin = (): boolean => {
  const jwt = getJwt()
  const jwtToken = jwt ? parseJwt(jwt) : null
  const isAdmin = isBrowser() ? localStorage.getItem(`isAdmin`) : ``
  return jwtToken?.owner || isAdmin === `true`
}

export const isExternalPage = (pathname: string): boolean => {
  return Boolean(pathname.match(/\/external\/.+(\/)?$/))
}
