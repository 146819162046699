export const info = `
    border
    border-ui-300
    bg-ui-100
    text-ui-600
    dark:bg-ui-50
  `
export const success = `
    border
    border-green-400
    bg-green-50
    text-green-600
    dark:text-green-400
    dark:bg-ui-50
  `
export const warning = `
    border
    border-yellow-400
    bg-yellow-50
    text-yellow-600
    dark:text-yellow-500
    dark:bg-ui-50
  `
export const error = `
    border
    border-red-400
    bg-red-50
    text-red-600
    dark:text-red-400
    dark:bg-ui-50
  `
export const infoContrast = `
    border
    border-ui-300
    bg-ui-300
    text-ui-600
    dark:bg-ui-0
  `
