import {inputHelpers} from '@myadbox/stellar-ui'
import {FormEvent} from 'react'

interface Props {
  value?: string
  changeHandler?(
    event: FormEvent<HTMLInputElement> & {target: {value: string}}
  ): void
  labelText?: string
  id: string
  readOnly?: boolean
  variant?: `sm` | `md`
  title?: string
}

const SearchField = ({
  changeHandler,
  value,
  labelText = `Search`,
  id,
  readOnly,
  variant = `md`,
  title,
}: Props) => {
  return (
    <label htmlFor={id}>
      <span className={`sr-only`}>{labelText}</span>
      <input
        id={id}
        autoComplete="off"
        className={inputHelpers.getInputClassName({readOnly, variant})}
        style={{contain: `paint`}}
        placeholder={labelText}
        type="search"
        onChange={changeHandler}
        value={value}
        readOnly={readOnly}
        title={title}
      />
    </label>
  )
}

export default SearchField
