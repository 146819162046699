import {CheckIcon as Tick} from '@heroicons/react/24/outline'
import {FC, MouseEvent} from 'react'
import {noop} from '../../utils/noop'
import {BulletPoint} from '../Icons'

interface Props {
  href?: string
  index?: number
  isSelected?: boolean
  clickHandler?(e: MouseEvent): void
  icon?: `tick` | `dot`
  children: React.ReactNode | React.ReactNode[]
  className?: string
}

export const firstItemClassName = `mt-1`
export const selectedClassName = `text-ui-700 font-normal`
const iconBaseClasses = `
  md:mr-0.5
  text-blue-600
  dark:text-blue-500
  block
  group-hocus:opacity-70
  right-[100%]
  top-0
  absolute
  w-3
  h-3
  translate-y-1/2
`

const FilterListItem: FC<Props> = ({
  children,
  href,
  index,
  isSelected = false,
  clickHandler = noop,
  icon = `dot`,
  className = ``,
  ...props
}) => {
  const InteractiveTag = href ? `a` : `button`
  const iconClasses = `${iconBaseClasses}${
    isSelected ? `opacity-100` : `opacity-0`
  }`
  const icons = {
    tick: <Tick className={iconClasses} />,
    dot: <BulletPoint className={iconClasses} />,
  }

  return (
    <li
      className={`
      ${index === 0 ? firstItemClassName : ``}
      group
      list-none
    `}
    >
      <InteractiveTag
        href={href}
        className={`
          hover:text-ui-700
          relative
          block
          text-sm
          font-light
          hover:underline
          ${className}
          ${isSelected ? selectedClassName : ``}
        `}
        style={{
          paddingTop: `2px`,
          paddingBottom: `2px`,
          lineHeight: `inherit`,
        }}
        onClick={clickHandler}
        {...props}
      >
        {icons[icon]}
        <span>{children}</span>
      </InteractiveTag>
    </li>
  )
}

export default FilterListItem
