export enum Format {
  GIF = `GIF`,
  JPG = `JPG`,
  PNG = `PNG`,
  PDF = `PDF`,
  MP4 = `MP4`,
  MOV = `MOV`,
  MP3 = `MP3`,
  WAV = `WAV`,
  HTML = `HTML`,
  EMAIL = `EMAIL`,
}

export const formats = Object.values(Format)

export type FormatUnion = `${Format}`
