import {NoticeProps, components} from 'react-select'
import {cn} from '../../../utils/tailwind'

const OurNoOptionsMessage = ({className = ``, ...props}: NoticeProps) => {
  return (
    <components.NoOptionsMessage
      {...props}
      className={cn(
        `
          px-3
          py-2
          text-center
          text-sm
          font-normal
        `,
        className
      )}
    />
  )
}

export default OurNoOptionsMessage
