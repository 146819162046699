import {ChevronDownIcon} from '@heroicons/react/20/solid'
import {DropdownIndicatorProps} from 'react-select'
import {cn} from '../../../util/tailwind'
import {getIsVariant} from '../helpers'

const BaseDropdownIndicator = (props: DropdownIndicatorProps) => {
  const {children, innerProps, className = ``} = props
  return (
    <span {...innerProps} className={cn(`px-2 text-inherit`, className)}>
      {children}
    </span>
  )
}

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  const {className = ``, selectProps} = props

  switch (true) {
    case getIsVariant(`inline`, selectProps):
      return (
        <BaseDropdownIndicator {...props} className={cn(`px-0.5`, className)}>
          <ChevronDownIcon width={14} height={14} role="img" />
        </BaseDropdownIndicator>
      )
    default:
      return (
        <BaseDropdownIndicator {...props}>
          <ChevronDownIcon width={18} height={18} role="img" />
        </BaseDropdownIndicator>
      )
  }
}

export default DropdownIndicator
