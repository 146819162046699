import {
  useConnectedIntegrations,
  useExportSocial,
  useIntegrations,
  ExportSocialInput,
} from '@myadbox/nebula-service-api'
import {useEffect, useReducer} from 'react'
import {
  SocialExportAction,
  SocialExportState,
  UseSocialsExportOutput,
} from './types'

const initialState: SocialExportState = {
  connectedIntegrations: [],
  isSubmitting: false,
}

export const socialExportReducer = (
  state: SocialExportState,
  action: SocialExportAction
): SocialExportState => {
  switch (action.type) {
    case `SET_SUBMITTING`:
      return {
        ...state,
        isSubmitting: action.payload.isSubmitting,
      }
    case `EXPORT_SOCIALS`:
      return {
        ...state,
      }
    case `SET_CONNECTED_INTEGRATIONS`:
      return {
        ...state,
        connectedIntegrations: action.payload.connectedIntegrations,
      }
    default:
      return state
  }
}

export const useSocialsExport = (): UseSocialsExportOutput => {
  const [state, dispatch] = useReducer(socialExportReducer, initialState)
  const {exportSocial} = useExportSocial()
  const {fetchConnectedIntegrations, fetchConnectedIntegrationsResults} =
    useConnectedIntegrations()
  const {fetchIntegrations, fetchIntegrationsResults} = useIntegrations()

  useEffect(() => {
    fetchIntegrations()
    fetchConnectedIntegrations()
  }, [fetchIntegrations, fetchConnectedIntegrations])

  useEffect(() => {
    const {data: connectedData} = fetchConnectedIntegrationsResults
    const {data: integrationsData} = fetchIntegrationsResults

    if (connectedData && integrationsData) {
      const {integrations} = integrationsData
      const {withIntegrations} = connectedData

      const isIntegrationConnected = (integrationId: string) =>
        integrations.some(conn => conn?.id === integrationId)

      const connectedIntegrations = withIntegrations.filter(integration =>
        isIntegrationConnected(integration?.integrationId)
      )

      dispatch({
        type: `SET_CONNECTED_INTEGRATIONS`,
        payload: {connectedIntegrations: connectedIntegrations},
      })
    }
  }, [fetchConnectedIntegrationsResults, fetchIntegrationsResults])

  const setSubmitting = (isSubmitting: boolean) => {
    dispatch({type: `SET_SUBMITTING`, payload: {isSubmitting}})
  }

  const handleExport = async (input: ExportSocialInput) => {
    if (state.isSubmitting) return

    setSubmitting(true)
    try {
      await exportSocial(input)
      dispatch({
        type: `EXPORT_SOCIALS`,
      })
    } finally {
      setSubmitting(false)
    }
  }

  return {
    ...state,
    handleExport,
    fetchConnectedIntegrationsResults,
    fetchIntegrationsResults,
  }
}
