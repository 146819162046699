type Props = {
  className?: string
  size?: number
}

export const Crop = ({className = ``, size = 12}: Props) => (
  <svg
    className={`${className}`}
    data-testid="crop-icon"
    fill="none"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    role="presentation"
  >
    <path
      d="M4 7H17V20"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 17L7 17L7 4"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Crop
