import {gql} from '@apollo/client'
import {APPROVER, REQUESTER} from './fragments'

export const FETCH_APPROVALS = gql`
  ${APPROVER}
  ${REQUESTER}
  query GetApprovals($assetIds: [String!]!) @api(name: "approvals") {
    approvals(assetIds: $assetIds) {
      id
      assetId
      approved
      approvers {
        id
        ...ApproverInfo
      }
      ...RequesterInfo
    }
  }
`
