import {useMutation} from '@apollo/client'
import {useCallback} from 'react'
import {CONNECT_TO_GOOGLE} from '../mutations'
import {ConnectToGoogleLogInput, UseGoogleConnectOutput} from '../types'

export const useGoogleConnect = (): UseGoogleConnectOutput => {
  const [googleConnect, googleConnectResponse] = useMutation(CONNECT_TO_GOOGLE)
  const connectToGoogle = useCallback(
    (input: ConnectToGoogleLogInput) => {
      return googleConnect({
        variables: {input},
      })
    },
    [googleConnect]
  )

  return {
    connectToGoogle,
    googleConnectResponse,
  }
}
