import {FC, HTMLAttributes} from 'react'
import Text from '../../Text'
import {XMarkIcon as IconClose} from '@heroicons/react/24/outline'

interface HeaderProps extends HTMLAttributes<HTMLElement> {
  title?: string
  close: () => void
}

const Header: FC<HeaderProps> = ({title, close}) => (
  <div
    className={`
      text-ui-300
      dark:text-ui-500
      pointer-events-none
      flex
      items-center
      justify-between
      px-2
      py-4
    `}
  >
    <Text
      display="inline-block"
      color="inherit"
      variant="heading"
      className={`pl-2`}
    >
      {title}
    </Text>
    <button
      id="close-button"
      onClick={close}
      aria-label="Close Gallery"
      className={`
        text-ui-300
        hocus:text-ui-100
        dark:text-ui-400
        dark:hocus:text-ui-500
        rounded-full
        p-1
      `}
    >
      <IconClose width={26} height={26} />
    </button>
  </div>
)

export default Header
