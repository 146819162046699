import {Link} from 'gatsby'
import {TFunction} from 'i18next'
import {createContext} from 'react'
import {TabRoute} from '../../hooks/useTabRoutes/useTabRoutes'
import {renderConditionally} from '../../utils/renderConditionally'
import Account from './Account'
import Billing from './Billing'
import Categories from './Categories'
import Integrations from './Integrations'
import Schemas from './Schemas'
import Teams from './Teams'
import Users from './Users'

export const createHydratorWithActive =
  (activeTab: string) => (tab: TabRoute<JSX.Element>) => ({
    ...tab,
    content: renderConditionally(tab.component, activeTab === tab.slug),
    as: Link,
  })

export const createLocaliser =
  (languagePrefix: string) => (tab: TabRoute<JSX.Element>) => ({
    ...tab,
    to: `${languagePrefix}${tab.to}`,
  })

export const getLanguagePrefix = (language: string, defaultLanguage: string) =>
  language === defaultLanguage ? `` : `/${language}`

export const getBaseRoutes = (t: TFunction) => [
  {
    slug: `account`,
    label: t`settings.account.tabLabel`,
    to: `/settings/account`,
    component: componentMap.Account,
  },
  {
    slug: `billing`,
    label: t`settings.billing.tabLabel`,
    to: `/settings/billing`,
    component: componentMap.Billing,
  },
  {
    slug: `teams`,
    label: t`settings.teams.tabLabel`,
    to: `/settings/teams`,
    component: componentMap.Teams,
  },
  {
    slug: `schemas`,
    label: t`settings.schemas.tabLabel`,
    to: `/settings/schemas`,
    component: componentMap.Schemas,
  },
  {
    slug: `categories`,
    label: t`settings.categories.tabLabel`,
    to: `/settings/categories`,
    component: componentMap.Categories,
  },
  {
    slug: `users`,
    label: t`settings.users.tabLabel`,
    to: `/settings/users`,
    component: componentMap.Users,
  },
]

export const getAdminRoutes = (t: TFunction) => [
  {
    slug: `integrations`,
    label: t`settings.integrations.tabLabel`,
    to: `/settings/integrations`,
    component: componentMap.Integrations,
  },
]

const componentMap = {
  Account,
  Billing,
  Teams,
  Schemas,
  Categories,
  Users,
  Integrations,
} as const

export type SettingsTabComponentName = keyof typeof componentMap

export type SettingsRoutePropsMap = {
  [K in SettingsTabComponentName]?: SettingsViewProps
}

export const SettingsContext = createContext<SettingsContextType | null>(null)

export type SettingsContextType = SettingsRoutePropsMap

export const MISSING_PROVIDER_ERROR = `useRouteComponentProps must be used within a SettingsContext.Provider`

export type ViewType = `view`

export type SettingsViewProps = {
  viewType?: ViewType
  id: string
  page: number | undefined
  search?: string
}
