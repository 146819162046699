import type {
  NestedSchemaField,
  SchemaFieldInput,
} from '@myadbox/nebula-service-api'
import {TFunction} from 'i18next'
import * as Yup from 'yup'
import type {
  RequiredSchemaFieldFormInput,
  SchemaFieldFormInput,
  SchemaFieldFormInputWithOrder,
  SchemaFieldsFormValues,
} from '../../types'

export const emptySchemaField: SchemaFieldFormInput = {
  title: ``,
  type: `text`,
  required: false,
  defaultValue: ``,
}

export const defaultInitialValues: SchemaFieldsFormValues = {
  fields: [emptySchemaField, emptySchemaField],
}

export const formValuesGenerator = (
  fields: NestedSchemaField[]
): SchemaFieldsFormValues => ({
  fields: fields.map(d => ({
    id: d.id,
    title: d.title,
    type: getFieldType(d.type, d.options),
    required: (d.options.required as boolean) || false,
    defaultValue: getDefaultValue(d.type, d.options?.defaultValue),
  })),
})

export const getDefaultValue = (type: string, value: unknown) => {
  if (type === `number`) {
    return Number(value) || 0
  } else if (type === `boolean`) {
    return Boolean(value)
  } else {
    return value || ``
  }
}

const getFieldType = (type: string, options = {}): string => {
  let fieldType = `text`

  if (type === `number` && options[`format`] === `currency`) {
    fieldType = `currency`
  } else if (type === `number`) {
    fieldType = `number`
  } else if (type === `boolean`) {
    fieldType = `checkbox`
  } else if (type === `string` && options[`paragraph`] === true) {
    fieldType = `paragraph`
  } else if (type === `uri`) {
    fieldType = `uri`
  }

  return fieldType
}

/**
 * Note:
 * 'currency' field has been disabled as it hasn't been handled properly yet
 */
export const getFieldTypeOptions = (t: TFunction) => {
  return [
    {
      value: `text`,
      label: t`settings.schemas.helpers.options.text`,
    },
    {
      value: `paragraph`,
      label: t`settings.schemas.helpers.options.paragraph`,
    },
    {
      value: `checkbox`,
      label: t`settings.schemas.helpers.options.checkbox`,
    },
    {
      value: `number`,
      label: t`settings.schemas.helpers.options.number`,
    },
    {
      value: `uri`,
      label: t`settings.schemas.helpers.options.uri`,
    },
    // {
    //   value: `currency`,
    //   label: t`settings.schemas.helpers.options.currency`,
    // },
  ]
}

export const getValidationSchema = (t: TFunction) => {
  return Yup.object().shape({
    fields: Yup.array().of(getFieldValidationSchema(t)),
  })
}

export const getFieldValidationSchema = (t: TFunction) => {
  return Yup.object().shape({
    title: Yup.string()
      .required(t`settings.schemas.helpers.validation.fields.title.required`)
      .min(3, t`settings.schemas.helpers.validation.min`)
      .max(50, t`settings.schemas.helpers.validation.max`)
      .matches(/^[^!@#$%^&*+=<>:;|~]*$/, {
        excludeEmptyString: true,
        message: t`settings.schemas.helpers.validation.fields.title.invalidChar`,
      }),
    type: Yup.string().required(
      t`settings.schemas.helpers.validation.fields.type.required`
    ),
  })
}

export const fieldValuesConverter = (
  field: SchemaFieldFormInputWithOrder
): SchemaFieldInput => {
  const defaultValues: SchemaFieldInput = {
    id: field.id,
    title: field.title,
    type: `string`,
    options: {
      order: field.order,
    },
  }

  let result: SchemaFieldInput

  switch (field.type) {
    case `text`:
      result = defaultValues
      break

    case `paragraph`:
      result = {
        ...defaultValues,
        options: {
          ...defaultValues.options,
          paragraph: true,
        },
      }
      break

    case `checkbox`:
      result = {
        ...defaultValues,
        type: `boolean`,
      }
      break

    case `number`:
      result = {
        ...defaultValues,
        type: `number`,
      }
      break

    case `currency`:
      result = {
        ...defaultValues,
        type: `number`,
        options: {
          ...defaultValues.options,
          format: `currency`,
        },
      }
      break

    case `uri`:
      result = {
        ...defaultValues,
        type: `uri`,
      }
      break

    default:
      result = defaultValues
  }

  if (field.required) {
    result.options.required = Boolean(field.required) || false
    result.options.defaultValue = getDefaultValue(
      field.type,
      field.defaultValue
    )
  }

  return result
}

export const formValuesMapper = ({
  fields,
}: SchemaFieldsFormValues): SchemaFieldInput[] => {
  return fields.map((field, index) =>
    fieldValuesConverter({
      ...field,
      type: field.type[`value`] || field.type,
      order: index,
    })
  )
}

export const handleSubmit = async ({
  values,
  initialValues,
  close,
  setRequiredFields,
  hasFields,
  upsertSchemaFields,
  schemaId,
}: {
  values: SchemaFieldsFormValues
  initialValues: SchemaFieldsFormValues
  close: () => void
  setRequiredFields: (args: RequiredSchemaFieldFormInput[]) => void
  hasFields?: boolean
  upsertSchemaFields
  schemaId: string
}): Promise<void> => {
  const requiredFieldsToAdd: RequiredSchemaFieldFormInput[] =
    values.fields.reduce((result, field, index) => {
      const wasRequiredInitially = initialValues.fields.find(
        ({required, id}) => required && id === field.id
      )

      if (field.required && !field.defaultValue && !wasRequiredInitially) {
        result.push({...field, index})
      }

      return result
    }, [])

  if (hasFields && requiredFieldsToAdd.length > 0) {
    setRequiredFields(requiredFieldsToAdd)
    return
  } else {
    const fields = formValuesMapper(values)
    await upsertSchemaFields(schemaId, fields)
  }
}
