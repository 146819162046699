import {UserCard} from '@myadbox/gatsby-theme-nebula'
import {UserOption} from './types'

export const getUserOptions = (
  options: UserOption[],
  addedText?: string
): UserOption[] =>
  options.map(option => ({
    ...option,
    displayNode: (
      <UserCard
        img={option.img}
        name={option.label}
        email={option.email}
        inlineActions={
          option.disabled && (
            <div className="text-ui-600 text-tiny italic">{addedText}</div>
          )
        }
      />
    ),
  }))
