import {FETCH_MORE_USERS} from './queries'
import {DocumentNode} from 'graphql'

type Data = {
  fetchMoreUsers?: {
    id: string
  }[]
  searchUsers?: {
    id: string
  }[]
}

type Cache = {
  readQuery?: (args: {
    query: DocumentNode
    variables?: {keywords?: string}
  }) => Data
  writeQuery?: (args: {
    query: DocumentNode
    data: Data
    variables?: {keywords?: string}
  }) => void
}

export const updateOnProfileCreate = () => {
  return (cache: Cache, {data}) => {
    const cachedData = cache.readQuery({query: FETCH_MORE_USERS})

    if (!cachedData || !data) return

    // Remove the user from the list if it already exists
    const filteredUsers = cachedData.fetchMoreUsers.filter(
      user => user.id !== data.createUserProfile.id
    )

    cache.writeQuery({
      query: FETCH_MORE_USERS,
      data: {
        fetchMoreUsers: [...filteredUsers, data.createUserProfile],
      },
    })
  }
}

export const updateOnProfileDisable = () => {
  return (cache: Cache, {data}) => {
    const cachedData = cache.readQuery({query: FETCH_MORE_USERS})

    if (!cachedData || !data) return

    const filteredUsers = cachedData.fetchMoreUsers.filter(
      user => user.id !== data.disableUserProfile.id
    )

    cache.writeQuery({
      query: FETCH_MORE_USERS,
      data: {
        fetchMoreUsers: [...filteredUsers],
      },
    })
  }
}
