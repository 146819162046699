/**
 * The following types had to be copied from the nebula-service-api package because that package is not available outside of Nebula.
 */

import {PageKey} from '../../types'
import {
  AssetFieldLimitBy,
  DatablockFieldLimitBy,
  FieldLimitBy,
  LimitBy,
  SchemaFieldLimitBy,
  SelectFieldLimitBy,
} from './limitBy'

export enum AssetType {
  Audio = `AUDIO`,
  Html = `HTML`,
  Image = `IMAGE`,
  Video = `VIDEO`,
  Template = `TEMPLATE`,
  Other = `OTHER`,
}

type NestedSchemaOption = Record<string, unknown>
type DatasetData = Record<string, unknown>

interface NestedSchema {
  id: string
  name: string
  title: string
  description?: string
  descendants: NestedSchemaField[]
  datasets?: Dataset[]
  datasetsCount: number
  options?: NestedSchemaOption
  createdById: string
  createdAt: string
  updatedAt: string
}

interface NestedSchemaField
  extends Pick<NestedSchema, `id` | `title` | `name` | `options`> {
  type: string
}

export interface Dataset {
  id: string
  nestedSchema: Pick<NestedSchema, `id` | `title` | `name` | `descendants`>
  data: DatasetData
}
/** end dodgy copied types */

export type FieldType =
  | `select`
  | `textarea`
  | `schema`
  | `text`
  | `number`
  | `date`
  | `image`
  | `asset`
  | `price`
  | `range`
  | `checkbox`
  | `email`
  | `tel`
  | `markdown`
  | `datablock`

export interface Field {
  name: string
  /**
   * A Field’s Options contains any additional data that is needed to render the field. It excludes valid native attributes, which should be applied at the top level of Field to be passed to the field's element.
   */
  options: Options
  required?: boolean
  disabled?: boolean
  hidden?: boolean
  readOnly?: boolean
  /**
   * Note, the `image` type is deprecated. Use `asset` instead.
   */
  type: FieldType
  /**
   * A list of field limits to apply to the field.
   */
  limitBy?: FieldLimitBy[]
}

export interface Options {
  /**
   * The brief name of the field.
   */
  label: string
  /**
   * Extra contextual information to aid users in completing the field.
   */
  details?: string
  /**
   * The name of the group the field belongs to, if any.
   */
  group?: string
  /**
   * The forms this field can be edited in.
   */
  form?: string

  limitBy?: LimitBy[]

  /**
   * Reloads the iFrame on change.
   */
  reloadOnChange?: boolean

  /**
   * Adds a `<option value="" disabled>{blankOptionLabel}</option>` at the first option
   */
  blankOptionLabel?: string
}

export type OptionsHasPrompt = Options & HasPrompt

export interface Choice {
  /**
   * The user-facing label of a choice option. Usually displayed as an item of a select dropdown.
   */
  label: string
  /**
   * The data value of a choice option that is passed to and used by the template.
   */
  value: string | number | boolean | Dataset | Record<string, unknown>

  disabled?: boolean
}

export type Control = `crop` | `upload`

export interface SchemaOptions {
  /**
   * Whether the field can be overridden by the user.
   */
  overridable?: string[]
  limitBy?: SchemaFieldLimitBy[]
}

export interface SelectFieldOptions extends Options {
  choices: Choice[]
  isMulti?: boolean
  limitBy?: SelectFieldLimitBy[]
}

export type DatablockRecordFilter = {
  key: string
  value: string | number | boolean
  include?: boolean
}

export interface SchemaFieldOptions
  extends Omit<Options, `limitBy`>,
    SchemaOptions {
  /**
   * The `name` of the schema from which to display datasets.
   */
  schema: string
  /**
   * The key of the schema field to use as the label for each item in the list. If omitted, the first field in the schema will be used.
   */
  labelAttribute?: string

  /**
   * This is a list of choices that will be displayed to the user. It will be populated with the datasets from the schema and does not need to be provided by the template.
   */
  choices?: Choice[]
  /**
   * This is a boolean for whether the user can select multiple choices.
   */
  isMulti?: boolean
  /**
   * This is a frontend filter that will be applied to the schema dropdown. Any rows matching the key's (datablock column name's) value will not appear in the dropdown. You can reverse this logic and filter **in** rows by setting `include` to `true`.
   */
  filter?: DatablockRecordFilter
}

export interface DataBlockFieldOptions extends Omit<Options, `limitBy`> {
  datablock: string
  limitBy?: DatablockFieldLimitBy[]
  filter?: DatablockRecordFilter
}
export interface AssetFieldOptions extends Options {
  assetType?: AssetType
  limitTo: string[]
  operator?: `and` | `or`
  view?: `list` | `grid`
  controls?: Control[]
  /**
   * Schema options for the asset field, key is the schema name.
   */
  schemas?: {
    [key: string]: SchemaOptions
  }
  limitBy?: AssetFieldLimitBy[]
}

export interface DateFieldOptions extends Options {
  locale: `en-AU` | `en-US` | `fr-CA`
  showTime?: boolean
}
export interface ImageFieldOptions extends Options {
  limitTo: string[]
  operator?: `and` | `or`
  view?: `list` | `grid`
}

export interface PriceFieldOptions extends Options {
  locale: `en-AU` | `en-US` | `fr-CA` | `en-IN`
  currency: `AUD` | `USD` | `CAD` | `INR`
  allowDecimals?: boolean
}

export interface MarkdownFieldOptions extends Options {
  allowedFormats: Array<`bold` | `italic` | `strikethrough` | `ul` | `ol`>
  maxLength?: number
}

export interface PriceField extends Field {
  type: `price`
  options: PriceFieldOptions
  maxLength?: number
}

export interface RangeField extends Field {
  type: `range`
  min?: number
  max?: number
  step: number
}

export interface CheckboxField extends Field {
  type: `checkbox`
}
export interface TextField extends Field {
  type: `text`
  maxLength?: number
  minLength?: number
  inputMode?: `text` | `tel` | `url` | `email` | `numeric`
  pattern?: string
  options: OptionsHasPrompt
}

export interface TextareaField extends Field {
  type: `textarea`
  rows?: number
  maxLength?: number
  minLength?: number
  options: OptionsHasPrompt
}

export interface SelectField extends Field {
  type: `select`
  options: SelectFieldOptions
}

export interface SchemaField extends Field {
  type: `schema`
  options: SchemaFieldOptions
}

export interface ImageField extends Field {
  type: `image`
  options: ImageFieldOptions
}

export interface AssetField extends Field {
  type: `asset`
  options: AssetFieldOptions
}

export interface DateField extends Field {
  type: `date`
  options: DateFieldOptions
  min?: string
  max?: string
}

export interface NumberField extends Field {
  type: `number`
}
export interface EmailField extends Field {
  type: `email`
  maxLength?: number
  minLength?: number
  pattern?: string
}
export interface TelField extends Field {
  type: `tel`
  maxLength?: number
  minLength?: number
  pattern?: string
}

export interface MarkdownField extends Field {
  type: `markdown`
  options: MarkdownFieldOptions
}

export interface DataBlockField extends Field {
  type: `datablock`
  options: DataBlockFieldOptions
}

export interface HasPrompt {
  aiPrompt?: Prompt
}
export interface Prompt {
  message: string
  relatedFields?: string[]
}

export type AllOptions =
  | Options
  | SelectFieldOptions
  | SchemaFieldOptions
  | AssetFieldOptions
  | ImageFieldOptions
  | PriceFieldOptions
  | DateFieldOptions
  | MarkdownFieldOptions
  | DataBlockFieldOptions

export type DeprecatedField = ImageField

export type AvailableField =
  | TextareaField
  | TextField
  | SelectField
  | SchemaField
  | DateField
  | AssetField
  | PriceField
  | RangeField
  | CheckboxField
  | TelField
  | EmailField
  | NumberField
  | MarkdownField
  | DataBlockField

export type Fields = AvailableField[]

export type PagesFieldsRecord = Record<PageKey, Fields>

/**
 * A dictionary keyed by field type. Pass in the type of the value you want to reference with the dictionary.
 *
 * @example
 * In this example, we’re creating a dictionary of validators for each field type.
 * ```ts
 * const fields: FieldDictionary<Validator> = {
 *   text: yup.string().required(),
 *   textarea: yup.string().,
 *   select: yup.string(),
 * }
 * ```
 *
 *
 */
export type FieldDictionary<T> = Partial<Record<FieldType, T>>
