import {HTMLAttributes} from 'react'
import {BulletPoint} from '../Icons'
import {IntentStatus, StatusSize} from './types'

interface Props extends HTMLAttributes<HTMLElement> {
  intent?: IntentStatus
  size?: StatusSize
}

export const intentClasses: Record<IntentStatus, string> = {
  success: `
    text-green-400
    dark:text-green-500
  `,
  error: `
    text-red-500
    dark:text-red-500
  `,
  warning: `
    text-yellow-400
    dark:text-yellow-500
  `,
  default: `
    text-ui-400
  `,
}

export const bulletSizes: Record<StatusSize, number> = {
  sm: 12,
  md: 20,
}

const Status = ({children, intent = `default`, size = `sm`}: Props) => {
  return (
    <div
      className={`
        flex
        items-center
        gap-1
      `}
    >
      <div
        data-testid="bullet-wrapper"
        className={`
        ${intentClasses[intent]}
      `}
      >
        <BulletPoint size={bulletSizes[size]} />
      </div>
      <div className={`leading-3.5 whitespace-nowrap`}>{children}</div>
    </div>
  )
}

export default Status
