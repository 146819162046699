import {SchemaUsageResponse} from '@myadbox/nebula-service-api'
import {getUsageDescription} from '../../SchemaView/helpers'
import {TFunction} from 'i18next'

export const getUsage = (data: SchemaUsageResponse, t: TFunction) => {
  if (!data) return

  const {assetsCount, usersCount} = data.usage.reduce(
    (acc, {assetsCount, usersCount}) => {
      acc.assetsCount += assetsCount
      acc.usersCount += usersCount
      return acc
    },
    {assetsCount: 0, usersCount: 0}
  )

  let usage: string | undefined

  if (assetsCount + usersCount > 0) {
    usage = getUsageDescription(assetsCount, usersCount, t)
  }

  return usage
}
