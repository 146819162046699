import {gql} from '@apollo/client'

export const FETCH_INVOICES = gql`
  query Invoices @api(name: "billing") {
    invoices {
      id
      invoiceNumber
      amountDue
      amountPaid
      amountRemaining
      created
      currency
      customerEmail
      dueDate
      invoicePdf
      status
    }
  }
`
