export const inputClasses = `
  focus:shadow-outline
  focus:outline-none
  sm:text-sm
  sm:leading-5
  rounded-400
  border
  block
  form-input
  pl-3
  pr-8
  py-2
  duration-150
  ease-in-out
  transition-default
  w-full
`

export const inputEnabledClasses = `
  bg-ui-50
  text-ui-700
  dark:bg-ui-200
  dark:text-ui-800
  dark:border-ui-200
  dark:hocus:bg-ui-200
  focus:bg-white
  hover:bg-white
`

export const inputDisabledClasses = `
  bg-ui-200
  border-ui-200
  text-ui-500
  dark:text-ui-300
  dark:bg-ui-50
  dark:border-ui-50
`

export const calendarClasses = `
  relative
  inline-flex
  text-ui-800
  border
  rounded-400
  bg-ui-0
  border-ui-200
  shadow-md
  dark:bg-ui-200
  dark:shadow-md-dense
  h-[--height-var-1]
  text-xs
  leading-5
  my-2
`

const dayClasses = `
  w-[--width-var-1]
  inline-block
  text-center
  p-1
  rounded-200
  cursor-pointer
`

export const dayClassesDefault = `
  ${dayClasses}
  hover:bg-ui-100
  focus:bg-ui-100
`

export const dayClassesActive = `
  ${dayClasses}
  text-ui-0
  bg-blue-600
  font-semibold
  hover:bg-blue-600
  focus:bg-blue-600
  dark:text-ui-800
  dark:bg-blue-500
`

export const monthClasses = `
  pt-0.5
  pb-0.5
`

export const popperClasses = `
  z-50
`

export const calendarHeaderClasses = `
  flex
  justify-between
  items-center
`

const timeClasses = `
  text-xs
  py-1.5
  px-2
  cursor-pointer
  text-center
`

export const timeClassesDefault = `
  ${timeClasses}
  text-ui-600
  hover:bg-ui-100
  focus:bg-ui-100
`

export const timeClassesActive = `
  ${timeClasses}
  text-blue-700
  bg-blue-50
  font-semibold
  hover:bg-blue-50
  focus:bg-blue-50
  dark:bg-ui-100
  dark:text-blue-400
`
