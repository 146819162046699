import {
  XCircleIcon as IconError,
  InformationCircleIcon as IconInfo,
  CheckCircleIcon as IconSuccess,
  ExclamationCircleIcon as IconWarning,
} from '@heroicons/react/24/outline'
import {CSSProperties, HTMLAttributes} from 'react'

import {XMarkIcon as IconClose} from '@heroicons/react/20/solid'
import * as styles from './styles'

interface Props extends HTMLAttributes<HTMLElement> {
  intent?: `info` | `success` | `warning` | `error` | `info-contrast`
  fadeIn?: boolean
  hasIcon?: boolean
  size?: `sm` | `md`
  onDismiss?: (args: unknown) => void
  children: React.ReactNode | React.ReactNode[]
}

const {infoContrast, ...restStyles} = styles

export const intentStyles = {
  ...restStyles,
  'info-contrast': infoContrast,
}

export type BannerIntent = keyof typeof intentStyles

export const sizeStyles = {
  sm: {
    banner: `text-xs px-3 py-2 rounded-200 !border-transparent !dark:border-transparent border`,
    iconSize: {
      width: 16,
      height: 16,
    },
  },
  md: {
    banner: `text-sm p-4 rounded-200 border`,
    iconSize: {
      width: 22,
      height: 22,
    },
  },
}

const icons: Record<BannerIntent, typeof IconInfo> = {
  info: IconInfo,
  'info-contrast': IconInfo,
  success: IconSuccess,
  warning: IconWarning,
  error: IconError,
}

const Banner = ({
  children,
  intent = `info`,
  hasIcon = true,
  fadeIn,
  onDismiss,
  size = `md`,
  ...props
}: Props) => {
  const Icon = icons[intent]

  return (
    <div
      {...props}
      className={`
        dark:border-ui-200
        w-full
        break-words
        ${fadeIn ? `animate-fade-in` : ``}
        ${intentStyles[intent]}
        ${sizeStyles[size].banner}
      `}
    >
      <div
        className={`
          albatross
          gap-2
        `}
        style={albatrossStyles}
      >
        {hasIcon && (
          <span
            data-testid="icon"
            className={`
              grow-0
              dark:opacity-80
            `}
          >
            {<Icon {...sizeStyles[size].iconSize} />}
          </span>
        )}
        <div className="grid w-full gap-2">{children}</div>
        {onDismiss && (
          <button
            onClick={onDismiss}
            className={`
              text-ui-400
              -m-1
              grow-0
              self-baseline
              p-1
            `}
            title="Dismiss"
          >
            <IconClose {...sizeStyles[size].iconSize} />
          </button>
        )}
      </div>
    </div>
  )
}

export default Banner

// CI isn't recognising our custom declaration that allows CSS custom properties
// to be used in the `style` prop.
const albatrossStyles = {
  '--albatross-breakpoint': `14.7em`,
  '--albatross-margin': 0,
} as CSSProperties
