import {ClassValue, clsx} from 'clsx'
import {twMerge} from 'tailwind-merge'

// Configure tailwind-merge with some common patterns
// - create custom twMerge functon with extendTailwindMerge
// - add values to existing class groups or define new ones
// - More info: https://github.com/dcastil/tailwind-merge/blob/v1.14.0/docs/recipes.md#adding-custom-scale-from-tailwind-config-to-tailwind-merge-config

export const cn = (...args: ClassValue[]) => {
  return twMerge(clsx(args))
}
