export const qualitiesMap = {
  Optimised: `ECO`,
  Low: `LOW`,
  High: `HIGH`,
  Maximum: `MAX`,
} as const

export const qualities = Object.keys(
  qualitiesMap
) as (typeof qualitiesMap)[keyof typeof qualitiesMap][]

export const qualityValues = Object.values(qualitiesMap)
