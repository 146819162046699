// This is a utility component that is used to toggle content on and off.
// it does not require storybook documentation and should potentially be moved out of the components folder.
import {
  ButtonHTMLAttributes,
  FC,
  MouseEvent,
  createContext,
  useContext,
  useDebugValue,
  useReducer,
} from 'react'
import * as styles from '../../styles/common'
import {slugifyText} from '../../utils/helpers'
import {isBrowser} from './helpers'

interface TogglerProps extends ButtonHTMLAttributes<HTMLElement> {
  label: string
  focusTargetId: string
}

interface FinalTogglerProps {
  [`aria-labelledby`]: string
  [`aria-expanded`]: boolean
  className: string
  onClick(event: MouseEvent<HTMLButtonElement>): void
}

interface FinalTargetProps {
  [`aria-hidden`]: boolean
  tabIndex?: -1
}

interface TogglerHook {
  on: boolean
  props: {
    togglerProps: FinalTogglerProps
    targetProps: FinalTargetProps
  }
}

export const StateContext = createContext(null)
export const DispatchContext = createContext(null)

StateContext.displayName = `Toggler StateContext`
DispatchContext.displayName = `Toggler DispatchContext`

type AllToggles = Record<string, boolean>

export const reducer = (state: AllToggles, label: string): AllToggles => {
  return {
    ...state,
    [label]: Boolean(!state[label]),
  }
}

export const TogglerProvider = ({children, initialState = {}}): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export const useToggler = (label, props = {}): TogglerHook => {
  useDebugValue(`useToggler ${label}`)

  const togglerProps = props[`togglerProps`] || {}

  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  const on = Boolean(state[label])

  const getTogglerProps = (
    label: string,
    {className: suppliedClassName = ``, ...togglerProps}
  ): FinalTogglerProps => {
    const labelId = `${slugifyText(label)}-toggle-label-id`
    const className = `${styles.iconButtonClassName} ${suppliedClassName}`
    const onClick = (e: MouseEvent): void => {
      dispatch(label)
      togglerProps.onClick?.(e)
    }

    return {
      ...togglerProps,
      'aria-labelledby': labelId,
      'aria-expanded': on,
      className,
      onClick,
    }
  }

  const getTargetProps = (): FinalTargetProps => {
    return {
      'aria-hidden': !on && isBrowser() ? true : null,
      tabIndex: on ? -1 : undefined,
    }
  }

  return {
    on,
    props: {
      togglerProps: getTogglerProps(label, togglerProps),
      targetProps: getTargetProps(),
    },
  }
}

export const Toggler: FC<TogglerProps> = ({
  label,
  focusTargetId,
  children,
  ...props
}) => {
  const {
    on,
    props: {togglerProps},
  } = useToggler(label, {togglerProps: props})

  return (
    <>
      <button type="button" {...togglerProps}>
        {children}
        <span className={`sr-only`} id={togglerProps[`aria-labelledby`]}>
          {on ? `Hide` : `Show`} {label}
        </span>
      </button>
      {on && focusTargetId && (
        <a href={`#${focusTargetId}`} className={`sr-only`}>
          focus toggled content
        </a>
      )}
    </>
  )
}

export default Toggler
