import {UserGroupIcon} from '@heroicons/react/24/outline'
import {useMemo} from 'react'
import {injectTransforms} from '../Image'
import {getInitials, getOnBrandColor} from './helpers'

type Props = {
  src?: string
  title?: string
  team?: boolean
  size?: `lg` | `md` | `sm`
  bordered?: boolean
}

export const sizes = {
  sm: 24,
  md: 32,
  lg: 48,
}

export const borderClasses = `
  box-content
  border-2
  border-ui-200
  dark:border-ui-0
`

const Avatar = ({
  /**
   * The image source URL. Must be a full Cloudinary source file.
   */
  src = ``,
  /**
   * The title of the avatar. Used to generate initials and color.
   */
  title = ``,
  /**
   * Whether the avatar is a team avatar.
   */
  team,
  /**
   * The size of the avatar.
   */
  size = `md`,
  /**
   * Whether the avatar has a border.
   * */
  bordered,
}: Props) => {
  const data = useMemo(
    () => ({
      color: getOnBrandColor(title),
      dimensions: sizes[size],
      initials: getInitials(title),
    }),
    [title, size]
  )

  const commonClasses = `
    aspect-square
    rounded-full
    uppercase
    self-start
    ${bordered ? borderClasses : ``}
  `

  // ensure src is https
  const secureSrc = src?.replace(/^http:\/\//i, `https://`)
  const resizedSrc = injectTransforms(
    secureSrc,
    `w_${sizes.lg * 2},q_auto:low,e_sharpen:50`
  )

  const avatarLabel = `${title} avatar`

  return (
    (resizedSrc && (
      <img
        data-testid="icon-custom"
        className={commonClasses}
        width={`${data.dimensions}`}
        height={`${data.dimensions}`}
        title={title}
        src={resizedSrc}
        loading="lazy"
        alt={avatarLabel}
      />
    )) ||
    (team && (
      <span
        data-testid="icon-team"
        title={title}
        className={`
          text-ui-0
          dark:text-ui-900
          flex
          shrink-0
          items-center
          justify-center
          ${commonClasses}
        `}
        style={{
          width: data.dimensions,
          height: data.dimensions,
          backgroundColor: data.color,
        }}
      >
        <UserGroupIcon
          height={data.dimensions - 8}
          width={data.dimensions - 8}
        />
      </span>
    )) || (
      <span
        data-testid="icon-initials"
        title={title}
        className={`
          relative
          block
          ${commonClasses}
        `}
        style={{
          width: data.dimensions,
          height: data.dimensions,
        }}
      >
        <svg
          role="presentation"
          height={`${data.dimensions}`}
          width={data.dimensions}
          fill={data.color}
        >
          <circle
            cx={`${data.dimensions / 2}`}
            cy={`${data.dimensions / 2}`}
            r={`${data.dimensions / 2}`}
          />
        </svg>
        <span
          className={`
            absolute
            -mt-px
            grid
            place-content-center
            font-semibold
            text-white
          `}
          data-testid="icon-initials-text"
          style={{
            fontSize: `${data.dimensions / 2 / 16}rem`,
            inset: 0,
            opacity: !data.initials ? `0.5` : `1`,
            width: data.dimensions,
          }}
        >
          {data.initials || `...`}
        </span>
      </span>
    )
  )
}

export default Avatar
