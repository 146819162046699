import {Legend, Pie, Progress, Text} from '@myadbox/gatsby-theme-nebula'
import {Segment} from '@myadbox/gatsby-theme-nebula/types'
import {useAccount, useAssets} from '@myadbox/nebula-service-api'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {useEffect} from 'react'

export const AccountTemplates = () => {
  const {t} = useTranslation()
  const {
    fetchAssetStats,
    fetchAssetStatsResults: {data},
  } = useAssets()
  const {account} = useAccount()

  useEffect(() => {
    fetchAssetStats()
  }, [fetchAssetStats])

  const variablesLimit = account?.billing?.limits?.variables
  const hasVariablesLimit = Boolean(variablesLimit && variablesLimit > 0)
  const hasData = !!data?.stats

  if (hasData && typeof data?.stats.Template === `undefined`) {
    return (
      <div className={`italic`}>
        <Text variant="subHeading">{t`storage.noTemplates`}</Text>
      </div>
    )
  }

  /**
   * Business rule
   *
   * The default preset of a template does not count as a billable variable
   * therefore we remove it from the total variables count
   * or rather the count from the presets
   *
   */

  const totalVariables = hasData
    ? data.stats.Template.totalFieldCount +
      data.stats.Template.totalPresetCount -
      data.stats.Template.count
    : 0

  const segments: Segment[] = hasData
    ? [
        {value: data.stats.Template.totalFieldCount, label: t`storage.fields`},
        {
          value:
            data.stats.Template.totalPresetCount - data.stats.Template.count,
          label: t`storage.presets`,
        },
      ]
    : [{value: 0, label: ``}]

  const variablesPercentage = Math.round(
    (totalVariables / variablesLimit) * 100
  )

  return (
    <div>
      {hasVariablesLimit && (
        <div
          className={`
            mb-4
            w-full
            md:w-[50%]
          `}
        >
          <Progress
            label={t(`storage.progress`, {
              value: totalVariables,
              max: variablesLimit,
            })}
            labelPos="right"
            value={totalVariables}
            max={variablesLimit}
          />
        </div>
      )}
      <div className={`flex`}>
        <div
          className={`
            w-18
            mr-4
            xl:w-[10%]
          `}
        >
          {hasData ? (
            <Pie
              segments={segments}
              total={variablesLimit}
              autoComplete={hasVariablesLimit}
            >
              {hasVariablesLimit && (
                <div className={`text-center`}>
                  <div
                    className={`
                      text-ui-900
                      dark:text-ui-900
                      text-sm
                      font-medium
                    `}
                  >
                    {variablesPercentage}%
                  </div>
                  <div className={`text-tiny`}>{t`storage.utilised`}</div>
                </div>
              )}
            </Pie>
          ) : (
            <Pie segments={segments} total={1} />
          )}
        </div>
        <div className={`grow`}>
          {hasData ? (
            <Legend
              header={{label: t`storage.variables`, value: totalVariables}}
              segments={segments}
            />
          ) : (
            <Text variant="subHeading">{t`storage.loading`}</Text>
          )}
        </div>
      </div>
    </div>
  )
}

export default AccountTemplates
