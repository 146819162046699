import differenceWith from 'lodash/differenceWith'
import flatMap from 'lodash/flatMap'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import {allSizes} from '../data/allSizes'
import {exportDefaults} from '../data/exportDefaults'
import {formatLabel} from '../strings'

import {
  AssetOutput,
  Categories,
  CategoriesPaths,
  DehydratedAssetOutput,
  EasyAssetOutput,
  EasyExportOverrides,
  ExportSettings,
  Format,
  Quality,
  SpecificPaths,
  TemplateSupport,
  TemplateSupportPaths,
  Unit,
} from '../types'

export const fallbackExportDefaultsKey = `jpgMax`

export const NO_SIZE: AssetOutput = {
  label: `None`,
  width: 0,
  height: 0,
  format: Format.PNG,
  quality: Quality.Optimised,
  unit: Unit.PX,
}

export const getSpecificSizes = (
  specific: SpecificPaths = [],
  source = allSizes
): DehydratedAssetOutput[] => {
  return specific.map(path => get(source, path)).filter(Boolean)
}

export const getCategorySizes = (
  categories: CategoriesPaths = [],
  source = allSizes
): DehydratedAssetOutput[] => {
  const hydratedPaths = categories.map(path => get(source, path))
  return getNestedItemsByKeyFromCategories(hydratedPaths)
}

export const getNestedItemsByKeyFromCategories = (
  categories: Categories = []
) => {
  return categories
    .filter(Boolean)
    .map(pathValue => getNestedItemsByKey(pathValue))
    .flat()
}

export const excludeItems = <Items>(
  supported: Items[],
  unsupported: Items[]
): Items[] => {
  return differenceWith(supported, unsupported, isEqual)
}

export const getNestedItemsByKey = (
  node: Record<string, object>,
  key = `sizes`
): DehydratedAssetOutput[] => {
  if (node[`sizes`]) {
    return Object.values(node[key])
  }
  return flatMap(Object.values(node), child =>
    getNestedItemsByKey(child as Record<string, object>)
  )
}

export const applyExportDefaults = (
  presets: DehydratedAssetOutput[] = []
): AssetOutput[] => {
  return presets.map(
    ({exportDefaults: key = fallbackExportDefaultsKey, ...size}) => {
      const exportOptions: ExportSettings = exportDefaults[key]
      return {...exportOptions, ...size}
    }
  )
}

export const getSupportedTemplatePresets = (
  templateConfig: TemplateSupportPaths,
  source = allSizes
): AssetOutput[] => {
  const supported = [
    ...getCategorySizes(templateConfig?.support?.categories, source),
    ...getSpecificSizes(templateConfig?.support?.specific, source),
  ]
  const unsupported = [
    ...getCategorySizes(templateConfig?.unsupport?.categories, source),
    ...getSpecificSizes(templateConfig?.unsupport?.specific, source),
  ]

  return applyExportDefaults(excludeItems(supported, unsupported))
}

export const getPresetByLabel = (
  label: string,
  presets?: AssetOutput[]
): AssetOutput => {
  // Legacy video template does not have presets, thus check presets with `?` optional chaining
  return presets?.find(preset => preset.label === label) || NO_SIZE
}
/**
 * When provided a {@link TemplateSupport} object, this function returns a flat array of requested sizes from the presets catalogue.
 *
 * @param templateConfig - a {@link TemplateSupport} object used to include and exclude a variety of sizes from the catalogue.
 * @returns AssetOutput[]
 *
 * @example
 * ```
 * // This example includes all `main` banner ad sizes as well as one specific size from the `extended` sub-category. From this set, the `mobile landscape` size is excluded.
 * embedSizes({
 *   support: {
 *     categories: [allSizes.bannerAd.main],
 *     specific: [allSizes.bannerAd.extended.sizes.mobileFullPage],
 *   },
 *   unsupport: {
 *     specific: [allSizes.bannerAd.main.sizes.mobileLandscape],
 *   }
 * })
 * ```
 */
export const embedSizes = (templateConfig: TemplateSupport): AssetOutput[] => {
  const supported = [
    ...getNestedItemsByKeyFromCategories(templateConfig?.support?.categories),
    ...(templateConfig?.support?.specific || []),
  ]
  const unsupported = [
    ...getNestedItemsByKeyFromCategories(templateConfig?.unsupport?.categories),
    ...(templateConfig?.unsupport?.specific || []),
  ]

  return applyExportDefaults(excludeItems(supported, unsupported))
}

/**
 * This function can alter the default format, quality and label of all sizes supplied.
 * @param sizes - an array of {@link AssetOutput} objects
 * @param overrides - an object of {@link ExportOverrides} values
 * @returns the altered array of AssetOutput objects
 *
 * @remarks
 * The most common use case for this function is to alter the default format of all sizes supplied. For example, the default format of animated banner ads should be set to `Format.HTML` rather than the initial default which is most suited for static image banners ads. This saves the user from having to manually change the format of each size before exporting.
 * Note: Modifying the label to include its size should only be used for sizes that are inately fixed such as digital banner ads. This is because newspaper sizes, for example, are intended to label a variable range of sizes.
 * When explicit sizes must be added, you can do so by passing a custom function as the `label` override, which will receive a size object from which you can alter the label to suit. We have premade a custom function for this purpose already {@link appendSize} which you can import from \@myadbox/nebula-template-utils.
 *
 * @example
 * The following example assumes the template is an animated HTML banner ad. We want to alter the default format of the supported banner ad sizes to be `HTML`, rather than their preset default of `JPG`. We also have a client request to add the size to the label of each size, so we import and apply the {@link appendSize} function to the label.
 * ```
 * import {defineConfig, embedSizes, modifySizes, allSizes, allFormats, appendSize} from '@myadbox/nebula-template-utils'
 *
 * // get the ad sizes
 * const bannerAdSizes = embedSizes({
 *   support: {
 *     categories: [allSizes.bannerAd.main],
 *   },
 * })
 *
 * // pass them to modifySizes, along with an object
 * // that overrides their default format and/or quality
 * const htmlBannerAdSizes = modifySizes(bannerAdSizes, {
 *   format: Format.HTML,
 *   label: appendSize, // appendSize imported, above
 *   // quality: Quality.Low, // <-- likely not something you’ll need to do
 * })
 *
 * // `htmlBannerAdSizes` is now an array of AssetOutput objects
 * // with the default format and quality overridden. Set the template
 * // config’s `sizes` property to this array to use the modified sizes.
 * export default defineConfig({
 *   sizes: htmlBannerAdSizes,
 *   defaultSize: htmlBannerAdSizes[0],
 *   formats: allFormats.web.animated,
 *   // ... etc ...
 * })
 * ```
 */
export const modifySizes = (
  sizes: EasyAssetOutput[],
  overrides: EasyExportOverrides
): AssetOutput[] =>
  sizes.map(size => {
    const {format, quality, label, ...rest} = size
    return {
      ...rest,
      format: overrides.format || format,
      quality: overrides.quality || quality,
      label:
        typeof overrides.label === `function` ? overrides.label(size) : label,
    } as AssetOutput
  })

/**
 * For custom sizes that do not qualify to be included in the preset catalogue, you may define a custom size using this function. It ensures you get type-checking help while defining your custom size, which avoids errors when deploying your template.
 *
 * @remarks
 * This function also allows you to define values more easily than requiring importing `Format`, `Quality`, and `Unit` from `@myadbox/nebula-template-utils` (although you still have that option). You will still be auto-suggested the correct values for `format`, `quality`, and `unit` when you define your custom size.
 *
 * @param size - an {@link AssetOutput} object defining a new size
 * @returns a valid AssetOutput object
 *
 * @example
 * This example uses the raw string values for `format`, `quality`, and `unit` to define a custom size, rather than importing `Format`, `Quality`, and `Unit` from `@myadbox/nebula-template-utils`.
 * ```
 * import {
 *   defineConfig,
 *   defineCustomSize,
 * } from '@myadbox/nebula-template-utils'
 *
 * const customSize = defineCustomSize({
 *   label: 'Custom Size',
 *   width: 310,
 *   height: 250,
 *   format: `JPG`,
 *   quality: `ECO`,
 *   units: `px`,
 * })
 *
 * const presetSizes = embedSizes({ ... omitted for brevity ...})
 *
 * export default defineConfig({
 *   // spread the preset sizes into the array, along with the single custom size
 *   sizes: [...presetSizes, customSize],
 *   defaultSize: customSize.label,
 *   ... config continues ...
 * })
 * ```
 *
 * @example
 * This example shows us creating multiple custom sizes, without needing to repeat all properties that are the same between sizes.
 * ```
 * import {
 *   defineConfig,
 *   defineCustomSize,
 * } from '@myadbox/nebula-template-utils'
 *
 * const customSize = defineCustomSize({
 *   label: 'Custom Size',
 *   width: 310,
 *   height: 250,
 *   format: `JPG`,
 *   quality: `ECO`,
 *   units: `px`,
 * })
 *
 * const similarCustomSize = defineCustomSize({
 *   // "spread" in the properies from the previous size
 *   ... customSize,
 *   // override only the properties that are different
 *   label: 'Similar Custom Size',
 *   width: 300,
 *   height: 450,
 * })
 *
 * const presetSizes = embedSizes({ ... omitted for brevity ...})
 *
 * export default defineConfig({
 *    // spread the preset sizes into the array, and add the custom sizes
 *   sizes: [...presetSizes, customSize, similarCustomSize],
 *   defaultSize: customSize.label,
 *   ... config continues ...
 * })
 * ```
 *
 */
export const defineCustomSize = (size: EasyAssetOutput): AssetOutput => {
  size.label = formatLabel(size.label)
  return size as AssetOutput
}

/**
 * This function takes a single AssetOutput and uses it to return a string for use as a label.
 * @param size - an {@link EasyAssetOutput} object
 * @returns a string with the size information appended to it.
 *
 * @remarks
 * The size is described in the context of the final media diplay size. For example, `300x250px` refers to “CSS pixels”, not device pixels. Hence, when output at a higher density, the file’s actual resolution will be multiplied by the density.
 */
export const appendSize = (size: EasyAssetOutput): string =>
  `${size.label} (${size.width}×${size.height}${size.unit})`
