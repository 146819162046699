import {Input} from '@myadbox/stellar-ui'
import {ReactNode, useEffect, useState} from 'react'
import {FieldError} from '../../../'
import useFileReader from '../../hooks/useFileReader'
import {convertCsvToJson} from './helpers'

const CSV_FILE_MIMES = `text/csv, text/plain, application/vnd.ms-excel`

type UploadFieldProps = {
  id: string
  name: string
  label: string
  labelHidden?: boolean
  details?: ReactNode
  onUpload: (data) => void
  converterOptions?: Record<string, string>
  headerTransformer?: (header: string) => string
}

export const UploadField = ({
  id,
  name,
  label,
  onUpload,
  converterOptions,
  headerTransformer,
  details,
  ...props
}: UploadFieldProps) => {
  const [error, setError] = useState(null)
  const [{result: csvData, error: readerError}, setFile] = useFileReader()

  useEffect(() => {
    if (!csvData) return

    convertCsvToJson({
      csv: csvData,
      converterOptions,
      headerTransformer,
    }).then(({data, error}) => {
      if (error) {
        setError({message: error})
      } else {
        onUpload(data)
      }
    })
  }, [csvData, onUpload, setError, converterOptions, headerTransformer])

  useEffect(() => {
    readerError && setError({message: readerError})
  }, [readerError])

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(null)
    onUpload(null)
    setFile(e.target.files[0])
  }

  return (
    <>
      <Input
        type="file"
        id={id}
        name={name}
        label={label}
        onInput={handleFileInput}
        accept={CSV_FILE_MIMES}
        details={details}
        {...props}
      />
      {error && <FieldError>{error.message}</FieldError>}
    </>
  )
}

export default UploadField
