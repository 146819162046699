import {
  XMarkIcon as CrossIcon,
  CheckIcon as TickIcon,
} from '@heroicons/react/24/solid'
import {FC, InputHTMLAttributes} from 'react'
import ToggleInputFormik from './ToggleInputFormik'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  on: boolean
  className?: string
}

/**
 * Note: Ensure to wrap this element inside a label element
 * for onChange to work correctly
 */
export const toggleWrapperClassNames = `
  focus-within:shadow-outline
  transition-default
  relative
  inline-block
  h-6
  w-11
  shrink-0
  cursor-pointer
  rounded-full
  border-2
  border-transparent
  duration-200
  ease-in-out
  focus:outline-none
`

const ToggleInput = ({
  on = false,
  className = ``,
  onChange,
  name,
  style,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  checked,
  ...props
}: Props) => {
  return (
    <span
      data-testid="toggleinput"
      className={`
        ${className}
        ${on ? `bg-blue-500` : `bg-ui-400 dark:bg-ui-300`}
        ${toggleWrapperClassNames}
      `}
      style={style}
    >
      <CheckCircle on={on} />
      <input
        className={hiddenButClickableInputClassNames}
        type="checkbox"
        name={name}
        onChange={onChange}
        defaultChecked={on}
        {...props}
      />
    </span>
  )
}

// Create a new component that wraps the ToggleInput component and passes the props to it
// so that we can hang the Formik component off of it. Mainly for legacy reasons
// so we can avoid changing all the places where we use the ToggleInput component
const ToggleInputWithFormik = (props: Props) => {
  return <ToggleInput {...props} />
}
ToggleInputWithFormik.Formik = ToggleInputFormik

export default ToggleInputWithFormik

const hiddenButClickableInputClassNames = `
  absolute
  w-full
  h-full
  p-0
  inset-0
  -m-px
  opacity-0
  cursor-pointer
  overflow-hidden
  whitespace-nowrap
  border-0
`

const iconClasses = `
  items-center
  flex
  h-full
  justify-center
  absolute
  transition-opacity
  w-full
`

export const crossOnClassNames = `
  opacity-0
  ease-out
  duration-100
`

export const crossOffClassNames = `
  opacity-100
  ease-in
  duration-200
`

export const tickOnClassNames = `
  opacity-100
  ease-in
  duration-200
`

export const tickOffClassNames = `
  opacity-0
  ease-out
  duration-100
`

export const CheckCircle: FC<Props> = ({on = false, className = ``}) => (
  <span
    aria-hidden="true"
    className={`
      ${className}
      ${on ? `translate-x-[100%]` : `translate-x-0`}
      bg-ui-0
      transition-default
      relative
      inline-block
      h-5
      w-5
      origin-right
      rounded-full
      shadow
      duration-200
      ease-in-out
    `}
  >
    <span
      className={`
        ${iconClasses}
        ${on ? crossOnClassNames : crossOffClassNames}
      `}
      data-testid="cross-wrapper"
    >
      <CrossIcon
        width={12}
        height={12}
        className={`text-ui-400`}
        data-testid="cross-icon"
      />
    </span>
    <span
      className={`
        ${iconClasses}
        ${on ? tickOnClassNames : tickOffClassNames}
      `}
      data-testid="tick-wrapper"
    >
      <TickIcon
        width={12}
        height={12}
        className={`text-blue-500 dark:text-white`}
        data-testid="tick-icon"
      />
    </span>
  </span>
)
