import {
  ApolloQueryResult,
  FetchResult,
  LazyQueryResult,
  MutationResult,
} from '@apollo/client'
import {FileVersion} from '../assets/types'

export enum EntityType {
  APPROVAL = `Approval`,
  ASSET = `Asset`,
}

export interface Annotation {
  coordinates: {
    x: number
    y: number
  }
  page: number
  version: FileVersion[`cloudinaryId`] // TODO: Update when we support UI-friendly IDs for versions.
}

export interface Comment {
  id: string
  body: string
  userId: string
  createdAt?: string
  resolved?: boolean
  replies: Reply[]
  annotation?: Annotation
}

export interface CommentCount {
  entityId: string
  total: number
}

// eslint-disable-next-line quotes
export type Reply = Omit<Comment, 'resolved' | 'replies'>

// eslint-disable-next-line quotes
export type CommentInput = Omit<Comment, 'createdAt' | 'resolved' | 'replies'>

export type CreateCommentMutator = (
  entityId: string,
  entityType: EntityType,
  body: string,
  entityIds: string[],
  annotation?: Annotation
) => Promise<FetchResult<Comment>>

export type DeleteCommentMutator = (
  entityId: string,
  entityIds: string[],
  entityType: EntityType,
  id: string,
  replyCount: number
) => Promise<FetchResult<Comment>>

export type ReplyToCommentMutator = (
  entityId: string,
  entityType: EntityType,
  id: string,
  body: string,
  entityIds: string[]
) => Promise<FetchResult<Comment>>

export type DeleteReplyMutator = (
  entityId: string,
  entityIds: string[],
  entityType: EntityType,
  id: string,
  replyId: string
) => Promise<FetchResult<Comment>>

export interface UseCommentsOutput {
  fetchComments: (entityId: string, entityType: EntityType) => void
  fetchCommentsResult: LazyQueryResult<{fetchComments: Comment[]}, {}>
  createComment: CreateCommentMutator
  createCommentResponse: MutationResult<{createComment: CommentInput}>
  deleteComment: DeleteCommentMutator
  deleteCommentResponse: MutationResult<{deleteComment: CommentInput}>
  replyToComment: ReplyToCommentMutator
  replyToCommentResponse: MutationResult<{replyToComment: CommentInput}>
  deleteReply: DeleteReplyMutator
  deleteReplyResponse: MutationResult<{deleteReply: CommentInput}>
  fetchCommentCount: (entityIds: string[], entityType: EntityType) => void
  fetchCommentCountResult: ApolloQueryResult<{
    fetchCommentCount: CommentCount[]
  }>
}
