import {FETCH_ACCOUNT} from './queries'

export const updateConfiguration = (
  accountName: string,
  key: string,
  mutation: string
) => {
  return (cache, {data}) => {
    const cachedData = cache.readQuery({
      query: FETCH_ACCOUNT,
      variables: {accountName},
    })

    if (!cachedData || !data) return

    cache.writeQuery({
      query: FETCH_ACCOUNT,
      data: {
        account: {
          ...cachedData.account,
          configuration: {
            ...cachedData.account.configuration,
            [key]: data[mutation].configuration[key],
          },
        },
      },
    })
  }
}
