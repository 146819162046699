import {RadioButton, TextArea} from '@myadbox/gatsby-theme-nebula'
import {
  Asset,
  ConnectedIntegration,
  ExportSocialInput,
} from '@myadbox/nebula-service-api'
import {ActionGroup, Button, FormItem, Text} from '@myadbox/stellar-ui'
import {Form, Formik} from 'formik'
import {Dispatch} from 'react'
import {useTranslation} from 'react-i18next'
import {useDefaultIntegrations, useSocialsExport} from '../../hooks'
import {
  DefaultIntegrationFormValues,
  IntegrationModalAction,
} from '../DefaultIntegrationsModal/types'

export const DefaultIntegrationsSelect = ({
  handleCancel,
  integration,
  assets,
  dispatch,
}: {
  handleCancel: () => void
  integration: ConnectedIntegration | null
  assets: Asset[]
  dispatch: Dispatch<IntegrationModalAction>
}) => {
  const {t} = useTranslation(`defaultIntegrations`)
  const {handleExport} = useSocialsExport()
  const {activeIntegrationType} = useDefaultIntegrations()

  const initialValues: DefaultIntegrationFormValues = {
    page: integration?.apps[0]?.id ?? null,
    asset: assets[0],
    message: ``,
    user: integration?.user?.name ?? `Sesimi User`,
  }

  const handleSubmit = async (values: DefaultIntegrationFormValues) => {
    const {asset, message, page} = values
    await handleExport({
      id: page,
      integrationId: integration?.integrationId,
      message,
      assetId: asset?.id,
      type: activeIntegrationType?.toString().toLocaleUpperCase(),
    } as ExportSocialInput)
    dispatch({type: `SET_CONTEXT`, payload: `SUCCESS`})
  }

  if (!integration || !integration.apps || integration.apps.length === 0)
    dispatch({type: `SET_CONTEXT`, payload: `ERROR`})

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({values, setFieldValue, isSubmitting}) => (
        <Form className="flex flex-col">
          <div className="flex flex-col gap-4 pb-6">
            <div>
              <Text variant="bodySmall" color="textSecondary">
                {t(`select.loginAs`)}
              </Text>
              <Text>{values?.user}</Text>
            </div>
            <FormItem
              label={<FormItem.Label>{t(`select.pages`)}</FormItem.Label>}
            >
              <div>
                {integration?.apps.map(app => (
                  <label
                    key={app.id}
                    className="flex flex-row items-center gap-2 py-1"
                  >
                    <RadioButton
                      name="page"
                      id={app.id}
                      value={app.name}
                      checked={values.page === app.id}
                      onChange={() => setFieldValue(`page`, app.id)}
                      required
                    />
                    <Text>{app.name}</Text>
                  </label>
                ))}
              </div>
            </FormItem>
            <TextArea.Formik
              placeholder={`Enter message here`}
              id="message"
              name="message"
              label={t(`select.enterMessage`)}
            />
          </div>
          <ActionGroup>
            <Button variant="secondary" onClick={handleCancel}>
              {t(`select.cancel`)}
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={!values.page || isSubmitting}
            >
              {t(`select.share`)}
            </Button>
          </ActionGroup>
        </Form>
      )}
    </Formik>
  )
}
