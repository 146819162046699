import {Button, Dropdown, StaticIcon} from '@myadbox/gatsby-theme-nebula'
import {TFunction} from 'i18next'

export type TriggerProps<T = {}> = T & {
  onClickHandler: (arg) => void
  onSecondaryClickHandler: (arg) => void
  t: TFunction
}

export const DefaultUploadTrigger = ({onClickHandler, t}: TriggerProps) => (
  <Button
    variant="primary"
    onClick={onClickHandler}
    iconSuffix={<StaticIcon name="upload" />}
    aria-label={t`assets.uploadButton.upload`}
  >
    {t`assets.uploadButton.upload`}
  </Button>
)

export const RawUploadTrigger = ({
  onClickHandler,
  onSecondaryClickHandler,
  t,
}: TriggerProps) => {
  return (
    <Dropdown
      trigger={
        <Dropdown.Button
          id="builder-download-menu-trigger"
          onButtonClick={onClickHandler}
          ariaLabel={t`assets.uploadButton.upload`}
        >
          {t`assets.uploadButton.upload`}
        </Dropdown.Button>
      }
    >
      <Dropdown.Item onSelect={onSecondaryClickHandler}>
        {t`assets.uploadButton.uploadRawFiles`}
      </Dropdown.Item>
    </Dropdown>
  )
}
