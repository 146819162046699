import {FieldHelperProps, useField} from 'formik'
import {ComponentProps, useEffect} from 'react'
import Select from '../Select'
import {getCurrentValue, isValueInOptions} from '../helpers'

const normalizedOnChange = (
  helper: FieldHelperProps<unknown>,
  isMulti: boolean
): ((value) => void) =>
  isMulti
    ? value => helper.setValue(prev => [...prev, value])
    : value => helper.setValue(value)

const SelectFormik = ({
  options,
  name,
  isMulti,
  findCurrentByProperty,
  validate = false,
  childFieldKeys,
  ...rest
}: ComponentProps<typeof Select> & {
  validate?: boolean
  childFieldKeys?: string[]
}) => {
  const [field, , helper] = useField(name)

  // Reset value to first option if value is not in the options
  useEffect(() => {
    if (!validate || field.value === undefined || !options?.length) return

    const isValueValid = isValueInOptions(
      options,
      field.value,
      isMulti,
      childFieldKeys
    )

    if (!isValueValid) {
      helper.setValue(isMulti ? [options[0].value] : options[0].value)
    }
  }, [field.value, helper, isMulti, options, validate, childFieldKeys])

  if (!options) return null

  const currValue = getCurrentValue({
    options,
    value: field.value,
    isMulti,
    findCurrentByProperty,
  })

  const onChange = normalizedOnChange(helper, isMulti)
  return (
    <Select onChange={onChange} value={currValue} options={options} {...rest} />
  )
}

export default SelectFormik
