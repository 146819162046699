type Props = React.SVGAttributes<SVGElement> & {
  className?: string
  size?: number
}

const FacebookIcon = ({size, ...rest}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 41 41"
    role="img"
    fill="none"
    {...rest}
  >
    <g clipPath="url(#clip0_353_116)">
      <path
        d="M17.3184 39.8383C7.81845 38.1383 0.618446 29.9383 0.618446 20.0383C0.618446 9.0383 9.61845 0.0382996 20.6184 0.0382996C31.6184 0.0382996 40.6184 9.0383 40.6184 20.0383C40.6184 29.9383 33.4184 38.1383 23.9184 39.8383L22.8184 38.9383H18.4184L17.3184 39.8383Z"
        fill="url(#paint0_linear_353_116)"
      />
      <path
        d="M28.4184 25.6383L29.3185 20.0383H24.0184V16.1383C24.0184 14.5383 24.6185 13.3383 27.0185 13.3383H29.6185V8.23829C28.2185 8.03829 26.6185 7.83829 25.2185 7.83829C20.6185 7.83829 17.4184 10.6383 17.4184 15.6383V20.0383H12.4184V25.6383H17.4184V39.7383C18.5184 39.9383 19.6185 40.0383 20.7185 40.0383C21.8185 40.0383 22.9185 39.9383 24.0184 39.7383V25.6383H28.4184Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_353_116"
        x1="20.6194"
        y1="38.6472"
        x2="20.6194"
        y2="0.0309318"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0062E0" />
        <stop offset="1" stopColor="#19AFFF" />
      </linearGradient>
      <clipPath id="clip0_353_116">
        <rect
          width="40"
          height="40"
          fill="white"
          transform="translate(0.618446 0.0382996)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default FacebookIcon
