import {InformationCircleIcon} from '@heroicons/react/24/outline'
import {
  Button,
  Card,
  FacebookIcon,
  InstagramIcon,
  Text,
} from '@myadbox/stellar-ui'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import SocialActiveModal from './SocialActiveModal'

interface SocialActiveCardProps {
  platform: string
  isConnected: boolean
  integrationId?: string
  isSubmitting: boolean
  handleDisconnect: (integrationId: string) => void
}

const SocialActiveCard: React.FC<SocialActiveCardProps> = ({
  platform,
  isConnected,
  integrationId,
  isSubmitting,
  handleDisconnect,
}) => {
  const {t} = useTranslation(`socialActiveConnections`)

  const interIcon =
    platform === `facebook` ? FacebookIcon : InformationCircleIcon
  const Icon = platform === `instagram` ? InstagramIcon : interIcon

  const interLabel = platform === `instagram` ? t(`instagramLbl`) : platform
  const label = interLabel === `facebook` ? t(`facebookLbl`) : interLabel

  const [isModalOpen, setIsModalOpen] = useState(false)

  if (!isConnected) return null

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)
  const confirmDisconnect = () => {
    handleDisconnect(integrationId ?? ``)
    closeModal()
  }

  return (
    <>
      <Card>
        <div className="items center flex flex-row gap-3 p-5">
          <Icon size={40} />
          <div className="flex flex-row items-center">
            <Text variant="body">{label}</Text>
          </div>
          <div className="flex flex-1 flex-row justify-end">
            <Button
              variant="secondary"
              type="button"
              disabled={isSubmitting}
              onClick={openModal}
            >
              {t(`disconnectBtn`)}
            </Button>
          </div>
        </div>
      </Card>
      {isModalOpen && (
        <SocialActiveModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onConfirm={confirmDisconnect}
          platform={platform}
        />
      )}
    </>
  )
}

export default SocialActiveCard
