import {HTMLAttributes} from 'react'

type Props = HTMLAttributes<HTMLElement>

const SidebarContentWrap = ({children, ...props}: Props) => {
  return (
    <div
      {...props}
      className={`
        until-md:gap-4
        grid
        grid-cols-[--grid-template-columns-var-2]
        gap-6
      `}
      style={{
        '--grid-template-columns-var-2': `repeat(auto-fit, minmax(9rem, 1fr))`,
      }}
    >
      {children}
    </div>
  )
}

export default SidebarContentWrap
