import React, {ReactNode} from 'react'
import {I18nextProvider} from 'react-i18next'
import packageI18n from './i18n'

type WithTranslationProviderProps = {
  children?: ReactNode
}

const withTranslationProvider = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P & WithTranslationProviderProps> => {
  return (props: P & WithTranslationProviderProps) => (
    <I18nextProvider i18n={packageI18n}>
      <Component {...props} />
    </I18nextProvider>
  )
}

export default withTranslationProvider
