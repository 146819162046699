import {HTMLAttributes} from 'react'
import {Asterisk} from '../Asterisk'

interface Props extends HTMLAttributes<HTMLElement> {
  text?: string
}

const FieldError = ({text, children, className = ``, ...props}: Props) => {
  return (
    <div
      role="alert"
      className={`
        ${className}
        text-ui-500
        animate-zoom-fade-in
        pt-1
        text-xs
        italic
      `}
      {...props}
    >
      <Asterisk intent={`error`} />
      {children || text}
    </div>
  )
}

export default FieldError
