import {HTMLAttributes} from 'react'

interface Props extends HTMLAttributes<HTMLElement> {
  spanGrid?: boolean
  width?: keyof typeof widths
  color?: keyof typeof colors
}

const widths = {
  1: `border-t`,
  2: `border-t-2`,
} as const

const colors = {
  default: `border-ui-200 dark:border-ui-50`,
  inherit: ``,
  'on-dark': `border-ui-border opacity-80 dark:opacity-50`,
} as const

const Divider = ({spanGrid, width = 1, color = `default`, ...props}: Props) => {
  return (
    <div
      className={`
        border-t
        ${spanGrid ? `col-span-full` : ``}
        ${width ? widths[width] : ``}
        ${color ? colors[color] : ``}
        h-0
      `}
      {...props}
    >
      <hr className={`sr-only`} />
    </div>
  )
}

export default Divider
