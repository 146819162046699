import {Button, FieldError} from '@myadbox/gatsby-theme-nebula'
import {
  AccountIntegration,
  Integration,
  useAccount,
} from '@myadbox/nebula-service-api'
import {isSelectOptions} from '@myadbox/nebula-template-utils'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {Fragment} from 'react'
import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {GoogleSheetName} from '../helpers'
import {
  FormInput as Input,
  FormSchemaPicker as SchemaPicker,
  FormSelect as Select,
} from './field-renderers'
import {useAccountIntegration} from './hooks/use-account-integration'
import {isDatasetOptions} from './typeguards'

export interface FormProps {
  integration: Integration
  accountIntegration?: AccountIntegration
  hideButton?: boolean
}

export type Inputs = {
  [key: string]: string
}

export const IntegrationForm = ({
  integration,
  accountIntegration,
  hideButton = false,
}: FormProps) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: {errors, isDirty},
  } = useForm<Inputs>({defaultValues: accountIntegration?.values || undefined})

  const {t} = useTranslation()

  const {
    updateAccountIntegration,
    updateAccountIntegrationResponse: {loading, data, reset: resetResponse},
  } = useAccount()

  useAccountIntegration({
    accountIntegration,
    data,
    isDirty,
    reset,
    resetResponse,
  })

  const onSubmit: SubmitHandler<Inputs> = data => {
    updateAccountIntegration({integrationId: integration.id, values: data})
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`grid grid-flow-row gap-4`}>
        {integration.config.fields?.map((field, index) => {
          const {name, options, required = false, disabled = false} = field

          return (
            <Fragment key={`${name}-${index}`}>
              <Controller
                key={name}
                name={name}
                rules={{required}}
                control={control}
                disabled={disabled}
                render={({field}) => {
                  if (isSelectOptions(options)) {
                    return <Select {...field} options={options} />
                  } else if (isDatasetOptions(options)) {
                    return <SchemaPicker {...field} options={options} />
                  } else {
                    return <Input {...field} options={options} />
                  }
                }}
              />
              {errors[name] && (
                <FieldError
                  text={t(`settings.integrations.fieldRequired`, {
                    label: options.label,
                  })}
                />
              )}
            </Fragment>
          )
        })}
        {!hideButton && (
          <div>
            <Button
              variant="primary"
              type="submit"
              loading={loading}
              disabled={!isDirty}
            >{t`settings.integrations.update`}</Button>
          </div>
        )}

        {integration.name === GoogleSheetName && accountIntegration?.values && (
          <div className="flex flex-col space-y-4">
            <div className="flex">
              <span className="w-40 font-bold">{t`settings.integrations.googleSheets.creationDate`}</span>
              <span>
                {new Date(
                  accountIntegration.values.requestedAt
                ).toLocaleString()}
              </span>
            </div>
            <div className="flex">
              <span className="w-40 font-bold">{t`settings.integrations.googleSheets.authScope`}</span>
              <span>{t`settings.integrations.googleSheets.readOnlyScope`}</span>
            </div>
          </div>
        )}
      </div>
    </form>
  )
}

export default IntegrationForm
