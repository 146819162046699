import {ImgHTMLAttributes} from 'react'
import {createSrcSet, injectTransforms} from './helpers'

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  src: string
}

const Image = ({
  src = ``,
  alt = ``,
  className = ``,
  style = {},
  ...props
}: Props) => {
  const {src: fallbackSrc, srcSet} = createSrcSet(src)
  return (
    <img
      alt={alt}
      className={`
        ${className}
        lazyload
        pointer-events-none
        w-full
      `}
      style={{
        height: `var(--image-height, auto)`,
        ...style,
        userSelect: `none`,
      }}
      width="200"
      src={injectTransforms(src, `e_blur:10,q_11,w_30`)}
      data-src={fallbackSrc}
      data-srcset={srcSet}
      data-sizes="auto"
      {...props}
    />
  )
}

export default Image
