import {gql} from '@apollo/client'

export const CATEGORY_BASIC = gql`
  fragment CategoryBasic on Category {
    title
    parent
  }
`

export const CATEGORY_DETAIL = gql`
  fragment CategoryDetail on Category {
    active
    createdById
    createdAt
  }
`
