import {MoonIcon, SunIcon} from '@heroicons/react/24/outline'
import {HTMLAttributes, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import useDarkMode from 'use-dark-mode'
import {styles} from '../../../'

const ColourSchemeToggler = ({
  className = ``,
}: HTMLAttributes<HTMLButtonElement>) => {
  const {t} = useTranslation(`layout`)

  // TODO: the rest of this function body (not the JSX render) could do with a refactor and moving to a custom hook
  const {value: isDark, toggle} = useDarkMode(false)
  const timer = useRef(null)
  const [isAnimating, setAnimating] = useState(false)
  const [isInitialised, setInitialised] = useState(false)

  useEffect(() => {
    if (!isInitialised) {
      setInitialised(true)
      return
    }
    setAnimating(true)
    clearInterval(timer.current)
    timer.current = setInterval(() => {
      setAnimating(false)
      clearInterval(timer.current)
    }, 501)
    const timerLocal = timer.current
    return (): void => {
      clearInterval(timerLocal)
      setAnimating(false)
    }
  }, [isDark, isInitialised])

  return (
    <>
      <style data-testid="animationStyles">{`
        *, *::before, *::after, *::placeholder {
          transition-property: opacity, stroke, fill, background-color, color, border-color, font-weight;
          ${
            isAnimating
              ? `
                transition-duration: var(--mode-switch-duration) !important;
                transition-timing-function: ease-in-out !important;
                `
              : ``
          }
        }
      `}</style>

      <button
        onClick={toggle}
        className={`
          group
          ${className}
          ${styles.iconButtonClassName}
        `}
        aria-label={t(`brightness.toggleColourSchema`)}
      >
        <SunIcon
          className={`
            group-hover:text-ui-900
            absolute
            col-start-1
            row-start-1
            h-6
            w-6
            transition-opacity
            duration-1000
            dark:opacity-0
          `}
          aria-label={t(`brightness.light`)}
          role="img"
        />
        <MoonIcon
          className={`
            group-hover:text-ui-900
            col-start-1
            row-start-1
            h-6
            w-6
            opacity-0
            transition-opacity
            duration-1000
            dark:opacity-100
          `}
          aria-label={t(`brightness.dark`)}
          role="img"
        />
      </button>
    </>
  )
}

export default ColourSchemeToggler
