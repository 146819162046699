import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ReactNode,
} from 'react'
import {buttonSizes, variants} from './styles'

export interface BaseButtonProps {
  variant?: keyof typeof variants
  size?: keyof typeof buttonSizes
  state?: unknown
  slim?: boolean
  loading?: boolean
  loadingText?: string | null
  iconPrefix?: ReactNode
  iconSuffix?: ReactNode
  disabled?: boolean
}

export type OnlyButtonProps = BaseButtonProps &
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    as?: never
    type?: ButtonHTMLAttributes<HTMLButtonElement>[`type`]
    to?: never
    href?: never
  } & ButtonHTMLAttributes<HTMLButtonElement>

export type OnlyAnchorProps = BaseButtonProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    as?: never
    href: string
    to?: never
    type?: never
  } & AnchorHTMLAttributes<HTMLAnchorElement>

export type CustomLinkLikeProps = {state?: unknown; to: string}

export type OnlyLinkProps = BaseButtonProps & {
  as: React.ElementType<CustomLinkLikeProps>
  to: string
  href?: never
  type?: never
  children?: ReactNode
  className?: string
}

export const isAnchor = (
  props: ConditionalButtonProps
): props is OnlyAnchorProps => {
  const hasHref = typeof props === `object` && `href` in props
  if (hasHref && typeof props.href === `undefined`) {
    throw new Error(HREF_ERROR_MSG)
  }
  return hasHref
}

export const isCustomLink = (
  props: ConditionalButtonProps
): props is OnlyLinkProps => typeof props === `object` && `to` in props

export const isButton = (
  props: ConditionalButtonProps
): props is OnlyButtonProps => !isAnchor(props) && !isCustomLink(props)

export type ConditionalButtonProps =
  | OnlyButtonProps
  | OnlyAnchorProps
  | OnlyLinkProps

export const HREF_ERROR_MSG = `if 'href' is provided, it must not be undefined`
