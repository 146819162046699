import {Button, Modal} from '@myadbox/gatsby-theme-nebula'
import {Dataset} from '@myadbox/nebula-service-api'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {useState} from 'react'
import ResetConfirmation from '../ResetConfirmation/ResetConfirmation'
import EditUserActions from './EditUserActions/EditUserActions'
import UserDatasetSelector from './UserDatasetSelector'

/**
 * NOTE:
 * Since we cannot identify Admin users to switch
 * the user pool to admin, we are unable to
 * Enable/Disable or Reset Password
 * for the admin users.
 */

type Props = {
  datasetIds: string[]
  disabled?: boolean
  fullName: string
  isAdmin: boolean
  profileId: string
  sso: boolean
  userId: string
  userPoolId: string
  datasets?: Dataset[]
}

export type EditUserModalState = {
  context: EditUserContext
  value?: string
}

export enum EditUserContext {
  DEFAULT = `default`,
  RESET_PASSWORD = `reset_password`,
  ADD_DATASET = `add_dataset`,
}

const EditUserModal = ({
  datasetIds,
  datasets = [],
  disabled,
  fullName,
  isAdmin,
  profileId,
  userId,
  userPoolId,
  sso,
}: Props) => {
  const {t} = useTranslation()
  const [state, setState] = useState<EditUserModalState>({
    context: EditUserContext.DEFAULT,
  })
  const resetState = () => setState({context: EditUserContext.DEFAULT})

  return (
    <Modal
      accessibleTitle={t`settings.users.edit.header`}
      maxWidth={state.context === EditUserContext.ADD_DATASET ? `xl` : `sm`}
      trigger={({open}) => (
        <Button
          disabled={disabled}
          type="button"
          variant="link"
          slim
          size="md"
          onClick={open}
        >
          {t`settings.users.edit.edit`}
        </Button>
      )}
    >
      {({close}) => {
        switch (state.context) {
          case EditUserContext.RESET_PASSWORD:
            return (
              <ResetConfirmation
                amzCognitoPoolId={userPoolId}
                userId={userId}
                fullName={fullName}
                close={close}
                resetContext={resetState}
              />
            )

          case EditUserContext.ADD_DATASET:
            return (
              <UserDatasetSelector
                schema={state.value}
                setContext={setState}
                amzCognitoPoolId={userPoolId}
                profileId={profileId}
                datasetIds={datasetIds}
              />
            )

          case EditUserContext.DEFAULT:
            return (
              <EditUserActions
                amzCognitoPoolId={userPoolId}
                close={close}
                datasetIds={datasetIds}
                datasets={datasets}
                isAdmin={isAdmin}
                profileId={profileId}
                setContext={setState}
                sso={sso}
              />
            )
        }
      }}
    </Modal>
  )
}

export default EditUserModal
