import {components, ValueContainerProps} from 'react-select'
import {cn} from '../../../util/tailwind'
import {getIsVariant} from '../helpers'

export const valueContainerClassName = `
  text-ui-500
  relative
  w-full
  px-3
  py-2
`

export const inlineClassName = `py-0.5 pl-1 pr-0`

const OurValueContainer = ({
  children,
  className = ``,
  ...props
}: ValueContainerProps) => {
  const isInline = getIsVariant(`inline`, props.selectProps)
  props.innerProps = {
    ...props.innerProps,
    className: cn(
      valueContainerClassName,
      isInline && inlineClassName,
      className
    ),
  }
  return (
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
  )
}

export default OurValueContainer
