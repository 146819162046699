import {FETCH_NESTEDSCHEMAS} from './queries'

export const addSchemaUpdater = () => {
  return (cache, {data}) => {
    const cachedData = cache.readQuery({
      query: FETCH_NESTEDSCHEMAS,
      variables: {isParent: true},
    })

    if (!cachedData || !data) return

    cache.writeQuery({
      query: FETCH_NESTEDSCHEMAS,
      variables: {isParent: true},
      data: {
        nestedSchemas: [...cachedData.nestedSchemas, data.addSchema],
      },
    })
  }
}
