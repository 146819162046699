import {AppLogo} from '@myadbox/stellar-ui'
import {useLocation} from '@reach/router'
import {Link} from 'gatsby-plugin-react-i18next'

const stripSlashes = (pathname: string): string => pathname.replace(/\//g, ``)

interface Props {
  to?: string
  title?: string
  fullStrength?: boolean
  children: React.ReactNode
}

export const LinkedAppLogo = ({
  to = `/dashboard`,
  title = `Back to dashboard`,
  fullStrength = false,
}: Omit<Props, `children`>) => {
  const {pathname} = useLocation()

  return stripSlashes(pathname) === stripSlashes(to) ? (
    <Logo fullStrength={fullStrength} />
  ) : (
    <LinkWrap to={to} title={title}>
      <Logo fullStrength={fullStrength} />
    </LinkWrap>
  )
}

const LinkWrap = ({to, title, children}: Props) => (
  <Link
    to={to}
    title={title}
    className={`
      ${/* will apply own focus styles below (group-hocus) */ ``}
      group
      mr-auto
      block
      focus:outline-none
    `}
  >
    {children}
  </Link>
)

export const logoColours = {
  frame: {
    full: `text-ui-900 dark:text-ui-800`,
    fade: `text-ui-300`,
  },
  brand: {
    full: `text-brand`,
    fade: ``,
  },
}

export const Logo = ({fullStrength = false}) => (
  <AppLogo
    className={`
      dark:text-ui-200
      group-hocus:text-ui-500
      transition-default
      m-4
      block
      h-10
      w-10
      duration-200
      ${fullStrength ? logoColours.frame.full : logoColours.frame.fade}
    `}
  />
)

export default LinkedAppLogo
