import {RecursiveKeyValuePair, ResolvableTo} from 'tailwindcss/types/config'

export const sesimiBrandColors = {
  blue: {
    50: `hsl(177, 100%, 96%)`,
    100: `hsl(180, 100%, 90%)`,
    200: `hsl(182, 100%, 81%)`,
    300: `hsl(183, 100%, 68%)`,
    400: `hsl(186, 99%, 51%)`,
    500: `hsl(187, 100%, 45%)`,
    600: `hsl(190, 100%, 37%)`,
    700: `hsl(191, 95%, 31%)`,
    800: `hsl(192, 81%, 27%)`,
    900: `hsl(194, 74%, 24%)`,
    950: `hsl(195, 92%, 15%)`,
  },
  lime: {
    50: `hsl(67, 100%, 95%)`,
    100: `hsl(68, 100%, 89%)`,
    200: `hsl(70, 100%, 80%)`,
    300: `hsl(72, 100%, 69%)`,
    400: `hsl(74, 100%, 55%)`,
    500: `hsl(75, 100%, 50%)`,
    600: `hsl(77, 100%, 42%)`,
    700: `hsl(77, 100%, 34%)`,
    800: `hsl(78, 100%, 31%)`,
    900: `hsl(79, 97%, 29%)`,
    950: `hsl(81, 100%, 20%)`,
  },
  red: {
    50: `hsl(338, 73%, 97%)`,
    100: `hsl(335, 70%, 95%)`,
    200: `hsl(334, 80%, 88%)`,
    300: `hsl(336, 83%, 82%)`,
    400: `hsl(337, 80%, 70%)`,
    500: `hsl(339, 76%, 60%)`,
    600: `hsl(342, 67%, 51%)`,
    700: `hsl(343, 73%, 42%)`,
    800: `hsl(344, 70%, 35%)`,
    900: `hsl(344, 65%, 30%)`,
    950: `hsl(345, 79%, 17%)`,
  },
  green: {
    50: `hsl(144, 88%, 97%)`,
    100: `hsl(151, 100%, 92%)`,
    200: `hsl(151, 97%, 85%)`,
    300: `hsl(152, 94%, 73%)`,
    400: `hsl(152, 85%, 59%)`,
    500: `hsl(152, 86%, 45%)`,
    600: `hsl(152, 94%, 36%)`,
    700: `hsl(153, 88%, 29%)`,
    800: `hsl(153, 79%, 24%)`,
    900: `hsl(154, 75%, 20%)`,
    950: `hsl(154, 100%, 10%)`,
  },
  yellow: {
    50: `hsl(60, 92%, 95%)`,
    100: `hsl(60, 100%, 88%)`,
    200: `hsl(59, 100%, 77%)`,
    300: `hsl(56, 100%, 64%)`,
    400: `hsl(54, 97%, 53%)`,
    500: `hsl(51, 95%, 47%)`,
    600: `hsl(47, 98%, 40%)`,
    700: `hsl(42, 93%, 33%)`,
    800: `hsl(38, 82%, 29%)`,
    900: `hsl(35, 74%, 26%)`,
    950: `hsl(32, 86%, 14%)`,
  },
}

export const colors: ResolvableTo<RecursiveKeyValuePair<string, string>> = ({
  colors,
}) => {
  return {
    inherit: `inherit`,
    current: `currentColor`,
    transparent: `transparent`,
    black: `#000`,
    white: `#fff`,
    blue: sesimiBrandColors.blue,
    red: sesimiBrandColors.red,
    green: sesimiBrandColors.green,
    yellow: sesimiBrandColors.yellow,
    // tailwind's slate, for curiosity's sake when pasting in Tailwind UI components
    'tw-gray': colors.gray,
    // Nebula's slate, tweaked for aesthetics
    gray: {
      DEFAULT: `hsl(var(--base-slate-500) / <alpha-value>)`,
      50: `hsl(var(--base-slate-50) / <alpha-value>)`,
      100: `hsl(var(--base-slate-100) / <alpha-value>)`,
      200: `hsl(var(--base-slate-200) / <alpha-value>)`,
      300: `hsl(var(--base-slate-300) / <alpha-value>)`,
      400: `hsl(var(--base-slate-400) / <alpha-value>)`,
      500: `hsl(var(--base-slate-500) / <alpha-value>)`,
      600: `hsl(var(--base-slate-600) / <alpha-value>)`,
      700: `hsl(var(--base-slate-700) / <alpha-value>)`,
      800: `hsl(var(--base-slate-800) / <alpha-value>)`,
      900: `hsl(var(--base-slate-900) / <alpha-value>)`,
      950: `hsl(var(--base-slate-950) / <alpha-value>)`,
    },
    ui: {
      DEFAULT: `hsl(var(--base-dynamic-ui-500) / <alpha-value>)`,
      0: `hsl(var(--base-dynamic-ui-0) / <alpha-value>)`,
      50: `hsl(var(--base-dynamic-ui-50) / <alpha-value>)`,
      100: `hsl(var(--base-dynamic-ui-100) / <alpha-value>)`,
      200: `hsl(var(--base-dynamic-ui-200) / <alpha-value>)`,
      300: `hsl(var(--base-dynamic-ui-300) / <alpha-value>)`,
      400: `hsl(var(--base-dynamic-ui-400) / <alpha-value>)`,
      500: `hsl(var(--base-dynamic-ui-500) / <alpha-value>)`,
      600: `hsl(var(--base-dynamic-ui-600) / <alpha-value>)`,
      700: `hsl(var(--base-dynamic-ui-700) / <alpha-value>)`,
      800: `hsl(var(--base-dynamic-ui-800) / <alpha-value>)`,
      900: `hsl(var(--base-dynamic-ui-900) / <alpha-value>)`,
      950: `hsl(var(--base-dynamic-ui-950) / <alpha-value>)`,
      border: `var(--ui-border)`,
      overlay: `var(--ui-overlay)`,
    },
    // Sesimi's lime
    'electric-lime': `#d1ff3b`,
    brand: `#d1ff3b`,
    lime: sesimiBrandColors.lime,
  }
}
