import {AccountIntegration, UseAccountOutput} from '@myadbox/nebula-service-api'
import {useEffect} from 'react'
import {UseFormReset} from 'react-hook-form'
import {Inputs} from '../IntegrationForm'

export const useAccountIntegration = ({
  accountIntegration,
  data,
  isDirty,
  reset,
  resetResponse,
}: {
  accountIntegration: AccountIntegration
  data: UseAccountOutput[`updateAccountIntegrationResponse`][`data`]
  isDirty: boolean
  reset: UseFormReset<Inputs>
  resetResponse: UseAccountOutput[`updateAccountIntegrationResponse`][`reset`]
}) => {
  useEffect(() => {
    if (!data || !isDirty) return

    if (accountIntegration) {
      const {values} = data.updateAccountIntegration.integrations.find(
        response => response.integrationId === accountIntegration.integrationId
      )
      reset(values)
    } else {
      // No previous account information exists, so we know that the first value in the array is the one we want
      reset(data.updateAccountIntegration.integrations[0].values)
    }
    resetResponse()
  }, [accountIntegration, data, isDirty, reset, resetResponse])
}
