import {withLDProvider} from 'launchdarkly-react-client-sdk'

export const wrapRootElement = ({element}, pluginOptions) => {
  if (!pluginOptions.clientSideID) {
    throw new Error(`LaunchDarkly client side ID is required`)
  }

  const App = () => <>{element}</>
  const LDApp = withLDProvider(pluginOptions)(App)
  return <LDApp />
}
