// Utility component, doesn't need to be documented or exposed outside other library components
import {GatsbyLinkProps, Link} from 'gatsby'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {ComponentType, RefObject, forwardRef} from 'react'

// cannot use gatsby-plugin-react-i18next's Link as it doesn't support ref forwarding
export const LocalisedLink = forwardRef(
  (props: GatsbyLinkProps<unknown>, ref) => {
    const {language, defaultLanguage} = useI18next()
    let link = props[`to`]
    if (language !== defaultLanguage) {
      link = `/${language}${props[`to`]}`
    }
    /* workaround for tricky @types/react@18 issue. See https://github.com/DefinitelyTyped/DefinitelyTyped/discussions/69006 */
    const TheLink = Link as ComponentType<GatsbyLinkProps<unknown>>
    return (
      <TheLink
        {...props}
        ref={ref as string & RefObject<HTMLAnchorElement>}
        to={link}
      />
    )
  }
)
LocalisedLink.displayName = `LocalisedLink`

export default LocalisedLink
