import {useEffect, useState} from 'react'
import {noop} from '../../utils/noop'

export interface FileReaderOptions {
  method?: string
  onload?: (result) => void
}

/**
 * @internal
 * @remarks as all of the state is related, it would be well suited to be refactored as a reducer.
 */
export const useFileReader = (options: FileReaderOptions = {}) => {
  const [file, setFile] = useState(null)
  const [error, setError] = useState(null)
  const [result, setResult] = useState(null)
  const [loading, setLoading] = useState(false)

  const {method = `readAsText`, onload: onloadHook = noop} = options

  useEffect(() => {
    if (!file) return

    const reader = new FileReader()

    reader.onloadstart = () => {
      setLoading(true)
    }

    reader.onloadend = () => {
      setLoading(false)
    }

    reader.onload = e => {
      setResult(e.target.result)
      onloadHook(e.target.result)
    }

    reader.onerror = e => {
      setError(e)
    }

    try {
      reader[method](file)
    } catch (e) {
      setError(e)
    }
  }, [file, method, onloadHook])

  // TODO: the output needs typing properly (result, error, file, loading)
  // Tuple Ref: https://fettblog.eu/typescript-react-typeing-custom-hooks/
  return [{result, error, file, loading}, setFile] as const
}

export default useFileReader
