import {HTMLAttributes} from 'react'

interface Props extends HTMLAttributes<HTMLElement> {
  tag?: `div` | `li`
  isSelected?: boolean
  isSelectable?: boolean
}

export const classNames = {
  selected: `
    bg-ui-0
    dark:bg-ui-200
    shadow-card-selected
  `,
  selectable: `
    focus-within:bg-ui-50
    hocus:bg-ui-50
    dark:focus-within:bg-ui-200
    dark:hocus:bg-ui-200
  `,
  default: `
    bg-ui-100
    dark:bg-ui-100
  `,
}

const Card = ({
  className = ``,
  children,
  tag: Container = `div`,
  isSelected = false,
  isSelectable = true,
  ...props
}: Props) => {
  return (
    <Container
      className={`
        ${className}
        ${classNames.default}
        ${isSelected ? classNames.selected : `shadow-card`}
        ${isSelectable && !isSelected ? classNames.selectable : ``}
        sm:rounded-400
        relative
        flex
        flex-col
      `}
      {...props}
    >
      {children}
    </Container>
  )
}

export default Card
