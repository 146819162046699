import {useCallback} from 'react'
import {useLazyQuery} from '@apollo/client'
import {QUERY_OVERVIEW, QUERY_RAW_DATA} from '../../queries'
import {ReportsOutput} from '../../types'
import {endOfDay, formatISO, startOfDay} from 'date-fns'

export const useReports = (): ReportsOutput => {
  const [lazyFetchReports, reportsResult] = useLazyQuery(QUERY_OVERVIEW, {
    fetchPolicy: `cache-and-network`,
  })
  const fetchReports = useCallback(
    (startDate: Date, endDate: Date, userIds: string[]) => {
      lazyFetchReports({
        variables: {
          startDateTime: formatISO(startOfDay(startDate)),
          endDateTime: formatISO(endOfDay(endDate)),
          userIds,
        },
      })
    },
    [lazyFetchReports]
  )

  const [lazyFetchRawData, rawDataResult] = useLazyQuery(QUERY_RAW_DATA, {
    fetchPolicy: `no-cache`,
  })
  const fetchRawData = useCallback(
    (startDate: Date, endDate: Date, userIds: string[]) => {
      lazyFetchRawData({
        variables: {
          startDateTime: formatISO(startOfDay(startDate)),
          endDateTime: formatISO(endOfDay(endDate)),
          userIds,
        },
      })
    },
    [lazyFetchRawData]
  )

  return {
    fetchReports,
    reportsResult,
    fetchRawData,
    rawDataResult,
  }
}
