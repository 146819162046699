import {ComponentProps} from 'react'
import {goBack} from '../../utils/goback'

import {BackButton as StellarBackButton} from '@myadbox/stellar-ui'

type StellarBackButtonProps = ComponentProps<typeof StellarBackButton>
type Props = Omit<StellarBackButtonProps, `backHandler`> & {
  // making this prop optional as the purpose of this wrapper is to provide a default backHandler
  backHandler?: StellarBackButtonProps[`backHandler`]
}

/**
 *
 * A wrapper for the Stellar BackButton component that provides a default backHandler.
 * Please prefer the Stellar UI version of this component for new usages.
 */
const BackButton = (props: Props) => {
  return <StellarBackButton {...props} backHandler={goBack} />
}

export default BackButton
