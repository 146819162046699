import {InputHTMLAttributes} from 'react'

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string
  name: string
}

export const checkboxClassName = `
  appearance-none
  bg-ui-0
  rounded-400
  border
  border-ui-500
  shadow
  text-blue-500
  inline-flex
  shrink-0
  w-4
  h-4
  align-middle
  form-checkbox
`

const Checkbox = ({id, name, className = ``, ...props}: CheckboxProps) => {
  return (
    <input
      {...props}
      id={id}
      type="checkbox"
      name={name}
      className={`${checkboxClassName} ${className}`}
    />
  )
}

export default Checkbox
