import {Toast} from '@myadbox/gatsby-theme-nebula'
import {
  Button,
  ErrorMessageStyler,
  FieldError,
  Input,
} from '@myadbox/stellar-ui'

import {useTranslation} from 'gatsby-plugin-react-i18next'
import {Controller, useForm} from 'react-hook-form'

import {zodResolver} from '@hookform/resolvers/zod'
import {
  brandAssetIdChoiceSchema,
  BrandAssetIdChoiceSchema,
} from '@myadbox/nebula-schemas-local'
import {UseAccountOutput} from '@myadbox/nebula-service-api'
import {useEffect} from 'react'

type Props = {
  accountData: UseAccountOutput
}

const resolver = zodResolver(brandAssetIdChoiceSchema)

const BrandAssetUpdateForm = ({accountData}: Props) => {
  const {t} = useTranslation(`settings`, {keyPrefix: `settings.account.brand`})

  const {
    account,
    updateAccountBrandAssetConfig,
    updateAccountBrandAssetConfigResponse,
  } = accountData

  const brandAssetId = account?.configuration?.brandAssetId

  const {handleSubmit, control, formState} = useForm<BrandAssetIdChoiceSchema>({
    defaultValues: {brandAssetId},
    resolver,
  })

  const {
    called,
    loading,
    error,
    data: updateData,
  } = updateAccountBrandAssetConfigResponse

  const successMessage = t(`successMessage`)
  const showSuccess =
    called && !loading && !error && updateData.updateAccountBrandAssetConfig

  useEffect(() => {
    if (showSuccess) {
      Toast.show(<Toast intent="success">{successMessage}</Toast>)
    }
  }, [successMessage, showSuccess])

  return (
    <form onSubmit={handleSubmit(updateAccountBrandAssetConfig)}>
      <Controller
        name="brandAssetId"
        control={control}
        render={({field}) => (
          <Input
            {...field}
            id={field.name}
            label={t`label`}
            inlineActions={
              <div className="shrink-0">
                <Button disabled={formState.isSubmitting}>{t`save`}</Button>
              </div>
            }
            endContent={
              formState.errors.brandAssetId && (
                <ErrorMessageStyler>
                  <FieldError>
                    <span>{formState.errors.brandAssetId.message}</span>
                  </FieldError>
                </ErrorMessageStyler>
              )
            }
          />
        )}
      />
    </form>
  )
}

export default BrandAssetUpdateForm
