import type {ReactNode} from 'react'
import type {LogoType} from '../../types'

interface Props extends LogoType {
  Menu: ReactNode
}

const SidebarStatic = ({Logo, Menu}: Props) => {
  return (
    <div
      role="menu"
      className={`
        w-18
        pointer-events-none
        relative
        z-30
        hidden
        md:pointer-events-auto
        md:flex
        md:shrink-0
      `}
    >
      <div
        className={`
          before:bg-ui-50
          shadow-right-px
          absolute
          flex
          h-full
          flex-col
          overflow-hidden
          shadow-[--ui-border]
          before:absolute
          before:inset-0
          before:-z-10
          before:block
        `}
      >
        {Logo}
        <div
          className={`
            mt-px
            flex
            flex-1
            flex-col
            overflow-y-auto
            pt-4
          `}
        >
          {Menu}
        </div>
      </div>
    </div>
  )
}

export default SidebarStatic
