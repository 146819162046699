import {CheckIcon} from '@heroicons/react/24/outline'
import {
  ActionGroup,
  Banner,
  Button,
  FormItem,
  Input,
  Modal,
  Select,
  Text,
  TextArea,
  ToggleInput,
} from '@myadbox/gatsby-theme-nebula'
import {useAccount} from '@myadbox/nebula-service-api'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next'
import {useState} from 'react'

export type BillingAdjustmentModalProps = {
  price: number
  currency: string
  billingEmail: string
  additionalInfo: string
  paymentTerm: number
  autoBilling: boolean
}

const currencyOptions = [
  {
    value: `aud`,
    label: `AUD`,
  },
  {
    value: `cad`,
    label: `CAD`,
  },
  {
    value: `usd`,
    label: `USD`,
  },
]

const BillingAdjustmentModal = (props: BillingAdjustmentModalProps) => {
  const {t} = useTranslation()
  const {
    updateBilling,
    updateBillingResponse: {error, data, loading},
  } = useAccount()

  const [billingAdjustment, setBillingAdjustment] = useState({...props})

  const handleSubmit = () => {
    //convert price to cents
    updateBilling({
      ...billingAdjustment,
      price: billingAdjustment.price * 100,
    })
  }

  return (
    <Modal
      maxWidth="md"
      trigger={({open}) => (
        <Button onClick={open} type="button" role="button" size="sm">
          {t`settings.billing.accountBilling.adjust`}
        </Button>
      )}
    >
      {({close}) => (
        <>
          <Modal.Header>{t`settings.billing.adjustmentForm.header`}</Modal.Header>
          <Modal.Body>
            <div>
              {error && (
                <div className="mb-4">
                  <Banner fadeIn intent="error">
                    <Trans
                      i18nKey="settings.billing.adjustmentForm.errorMsg"
                      values={{errMessage: error.message}}
                    />
                  </Banner>
                </div>
              )}
            </div>
            <div className="grid gap-4">
              <Input
                type="number"
                label={t`settings.billing.adjustmentForm.price`}
                name="price"
                id="price"
                value={billingAdjustment.price.toString()}
                onChange={e =>
                  setBillingAdjustment({
                    ...billingAdjustment,
                    price: e.target.value ? parseFloat(e.target.value) : 0,
                  })
                }
              />
              <Select
                id="currency"
                name="currency"
                value={currencyOptions.find(
                  o => o.value === billingAdjustment.currency
                )}
                label={t`settings.billing.adjustmentForm.currency`}
                onChange={({value}) => {
                  setBillingAdjustment({
                    ...billingAdjustment,
                    currency: value.toString(),
                  })
                }}
                options={currencyOptions}
              />

              <Input
                type="text"
                label={t`settings.billing.adjustmentForm.billingEmail`}
                name="billingEmail"
                id="billingEmail"
                value={billingAdjustment.billingEmail}
                onChange={e =>
                  setBillingAdjustment({
                    ...billingAdjustment,
                    billingEmail: e.target.value,
                  })
                }
              />
              <Input
                type="number"
                label={t`settings.billing.adjustmentForm.paymentTerm`}
                name="paymentTerm"
                id="paymentTerm"
                value={billingAdjustment.paymentTerm}
                onChange={e =>
                  setBillingAdjustment({
                    ...billingAdjustment,
                    paymentTerm: e.target.value ? parseInt(e.target.value) : 0,
                  })
                }
              />
              <TextArea
                label={t`settings.billing.adjustmentForm.additionalInfo`}
                name="additionalInfo"
                id="additionalInfo"
                value={billingAdjustment.additionalInfo}
                onChange={e => {
                  setBillingAdjustment({
                    ...billingAdjustment,
                    additionalInfo: e.target.value,
                  })
                }}
              />
              <FormItem
                onClick={() => {
                  setBillingAdjustment({
                    ...billingAdjustment,
                    autoBilling: !billingAdjustment.autoBilling,
                  })
                }}
                label={
                  <FormItem.Label htmlFor="autoBilling">
                    {t`settings.billing.adjustmentForm.autoBilling`}
                  </FormItem.Label>
                }
              >
                <ToggleInput
                  id={`autoBilling`}
                  on={billingAdjustment.autoBilling}
                />
              </FormItem>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ActionGroup>
              {data ? (
                <div
                  className={`
                    flex
                    items-center
                    justify-center
                    gap-1
                    text-green-600
                  `}
                >
                  <CheckIcon width={24} height={24} />
                  <Text>{t`settings.billing.adjustmentForm.successMsg`}</Text>
                </div>
              ) : (
                <>
                  <Button variant="secondary" onClick={close}>
                    {t`settings.billing.adjustmentForm.cancel`}
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    loading={loading}
                  >
                    {t`settings.billing.adjustmentForm.save`}
                  </Button>
                </>
              )}
            </ActionGroup>
          </Modal.Footer>
        </>
      )}
    </Modal>
  )
}
export default BillingAdjustmentModal
