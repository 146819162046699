import {MutationHookOptions, useMutation} from '@apollo/client'
import {EXCHANGE_TOKEN} from '../mutations'
import {ExchangeTokenInput, UseExchangeTokenOutput} from '../types'

export const useExchangeToken = (
  options?: MutationHookOptions
): UseExchangeTokenOutput => {
  const [doExchangeToken, exchangeTokenResponse] = useMutation(
    EXCHANGE_TOKEN,
    options
  )

  const exchangeToken = (input: ExchangeTokenInput) => {
    return doExchangeToken({
      variables: {input},
    })
  }

  return {
    exchangeToken,
    exchangeTokenResponse,
  }
}
