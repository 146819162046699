import {Asset} from '../../../assets/types'
import {SessionUser} from '../../../utils/types'
import {AccessLevel} from '../../types'

interface Permissions {
  canDelete: boolean
  canEdit: boolean
  canExport: boolean
  canOverrideExpiry: boolean
  canShare: boolean
}

const EDITORS: AccessLevel[] = [`owner`, `collaborator`]
const EXPORTERS: AccessLevel[] = [`owner`, `collaborator`, `publisher`]

const DEFAULT_PERMISSIONS: Permissions = {
  canDelete: false,
  canEdit: false,
  canExport: false,
  canOverrideExpiry: false,
  canShare: false,
}

const CREATOR_PERMISSIONS: Permissions = {
  canDelete: true,
  canEdit: true,
  canExport: true,
  canOverrideExpiry: true,
  canShare: true,
}

export const isAllTrue = (perms: boolean[]): boolean => {
  return perms.every(Boolean)
}

export const usePermissions = (
  user: SessionUser,
  assets: Asset[] = []
): Permissions => {
  if (!user) return DEFAULT_PERMISSIONS

  const {accesses} = user.attributes
  const accessIds = accesses.map(access => access.id)

  const singleAssetPerms = assets.map(asset => {
    if (user.username === asset.createdBy.userId) {
      return CREATOR_PERMISSIONS
    }

    const relevantAccesses = asset.accesses.filter(access =>
      accessIds.includes(access.id)
    )

    if (!relevantAccesses.length) {
      return DEFAULT_PERMISSIONS
    }

    return {
      canDelete: relevantAccesses.some(
        access => access.accessLevel === `owner`
      ),
      canEdit: relevantAccesses.some(access =>
        EDITORS.includes(access.accessLevel)
      ),
      canExport: relevantAccesses.some(access =>
        EXPORTERS.includes(access.accessLevel)
      ),
      canOverrideExpiry: relevantAccesses.some(
        access => access.accessLevel === `owner`
      ),
      canShare: relevantAccesses.some(access => access.accessLevel === `owner`),
    }
  })

  return {
    canDelete: singleAssetPerms.map(p => p.canDelete).every(Boolean),
    canEdit: singleAssetPerms.map(p => p.canEdit).every(Boolean),
    canExport: singleAssetPerms.map(p => p.canExport).every(Boolean),
    canOverrideExpiry: singleAssetPerms
      .map(p => p.canOverrideExpiry)
      .every(Boolean),
    canShare: singleAssetPerms.map(p => p.canShare).every(Boolean),
  }
}

export default usePermissions
