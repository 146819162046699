import {gql} from '@apollo/client'

export const ACCESS_DETAIL = gql`
  fragment AccessDetail on Access {
    teamId
    userId
    accessLevel
    validFrom
    validTo
  }
`

export const EXTERNAL_SHARE_DETAIL = gql`
  fragment ExternalShareDetail on ExternalShare {
    assetIds
    email
    expiry
    accessLevel
  }
`
