const supportedLanguages = {
  'en-AU': `English (AU)`,
  'en-US': `English (US)`,
  'fr-CA': `Français (CA)`,
  'id-ID': `Bahasa Indonesia`,
  'ko-KR': `한국어`,
  'pt-BR': `Português (BR)`,
}

const languages = Object.keys(supportedLanguages)
const defaultLanguage = `en-AU`

const languageConfig = {
  defaultLanguage,
  languages,
  languageKeyVal: supportedLanguages,
  gatsbyPluginReactI18next: {
    resolve: `gatsby-plugin-react-i18next`,
    options: {
      languages,
      defaultLanguage,
      localeJsonSourceName: `locale`,
      // if you are using Helmet, you must include siteUrl, and make sure you add http:https
      // siteUrl: `http://localhost:8000/`,
      i18nextOptions: {
        debug: false, // please avoid committing this when set to true
        nsSeparator: false,
        useSuspense: false,
        fallbackLng: defaultLanguage,
        interpolation: {
          escapeValue: false,
        },
      },
    },
  },
}

module.exports = {languageConfig, supportedLanguages}
