import {components, MultiValueGenericProps} from 'react-select'
import {cn} from '../../../utils/tailwind'

type OurMultiValueProps = MultiValueGenericProps & {className?: string}

const OurMultiValueContainer = ({
  className = ``,
  ...props
}: OurMultiValueProps) => {
  const newProps = {
    ...props,
    innerProps: {
      ...props.innerProps,
      className: cn(
        `
          bg-ui-100
          inline-flex
          text-ui-500
          min-w-0
          p-0.5
          pl-2
          rounded
          mr-1
          items-start
        `,
        className
      ),
    },
  }
  return <components.MultiValueContainer {...newProps} />
}

export default OurMultiValueContainer
