import {MutationHookOptions, useMutation} from '@apollo/client'
import {DISCONNECT_SOCIAL} from '../mutations'
import {DisconnectSocialInput, UseDisconnectSocialOutput} from '../types'

export const useDisconnectSocial = (
  options?: MutationHookOptions
): UseDisconnectSocialOutput => {
  const [doDisconnect, disconnectResponse] = useMutation(
    DISCONNECT_SOCIAL,
    options
  )

  const disconnectSocial = (input: DisconnectSocialInput) => {
    return doDisconnect({
      variables: {input},
    })
  }

  return {
    disconnectSocial,
    disconnectResponse,
  }
}
