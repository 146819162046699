import {Size, Unit, UnitConverter, UnitUnion} from '../types'

export const CSS_PX_PER_MM = 3.779527559055
export const MM_PER_INCH = 25.4

// deprecated
export const CSS_PX_RATIO = CSS_PX_PER_MM

const roundTo = (decimalPlaces: number) => (val: number | string) => {
  const modifier = Math.pow(10, decimalPlaces)
  return Math.round(parseFloat(val as string) * modifier) / modifier
}

export const pxToMm: UnitConverter = (val: number): number =>
  val / CSS_PX_PER_MM

export const pxToIn: UnitConverter = (val: number): number =>
  val / CSS_PX_PER_MM / MM_PER_INCH

export const getRounder = (unit: Unit): UnitConverter => rounders[unit]

// to px normaliser helpers
export const normaliseIn = (value: number) =>
  value * MM_PER_INCH * CSS_PX_PER_MM
export const normaliseMm = (value: number) => value * CSS_PX_PER_MM
export const normalisePx = (value: number) => value

const rounders = {
  [Unit.IN]: roundTo(5),
  [Unit.MM]: roundTo(3),
  [Unit.PX]: Math.round,
}

const normalisers = {
  [Unit.PX]: normalisePx,
  [Unit.MM]: normaliseMm,
  [Unit.IN]: normaliseIn,
}

const converters = {
  [Unit.PX]: Math.round,
  [Unit.MM]: pxToMm,
  [Unit.IN]: pxToIn,
}

const getProcessors = (
  fromUnit: Unit | UnitUnion,
  toUnit: Unit | UnitUnion
): UnitConverter[] => [
  normalisers[fromUnit],
  converters[toUnit],
  rounders[toUnit],
]

const convert = (value: string | number, processors: UnitConverter[]) =>
  processors.reduce(
    (acc, curr) => (typeof curr === `function` ? curr(acc) : acc),
    parseFloat(value as string)
  )

/**
 * Converts a {@link Size} object to values of any {@link Unit}
 *
 * @param currentSize - the Size to convert from
 * @param toUnit - the Unit to convert the values of currentSize to
 * @returns Size
 *
 * @example
 * ```
 * const mmSize: Size = {
 *   width: 100,
 *   height: 100,
 *   unit: `mm`,
 * }
 *
 * const pxSize: Size = sizeConverter(fromSize, 'px')
 *
 * console.log(pxSize)
 * // {
 * //   width: 378,
 * //   height: 378,
 * //   unit: `px`,
 * // }
 *```
 */
export const sizeConverter = <S extends Size, U extends Unit = Unit.PX>(
  currentSize: S,
  toUnit: U
): S & {unit: U} => {
  const {width, height, unit: fromUnit} = currentSize
  const processors = getProcessors(fromUnit, toUnit)
  return {
    ...currentSize,
    unit: toUnit,
    width: convert(width, processors),
    height: convert(height, processors),
  }
}

// deprecated (used in Exoplanet)
export const mmToPx = (val: string | number) =>
  rounders[Unit.PX](normaliseMm(parseFloat(val as string)))
