import {useLazyQuery, useMutation} from '@apollo/client'
import {useCallback} from 'react'
import {UPDATE_BRAND} from '../../mutations'
import {FETCH_BRAND} from '../../queries'
import {UseBrandOutput} from '../../types'
import {createBrandUpdater} from '../../updaters'

export const useBrand = (): UseBrandOutput => {
  const [lazyFetchBrand, fetchBrandResult] = useLazyQuery(FETCH_BRAND)
  const fetchBrand = useCallback(
    (accountId?: string) => {
      lazyFetchBrand({
        variables: {accountId},
      })
    },
    [lazyFetchBrand]
  )

  const [doUpdateBrand, updateBrandResponse] = useMutation(UPDATE_BRAND)
  const updateBrand = useCallback(
    input => {
      return doUpdateBrand({
        variables: {
          input,
        },
        update: createBrandUpdater(),
      })
    },
    [doUpdateBrand]
  )

  return {
    updateBrand,
    updateBrandResponse,
    fetchBrand,
    fetchBrandResult,
  }
}
