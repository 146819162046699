import {gql} from '@apollo/client'
import {BRAND_SETTINGS} from './fragments'

export const FETCH_BRAND = gql`
  ${BRAND_SETTINGS}
  query Brand($accountId: String) @api(name: "brands") {
    brand(accountId: $accountId) {
      id
      ...BrandSettings
    }
  }
`
