import {
  ConditionalIcon,
  type HasConditionalIcon,
  IconTab,
} from '@myadbox/stellar-ui'
import type {ElementType, HTMLAttributes} from 'react'
import MenuItemExternal from '../MenuItemExternal'

interface Props extends HTMLAttributes<HTMLAnchorElement>, HasConditionalIcon {
  mobile?: boolean
  label: string
}
interface PropsInternal extends Props {
  to: string
  isActive?: boolean
  as: ElementType
}
export interface PropsExternal extends Props {
  href: string
  target?: `_self` | `_blank` | `_parent`
}

export const iconClasses = `mx-6 w-6 h-6 text-inherit shrink-0`
export const commonClassName = `group flex items-center px-px py-5 leading-5 hocus:bg-ui-100 transition ease-in-out duration-150 overflow-hidden h-18 whitespace-nowrap`
export const activeClassName = `is-selected bg-ui-100 dark:bg-ui-100 text-blue-500 hover:bg-ui-100 font-medium`
export const inActiveClassName = `text-ui-600 dark:text-ui-600 hover:bg-ui-100 hover:text-ui-700 font-normal`

export const getActiveClass = (className: string, isActive: boolean): string =>
  isActive
    ? `${className} ${commonClassName} ${activeClassName}`
    : `${className} ${commonClassName} ${inActiveClassName}`

const MenuItem = ({
  to,
  className = ``,
  label,
  activeIcon,
  defaultIcon,
  mobile,
  as,
  isActive = false,
}: PropsInternal) => {
  const Link = as

  return !mobile ? (
    <IconTab
      to={to}
      as={Link}
      label={label}
      activeIcon={activeIcon}
      defaultIcon={defaultIcon}
      isActive={isActive}
    />
  ) : (
    <Link to={to} className={getActiveClass(className, isActive)}>
      <ConditionalIcon
        className={iconClasses}
        current={isActive}
        activeIcon={activeIcon}
        defaultIcon={defaultIcon}
      />
      <span>{label}</span>
    </Link>
  )
}

MenuItem.External = MenuItemExternal
export default MenuItem
