import {createSrcSet, injectTransforms} from '@myadbox/stellar-ui'
import {sanitizeCsvString} from './src/utils/csv'
import {
  localeMap,
  shortLocaleFormats,
  supportedLocaleFormats,
} from './src/utils/dates'
import {
  downloadErrorTextFile,
  getToggledClassName,
  isBrowser,
  isEmptyObject,
  slugifyText,
  startsWithSpecialChar,
  stripTrailingFileName,
  stripTrailingSlash,
  toTitleCase,
} from './src/utils/helpers'
import {getRenderTranslated} from './src/utils/i18n/getRenderTranslated'
import {noop} from './src/utils/noop'
import silhouetteStylesWhen from './src/utils/states/silhouetteStylesWhen'
import {
  DateStyle,
  NumDateStyle,
  TimeStyle,
  getDefaultTimezone,
  getUserTimezoneTime,
  toLocaleTimezone,
  toUTC,
} from './src/utils/timezone'

export {
  DateStyle,
  NumDateStyle,
  TimeStyle,
  createSrcSet,
  downloadErrorTextFile,
  getDefaultTimezone,
  getRenderTranslated,
  getToggledClassName,
  getUserTimezoneTime,
  injectTransforms,
  isBrowser,
  isEmptyObject,
  localeMap,
  noop,
  sanitizeCsvString,
  shortLocaleFormats,
  silhouetteStylesWhen,
  slugifyText,
  startsWithSpecialChar,
  stripTrailingFileName,
  stripTrailingSlash,
  supportedLocaleFormats,
  toLocaleTimezone,
  toTitleCase,
  toUTC,
}

export default {
  getToggledClassName,
  isEmptyObject,
  slugifyText,
  stripTrailingSlash,
  stripTrailingFileName,
  toTitleCase,
  silhouetteStylesWhen,
  getDefaultTimezone,
  getUserTimezoneTime,
  toLocaleTimezone,
  toUTC,
  DateStyle,
  NumDateStyle,
  TimeStyle,
  injectTransforms,
  createSrcSet,
  shortLocaleFormats,
  supportedLocaleFormats,
  localeMap,
  isBrowser,
  sanitizeCsvString,
  downloadErrorTextFile,
  startsWithSpecialChar,
}
