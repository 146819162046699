import {gql} from '@apollo/client'
import {ACCESS_DETAIL, EXTERNAL_SHARE_DETAIL} from './fragments'

export const UPSERT_SHARES = gql`
  ${ACCESS_DETAIL}
  mutation UpsertShares($inputs: [AccessInput!]!, $context: UpsertContext)
  @api(name: "access") {
    upsertAccess(inputs: $inputs, context: $context) {
      id
      ...AccessDetail
    }
  }
`

export const UPDATE_SHARES = gql`
  ${ACCESS_DETAIL}
  mutation UpdateShares(
    $inputs: [UpdateAccessInput!]!
    $context: UpdateContext!
  ) @api(name: "access") {
    updateAccess(inputs: $inputs, context: $context) {
      id
      ...AccessDetail
    }
  }
`

/**
 * External Shares
 */

export const ADD_EXTERNAL_SHARES = gql`
  ${EXTERNAL_SHARE_DETAIL}
  mutation AddExternalShares($input: ExternalShareInput!)
  @api(name: "access/external") {
    addExternalShares(input: $input) {
      id
      ...ExternalShareDetail
    }
  }
`

export const DELETE_EXTERNAL_SHARE = gql`
  ${EXTERNAL_SHARE_DETAIL}
  mutation DeleteExternalShare($id: ID!) @api(name: "access/external") {
    deleteExternalShare(id: $id) {
      id
      ...ExternalShareDetail
    }
  }
`
