const buttonBase = `
  items-center
  border
  inline-flex
  font-medium
  text-left
  justify-center
  duration-75
  ease-in-out
  transition-property-default
  whitespace-nowrap
`

export const buttonSizes = {
  xs: {
    regular: `
      rounded
      text-xs
      leading-4
      px-2
      py-1.5
    `,
    slim: `
      rounded-sm
      text-xs
      leading-3
      px-2
      py-1
    `,
  },
  sm: {
    regular: `
      rounded
      text-sm
      leading-4
      px-3
      py-2
    `,
    slim: `
      rounded
      text-sm
      leading-3
      px-2
      py-1
    `,
  },
  md: {
    regular: `
      rounded
      text-sm
      leading-5
      px-4
      py-2
    `,
    slim: `
      rounded
      text-sm
      leading-4
      px-3
      py-1
    `,
  },
  lg: {
    regular: `
      rounded
      text-lg
      leading-6
      px-6
      py-3
    `,
    slim: `
      rounded
      text-lg
      leading-6
      px-4
      py-2
    `,
  },
}

const buttonVariantPrimary = `
  bg-lime-500
  text-lime-950
  bg-gradient-to-b
  hover:bg-lime-400
  active:from-lime-600
  disabled:bg-lime-500
  disabled:from-lime-500
  dark:border-transparent
  dark:text-lime-950
  shadow-lime-inset
  font-medium-only
`

const buttonVariantSecondary = `
  active:bg-ui-50
  active:text-ui-800
  focus:border-lime-300
  hover:border-ui-500
  hover:text-ui-700
  text-ui-600
  dark:hover:border-ui-300
`

const buttonVariantText = `
  border-transparent
  text-current
  hover:bg-ui-200
`

const buttonVariantOutline = `
  border-slate-500
  dark:border-slate-600
  hover:text-ui-700
  border-dashed
  hover:bg-ui-100
`

const buttonVariantLink = `
  border-transparent
  text-blue-600
  dark:text-blue-400
  hover:bg-ui-200
`

const buttonVariantDanger = `
  border-transparent
  bg-red-600
  text-white
  hover:bg-red-500
`

const buttonDisabled = `
  opacity-70
  relative
  cursor-not-allowed
`

export const variants = {
  primary: buttonVariantPrimary,
  secondary: buttonVariantSecondary,
  text: buttonVariantText,
  link: buttonVariantLink,
  danger: buttonVariantDanger,
  outline: buttonVariantOutline,
}

export const getStyles = (slim: boolean) => {
  const weight = slim ? `slim` : `regular`
  return {
    base: buttonBase,
    size: {
      xs: buttonSizes.xs[weight],
      sm: buttonSizes.sm[weight],
      md: buttonSizes.md[weight],
      lg: buttonSizes.lg[weight],
    },
    variant: variants,
    disabled: buttonDisabled,
  }
}
