import {ReactNode, useRef, useState} from 'react'

import {XMarkIcon as CloseIcon} from '@heroicons/react/20/solid'
import AriaModal from 'react-aria-modal'
import {Transition} from 'react-transition-group'
import {noop} from '../../utils/noop'
import Text from '../Text'

import {generateTrigger} from './helpers'
import type {ModalProps, OptionalChildren} from './types'

const transitions = {
  entering: `opacity-0`,
  entered: `opacity-1`,
  exiting: `opacity-0`,
}

const maxWidths = {
  sm: `max-w-sm`,
  md: `max-w-lg`,
  lg: `max-w-xl`,
  xl: `max-w-2xl`,
}

const duration = 200

const Modal = ({
  children,
  trigger,
  accessibleTitle,
  onExit = noop,
  onEnter = noop,
  maxWidth = `sm`,
  controls,
}: ModalProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const timer = useRef(null)

  const maxWidthClassName = maxWidths[maxWidth] || maxWidths.sm

  const open = (): void => {
    onEnter()
    setIsOpen(true)
    setIsVisible(true)
  }

  const close = (): void => {
    setIsVisible(false)
    window.clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      setIsOpen(false)
      onExit()
      controls?.onClose()
    }, duration)
  }

  const externalIsOpen = controls?.isOpen || false

  const triggerWithOpen = generateTrigger(trigger, open)

  return (
    <>
      {triggerWithOpen}
      <Transition timeout={duration} in={isVisible || externalIsOpen}>
        {(transitionState: string): ReactNode =>
          (isOpen || externalIsOpen) && (
            <AriaModal
              verticallyCenter
              titleText={accessibleTitle || `Modal`}
              onExit={close}
              focusDialog={true}
              getApplicationNode={(): HTMLElement =>
                document.getElementById(`root`)
              }
              includeDefaultStyles={false}
              underlayClass={`
                items-center
                bg-[--cloudinary-overlay]
                cursor-pointer
                flex
                h-full
                justify-center
                overflow-x-hidden
                overflow-y-auto
                inset-0
                fixed
                duration-400
                w-full
                z-50
                ${transitions[transitionState] || `opacity-0`}
              `}
              dialogClass={`
                focus:outline-none
                cursor-default
                inline-block
                max-w-full
              `}
            >
              <div
                className={`
                  ${maxWidthClassName}
                  bg-ui-100
                  rounded-500
                  text-ui-600
                  dark:text-ui-500
                  relative
                  m-auto
                  max-h-screen
                  w-screen
                  overflow-y-auto
                `}
              >
                <button
                  id="close-button"
                  onClick={close}
                  aria-label="Close modal"
                  className={`
                    hocus:bg-ui-200
                    hocus:text-ui-900
                    text-ui-500
                    absolute
                    right-0
                    top-0
                    z-10
                    mr-4
                    mt-5
                    rounded-full
                    p-1.5
                  `}
                >
                  <CloseIcon width="18" height="18" />
                </button>
                {typeof children === `function` ? children({close}) : children}
              </div>
            </AriaModal>
          )
        }
      </Transition>
    </>
  )
}

const ModalHeader = ({children}: OptionalChildren) => {
  return (
    <div
      className={`
        border-b-ui-200
        dark:border-b-ui-50
        border-b
        py-6
        pl-6
        pr-12
      `}
    >
      <Text tag="h2" variant="headingLarge">
        {children}
      </Text>
    </div>
  )
}

const ModalBody = ({children}: OptionalChildren) => {
  return (
    <div
      className={`
        p-6
      `}
    >
      {children}
    </div>
  )
}

const ModalFooter = ({children}: OptionalChildren) => {
  return (
    <footer
      className={`
        px-6
        pb-6
      `}
    >
      {children}
    </footer>
  )
}

Modal.Header = ModalHeader
Modal.Body = ModalBody
Modal.Footer = ModalFooter

export default Modal
export {Modal}
