import {CSSProperties, Children} from 'react'
import {MenuListProps, components} from 'react-select'
import VirtualList, {ItemStyle} from 'react-tiny-virtual-list'
import {cn} from '../../../utils/tailwind'
import {BaseSelectProps, VirtualProps} from '../types'

const baseClassName = `
  rounded-400
`

const OurMenuList = ({className = ``, ...props}: MenuListProps) => {
  return (
    <components.MenuList {...props} className={cn(baseClassName, className)} />
  )
}

export const OurVirtualMenuList = ({
  children,
  className = ``,
  itemHeight: itemHeightProp = 66,
  maxMenuHeight: maxMenuHeightProp = 300,
  selectProps,
}: MenuListProps & VirtualProps & {selectProps: BaseSelectProps}) => {
  const options = Children.toArray(children)
  const totalOptions = options.length

  const maxMenuHeight =
    (typeof selectProps.virtualList === `object` &&
      selectProps.virtualList?.maxMenuHeight) ||
    maxMenuHeightProp

  const itemHeight =
    (typeof selectProps.virtualList === `object` &&
      selectProps.virtualList?.itemHeight) ||
    itemHeightProp

  const height =
    totalOptions * itemHeight < maxMenuHeight
      ? totalOptions * itemHeight
      : maxMenuHeight

  return (
    <VirtualList
      width={`100%`}
      height={height}
      itemCount={totalOptions}
      renderItem={({index, style}: {index: number; style: ItemStyle}) => {
        return (
          <div key={`virtual-option-${index}`} style={style as CSSProperties}>
            {options[index]}
          </div>
        )
      }}
      className={cn(baseClassName, className)}
      itemSize={itemHeight}
    />
  )
}

export default OurMenuList
