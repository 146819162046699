import {Field} from 'formik'

interface Props {
  items: {
    name: string
    value: string | number
    id: string | number
    label: string
  }[]
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void
  selectedItem?: string | number
}

export const activeClassName = `
  bg-ui-0
  text-blue-600
  border-blue-600
  font-semibold
  dark:text-blue-400
  dark:bg-ui-50
  dark:border-blue-500
  relative
  z-10
`

const ButtonToggleGroup = ({items, onChange, selectedItem}: Props) => {
  if (!items || items?.length === 0) return null
  return (
    <div
      role="group"
      aria-labelledby="unit-radio-group"
      className={`
        my-0.5
        inline-flex
        font-medium
      `}
    >
      {items.map((item, i) => {
        const isFirst = i === 0
        const isLast = i === items.length - 1
        const isSelected =
          selectedItem === item.value ||
          `${selectedItem}` === `${item.value}` ||
          selectedItem === item.id
        return (
          <div key={`${item.name}-${item.value}-${i}`}>
            <Field
              onChange={onChange}
              type="radio"
              id={`${item.id}`}
              name={item.name}
              value={item.value}
              className={`
                sr-only
              `}
            />
            <label
              htmlFor={`${item.id}`}
              className={`
                leading-3.5
                transition-default
                -mr-px
                cursor-pointer
                border
                px-2
                py-1
                text-xs
                uppercase
                duration-150
                ease-in-out
                ${
                  isSelected
                    ? activeClassName
                    : `
                  bg-ui-50
                  dark:bg-ui-200
                  dark:hover:bg-ui-100
                  hover:bg-ui-100
                  text-ui-600
                  dark:text-ui-400
                `
                }
                ${isFirst && `rounded-l`}
                ${isLast && `rounded-r`}
              `}
            >
              {item.label}
            </label>
          </div>
        )
      })}
    </div>
  )
}

export default ButtonToggleGroup
