import {Unit} from '../units/types'
import {exportDefaults as exportDefaultsObj} from './exportDefaults'

const exportDefaults = `print`

export const paper = {
  iso: {
    a: {
      a0: {
        sizes: {
          portrait: {
            label: `A0 (portrait)`,
            width: 841,
            height: 1189,
            exportDefaults,
          },
          landscape: {
            label: `A0 (landscape)`,
            width: 1189,
            height: 841,
            exportDefaults,
          },
        },
      },
      a1: {
        sizes: {
          portrait: {
            label: `A1 (portrait)`,
            width: 594,
            height: 841,
            exportDefaults,
          },
          landscape: {
            label: `A1 (landscape)`,
            width: 841,
            height: 594,
            exportDefaults,
          },
        },
      },
      a2: {
        sizes: {
          portrait: {
            label: `A2 (portrait)`,
            width: 420,
            height: 594,
            exportDefaults,
          },
          landscape: {
            label: `A2 (landscape)`,
            width: 594,
            height: 420,
            exportDefaults,
          },
        },
      },
      a3: {
        sizes: {
          portrait: {
            label: `A3 (portrait)`,
            width: 297,
            height: 420,
            exportDefaults,
          },
          landscape: {
            label: `A3 (landscape)`,
            width: 420,
            height: 297,
            exportDefaults,
          },
        },
      },
      a4: {
        sizes: {
          portrait: {
            label: `A4 (portrait)`,
            width: 210,
            height: 297,
            exportDefaults,
          },
          landscape: {
            label: `A4 (landscape)`,
            width: 297,
            height: 210,
            exportDefaults,
          },
        },
      },
      a5: {
        sizes: {
          portrait: {
            label: `A5 (portrait)`,
            width: 148,
            height: 210,
            exportDefaults,
          },
          landscape: {
            label: `A5 (landscape)`,
            width: 210,
            height: 148,
            exportDefaults,
          },
        },
      },
      a6: {
        sizes: {
          portrait: {
            label: `A6 (portrait)`,
            width: 105,
            height: 148,
            exportDefaults,
          },
          landscape: {
            label: `A6 (landscape)`,
            width: 148,
            height: 105,
            exportDefaults,
          },
        },
      },
      a7: {
        sizes: {
          portrait: {
            label: `A7 (portrait)`,
            width: 74,
            height: 105,
            exportDefaults,
          },
          landscape: {
            label: `A7 (landscape)`,
            width: 105,
            height: 74,
            exportDefaults,
          },
        },
      },
      a8: {
        sizes: {
          portrait: {
            label: `A8 (portrait)`,
            width: 52,
            height: 74,
            exportDefaults,
          },
          landscape: {
            label: `A8 (landscape)`,
            width: 74,
            height: 52,
            exportDefaults,
          },
        },
      },
    },
    b: {
      b4: {
        sizes: {
          portrait: {
            label: `B4 (portrait)`,
            width: 0,
            height: 0,
            exportDefaults,
          },
          landscape: {
            label: `B4 (landscape)`,
            width: 0,
            height: 0,
            exportDefaults,
          },
        },
      },
      b5: {
        sizes: {
          portrait: {
            label: `B5 (portrait)`,
            width: 182,
            height: 257,
            exportDefaults,
          },
          landscape: {
            label: `B5 (landscape)`,
            width: 257,
            height: 182,
            exportDefaults,
          },
        },
      },
    },
    // c: []
    d: {
      dl: {
        sizes: {
          portrait: {
            label: `DL (portrait)`,
            width: 100,
            height: 210,
            exportDefaults,
          },
          landscape: {
            label: `DL (landscape)`,
            width: 210,
            height: 100,
            exportDefaults,
          },
        },
      },
    },
  },
  us: {
    letter: {
      sizes: {
        portrait: {
          label: `US Letter (portrait)`,
          width: 8.5,
          height: 11,
          ...exportDefaultsObj[`print`],
          unit: Unit.IN,
        },
        landscape: {
          label: `US Letter (landscape)`,
          width: 11,
          height: 8.5,
          ...exportDefaultsObj[`print`],
          unit: Unit.IN,
        },
      },
    },

    // legal: [],
    // tabloid: [],
  },
} as const
