import {FETCH_CATEGORIES} from './queries'

export const addCategoryUpdater = () => {
  return (cache, {data}) => {
    const cachedData = cache.readQuery({
      query: FETCH_CATEGORIES,
    })

    if (!cachedData || !data) return

    cache.writeQuery({
      query: FETCH_CATEGORIES,
      data: {
        categories: [...cachedData.categories, data.createCategory],
      },
    })
  }
}

export const deleteCategoryUpdater = () => {
  return (cache, {data}) => {
    const cachedData = cache.readQuery({query: FETCH_CATEGORIES})

    if (!cachedData || !data) return

    const categories = cachedData.categories.filter(
      ({id}) => id !== data.deleteCategory.id
    )

    cache.writeQuery({
      query: FETCH_CATEGORIES,
      data: {categories},
    })
  }
}
