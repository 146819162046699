import type {NestedSchemaField} from '@myadbox/nebula-service-api'

export const getSortedFields = (schemas: NestedSchemaField[]) => {
  if (!schemas) return []

  const headers = Object.values(schemas).sort((a, b) => {
    return Number(a.options?.[`order`] || 0) - Number(b.options?.[`order`] || 0)
  })

  return headers
}
