import {FC, FormEvent} from 'react'
import {MagnifyingGlassIcon} from '@heroicons/react/24/outline'
import {useTranslation} from 'react-i18next'

export interface SearchFieldProps {
  value?: string
  changeHandler?(
    event: FormEvent<HTMLInputElement> & {target: {value: string}}
  ): void
  labelText?: string
  id: string
  disabled?: boolean
  title?: string
  defaultValue?: string
}

const SearchFieldMain: FC<SearchFieldProps> = ({
  changeHandler,
  value,
  labelText,
  id,
  disabled,
  defaultValue,
}) => {
  const {t} = useTranslation(`layout`)

  const label = labelText || t`search`
  return (
    <div
      className={`
        flex
        w-full
        md:ml-0
        ${disabled ? `pointer-events-none opacity-80` : ``}
      `}
    >
      <label
        htmlFor={id}
        className={`
          focus-within:text-ui-600
          text-ui-400
          relative
          flex
          w-full
        `}
      >
        <span className={`sr-only`}>{label}</span>

        <div
          className={`
            pointer-events-none
            flex
            items-center
          `}
        >
          <MagnifyingGlassIcon
            width={24}
            className={`
              ml-3
              mr-2
              h-6
              w-6
              md:ml-7
            `}
          />
        </div>
        <input
          id={id}
          autoComplete="off"
          className={`
            placeholder:text-ui-500
            focus:placeholder:text-ui-400
            text-ui-900
            block
            h-full
            w-full
            bg-transparent
            py-2
            focus:outline-none
            md:text-sm
          `}
          placeholder={label}
          disabled={disabled}
          type="search"
          onChange={changeHandler}
          value={value}
          defaultValue={defaultValue}
        />
      </label>
    </div>
  )
}

export default SearchFieldMain
