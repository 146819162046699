import {Annotation} from '../comments/types'

export const isAnnotationEqual = (a: Annotation, b: Annotation) => {
  if (!a || !b || !a.page || !b.page) return false

  return (
    a.coordinates.x === b.coordinates.x &&
    a.coordinates.y === b.coordinates.y &&
    a.page === b.page &&
    a.version === b.version
  )
}
