import {Avatar, Status, Table} from '@myadbox/gatsby-theme-nebula'
import {Team, User} from '@myadbox/nebula-service-api'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {ReactNode} from 'react'
import {tableHelpers} from '../../../../utils/tableHelpers'
import DeleteUserModal from '../DeleteUserModal'
import EditUserModal from '../EditUserModal'
import UserDatasetsList from '../UserDatasetsList/UserDatasetsList'
import UserTeamsModal from '../UserTeamsModal/UserTeamsModal'
import {getStatusText, statusToIntent} from '../helpers'

type UserTableProps = {
  fetchMoreLoading: boolean
  searchUsersLoading: boolean
  profiles: User[]
  userPoolId: string
  rowRef: React.LegacyRef<HTMLTableRowElement>
  keywords: string
  teamsByUserIdMap: Record<string, Team[]>
}

const UserTable = (props: UserTableProps) => {
  const {t} = useTranslation()
  const {
    fetchMoreLoading,
    searchUsersLoading,
    profiles,
    teamsByUserIdMap,
    userPoolId,
    rowRef,
    keywords,
  } = props

  return (
    <Table contrast data-testid="users">
      <Table.Head>
        <Table.Row>
          <Table.HeadCell>{t`settings.users.table.name`}</Table.HeadCell>
          <Table.HeadCell>{t`settings.users.table.email`}</Table.HeadCell>
          <Table.HeadCell>{t`settings.users.table.datasets`}</Table.HeadCell>
          <Table.HeadCell>{t`settings.users.table.teams`}</Table.HeadCell>
          <Table.HeadCell>{t`settings.users.table.status`}</Table.HeadCell>
          <Table.HeadCell>{t`settings.users.table.role`}</Table.HeadCell>
          <Table.HeadCell
            style={{width: tableHelpers.actionsWidth}}
          >{t`settings.users.table.actions`}</Table.HeadCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {!fetchMoreLoading &&
          !searchUsersLoading &&
          profiles.map(
            ({
              id,
              userId,
              avatar,
              fullName,
              email,
              status,
              sso,
              isAdmin,
              datasetIds = [],
              datasets = [],
            }: User): ReactNode => {
              const teams = teamsByUserIdMap[userId] || []
              return (
                <Table.Row key={id}>
                  <Table.Cell verticalAlign="middle">
                    <div
                      className={`
                              relative
                              pl-10
                            `}
                    >
                      <div
                        className={`
                              absolute
                              left-0
                              top-[50%]
                              -translate-y-[50%]
                            `}
                      >
                        <Avatar src={avatar} title={fullName} />
                      </div>
                      {fullName}
                    </div>
                  </Table.Cell>
                  <Table.Cell verticalAlign="middle">{email}</Table.Cell>
                  <Table.Cell verticalAlign="middle">
                    <UserDatasetsList datasets={datasets} fullName={fullName} />
                  </Table.Cell>
                  <Table.Cell verticalAlign="middle">
                    <UserTeamsModal userName={fullName} teams={teams} />
                  </Table.Cell>
                  <Table.Cell verticalAlign="middle">
                    <Status intent={statusToIntent[status]}>
                      {getStatusText(t, status)}
                    </Status>
                  </Table.Cell>
                  <Table.Cell verticalAlign="middle">
                    {isAdmin
                      ? t`settings.users.table.admin`
                      : t`settings.users.table.member`}
                  </Table.Cell>
                  <Table.Cell verticalAlign="middle">
                    <div
                      className={`
                        text-ui-600
                        dark:text-ui-500
                        -ml-3
                      `}
                    >
                      <EditUserModal
                        datasetIds={datasetIds}
                        datasets={datasets}
                        disabled={status === `DISABLED`}
                        fullName={fullName}
                        isAdmin={isAdmin}
                        profileId={id}
                        sso={sso}
                        userId={userId}
                        userPoolId={userPoolId}
                      />
                      <span className="text-ui-400 dark:text-ui-300">|</span>
                      <DeleteUserModal
                        avatar={avatar}
                        userId={userId}
                        profileId={id}
                        fullName={fullName}
                        userPoolId={userPoolId}
                        keywords={keywords}
                      />
                    </div>
                  </Table.Cell>
                </Table.Row>
              )
            }
          )}
        <tr ref={rowRef}></tr>
      </Table.Body>
    </Table>
  )
}

export default UserTable
