import {gql} from '@apollo/client'
import {USER_INFO, USER_DATASETS, USER_SETTINGS} from './fragments'

export const CREATE_USER_PROFILE = gql`
  ${USER_INFO}
  ${USER_DATASETS}
  mutation CreateUserProfile($params: CreateProfileParams!)
  @api(name: "gateway") {
    createUserProfile(params: $params) {
      id
      ...UserInfo
      ...UserDatasets
    }
  }
`

export const DISABLE_USER_PROFILE = gql`
  ${USER_INFO}
  mutation DisableUserProfile($params: DisableProfileParams!)
  @api(name: "profiles") {
    disableUserProfile(params: $params) {
      id
      ...UserInfo
    }
  }
`

export const UPDATE_USER_PROFILE = gql`
  ${USER_INFO}
  ${USER_SETTINGS}
  mutation UpdateUserProfile($profileId: ID!, $params: UpdateProfileParams!)
  @api(name: "profiles") {
    updateUserProfile(profileId: $profileId, params: $params) {
      id
      ...UserInfo
      ...UserSettings
    }
  }
`

export const BULK_UPDATE_PROFILES = gql`
  mutation BulkUpdateProfiles($params: [BulkUpdateProfileParams!]!)
  @api(name: "profiles") {
    bulkUpdateProfiles(params: $params) {
      id
    }
  }
`

export const UPDATE_AVATAR = gql`
  ${USER_INFO}
  mutation UpdateAvatar(
    $profileId: ID!
    $avatarCloudinaryId: String!
    $avatar: String!
  ) @api(name: "profiles") {
    updateAvatar(
      profileId: $profileId
      avatarCloudinaryId: $avatarCloudinaryId
      avatar: $avatar
    ) {
      id
      ...UserInfo
    }
  }
`

export const SET_STATUS = gql`
  ${USER_INFO}
  mutation SetStatus(
    $profileId: ID!
    $amzCognitoPoolId: String!
    $status: UserStates!
  ) @api(name: "profiles") {
    setStatus(
      profileId: $profileId
      amzCognitoPoolId: $amzCognitoPoolId
      status: $status
    ) {
      id
      ...UserInfo
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation ResetPassword($userId: String!, $amzCognitoPoolId: String!)
  @api(name: "profiles") {
    resetPassword(userId: $userId, amzCognitoPoolId: $amzCognitoPoolId) {
      success
    }
  }
`

export const PROMOTE_TO_ADMIN = gql`
  ${USER_INFO}
  mutation PromoteUsertoAdmin($profileId: ID!) @api(name: "profiles") {
    promoteUsertoAdmin(profileId: $profileId) {
      id
      ...UserInfo
    }
  }
`

export const DEMOTE_TO_USER = gql`
  ${USER_INFO}
  mutation DemoteAdmintoUser($profileId: ID!) @api(name: "profiles") {
    demoteAdmintoUser(profileId: $profileId) {
      id
      ...UserInfo
    }
  }
`

export const VERIFY_EMAIL = gql`
  ${USER_INFO}
  mutation VerifyEmail($accessToken: String!, $code: String!)
  @api(name: "profiles") {
    verifyEmail(accessToken: $accessToken, code: $code) {
      id
      ...UserInfo
    }
  }
`
