export const sectionPaddingY = `
  py-10
`

export const sectionPaddingLeft = `
  md:pl-8
  pl-4
`

export const sectionPaddingRight = `
  md:pr-8
  pr-4
`

export const sectionPadding = `
  ${sectionPaddingY}
  ${sectionPaddingLeft}
  ${sectionPaddingRight}
`

export const sectionSpacing = `
  grid
  content-start
  gap-2
`

export const sectionBleed = `
  ${sectionPadding}
  md:-mx-8
  -mx-4
  -my-10
`

export const buttonLinkLike = `
  hocus:underline
  inline-flex
  font-medium
`

export const input = `
  form-input
  bg-ui-0
  border
  rounded-400
  sm:text-sm
  sm:leading-5
  dark:bg-ui-200
  dark:border-ui-200
  block
  duration-150
  ease-in-out
  transition-default
  px-3
  py-2
  w-full
  focus:outline-none
  hocus:bg-ui-100
  hocus:text-ui-1000
  focus:shadow-outline
`

export const iconInteractionColours = `
  hocus:bg-ui-100
  hocus:text-ui-1000
`

export const iconButtonClassName = `
  ${iconInteractionColours}
  rounded-full
  text-ui-400
  p-1
  relative
`

export const mainSpacing = `
  ${sectionPadding}
  grid
  content-start
  gap-4
`
