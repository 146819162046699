import {HTMLAttributes, ReactElement, ReactNode} from 'react'
import {Text} from '../Text'

interface Props extends HTMLAttributes<HTMLElement> {
  heading: string | ReactNode
  description?: string | ReactElement | null
  leadIn?: boolean
  priority?: `1` | `2` | `3` | `4` | `5` | `6`
}

type HeadingTag = `h1` | `h2` | `h3` | `h4` | `h5` | `h6`

const HeadingGroup = ({
  heading,
  className = ``,
  description = ``,
  leadIn = false,
  priority = `2`,
  ...props
}: Props) => {
  const Tag = `h${priority}` as HeadingTag
  return heading || description ? (
    <div
      className={`
        tabular-nums
        ${className}
        ${
          leadIn
            ? `
                flex
                flex-wrap
                items-baseline
              `
            : ``
        }
      `}
      {...props}
    >
      {heading && (
        <Text
          tag={Tag}
          className={`
            mr-3
            truncate
          `}
          variant="heading"
        >
          {heading}
        </Text>
      )}
      {description && (
        <p
          role="doc-subtitle"
          className={`dark:text-ui-400 mt-1 min-w-0 max-w-xl text-xs leading-5`}
          style={{
            textWrap: `pretty`,
          }}
        >
          {description}
        </p>
      )}
    </div>
  ) : null
}

export default HeadingGroup

const HeadingSpan = ({children}: {children: ReactNode}) => (
  <span className={`text-ui dark:text-ui-400 ml-1 text-xs italic`}>
    {children}
  </span>
)

HeadingGroup.Span = HeadingSpan
