import {gql} from '@apollo/client'

export const USER_INFO = gql`
  fragment UserInfo on Profile {
    userId
    givenName
    familyName
    fullName
    email
    tempEmail
    avatar
    status
    sso
    isAdmin
  }
`

export const USER_DATASETS = gql`
  fragment UserDatasets on Profile {
    datasetIds
    datasets {
      id
      data
      nestedSchema {
        id
        title
        name
        descendants {
          title
          name
          type
        }
      }
    }
  }
`

export const USER_SETTINGS = gql`
  fragment UserSettings on Profile {
    language
    darkMode
    timezone
    notificationPreferences {
      templateShared
    }
  }
`
