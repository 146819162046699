import {ApolloQueryResult, FetchResult, MutationResult} from '@apollo/client'

export enum ApprovalStatus {
  APPROVED = `APPROVED`,
  DECLINED = `DECLINED`,
  PENDING = `PENDING`,
}

export enum ApproverType {
  TEAM = `TEAM`,
  USER = `USER`,
}

export enum RequesterType {
  TEAM = `TEAM`,
  USER = `USER`,
}

export enum ApproverActions {
  APPROVE = `Approve`,
  DECLINE = `Decline`,
  REVOKE = `Revoke`,
}

export enum ApprovalType {
  VIEWER_PROMOTION = `VIEWER_PROMOTION`,
  ASSET_SIGN_OFF = `ASSET_SIGN_OFF`,
}

export enum ApprovalRole {
  REQUESTER = `requester`,
  APPROVER = `approver`,
  ALL = `all`,
}
export interface Approver {
  id: string
  approverId: string // teamId or userId
  approverType: ApproverType
  approvalStatus: ApprovalStatus
  updatedAt: string
}

export interface Requester {
  requesterId: string // teamId or userId
  requesterType: RequesterType
}

export interface ApproverInput {
  approverId: string // teamId or userId
  approverType: ApproverType
}
export interface Approval {
  id: string
  approved: boolean
  approvers: Approver[]
  assetId: string
  requesterId: string
  requesterType: RequesterType
}

export interface RespondApprovalInput {
  approvalId: string
  approvalType: ApprovalType
  context: {
    assetUrl: string
  }
  teamIds?: string[]
}

export interface CreateApprovalRequestInput {
  approvers: ApproverInput[]
  assetId: string
  requester: Requester
}

export interface UseApprovalsOutput {
  approveApproval: ApprovalRequestMutator
  approveApprovalResponse: MutationResult<{approval: Approval}>
  declineApproval: ApprovalRequestMutator
  declineApprovalResponse: MutationResult<{approval: Approval}>
  fetchApprovals(assetIds: string[]): void
  fetchApprovalsResults: ApolloQueryResult<{approvals: Approval[]}>
  createApprovalRequest: CreateRequestApprovalMutator
  createApprovalRequestResponse: MutationResult<{
    createApproval: Approval
  }>
  revokeApproval: ApprovalRequestMutator
  revokeApprovalResponse: MutationResult<{approval: Approval}>
}

export type CreateRequestApprovalMutator = (
  input: CreateApprovalRequestInput
) => Promise<FetchResult<{approval: Approval}>>

export type ApprovalRequestMutator = (
  input: RespondApprovalInput
) => Promise<FetchResult<{approval: Approval}>>
