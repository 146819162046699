import {Billing} from '@myadbox/nebula-service-api'

export const getReadableDate = (unixTimestamp: number): string => {
  const milliseconds = unixTimestamp * 1000
  const dateObject = new Date(milliseconds)
  return dateObject.toLocaleString()
}

export const hasBillingSetup = (billing: Billing): boolean => {
  return !!(
    billing &&
    billing.price &&
    billing.currency &&
    billing.billingEmail
  )
}
