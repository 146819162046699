const DEFAULT_OPTIONS = {
  checkColumn: true,
  trim: true,
  noheader: false,
}

export const convertCsvToJson = async ({
  csv,
  converterOptions = {},
  headerTransformer,
}: {
  csv: string
  converterOptions?: Record<string, string>
  headerTransformer?: (header: string) => string
}) => {
  const result = {
    data: [],
    error: null,
  }

  try {
    const {default: converter} = await import(`csvtojson`)

    const options = {
      ...DEFAULT_OPTIONS,
      ...converterOptions,
    }

    const data = await converter(options)
      .fromString(csv)
      .preFileLine((fileLine, lineNumber) => {
        if (!headerTransformer) return fileLine

        return lineNumber === 0 ? headerTransformer(fileLine) : fileLine
      })

    if (data.length) {
      result.data = data
    } else {
      //TODO: Figure out translation in component
      result.error = `No data found in the .csv file`
    }
  } catch (e) {
    //TODO: Figure out translation in component
    result.error = `Invalid CSV`
  }

  return result
}
