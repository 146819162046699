export enum Unit {
  MM = `mm`,
  PX = `px`,
  IN = `in`,
}

export type UnitConverter = (
  val: number,
  fn?: (val: number | string) => number
) => number

export type UnitUnion = `${Unit}`
