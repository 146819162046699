import {FETCH_BRAND} from './queries'

export const createBrandUpdater = () => {
  return (cache, {data}) => {
    if (!data) return

    cache.writeQuery({
      query: FETCH_BRAND,
      data: {
        brand: data.updateBrand,
      },
    })
  }
}
