import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext'
import {useCallback, useEffect, useState} from 'react'
import Button from '../../Button'
import {
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  $getSelection,
  $isRangeSelection,
} from 'lexical'
import {$getNearestNodeOfType, mergeRegister} from '@lexical/utils'
import {MarkdownTextFormat} from '../types'
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode,
} from '@lexical/list'
import {BoldText, ItalicText, StrikethroughText, OrderedList} from '../../Icons'
import {ListBulletIcon} from '@heroicons/react/24/outline'

const LowPriority = 1

type Props = {
  allowedFormats: MarkdownTextFormat[]
}

export const Toolbar = ({allowedFormats}: Props) => {
  const [editor] = useLexicalComposerContext()
  const [blockType, setBlockType] = useState(`paragraph`)
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isStrikethrough, setIsStrikethrough] = useState(false)

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      // necessary for formatting and removing lists
      const anchorNode = selection.anchor.getNode()
      const element =
        anchorNode.getKey() === `root`
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow()
      const elementKey = element.getKey()
      const elementDOM = editor.getElementByKey(elementKey)
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode)
          const type = parentList ? parentList.getTag() : element.getTag()
          setBlockType(type)
        } else {
          setBlockType(element.getType())
        }
      }

      // update format active states
      setIsBold(selection.hasFormat(`bold`))
      setIsItalic(selection.hasFormat(`italic`))
      setIsStrikethrough(selection.hasFormat(`strikethrough`))
    }
  }, [editor])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({editorState}) => {
        editorState.read(() => {
          updateToolbar()
        })
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar()
          return false
        },
        LowPriority
      )
    )
  }, [editor, updateToolbar])

  return (
    <div
      data-testid="toolbar"
      className={`
        bg-ui-50
        mb-1
        flex
        items-center
        justify-start
        gap-2
        rounded
        p-1
      `}
    >
      {allowedFormats.includes(`bold`) && (
        <Button
          size="xs"
          slim
          variant="text"
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, `bold`)
          }}
          title="Format Bold"
          name="Format Bold"
          type="button"
        >
          <BoldText
            className={`
              ${isBold ? `text-blue-400` : ``}
              h-4
              w-4
            `}
          />
        </Button>
      )}
      {allowedFormats.includes(`italic`) && (
        <Button
          onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, `italic`)}
          size="xs"
          slim
          variant="text"
          title="Format Italics"
          type="button"
        >
          <ItalicText
            className={`
              ${isItalic ? `text-blue-400` : ``}
              h-4
              w-4
            `}
          />
        </Button>
      )}
      {allowedFormats.includes(`strikethrough`) && (
        <Button
          onClick={() =>
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, `strikethrough`)
          }
          size="xs"
          slim
          variant="text"
          aria-label="Format Strikethrough"
          type="button"
        >
          <StrikethroughText
            className={`
              ${isStrikethrough ? `text-blue-400` : ``}
              h-4
              w-4
            `}
          />
        </Button>
      )}
      {allowedFormats.includes(`ul`) && (
        <Button
          size="xs"
          slim
          variant="text"
          aria-label="Format Unordered List"
          name="Format Unordered List"
          type="button"
          onClick={() => {
            if (blockType !== `ul`) {
              editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, null)
            } else {
              editor.dispatchCommand(REMOVE_LIST_COMMAND, null)
            }
          }}
        >
          <ListBulletIcon
            className={`
              ${blockType === `ul` ? `text-blue-400` : ``}
              h-4
              w-4
            `}
          />
        </Button>
      )}
      {allowedFormats.includes(`ol`) && (
        <Button
          size="xs"
          slim
          variant="text"
          aria-label="Format Ordered List"
          name="Format Ordered List"
          type="button"
          onClick={() => {
            if (blockType !== `ol`) {
              editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, null)
            } else {
              editor.dispatchCommand(REMOVE_LIST_COMMAND, null)
            }
          }}
        >
          <OrderedList
            className={`
              ${blockType === `ol` ? `text-blue-400` : ``}
              h-4
              w-4
            `}
          />
        </Button>
      )}
    </div>
  )
}

export default Toolbar
