type Props = React.SVGAttributes<SVGElement> & {
  className?: string
  size?: number
}

const AnnotateIcon = ({size = 24, ...rest}: Props) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0001 1.75C5.89143 1.75 1.75006 5.89137 1.75006 11V20.25H11.0001C16.1087 20.25 20.2501 16.1086 20.2501 11C20.2501 5.89137 16.1087 1.75 11.0001 1.75ZM1.00006 21L0.250061 20.9999V11C0.250061 5.06294 5.063 0.25 11.0001 0.25C16.9371 0.25 21.7501 5.06294 21.7501 11C21.7501 16.9371 16.9371 21.75 11.0001 21.75H1.00014L1.00006 21ZM1.00006 21L1.00014 21.75H0.250061V20.9999L1.00006 21Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 6.25C11.4142 6.25 11.75 6.58579 11.75 7V10.9643H16C16.4142 10.9643 16.75 11.3001 16.75 11.7143C16.75 12.1285 16.4142 12.4643 16 12.4643H11.75V16C11.75 16.4142 11.4142 16.75 11 16.75C10.5858 16.75 10.25 16.4142 10.25 16V12.4643H6C5.58579 12.4643 5.25 12.1285 5.25 11.7143C5.25 11.3001 5.58579 10.9643 6 10.9643H10.25V7C10.25 6.58579 10.5858 6.25 11 6.25Z"
      fill="currentColor"
    />
  </svg>
)

export default AnnotateIcon
