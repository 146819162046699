import {ReactElement} from 'react'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {Modal, Button} from '@myadbox/gatsby-theme-nebula'

import {PlusIcon} from '@heroicons/react/20/solid'

import AddColumnForm from '../AddColumnForm/AddColumnForm'

interface Props {
  schemaId: string
  order: number
}

export const AddColumnModal = ({schemaId, order}: Props) => {
  const {t} = useTranslation()

  return (
    <Modal
      trigger={({open}): ReactElement => (
        <Button
          size="xs"
          slim
          variant="link"
          type="button"
          onClick={open}
          title={t(`settings.schemas.addColumn.triggerBtn`)}
        >
          <PlusIcon width={18} />
        </Button>
      )}
    >
      {({close}) => (
        <AddColumnForm schemaId={schemaId} order={order} close={close} />
      )}
    </Modal>
  )
}

export default AddColumnModal
