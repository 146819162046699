import {FetchResult, LazyQueryResult, MutationResult} from '@apollo/client'
import {
  AssetContent,
  AssetOutput,
  AudioCodec,
  ColorMode,
  Config,
  Fields,
  Format,
  MultiPage,
  MultiPageConfig,
  MultiPageValues,
  Quality,
  Unit,
  VideoCodec,
} from '@myadbox/nebula-template-utils'
import {AddAssetsInput} from '../assets/types'

export enum TemplateType {
  Html = `HTML`,
  Video = `VIDEO`,
  Remotion = `REMOTION`,
}

export interface VideoConfig extends Config {
  transformation?: object[]
}

/**
 * This is the type of the data returned by nebula-service-api and is used to derive the more specific template types used in Mimosa. This type should not be used beyond useTemplateInfo..
 */
export interface Template<C = Config, D = Record<string, unknown>> {
  id: string
  type: TemplateType
  publicId: string
  secureUrl: string
  thumbnailPublicId?: string
  pageThumbnailPublicIds?: MultiPage<string>
  templateUrl: string
  hostedUrl?: string
  remotionSiteUrl?: string
  configValues: C
  defaultValues: D
}

export interface OutputSettings {
  pxWidth?: number
  pxHeight?: number
  format: Format
  quality?: Quality
  pxSlug?: number
  pxBleed?: number
  hasBleed?: boolean
  cdnImages?: boolean
  colorMode?: ColorMode
  videoCodec?: VideoCodec
  audioCodec?: AudioCodec
  resolution?: number
}

export interface AssetDerivation {
  preset: string
  width: number
  height: number
  unit: Unit
  fields?: MultiPageValues
  templateUrl?: string
  secureUrl?: string
  outputSettings?: AssetOutput
}

export interface RenderInput {
  assetId: string
  templateUrl: string
  content: unknown
  options: OutputSettings
  derivation: AssetDerivation
  recordDownload: boolean
}

export interface MultiPageConfigExt extends MultiPageConfig {
  fields: MultiPage<Fields> & {
    deleted?: string[]
    references?: {
      [key: string]: string
    }
  }
}

export interface PackageTemplateInput {
  account: {
    id: string
    accountName: string
  }
  templateUrl: string
  configValues: MultiPageConfigExt
  defaultValues: MultiPageValues | AssetContent
  addToListing: boolean
}

export interface RenderTemplateResult {
  render: {
    publicId: string
    secureUrl: string
  }
}

export type RenderResponse = Promise<FetchResult<RenderTemplateResult>>
export type RenderTemplateMutator = (values: RenderInput) => RenderResponse

export type PackageTemplateResponse = Promise<FetchResult<{packageTemplate}>>
export type PackageTemplateMutator = (
  values: PackageTemplateInput
) => PackageTemplateResponse

export type GetAiSuggestionResponse = Promise<
  FetchResult<{getAiSuggestion: string}>
>
export type GetAiSuggestionMutator = (values: string) => GetAiSuggestionResponse

export interface UseTemplatesOutput {
  query(templateUrl: string): void
  queryResult: LazyQueryResult<{template: Template}, {}>
  render: RenderTemplateMutator
  renderResult: MutationResult<RenderTemplateResult>
  packageTemplate: PackageTemplateMutator
  packageTemplateResult: MutationResult<{packageTemplate: AddAssetsInput}>
  hostedUrl: string
  getAiSuggestion: GetAiSuggestionMutator
  getAiSuggestionResult: MutationResult<{getAiSuggestion: string}>
}
