import {gql} from '@apollo/client'
export const UPDATE_BILLING = gql`
  mutation UpdateBilling($billingInput: BillingInput!, $accountName: String)
  @api(name: "accounts") {
    updateBilling(billingInput: $billingInput, accountName: $accountName) {
      id
      billing {
        price
        currency
        billingEmail
        paymentTerm
        additionalInfo
        autoBilling
      }
    }
  }
`

export const UPDATE_AI_TAGS_CONFIG = gql`
  mutation UpdateAITagsConfig($aiTagsEnabled: Boolean!) @api(name: "accounts") {
    updateAITagsConfig(aiTagsEnabled: $aiTagsEnabled) {
      id
      configuration {
        aiTagsEnabled
      }
    }
  }
`

export const UPDATE_ACCOUNT_INTEGRATION = gql`
  mutation UpdateAccountIntegration(
    $accountIntegrationInput: AccountIntegrationInput!
  ) @api(name: "accounts") {
    updateAccountIntegration(
      accountIntegrationInput: $accountIntegrationInput
    ) {
      integrations {
        integrationId
        values
        enabled
      }
    }
  }
`

export const UPDATE_ACCOUNT_IMAGE_CONFIG = gql`
  mutation UpdateAccountImageConfig($imageConfigInput: ImageConfigInput!)
  @api(name: "accounts") {
    updateAccountImageConfig(imageConfigInput: $imageConfigInput) {
      id
      configuration {
        loginBackgroundUrl
        logoUrl
      }
    }
  }
`

export const UPDATE_ACCOUNT_BRAND_ASSET_CONFIG = gql`
  mutation UpdateAccountBrandAssetConfig(
    $brandAssetConfigInput: BrandAssetConfigInput!
  ) @api(name: "accounts") {
    updateAccountBrandAssetConfig(
      brandAssetConfigInput: $brandAssetConfigInput
    ) {
      id
      configuration {
        brandAssetId
      }
    }
  }
`

export const TOGGLE_WHITE_LABEL = gql`
  mutation ToggleWhiteLabel @api(name: "accounts") {
    toggleWhiteLabel {
      id
      configuration {
        isWhiteLabelled
      }
    }
  }
`
