import {gql} from '@apollo/client'
import {COMMENT_INFO, COMMENT_REPLY, COMMENT_ANNOTATION} from './fragments'

export const FETCH_COMMENTS = gql`
  ${COMMENT_INFO}
  ${COMMENT_REPLY}
  ${COMMENT_ANNOTATION}
  query FetchComments($entityId: String!, $entityType: EntityType!)
  @api(name: "comments") {
    fetchComments(entityId: $entityId, entityType: $entityType) {
      id
      ...CommentInfo
      annotation {
        ...CommentAnnotation
      }
      replies {
        id
        ...CommentReply
      }
    }
  }
`

export const FETCH_COMMENT_COUNT = gql`
  query FetchCommentCount($entityIds: [String]!, $entityType: EntityType!)
  @api(name: "comments") {
    fetchCommentCount(entityIds: $entityIds, entityType: $entityType) {
      entityId
      total
    }
  }
`
