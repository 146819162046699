import {Toast} from '@myadbox/gatsby-theme-nebula'
import {GatsbyBrowser} from 'gatsby'
import React from 'react'
import {
  SettingsContext,
  SettingsRoutePropsMap,
} from './src/views/settings/helpers'

const nullPropsMap = {
  current: {},
} as SettingsRoutePropsMap

export const wrapPageElement: GatsbyBrowser[`wrapPageElement`] = ({
  element,
}) => {
  return (
    <SettingsContext.Provider value={nullPropsMap}>
      <Toast.Container />
      {element}
    </SettingsContext.Provider>
  )
}
