import {useCallback} from 'react'
import {useLazyQuery, useMutation} from '@apollo/client'
import {FETCH_INTEGRATIONS} from '../../queries'
import {INTEGRATE} from '../../mutations'
import {IntegrationLogInput, UseIntegrationsOutput} from '../../types'

export const useIntegrations = (): UseIntegrationsOutput => {
  const [lazyFetchIntegrations, fetchIntegrationsResults] =
    useLazyQuery(FETCH_INTEGRATIONS)
  const fetchIntegrations = useCallback(() => {
    lazyFetchIntegrations()
  }, [lazyFetchIntegrations])

  const [doIntegrate, integrateResponse] = useMutation(INTEGRATE)
  const integrate = useCallback(
    (input: IntegrationLogInput) => {
      return doIntegrate({
        variables: {input},
      })
    },
    [doIntegrate]
  )

  return {
    fetchIntegrations,
    fetchIntegrationsResults,
    integrate,
    integrateResponse,
  }
}
