import {Modal} from '@myadbox/gatsby-theme-nebula'
import {
  Asset,
  ConnectedIntegration,
  Integration,
} from '@myadbox/nebula-service-api'
import {ActionGroup, Button, Text} from '@myadbox/stellar-ui'
import {useEffect, useReducer} from 'react'
import {useTranslation} from 'react-i18next'
import {
  DefaultIntegrationsConnect,
  DefaultIntegrationsSelect,
  useDefaultIntegrations,
  useSocialsExport,
} from '../../../index'
import withTranslationProvider from '../../locales/withTranslationProvider'
import {
  DefaultIntegrationsContext,
  IntegrationModalAction,
  IntegrationModalState,
} from './types'
import {DefaultIntegrationsEmbed} from '../DefaultIntegrationsEmbed'

export const integrationModalReducer = (
  prevState: IntegrationModalState,
  action: IntegrationModalAction
) => {
  if (action.type === `SET_CONTEXT`) {
    return {
      ...prevState,
      context: action.payload,
    }
  } else {
    throw new Error(
      `Unknown dispatch action type supplied to integrations modal reducer`
    )
  }
}

export const DefaultIntegrationsModal = ({assets}: {assets: Asset[]}) => {
  const {t} = useTranslation(`defaultIntegrations`)
  const {
    activeIntegrationType,
    defaultIntegrations,
    removeDefaultActiveIntegration,
  } = useDefaultIntegrations()
  const {connectedIntegrations} = useSocialsExport()
  const isOpen = !!activeIntegrationType

  const [state, dispatch] = useReducer(integrationModalReducer, {
    context: `CONNECT`,
  })

  const onClose = () => {
    dispatch({
      type: `SET_CONTEXT`,
      payload: `CONNECT`,
    })
    removeDefaultActiveIntegration()
  }

  const selectedIntegrationId = activeIntegrationType
    ? defaultIntegrations.find(
        (integration: Integration) => integration.name === activeIntegrationType
      )?.id
    : null

  const integration =
    connectedIntegrations.find(
      (integration: ConnectedIntegration) =>
        integration.integrationId === selectedIntegrationId
    ) ?? null

  useEffect(() => {
    if (activeIntegrationType === `Embed`) {
      dispatch({type: `SET_CONTEXT`, payload: `EMBED`})
      return
    }

    const expiryDate = integration
      ? new Date(integration.user.token.expiresAt).getTime()
      : Date.now() - 1000
    expiryDate <= Date.now()
      ? dispatch({type: `SET_CONTEXT`, payload: `CONNECT`})
      : dispatch({type: `SET_CONTEXT`, payload: `SELECT`})
  }, [activeIntegrationType, integration])

  const headerText = (context: DefaultIntegrationsContext) => {
    switch (context) {
      case `CONNECT`:
        return t(`heading.connect`, {
          name: activeIntegrationType ?? ``,
        })
      case `EMBED`:
        return t(`heading.embed`)
      case `SELECT`:
        return t(`heading.select`, {
          name: activeIntegrationType ?? ``,
        })
      case `SUCCESS`:
        return t(`heading.success`)
      case `ERROR`:
        return t(`heading.error`)
    }
  }

  const renderModalContent = (context: DefaultIntegrationsContext) => {
    switch (context) {
      case `CONNECT`:
        return (
          <DefaultIntegrationsConnect
            handleCancel={onClose}
            dispatch={dispatch}
            integration={integration}
          />
        )
      case `EMBED`:
        return (
          <DefaultIntegrationsEmbed assets={assets} handleCancel={onClose} />
        )
      case `SELECT`:
        return (
          <DefaultIntegrationsSelect
            handleCancel={onClose}
            integration={integration}
            assets={assets}
            dispatch={dispatch}
          />
        )
      case `SUCCESS`:
        return (
          <div className="grid grid-flow-row gap-4">
            <Text variant="body">
              {t(`success.successMessage`, {
                name: integration ? activeIntegrationType : ``,
                page: integration ? activeIntegrationType : ``,
              })}
            </Text>
            <div className="text-right">
              <Button variant="primary" type="button" onClick={onClose}>
                {t(`success.ok`)}
              </Button>
            </div>
          </div>
        )
      case `ERROR`:
        return (
          <div>
            <Text>
              {t(`defaultExportModal.errorMessage`, {
                integration: activeIntegrationType,
              })}
            </Text>
            <ActionGroup>
              <Button
                variant="secondary"
                className="mt-4"
                type="button"
                onClick={() =>
                  dispatch({
                    type: `SET_CONTEXT`,
                    payload: integration ? `SELECT` : `CONNECT`,
                  })
                }
              >
                {t(`defaultExportModal.back`)}
              </Button>
            </ActionGroup>
          </div>
        )
    }
  }

  return (
    <Modal
      controls={{isOpen, onClose}}
      maxWidth="md"
      id="default-integrations-modal"
    >
      <Modal.Header>{headerText(state.context)}</Modal.Header>
      <Modal.Body>{renderModalContent(state.context)}</Modal.Body>
    </Modal>
  )
}

export default withTranslationProvider(DefaultIntegrationsModal)
