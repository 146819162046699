import {Image} from '@myadbox/gatsby-theme-nebula'

import {
  FacebookIcon,
  GoogleSheetsIcon,
  InstagramIcon,
} from '@myadbox/stellar-ui'

// Logo Renderer
interface IntegrationLogo {
  name: string
  logo?: string
}

interface Icon {
  name: string
  icon: JSX.Element
}

export const renderLogo = ({
  name: integrationName,
  logo: logoUrl,
}: IntegrationLogo): JSX.Element => {
  const icons: Icon[] = [
    {
      name: `facebook`,
      icon: <FacebookIcon size={40} />,
    },
    {
      name: `instagram`,
      icon: <InstagramIcon size={40} />,
    },
    {
      name: `google sheets`,
      icon: <GoogleSheetsIcon size={40} />,
    },
  ]

  const icon = icons.find(i => integrationName?.toLowerCase().includes(i.name))
  if (icon) {
    return icon.icon
  }

  return <Image src={logoUrl} />
}
export const GoogleSheetName = `Google Sheets`

export const badgeNameTranslation = {
  SOCIAL: `settings.integrations.badge.social`,
  PRINTER: `settings.integrations.badge.printer`,
  SESIMI_SUITE: `settings.integrations.badge.sesimiSuite`,
  SPREADSHEET: `settings.integrations.badge.spreadsheet`,
}

export const paramsToObject = (query: string): {[key: string]: string} => {
  const regex = /^[?&]?([\w-]+=[^&]*)?(?:&[\w-]+=[^&]*)*$/
  const isValid = regex.test(query)
  if (!isValid) return {}
  try {
    const params = new URLSearchParams(query)
    const obj: {[key: string]: string} = {}
    for (const [key, value] of params) {
      obj[key] = value
    }
    return obj
  } catch (error) {
    return {}
  }
}
