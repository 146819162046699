import {Text} from '@myadbox/stellar-ui'
import type {ReactNode} from 'react'

type Props = {
  headingText: string
  headingTag?: Parameters<typeof Text>[0][`tag`]
  tag?: `div` | `section` | `article` | `aside` | `fieldset`
  children: ReactNode
}

const LayoutFormChildrenWithHeading = ({
  headingText,
  headingTag = `h3`,
  tag: Tag = `div`,
  children,
}: Props) => {
  return (
    <Tag className={`grid gap-4`}>
      <Text tag={headingTag} variant="caption">
        {headingText}
      </Text>
      <div className={`grid gap-8`}>{children}</div>
    </Tag>
  )
}

export default LayoutFormChildrenWithHeading
