import {create} from 'zustand'

export type ContentStateEvent = `default` | `error`

interface GoogleModalStore {
  isModalOpen: boolean
  integrationId: string
  modalState: ContentStateEvent
  setModalState: (modal: ContentStateEvent) => void
  setIntegrationsId: (id: string) => void
  setModalOpen: () => void
}

const useGoogleSheetsModalStore = create<GoogleModalStore>()(set => ({
  isModalOpen: false,
  integrationId: ``,
  modalState: `default`,
  setModalState: (modal: ContentStateEvent) => set({modalState: modal}),
  setIntegrationsId: (id: string) => set({integrationId: id}),
  setModalOpen: () =>
    set(state => ({isModalOpen: !state.isModalOpen, modalState: `default`})),
}))

export default useGoogleSheetsModalStore
