import {ListItemNode, ListNode} from '@lexical/list'
import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  TRANSFORMERS,
} from '@lexical/markdown'
import {OverflowNode} from '@lexical/overflow'
import {CharacterLimitPlugin} from '@lexical/react/LexicalCharacterLimitPlugin'
import {LexicalComposer} from '@lexical/react/LexicalComposer'
import {ContentEditable} from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin'
import {ListPlugin} from '@lexical/react/LexicalListPlugin'
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin'
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin'
import {HeadingNode} from '@lexical/rich-text'
import {MaxLengthPlugin} from '@myadbox/maxlength-lexical-plugin'
import {useField} from 'formik'
import {useEffect, useState} from 'react'
import FormItem from '../FormItem/FormItem'
import Toolbar from './Toolbar'
import MarkdownStyles from './markdownStyles'
import {MarkdownProps} from './types'

const Placeholder = () => {
  return (
    <div className="text-gray-70 pointer-events-none absolute left-2 top-3 inline-block select-none overflow-hidden overflow-ellipsis font-medium text-gray-500">
      Enter some rich text...
    </div>
  )
}

/**
 * HeadingNode is added here just to stop the editor from exploding
 * when the content has `#` in it.
 * Currently, the Markdown editor does not support headings.
 */
const nodes = [ListNode, ListItemNode, HeadingNode, OverflowNode]

const Markdown = ({
  onChange,
  id,
  name,
  allowedFormats = [`bold`, `italic`, `strikethrough`, `ul`, `ol`],
  label,
  details,
  className,
  maxLength,
}: MarkdownProps) => {
  const [field, , helper] = useField(name)
  const {value} = field
  const [editorKey, setEditorKey] = useState(id)

  useEffect(() => {
    setEditorKey(id)
  }, [id])

  const onEditorChange = editor => {
    editor.read(() => {
      const markdown = $convertToMarkdownString(TRANSFORMERS)
      if (maxLength && markdown.length > maxLength) {
        return
      }
      onChange ? onChange({value: markdown}) : helper.setValue(markdown)
    })
  }

  return (
    <FormItem
      label={<FormItem.Label>{label}</FormItem.Label>}
      details={details}
    >
      <LexicalComposer
        key={editorKey}
        initialConfig={{
          theme: MarkdownStyles,
          namespace: ``,
          onError(error) {
            throw error
          },
          nodes,
          ...(value && {
            editorState: () => $convertFromMarkdownString(value, TRANSFORMERS),
          }),
        }}
      >
        <div
          className={`
          relative
          max-w-lg
          text-left
          text-sm
        `}
        >
          <Toolbar allowedFormats={allowedFormats} />
          <div className={`relative`}>
            <RichTextPlugin
              contentEditable={
                <ContentEditable
                  maxLength={maxLength}
                  id={name}
                  className={`
                    ${className}
                    min-h-150
                    bg-ui-200
                    text-ui-900
                    transition-default
                    focus:shadow-outline
                    relative
                    resize-none
                    rounded
                    px-2
                    py-3
                    tabular-nums
                    outline-none
                    duration-150
                    ease-in-out
                    focus:outline-none
                  `}
                />
              }
              placeholder={<Placeholder />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <ListPlugin />
            {Boolean(maxLength) && (
              <>
                <CharacterLimitPlugin
                  charset={`UTF-16`}
                  maxLength={maxLength}
                />
                <MaxLengthPlugin maxLength={maxLength} />
              </>
            )}
            <OnChangePlugin onChange={onEditorChange} />
          </div>
        </div>
        <input defaultValue={value} id={id} name={name} className="hidden" />
      </LexicalComposer>
    </FormItem>
  )
}

export default Markdown
