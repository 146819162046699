import {useQuery} from '@apollo/client'
import {useLocation} from '@reach/router'
import {useState} from 'react'
import {FETCH_LOGIN_ACCOUNT} from '../../queries'
import {Account, UseLoginAccountOutput} from '../../types'

export const useLoginAccount = (): UseLoginAccountOutput => {
  const {hostname} = useLocation()
  const [accountName, setAccountName] = useState<string>(
    hostname?.split(`.`)[0]
  )

  const {data} = useQuery<{account: Account}>(FETCH_LOGIN_ACCOUNT, {
    variables: {accountName},
    skip: !accountName,
  })

  if (data?.account && data.account?.accountName !== accountName) {
    setAccountName(data.account?.accountName)
  }

  return {
    account: data?.account,
    accountName,
  }
}
