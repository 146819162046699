import {useLazyQuery, useMutation} from '@apollo/client'
import {useCallback} from 'react'
import {
  CREATE_COMMENT,
  DELETE_COMMENT,
  DELETE_REPLY,
  REPLY_TO_COMMENT,
} from '../../mutations'
import {FETCH_COMMENTS, FETCH_COMMENT_COUNT} from '../../queries'
import {
  Annotation,
  CreateCommentMutator,
  DeleteCommentMutator,
  DeleteReplyMutator,
  EntityType,
  ReplyToCommentMutator,
  UseCommentsOutput,
} from '../../types'
import {countUpdater, createUpdater, replyUpdater} from '../../updaters'

/**
 * Note:
 * cache updaters for delete methods have been avoided
 * as the custom merge function to be written for them
 * were too complicated.
 */
export const useComments = (): UseCommentsOutput => {
  const [lazyFetchComments, fetchCommentsResult] = useLazyQuery(FETCH_COMMENTS)
  const fetchComments = useCallback(
    (entityId: string, entityType: EntityType) =>
      lazyFetchComments({variables: {entityId, entityType}}),
    [lazyFetchComments]
  )

  const [doCreateComment, createCommentResponse] = useMutation(CREATE_COMMENT)
  const [doDeleteComment, deleteCommentResponse] = useMutation(DELETE_COMMENT)
  const [doReplyToComment, replyToCommentResponse] =
    useMutation(REPLY_TO_COMMENT)
  const [doDeleteReply, deleteReplyResponse] = useMutation(DELETE_REPLY)

  const createComment: CreateCommentMutator = useCallback(
    (
      entityId: string,
      entityType: EntityType,
      body: string,
      entityIds?: string[],
      annotation?: Annotation
    ) => {
      return doCreateComment({
        variables: {entityId, entityType, body, annotation: annotation ?? null},
        update: createUpdater(entityId, entityType, entityIds),
      })
    },
    [doCreateComment]
  )

  const deleteComment: DeleteCommentMutator = useCallback(
    (entityId, entityIds, entityType, id, replyCount) => {
      // count changes negatively (replies + comment)
      const count = (replyCount + 1) * -1
      return doDeleteComment({
        variables: {id},
        refetchQueries: [
          {
            query: FETCH_COMMENTS,
            variables: {entityId, entityType},
          },
        ],
        update: countUpdater(entityId, entityType, entityIds, count),
      })
    },
    [doDeleteComment]
  )

  const replyToComment: ReplyToCommentMutator = useCallback(
    (entityId, entityType, id, body, entityIds) => {
      return doReplyToComment({
        variables: {id, body},
        update: replyUpdater(entityId, entityType, entityIds),
      })
    },
    [doReplyToComment]
  )

  const deleteReply: DeleteReplyMutator = useCallback(
    (entityId, entityIds, entityType, id, replyId) => {
      return doDeleteReply({
        variables: {id, replyId},
        refetchQueries: [
          {
            query: FETCH_COMMENTS,
            variables: {entityId, entityType},
          },
        ],
        update: countUpdater(entityId, entityType, entityIds, -1),
      })
    },
    [doDeleteReply]
  )

  const [lazyFetchCommentCount, fetchCommentCountResult] =
    useLazyQuery(FETCH_COMMENT_COUNT)
  const fetchCommentCount = useCallback(
    (entityIds: string[], entityType: EntityType) =>
      lazyFetchCommentCount({variables: {entityIds, entityType}}),
    [lazyFetchCommentCount]
  )

  return {
    fetchComments,
    fetchCommentsResult,
    createComment,
    createCommentResponse,
    deleteComment,
    deleteCommentResponse,
    replyToComment,
    replyToCommentResponse,
    deleteReply,
    deleteReplyResponse,
    fetchCommentCount,
    fetchCommentCountResult,
  }
}
