import {Dispatch, useEffect, useRef, useState} from 'react'

export const useLocalStorage = <T>(
  key: string,
  defaultValue: T,
  {serialize = JSON.stringify, deserialize = JSON.parse} = {}
): [T, Dispatch<T>] => {
  const [state, setState] = useState(() => {
    if (!key) return defaultValue
  })
  // useDebugValue(`current: ${JSON.stringify(state)}`)

  const prevKeyRef = useRef(key)

  useEffect(() => {
    if (!key) return

    const valueInLocalStorage = window.localStorage.getItem(key)
    const newValue = valueInLocalStorage
      ? deserialize(valueInLocalStorage)
      : defaultValue
    // setState(newValue)
    if (newValue) setState(newValue)
  }, [defaultValue, deserialize, key])

  useEffect(() => {
    if (key && prevKeyRef.current !== key) {
      window.localStorage.removeItem(prevKeyRef.current)
    }
    if (preventSettingNull<T>(key, state, defaultValue)) return
    prevKeyRef.current = key
    window.localStorage.setItem(key, serialize(state || defaultValue))
  }, [key, state, serialize, defaultValue])

  return [state, setState]
}

export const preventSettingNull = <T>(
  key: string,
  state: T,
  defaultValue: T
) => {
  return !key || !(state || defaultValue)
}
