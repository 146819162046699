import {SelectHTMLAttributes} from 'react'
import {BaseSelectProps} from '../types'

// backwards compatible nativeSelect
export type NativeSelectType = {
  name: string
  variants?: BaseSelectProps[`variants`]
}

export const inlineSelectClassName = `
  border-transparent
  bg-transparent
  text-ui-600
  text-xs
  leading-3.5
  focus:shadow-outline-blue
  focus:outline-none
  hover:bg-ui-300
  dark:hover:bg-ui-300
  py-1
  pl-1.5
  pr-5
`

export const selectClassName = `
  py-2
  pl-3
  pr-6
  focus:outline-none
  hocus:bg-ui-100
  hocus:text-ui-1000
  focus:shadow-outline
  sm:text-sm
  sm:leading-5
  dark:bg-ui-200
  dark:border-ui-200
`

export const selectStyle = {
  backgroundImage: `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3E%3Cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3E%3C/svg%3E")`,
  backgroundSize: `1.9rem 1.9rem`,
  backgroundPosition: `right .1rem center`,
}

export const inlineSelectStyle = {
  backgroundImage: `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3E%3Cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3E%3C/svg%3E")`,
  backgroundSize: `1.4rem 1.4rem`,
  backgroundPosition: `right .1rem center`,
}

const NativeSelect = ({
  children,
  name,
  disabled,
  value,
  variants = [],
  ...props
}: SelectHTMLAttributes<HTMLSelectElement> & NativeSelectType) => {
  if (!(children && name)) return null
  const isInline = variants.includes(`inline`)
  return (
    <select
      disabled={disabled}
      className={`
        rounded-400
        transition-default
        block
        w-full
        appearance-none
        truncate
        border
        bg-no-repeat
        duration-150
        ease-in-out
        ${isInline ? inlineSelectClassName : selectClassName}
      `}
      style={isInline ? inlineSelectStyle : selectStyle}
      name={name}
      id={name}
      value={value}
      {...props}
    >
      {children}
    </select>
  )
}

export type NativeSelectOptionType = {
  children: string
}

const NativeSelectOption = ({
  children,
  ...props
}: SelectHTMLAttributes<HTMLOptionElement> & NativeSelectOptionType) => {
  return <option {...props}>{children}</option>
}

export {NativeSelect, NativeSelectOption}
export default NativeSelect
