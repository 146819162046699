import {HTMLAttributes, ReactNode, CSSProperties} from 'react'

type Props = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode
  minWidth?: CSSProperties[`minWidth`]
}

export const TableScrollWrap = ({
  children,
  className = ``,
  minWidth,
  ...props
}: Props) => {
  return (
    <div
      className={`${className} until-md:w-[calc(100vw-2rem)] overflow-x-auto overflow-y-clip`}
      {...props}
    >
      <div style={{minWidth: minWidth ?? null}}>{children}</div>
    </div>
  )
}

export default TableScrollWrap
